import accounting from 'accounting-js';
// handle all variable processing, converting,...

class VarHelper {
  erria = async (callback) => {
    try {
      const data = await callback();
      return [data, null];
    } catch(err) {
      return [null, err];
    }
  }

  imgApiStringifyGetOne = (stringifyValue) => {
    try {
      const data = JSON.parse(stringifyValue);
      return data[0].url;
    } catch(err) {
      return '';
    }
  }

  imgApiStringifyGetAll = (stringifyValue) => {
    if (!stringifyValue) return [];
    try {
      const data = JSON.parse(stringifyValue);
      return data.map(val => val.url);
    } catch(err) {
      return [];
    }
  }

  money = number => {
    return accounting.formatMoney(Number(number), { thousand: ',', decimal: '.', symbol: '£' });
  }

  genNameFromPath = (path) => {
    if (!path || typeof path !== 'string') return '';
    const parts = path.split('/');
    return parts.length === 0 ? '' : parts[parts.length - 1];
  }
}

export default new VarHelper();
