import React, { useEffect, useState } from "react";
import { ASSETS, COLOR, fSize, spacing } from "const";
import { StyleSheet, Image } from "react-native";
import {
  Col,
  Scroll,
  Text,
  Row,
  useDynamicResponsiveValue,
} from "react-quick-style-components";
import { jsPDF } from 'jspdf'
import 'svg2pdf.js'
import { Input, Button, CertSVG } from "components";
import { IScreen } from "type";
import qs from 'qs';

import './CertGenerator.css';

const CertGenerator: IScreen = () => {
  const obj = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  console.log('obj', obj);
  return (
    <div
      className="print-content middle"
    >
      <CertSVG data={obj} />
    </div>
  );
};

CertGenerator.routeInfo = {
  title: "Certificate",
  path: "/cert",
};

export default CertGenerator;