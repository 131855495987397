import React, { useState } from 'react';
import { StyleSheet, Image, View, useWindowDimensions } from 'react-native';
import { Col, Row, useDynamicResponsiveValue, Text, Scroll } from 'react-quick-style-components';
import { useNavFunc } from 'navigation';
import { IScreen } from 'type';
import { ASSETS, COLOR, fSize, ICON, SCREEN_NAME } from 'const';
import { Footer, Header, Card, Button, Input, ChatBox, QuickMessage } from "components";
import { BlurView } from 'expo-blur';

const events = [
  {
    title: 'Event 1',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris ni….',
    image: 'https://anhdepblog.com/wp-content/uploads/2020/10/kiet-tac-cua-thien-nhien-qua-nhung-hinh-anh-dep-6.jpg',
  },
  {
    title: 'Event 2',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris ni….',
    image: 'https://pdp.edu.vn/wp-content/uploads/2021/02/tong-hop-hinh-nen-thien-nhien-4k-dep-1.jpg',
  },
]

const news = [
  {
    title: 'Event 1',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris ni….',
    image: 'https://anhdepblog.com/wp-content/uploads/2020/10/kiet-tac-cua-thien-nhien-qua-nhung-hinh-anh-dep-6.jpg',
  },
  {
    title: 'Event 2',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris ni….',
    image: 'https://pdp.edu.vn/wp-content/uploads/2021/02/tong-hop-hinh-nen-thien-nhien-4k-dep-1.jpg',
  },
]

const notifications = [
  {
    title: 'Message from Vintage Acquisitions',
    read: true,
  },
  {
    title: 'Message from Vintage Acquisitions',
    read: false,
  },
  {
    title: 'Message from Vintage Acquisitions',
    read: false,
  },
  {
    title: 'Message from Vintage Acquisitions',
    read: false,
  },
]

const Dashboard: IScreen = () => {
  const { navigate } = useNavFunc();
  const { width, height } = useWindowDimensions();
  const rV = useDynamicResponsiveValue();
  const BLUR_WIDTH = rV({ xs: (width - 80) / 2, md: (width - 110) / 3, lg: (width - 200) / 5 });
  const ITEM_WIDTH = rV({ xs: (width - 40), md: (width - 90) / 2, lg: (width - 110) / 3 });
  const [email, setEmail] = useState('')
  const [friendEmail, setFriendEmail] = useState('')
  const [message, setMessage] = useState('')

  const renderContainer = ({ children }) => {
    return (
      <Col flex1>
        <Scroll height={height}>
          {children}
        </Scroll>
      </Col>
    );
  };

  const ScreenContainer = React.Fragment;

  const EventItem = ({ title, description, image, onPress }) => {
    return (
      <Row marginBottom={10} style={{ alignItems: 'flex-start' }} onPress={onPress}>
        <Image source={{ uri: image }} style={{ width: 120, height: 120, resizeMode: 'cover' }} />
        <Col flex1 marginLeft20>
          <Text color={COLOR.MAIN} bold fontSize={fSize(rV, 20)}>
            {title}
          </Text>
          <Text numberOfLines={3} color={COLOR.FONT} fontSize={fSize(rV, 18)} marginTop10>
            {description}
          </Text>
        </Col>
      </Row>
    )
  }

  const NewItem = ({ title, description, image, onPress }) => {
    return (
      <Row marginBottom={10} style={{ alignItems: 'flex-start' }} onPress={onPress}>
        <Image source={{ uri: image }} style={{ width: 120, height: 120, resizeMode: 'cover' }} />
        <Col flex1 marginLeft20>
          <Text color={COLOR.MAIN} bold fontSize={fSize(rV, 20)}>
            {title}
          </Text>
          <Text numberOfLines={3} color={COLOR.FONT} fontSize={fSize(rV, 18)} marginTop10>
            {description}
          </Text>
        </Col>
      </Row>
    )
  }

  const NotificationItem = ({ title, read }) => {
    return (
      <Row marginBottom={20}>
        <Col width18 height18 borderRadius9 backgroundColor={read ? "#835959" : "#D6D3D3"} />
        <Text numberOfLines={1} color={COLOR.FONT} fontSize={fSize(rV, 18)} marginLeft15>
          {title}
        </Text>
      </Row>
    )
  }

  const renderEvents = () => {
    return (
      <Card width={ITEM_WIDTH} height={rV({ xs: 330, lg: 350 })} title={"Events"} marginHorizontal10 marginBottom10 iconRigth={<ICON.IconPie />}>
        {events.map((val, index) => (
          <EventItem
            key={'event-' + index}
            image={val.image}
            title={val.title}
            description={val.description}
            onPress={() => navigate(SCREEN_NAME.Events)}
          />
        ))}
      </Card>
    )
  }

  const renderNews = () => {
    return (
      <Card width={ITEM_WIDTH} height={rV({ xs: 330, lg: 350 })} title={"News"} marginHorizontal10 marginBottom10 iconRigth={<ICON.IconPaper />}>
        {news.map((val, index) => (
          <NewItem
            key={'new-' + index}
            image={val.image}
            title={val.title}
            description={val.description}
            onPress={() => navigate(SCREEN_NAME.NewDetail)}
          />
        ))}
      </Card>
    )
  }

  const renderNotifications = () => {
    return (
      <Card width={ITEM_WIDTH} height={rV({ xs: 330, lg: 350 })} title={"Notifications"} marginHorizontal10 marginBottom10 iconRigth={<ICON.IconNotification />}>
        <Col flex1>
          {notifications.map((val, index) => (
            <NotificationItem
              key={'notifications-' + index}
              title={val.title}
              read={val.read}
            />
          ))}
        </Col>
        <Button marginTop20 width189 borderRadius10 alignSelf={rV({ xs: 'center', md: 'flex-end' })}
          title="VIEW ALL"
        />
      </Card>
    )
  }

  return (
    <Col flex1 backgroundColor={COLOR.BG}>
      <Header />
      <Col flex1>
        <Row>
          <Col onPress={() => navigate(SCREEN_NAME.Home)}>
            <Text marginLeft={rV({ xs: 30, md: 40, lg: 50 })} marginTop20 color={COLOR.FONT} fontSize={fSize(rV, 26)}>
              Portal Home
            </Text>
          </Col>
          <Text marginLeft8 marginTop20 color={COLOR.FONT} fontSize={fSize(rV, 26)}>
            {`> Home`}
          </Text>
        </Row>
        <Row
          style={{ flexWrap: 'wrap' }}
          marginBottom={10}
          marginTop20
          marginHorizontal={rV({ xs: 25, md: 35, lg: 45 })}
        >
          <Col onPress={() => navigate(SCREEN_NAME.Orders)} overflowH borderRadius10 marginHorizontal={rV({ xs: 5, lg: 10 })} marginBottom10 >
            <Image source={ASSETS.IMG_ORDER} style={{ width: BLUR_WIDTH, height: BLUR_WIDTH / 1.2, resizeMode: 'cover' }} />
            <BlurView intensity={30} style={[StyleSheet.absoluteFill, styles.nonBlurredContent]}>
              <Text bold color={COLOR.WHITE} fontSize={fSize(rV, 100)}>
                5
              </Text>
              <Text bold color={COLOR.WHITE} fontSize={fSize(rV, 26)}>
                Orders
              </Text>
            </BlurView>
          </Col>
          <Col onPress={() => navigate(SCREEN_NAME.Addresses)} overflowH borderRadius10 marginHorizontal={rV({ xs: 5, lg: 10 })} marginBottom10>
            <Image source={ASSETS.IMG_ADDRESS} style={{ width: BLUR_WIDTH, height: BLUR_WIDTH / 1.2, resizeMode: 'cover' }} />
            <BlurView intensity={30} style={[StyleSheet.absoluteFill, styles.nonBlurredContent]}>
              <Text bold color={COLOR.WHITE} fontSize={fSize(rV, 100)}>
                2
              </Text>
              <Text bold color={COLOR.WHITE} fontSize={fSize(rV, 26)}>
                Addresses
              </Text>
            </BlurView>
          </Col>
          <Col onPress={() => navigate(SCREEN_NAME.PaymentMethod)} overflowH borderRadius10 marginHorizontal={rV({ xs: 5, lg: 10 })} marginBottom10>
            <Image source={ASSETS.IMG_PAYMENT} style={{ width: BLUR_WIDTH, height: BLUR_WIDTH / 1.2, resizeMode: 'cover' }} />
            <BlurView intensity={30} style={[StyleSheet.absoluteFill, styles.nonBlurredContent]}>
              <Text bold color={COLOR.WHITE} fontSize={fSize(rV, 26)}>
                Payment menthod
              </Text>
            </BlurView>
          </Col>
          <Col onPress={() => navigate(SCREEN_NAME.AccountDetail)} overflowH borderRadius10 marginHorizontal={rV({ xs: 5, lg: 10 })} marginBottom10>
            <Image source={ASSETS.IMG_ACCOUNT} style={{ width: BLUR_WIDTH, height: BLUR_WIDTH / 1.2, resizeMode: 'cover' }} />
            <BlurView intensity={30} style={[StyleSheet.absoluteFill, styles.nonBlurredContent]}>
              <Text bold color={COLOR.WHITE} fontSize={fSize(rV, 26)}>
                Account Details
              </Text>
            </BlurView>
          </Col>
          <Col onPress={() => navigate(SCREEN_NAME.Logout)} overflowH borderRadius10 marginHorizontal={rV({ xs: 5, lg: 10 })} marginBottom10>
            <Image source={ASSETS.IMG_LOGOUT} style={{ width: BLUR_WIDTH, height: BLUR_WIDTH / 1.2, resizeMode: 'cover' }} />
            <BlurView intensity={30} style={[StyleSheet.absoluteFill, styles.nonBlurredContent]}>
              <Text bold color={COLOR.WHITE} fontSize={fSize(rV, 26)}>
                Logout
              </Text>
            </BlurView>
          </Col>
        </Row>
        <Row
          style={{ flexWrap: 'wrap' }}
          marginBottom={10}
          marginHorizontal={rV({ xs: 10, md: 20, lg: 20 })}
          marginTop20
        >
          {renderEvents()}
          {renderNews()}
          {renderNotifications()}
        </Row>
        <Col marginHorizontal={rV({ xs: 20, md: 30, lg: 30 })} marginBottom10>
          <QuickMessage />
        </Col>
      </Col>
      <Footer />
    </Col>
  );
};

Dashboard.routeInfo = {
  title: 'Vintage Bottlers - Dashboard',
  path: '/dashboard/vintage-bottlers',
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  blurredImage: {
    width: 192,
    height: 192,
  },
  nonBlurredContent: {
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default Dashboard;