import { ASSETS, COLOR, fSize, ICON } from 'const';
import React from 'react';
import { Col, Row, Text, usePropsStyle, Img, useDynamicResponsiveValue } from 'react-quick-style-components';

interface ICloneMeProps {
  // props here
  [anyProp: string]: any,
}

const SOCIALS = [
  { img: ICON.Facebook, link: 'https://www.facebook.com/vintageacquisitionsltd/' },
  { img: ICON.Twitter, link: 'https://twitter.com/VintageGroupUK' },
  { img: ICON.Instagram, link: 'https://www.instagram.com/vintage_acquisitions/' },
  // { img: ICON.Youtube },
]

const LINKS = [
  { title: 'Privacy & Cookie Policy', link: 'https://www.vintageacquisitions.com/privacy-policy/' },
  { title: 'Terms & Conditions', link: 'https://www.vintageacquisitions.com/terms-and-conditions/' },
  // { title: 'Cookies Policy' }
]

const CloneMe = (props: ICloneMeProps) => {
  const propStype = usePropsStyle(props);
  const rV = useDynamicResponsiveValue();
  return (
    <Col
      backgroundColor={COLOR.MAIN} width100p
      style={propStype}
    >
      <Row
        xs='100%' lg='1:4'
        paddingVertical={fSize(rV, 18)}
      >
        {/* <Col borderRightWidth={rV({ xs: 0, lg: 1 })} borderRightColor={COLOR.WHITE} middle>
          <Img source={ASSETS.LOGO} width={fSize(rV, 215 / 2)} height={fSize(rV, 114 / 2)} />
        </Col> */}
        <Col borderRightWidth={rV({ xs: 0, lg: 1 })} borderRightColor={COLOR.WHITE} height={fSize(rV, 114 / 2)} justifyContent="center">
          <Row justifyContent={rV({ xs: "center", lg: 'space-around' })} width={rV({ xs: '100%', md: '50%' })} alignSelf='center'>
            {SOCIALS.map((social, i) => (
              <a href={social.link} key={`img${i}`} target="_blank" style={{ textDecoration: 'none' }}>
                <Col 
                  marginHorizontal={rV({ xs: 10, lg: 0 })}
                  height={fSize(rV, 20)} width={fSize(rV, 30)} resizeMode="contain" middle
                >
                  <social.img />
                </Col>
              </a>
            ))}
          </Row>
        </Col>
        <Col>
          <Row justifyContent={rV({ xs: "space-around", lg: 'flex-start' })}>
            {LINKS.map((social, i) => (
              <a href={social.link} key={`link${i}`} target="_blank" style={{ textDecoration: 'none' }}>
                <Text
                  color={COLOR.WHITE}
                  marginLeft={fSize(rV, 38)}
                >{social.title}</Text>
              </a>
            ))}
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

export default CloneMe;
