import React, { useState } from 'react';
import { Col, Text, Row, useDynamicResponsiveValue, Scroll } from 'react-quick-style-components';
import { IScreen } from 'type';
import { ASSETS, COLOR, fSize, ICON, SCREEN_NAME } from 'const';
import { Image, StyleSheet, useWindowDimensions } from 'react-native';
import { Footer, Header, Button, Input, Dropdown, TableWithAction, ChatBox } from 'components';
import { TSingleOption } from 'components/Dropdown';
const SPACE_UNIT = 50;

const PaymentMethod: IScreen = () => {
  const { height } = useWindowDimensions();
  const rV = useDynamicResponsiveValue()
  const bannerHeight = rV({ xs: 150, lg: 200, xxxl: 262 })
  const iconSize = rV({ xs: 15, lg: 20, xxxl: 25 })
  const [showForm, setShowForm] = useState(false)
  const [cardNumber, setCardNumber] = useState('');
  const [startDate, setStartDate] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [cvc, setCvc] = useState('');
  const [cardType, setCardType] = useState<TSingleOption>()
  const [data, setData] = useState([
    { cardType: 'Mastercard', cardNumber: '**** **** **** **** 4564', startDate: '10/20', expiryDate: '10/23', cvc: '***' },
    { cardType: 'Visa', cardNumber: '**** **** **** **** 4564', startDate: '02/20', expiryDate: '02/23', cvc: '***' },
    { cardType: 'Visa Debit', cardNumber: '**** **** **** **** 4564', startDate: '03/17', expiryDate: '03/21', cvc: '***' },
    { cardType: 'American Express', cardNumber: '**** **** **** **** 4564', startDate: '05/18', expiryDate: '05/22', cvc: '***' },
    { cardType: 'PayPal', cardNumber: '**** **** **** **** 4564', startDate: '04/20', expiryDate: '04/23', cvc: '***' },
  ])
  const dataInput = [
    { placeholder: 'Card Number', value: cardNumber, onChageText: setCardNumber },
    { placeholder: 'Start Date', value: startDate, onChageText: setStartDate },
    { placeholder: 'Expiry Date', value: expiryDate, onChageText: setExpiryDate },
    { placeholder: 'CVC', value: cvc, onChageText: setCvc },
  ]

  const renderContainer = ({ children }) => {
    return (
      <Col>
        <Scroll height={height}>
          {children}
        </Scroll>
      </Col>
    );
  };

  const ScreenContainer = React.Fragment;

  const resetData = () => {
    setShowForm(false);
    setCardNumber('');
    setStartDate('');
    setExpiryDate('');
    setCvc('setCvc');
    setCardType(undefined);
  }

  const onAdd = () => {
    let item = {
      cardType: cardType.label, cardNumber, startDate, expiryDate, cvc
    }
    let newArr = [...data, item]
    setData(newArr)
    resetData()
  }

  const onClose = () => {
    setShowForm(false)
    resetData()
  }

  return (
    <Col flex1 backgroundColor={COLOR.BG}>
      <ScreenContainer >
        <Header />
        <Col middle height={bannerHeight}>
          <Image source={ASSETS.BANNER_ACCOUNT} style={[{ height: bannerHeight }, styles.banner]} />
          <Col zIndex99 center>
            <Text fontSize={fSize(rV, 52)} colorWhite>Payment Methods</Text>
            <Col alignSelf="center">
              <ICON.IcPattern />
            </Col>
          </Col>
        </Col>
        <Col style={{ minHeight: height - 300 }} middle marginVertical={rV({ xs: 16, lg: 30, xxxl: 40 })}>
          <Col width={rV({ xs: '90%', sm: '90%', md: '90%', lg: '90%', xl: '90%' })} >
            <Col >
              <Col style={[styles.contentBox, { paddingBottom: rV({ xs: 40, xxxl: 65 }) }]} >
                <Col height66 bgMain paddingLeft26 justifyContent="center">
                  <Text fontSize26 colorWhite>Payment Methods</Text>
                </Col>
                <TableWithAction
                  columns={[
                    { key: 'cardType', title: 'Card Type', flex: 3 },
                    { key: 'cardNumber', title: 'Card Number', flex: 6, padding: 20 },
                    { key: 'startDate', title: 'Start Date', flex: 2, padding: 20 },
                    { key: 'expiryDate', title: 'Expiry Date', flex: 2, padding: 20 },
                    { key: 'cvc', title: 'CVC', flex: 2, padding: 20 },
                  ]}
                  data={data}
                  breakpointToScroll="sm"
                  style={{ margin: 20 }}
                />
              </Col>
            </Col>
            {!!showForm &&
              <Col flex1 middle marginVertical={rV({ xs: 16, lg: 30, xxxl: 40 })}>
                <Col width={rV({ xs: '90%', sm: '80%', md: '70%', lg: '70%', xl: '70%' })} >
                  <Col style={[styles.contentBox, { paddingVertical: rV({ xs: 40, xxxl: 65 }) }]} >
                    <Col>
                      <Dropdown
                        marginHorizontal={rV({ xs: SPACE_UNIT / 3, lg: SPACE_UNIT / 2, xxxl: SPACE_UNIT })}
                        value={cardType}
                        placeholder="Card Type"
                        options={
                          [
                            { value: 1, label: 'Mastercard' },
                            { value: 2, label: 'Visa' },
                            { value: 3, label: 'Visa Debit' },
                            { value: 4, label: 'American Express' },
                            { value: 5, label: 'PayPal' },
                          ]}
                        onChange={(data) => {
                          setCardType(data)
                        }}
                      />
                      <Row xs='100%' md='50%' xxxl='50%'>
                        {dataInput.map((item, index) => {
                          return (
                            <Input marginHorizontal={rV({ xs: SPACE_UNIT / 3, lg: SPACE_UNIT / 2, xxxl: SPACE_UNIT })} marginTop0 marginBottom={rV({ xs: SPACE_UNIT / 3 })}
                              key={`id_${index}`} placeholder={item.placeholder}
                              value={item.value} onChangeText={(text) => item.onChageText(text)}
                            />
                          )
                        })}
                      </Row>

                    </Col>
                    <Row alignSelf="center">
                      <Button
                        preset="white"
                        width120
                        marginTop={rV({ xs: 30, xxxl: 45 })}
                        title="CANCEL" onPress={onClose} />
                      <Col width16 />
                      <Button
                        width120
                        marginTop={rV({ xs: 30, xxxl: 45 })}
                        title="ADD" onPress={onAdd} />
                    </Row>

                  </Col>
                </Col>
              </Col>
            }
            <Row onPress={() => setShowForm(true)} justifyContent="flex-end" alignItems="center" paddingTop22>
              <Col width={iconSize} height={iconSize}>
                <ICON.IcPlus />
              </Col>
              <Text paddingBottom2 paddingLeft10 fontSize={fSize(rV, 20)} textDecorationLine="underline">Add new payment methods</Text>
            </Row>
          </Col>
        </Col>
        <Footer />
      </ScreenContainer>
      <ChatBox />
    </Col>
  );
};

PaymentMethod.routeInfo = {
  title: 'Payment Method',
  path: '/payment-method',
};

const styles = StyleSheet.create({
  boxRightItem: {
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    shadowColor: 'rgba(0, 0, 0, 0.16)',
    shadowOffset: {
      width: 0,
      height: 3
    },
    shadowRadius: 6,
  },
  banner: {
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0
  },
  dot: {
    width: 8,
    height: 8,
    borderRadius: 4,
    backgroundColor: COLOR.MAIN,
    marginHorizontal: 10,
  },
  contentBox: {
    backgroundColor: COLOR.WHITE,
    borderColor: COLOR.MAIN,
    borderWidth: 1,
    borderTopLeftRadius: 10,
    borderBottomRightRadius: 10,
    overflow: 'hidden',
  },
  dropdown: {
    flex: 1,
    backgroundColor: COLOR.BG,
    color: COLOR.MAIN,
    padding: 0,
    justifyContent: 'center',
  }
})
export default PaymentMethod;


