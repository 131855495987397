import axios from 'axios';
const qs = require('qs');

declare global {
  interface Window {
    [key: string]: any;
  }
}

// it connects to backend api, so different token header

class RequestBEHelper {

  _afterEachCall = undefined;
  _beforeEachCall = undefined;

  // this method will be overide soon
  getToken = async () => {
    return  '';
  }

  makeHeader = async (method, isUpload = false) => {
    if (typeof this._beforeEachCall === 'function') this._beforeEachCall();
    let token = await this.getToken();
    let headers = {
      'Accept': 'application/json, text/plain, */*',
    };
    if(token) headers["authorization"] = token;
    if(method === "POST" || method === "PUT" ) headers["Content-Type"] = "application/json";
    if(isUpload) {
      headers["Content-Type"] = 'multipart/form-data';
    }
    return headers;
  }

  querify = (url, queryObject) => {
    let newUrl = url;
    if(!queryObject) return newUrl;
    newUrl += "?" + qs.stringify(queryObject);
    return newUrl;
  }

  getNoHeader = async (URL, queryObject) => {
    const urlWithQuery = this.querify(URL, queryObject);
    const res = await axios.request({
      url: urlWithQuery,
      method: 'get',
      headers: {},
    });
    return {
      headers: res.headers,
      json: async () => res.data,
      text: async () => res.data,
      data: res.data,
    };
  }

  get = async (URL, queryObject) => {
    try {
      const urlWithQuery = this.querify(URL, queryObject);
      const res = await axios.request({
        url: urlWithQuery,
        method: 'get',
        headers: await this.makeHeader("GET"),
      });
      return {
        headers: res.headers,
        json: async () => res.data,
        text: async () => res.data,
        data: res.data,
      };
    } catch(err) {
      if (err.response) {
        const res = err.response;
        return {
          headers: res.headers,
          json: async () => res.data,
          text: async () => res.data,
          data: res.data,
        }
      }
    }
    
  }

  post = async (URL, bodyObject) => {
    try {
      const res = await axios.request({
        url: URL,
        method: 'post',
        headers: await this.makeHeader("POST"),
        data: JSON.stringify(bodyObject),
      });
      return {
        headers: res.headers,
        json: async () => res.data,
        text: async () => res.data,
        data: res.data,
      };
    } catch(err) {
      if (err.response) {
        const res = err.response;
        return {
          headers: res.headers,
          json: async () => res.data,
          text: async () => res.data,
          data: res.data,
        }
      }
      
    }
  }

  postUpload = async (URL, bodyObject, callback = (e) => {}) => {
    const res =  await axios.request({
      url: URL,
      method: 'post',
      headers: await this.makeHeader("POST", true),
      data: bodyObject,
      onUploadProgress: (progressEvent) => {
        callback(progressEvent.loaded/progressEvent.total)
        // console.log(progressEvent.loaded/progressEvent.total)
      }
    })
    return {
      headers: res.headers,
      json: async () => res.data,
      text: async () => res.data,
      data: res.data,
    };
  }

  put = async (URL, bodyObject) => {
    const res = await axios.request({
      url: URL,
      method: 'put',
      headers: await this.makeHeader("PUT"),
      data: JSON.stringify(bodyObject)
    });
    return {
      headers: res.headers,
      json: async () => res.data,
      text: async () => res.data,
      data: res.data,
    };
  }

  delete = async (URL) => {
    const res = await axios.request({
      url: URL,
      method: 'delete',
      headers: await this.makeHeader("DELETE"),
      // data: JSON.stringify(bodyObject)
    });
    return {
      headers: res.headers,
      json: async () => res.data,
      text: async () => res.data,
      data: res.data,
    };
  }

}

const requestBEHelper = new RequestBEHelper();
export default requestBEHelper;