import * as React from "react"
import Svg, { Defs, LinearGradient, Stop, G, Path } from "react-native-svg"
/* SVGR has dropped some elements not supported by react-native-svg: filter */

function SvgComponent(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={575}
      height={1097}
      viewBox="0 0 575 1097"
      {...props}
    >
      <Defs>
        <LinearGradient
          id="prefix__b"
          x1={0.5}
          x2={0.5}
          y2={1}
          gradientUnits="objectBoundingBox"
        >
          <Stop offset={0} stopColor="#f3f0e9" />
          <Stop offset={1} stopColor="#e8e1c9" />
        </LinearGradient>
      </Defs>
      <G filter="url(#prefix__a)">
        <Path
          data-name="Rectangle 120"
          transform="translate(9 6)"
          fill="url(#prefix__b)"
          d="M0 0h557v1079H0z"
        />
      </G>
    </Svg>
  )
}

export default SvgComponent
