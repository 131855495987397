import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Col, Text, Row, useDynamicResponsiveValue, Scroll } from 'react-quick-style-components';
import { ChartMultiple } from '../index';
import { VarHelper } from 'helpers';
import { ASSETS, COLOR, fSize, genColor, ICON, SCREEN_NAME } from 'const';
import Store from 'store';
import { REGIONS_DES } from './regionDes';
import { DISTILLERY_DES } from './distilleryDes';
import { useWindowDimensions } from 'react-native'

const DistilleryChart = ({ data: distilleryPieChartData }) => {
  const [{ listDistilleries }, iApi] = Store.Info.createStore();
  useEffect(() => {
    iApi.getListDistilleries();
  }, []);
  const [hoverRegionData, setHoverRegionData] = useState<any>();
  const [focusedInactiveDistillery, setFocusedInactiveDistillery] = useState<any>();
  const rV = useDynamicResponsiveValue();
  const { width } = useWindowDimensions();
  const chartRef = useRef<{ getComputedData: Function }>();
  const onSideItemPress = (i) => {
    const data = chartRef.current?.getComputedData(distilleryPieChartData);
    setHoverRegionData(data[i]);
  }
  if (!distilleryPieChartData || distilleryPieChartData.length === 0) return null;
  // console.log('distilleryPieChartData', distilleryPieChartData);
  const unitSize = rV({ xs: 5, lg: 10 });
  const equalColumnWidth = rV({ xs: width / 2 - unitSize * 2, md: (width / 2 - unitSize * 2) / 2, });
  // console.log('hoverRegionData', hoverRegionData);

  const distilleryPieChartDataWithColors = useMemo(() => {
    return distilleryPieChartData.map((val, index) => {
      const find = listDistilleries.find(item => item.Name === val.title);
      if (!find) return { ...val, color: genColor(index) };
      return { ...val, color: find.Color };
    })
  }, [distilleryPieChartData, listDistilleries]);

  // console.log('distilleryPieChartDataWithColors', distilleryPieChartDataWithColors);

  const listInactiveDistilleries = Object.keys(DISTILLERY_DES).filter(val => {
    const find = distilleryPieChartData.find(item => item.title === val);
    return !find;
  });

  const displayDistellery = String(hoverRegionData?.title || focusedInactiveDistillery).trim();
  // const displayDistelleryData = DISTILLERY_DES[displayDistellery];
  const displayDistelleryData = (() => {
    const find = listDistilleries.find(val => val.Name === displayDistellery);
    if (!find) return DISTILLERY_DES[displayDistellery];
    return [
      [find.Introduction],
      ...find['Other Info'].split('\n').map(part => part.split(':').map(v => v.trim())),
    ];
  })();

  useEffect(() => {
    if (distilleryPieChartData.length === 1) {
      console.log('distilleryPieChartData', distilleryPieChartData);
      onSideItemPress(0);
    }
  }, []);

  // console.log('displayDistellery', displayDistellery);
  // console.log('displayDistelleryData', displayDistelleryData);

  return (
    <Row xs='100%' md='50%' alignItems="flex-start">
      <Col paddingHorizontal10 paddingTop30>
        <Row xs='100%' md='any:any:1' marginBottom20 stretch marginHorizontal={-rV({ xs: 10, lg: 20 })}>
          <Col padding={unitSize} width={equalColumnWidth}>
            <ChartMultiple
              size={equalColumnWidth}
              data={distilleryPieChartDataWithColors}
              // onHoverPart={setHoverRegionData}
              initialActiveSetPart={setHoverRegionData}
              onPressPart={setHoverRegionData}
              dummyClickable
              ref={chartRef}
            />
            {Boolean(hoverRegionData) && (
              <Col paddingTop30>
                <Text fontSize50 center color={hoverRegionData.color} bold>{Math.round(hoverRegionData?.percent)} %</Text>
              </Col>
            )}
          </Col>
          <Col padding={unitSize} width={equalColumnWidth} middle>
            <Col width="50%">
              <Text color={COLOR.MAIN} fontSize={fSize(rV, 20)} fontFamily='PlayfairDisplay_600SemiBold'>DISTILLERIES</Text>
              <Col flex1 paddingRight20>
                <Scroll height={equalColumnWidth}>
                  <Row xs='100%' md='100%' xxl="100%" marginTop8>
                    {distilleryPieChartDataWithColors.map((item, i) => (
                      <Row key={`chart${i}`} marginTop10 onPress={() => {
                        setFocusedInactiveDistillery(undefined);
                        onSideItemPress(i);
                      }}>
                        <Col width={25} height={25} borderRadius={3} backgroundColor={item.color} />
                        <Text marginLeft5 fontSize={fSize(rV, 16)}>{item.title}</Text>
                      </Row>
                    ))}
                    {listInactiveDistilleries.sort().map((item, i) => (
                      <Row key={`chart-inactive-${i}`} marginTop10 onPress={() => {
                        setHoverRegionData(undefined);
                        setFocusedInactiveDistillery(item);
                      }}>
                        <Col width={25} height={25} borderRadius={3} backgroundColor={COLOR.GRAY_NONE} />
                        <Text marginLeft5 fontSize={fSize(rV, 16)}>{item}</Text>
                      </Row>
                    ))}
                  </Row>
                </Scroll>
              </Col>
            </Col>
          </Col>
        </Row>
      </Col>
      <Col paddingHorizontal10 paddingTop30>
        {Boolean(hoverRegionData || focusedInactiveDistillery) && (
          <>
            <Text fontSize30 fontFamily='PlayfairDisplay_600SemiBold' color={hoverRegionData?.color || COLOR.MAIN}>{displayDistellery}</Text>
            {!!displayDistelleryData && (
              <Col marginTop20>
                {displayDistelleryData.map((row, i) => {
                  return row.length === 1 ? (
                    <Text key={'row-'+i} marginTop10 body1>
                      {row[0]}
                    </Text>
                  ) : (
                    <Text key={'row-'+i} marginTop10 body1>
                      <Text body1 bold>{row[0]}</Text>: {row[1]}
                    </Text>
                  );
                })}
              </Col>
            )}
          </>
        )}
      </Col>
    </Row>
  );
}

export default DistilleryChart;