import React, { useState } from 'react';
import { Col, Text, useDynamicResponsiveValue } from 'react-quick-style-components';
import { Input, Button, Header } from 'components'
import { ASSETS, COLOR, fSize, SCREEN_NAME, spacing } from 'const';
import { StyleSheet, Image } from 'react-native';
import { IScreen } from 'type';
import { useNavFunc } from 'navigation';
import GlobalEvent from 'js-events-listener/react';
import Store from 'store';

const ForgottenPassword: IScreen = () => {
  const { reset, navigate } = useNavFunc();
  const rV = useDynamicResponsiveValue();
  

  const [{ name : savedName, email: savedEmail }, uApi] = Store.User.createStore();

  const [name, setName] = useState(savedName);
  const [email, setEmail] = useState(savedEmail);

  const handleLogin = async () => {
    // GlobalEvent.emit('LOGIN', undefined);
    // reset(SCREEN_NAME.Home);

    if (!email) return alert('Please input your email');
    const [res, err] = await uApi.sendPasswordResetEmail(email);
    if (err) return alert(String(err));
    alert('Request sent, please check your mail box');
  };

  return (
    <Col flex1>
      <Header />
      <Col flex1 backgroundColor={COLOR.BG} middle>
        <Col width={rV({ xs: '90%', sm: '80%', md: '70%', lg: '60%', xl: '50%' })} >
          <Col style={[styles.contentBox, { paddingBottom: rV({ xs: 40, xxxl: 65 }) }]} >
            <Col alignItems="center">
              <Image source={ASSETS.LOGIN_LOGO_DARK} style={{ width: 306 * 0.8 , height: 156 * 0.8, resizeMode: 'contain' }} />
            </Col>
            <Text textAlign="center" bold fontSize={fSize(rV, 50)} colorMain paddingVertical16>LOGIN</Text>
            <Col marginHorizontal={rV({ xs: spacing[4], md: spacing[8], lg: spacing[12], xl: spacing[16] })}>
              {/* <Input label="Your name"
                value={name} onChangeText={setName}
              /> */}
              <Input label="Email Address"
                value={email} onChangeText={(text) => setEmail(text)}
              />
            </Col>
            <Button
              marginTop={rV({ xs: 30, xxxl: 59 })}
              width={250}
              title="SEND RESET MAIL" alignSelf="center" onPress={handleLogin} />
          </Col>
          <Text
            fontSize={fSize(rV, 24)} colorMain paddingTop13 textAlign="center"
            textDecorationLine='underline'
            onPress={() => navigate(SCREEN_NAME.Register)}
          >
            Don’t have an account? Register now
          </Text>
        </Col>
      </Col>
    </Col>
  );
};

ForgottenPassword.routeInfo = {
  title: 'Forgotten Password',
  path: '/forgotten-password',
}

const styles = StyleSheet.create({
  contentBox: {
    paddingTop: 32,
    backgroundColor: COLOR.WHITE,
    borderColor: COLOR.BORDER,
    borderWidth: 1,
    borderTopLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
})
export default ForgottenPassword;

