import GlobalEvent, { useGlobalState } from 'js-events-listener/react';
import { useEffect } from 'react';
import Request from './Request.utils';
import RequestBE from './RequestBE.utils';
import Persist, { PersistReady } from './Persist.utils';
import { BE_HOST, HOST } from './host';
import { IUser } from 'type';
import { VarHelper } from 'helpers';
import UserStore from './User.Store';
import { genColor } from 'const';

interface ISetters {
  setListNews?(v : any): void,
  setListEvents?(v : any): void,
  setListPages?(v : any): void,
  setListReadNotification?(v : any): void,
  setListDistilleries?(v : any): void,
  [additionSetter: string]: (v : any) => void,
}

class Info extends PersistReady {

  constructor() {
    super();
  }

  state : any = {
    listNews: [],
    listEvents: [],
    listPages: [],
    listReadNotification: [],
    listDistilleries: [],
  };

  setters : ISetters = {};

  updateState(obj, allowUndefined = true) {
    for (let key in obj) {
      if (allowUndefined || (obj[key] !== null && obj[key] !== undefined)) this.state[key] = obj[key];
    }
  }

  createStore() {
    const [listEvents, setListEvents] = useGlobalState<Array<any>>(this.state.listEvents, 'infoStore_event');
    const [listNews, setListNews] = useGlobalState<Array<any>>(this.state.listNews, 'infoStore_news');
    const [listPages, setListPages] = useGlobalState<Array<any>>(this.state.listPages, 'infoStore_pages');
    const [listReadNotification, setListReadNotification] = useGlobalState<Array<any>>(this.state.listReadNotification, 'infoStore_listReadNotification');
    const [listDistilleries, setListDistilleries] = useGlobalState<Array<any>>(this.state.listDistilleries, 'infoStore_listDistilleries');

    if (!this.setters.setListEvents) this.setters.setListEvents = setListEvents;
    if (!this.setters.setListNews) this.setters.setListNews = setListNews;
    if (!this.setters.setListPages) this.setters.setListPages = setListPages;
    if (!this.setters.setListReadNotification) this.setters.setListReadNotification = setListReadNotification;
    if (!this.setters.setListDistilleries) this.setters.setListDistilleries = setListDistilleries;

    useEffect(() => {
      this.updateState({ listEvents, listNews, listPages, listReadNotification, listDistilleries });
    }, [listEvents, listNews, listPages, listReadNotification, listDistilleries]);

    const listNoti = [...listEvents, ...listNews].sort((a, b) => a.created_at > b.created_at ? -1 : 1);

    const haveUnreadNotification = (() => {
      // if (listReadNotification.length === 0) return true;
      const listUnRead = listNoti.map(val => {
        const type = !!val['Event Name'] ? 'Event' : 'News';
        val.didRead = !!listReadNotification.find(r => r['Entity ID'] === String(val.id) && r['Type'] === type);
        return val;
      }).filter(val => !val.didRead);
      // console.log('listUnRead.length', listUnRead.length);
      return listUnRead.length > 0;
    })();

    return [
      { listEvents, listNews, listPages, listNoti, listReadNotification, haveUnreadNotification, listDistilleries },
      {
        getListNews: this.getListNews,
        getListEvents: this.getListEvents,
        getListPages: this.getListPages,
        convertTextAddressToLatLong: this.convertTextAddressToLatLong,
        addCacheLocation: this.addCacheLocation,
        getListReadNotification: this.getListReadNotification,
        markReadNoti: this.markReadNoti,
        getListDistilleries: this.getListDistilleries,
        getCaskIOwnChartType: this.getCaskIOwnChartType,
        getNewsDetail: this.getNewsDetail,
      }
    ];
  }

  _handleAccessDenied(msg) {
    if (!msg) return;
    if (msg.includes('Access Denied')) {
      UserStore.logout();
    }
  }

  getListNews = async () => {
    return VarHelper.erria(async () => {
      // const res = await Request.get(HOST + '/News', {});
      // if (!!res.data && !res.data.msg) {
      //   !!this.setters.setListNews && this.setters.setListNews(res.data);
      // } else {
      //   this._handleAccessDenied(res.data.msg);
      // }
      // return res.data;
      if (this.state.listNews.length > 0) return this.state.listNews;
      const res = await fetch('https://vintageacquisitions.com/wp-json/wp/v2/posts?categories=77&per_page=100&_embed');
      const json = await res.json();

      const decodeText = (str) => {
        var e = document.createElement('textarea');
        e.innerHTML = str;
        return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
      };

      const getTextContent = (html) => {
        var e = document.createElement('div');
        e.innerHTML = html;
        return (e.textContent || e.innerText).trim();
      };

      const list = json.map(val => ({
        Image: val._embedded["wp:featuredmedia"][0].source_url,
        Title: decodeText(val.title.rendered),
        Description: getTextContent(val.excerpt.rendered),
        Content: val.content.rendered,
        // Content: getTextContent(val.content.rendered),
        id: val.id,
        created_at: val.date,
        updated_at: val.modified,
      }));
      !!this.setters.setListNews && this.setters.setListNews(list);
      return list;
    });
  }

  getNewsDetail = async (id) => {
    return VarHelper.erria(async () => {
      if (this.state.listNews.length > 0) return this.state.listNews;
      const res = await fetch(`https://vintageacquisitions.com/wp-json/wp/v2/posts/${id}?_embed`);
      const json = await res.json();

      const decodeText = (str) => {
        var e = document.createElement('textarea');
        e.innerHTML = str;
        return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
      };

      const getTextContent = (html) => {
        var e = document.createElement('div');
        e.innerHTML = html;
        return (e.textContent || e.innerText).trim();
      };
      const val = json;
      const data = {
        Image: val._embedded?.["wp:featuredmedia"]?.[0]?.source_url,
        Title: decodeText(val.title?.rendered),
        Description: getTextContent(val.excerpt?.rendered),
        Content: val.content.rendered,
        // Content: getTextContent(val.content.rendered),
        id: val.id,
        created_at: val.date,
        updated_at: val.modified,
        link: val.link,
      };
      return data;
    });
  }

  getListEvents = async () => {
    return VarHelper.erria(async () => {
      // if (this.state.listEvents.length > 0) return this.state.listEvents;
      // const res = await RequestBE.get(BE_HOST + '/events', {});
      // if (!!res.data.success && !!res.data.data) {
      //   this.setters.setListEvents && this.setters.setListEvents(res.data.data);
      // }
      // return res.data;
      return [];
    });
  }

  getListPages = async () => {
    return VarHelper.erria(async () => {
      if (this.state.listPages.length > 0) return this.state.listPages;
      const res = await RequestBE.get(BE_HOST + '/page-info', {});
      if (!!res.data.success && !!res.data.data) {
        this.setters.setListPages && this.setters.setListPages(res.data.data);
      }
      return res.data;
    });
  }

  _POSITION_STACK_APIKEY = '93c58af72894d341383dcf7420bd0bec';
  convertTextAddressToLatLong = async address => {
    return VarHelper.erria(async () => {
      // const res = await fetch(`http://api.positionstack.com/v1/forward?access_key=${this._POSITION_STACK_APIKEY}&query=${address}&limit=1&output=json`);
      const res = await RequestBE.post(BE_HOST + '/clients/get-location-coordinate', { query: address });
      const json = await res.json();
      const { data } = json;
      // { latitude, longitude, county }
      return data.length > 0 && !Array.isArray(data[0]) ? data[0] : undefined;
    });
  }

  addCacheLocation = async (arr) => {
    if (arr.length === 0) return {};
    return VarHelper.erria(async () => {
      const res = await RequestBE.post(BE_HOST + '/clients/cache-location', arr);
      return res.data;
    });
  }

  getListReadNotification = async (email = UserStore.state.email) => {
    return VarHelper.erria(async () => {
      const res = await RequestBE.get(BE_HOST + '/users/notification-read', {});
      if (!!res.data && !res.data.msg && res.data.success !== false) {
        !!this.setters.setListReadNotification && this.setters.setListReadNotification(res.data);
      }
      return res.data;
    });
  }

  markReadNoti = async (type, id) => {
    return VarHelper.erria(async () => {
      const res = await RequestBE.post(BE_HOST + '/users/notification-read', { type, id });
      if (!!res.data && !res.data.msg && res.data.success !== false) {
        this.getListReadNotification();
      }
      return res.data;
    });
  }

  getListDistilleries = async () => {
    return VarHelper.erria(async () => {
      if (this.state.listDistilleries.length > 0) return this.state.listDistilleries;
      const res = await RequestBE.get(BE_HOST + '/distilleries', {});
      if (!!res.data.success && !!res.data.data) {
        this.setters.setListDistilleries && this.setters.setListDistilleries(res.data.data);
      }
      return res.data;
    });
  }

  getCaskIOwnChartType = async () => {
    return VarHelper.erria(async () => {
      const res = await RequestBE.get(BE_HOST + '/cask-i-own-type-chart', {});
      if (res.data.success && res.data.data) {
        // res.data.parsedData = res.data.data.map(val => {
        //   const lines = val.Data.split('\n');
        //   let Introduction = '';
        //   let OtherInfo = [];
        //   lines.forEach((line, lineIndex) => {
        //     if (lineIndex <= 1 || line.includes('Color')) return;
        //     if (line.includes(':')) {
        //       const parts = line.split(':');
        //       OtherInfo.push(parts.map(v => v.trim()));
        //     } else {
        //       Introduction += `\n${line}`;
        //     }
        //   });
        //   val.parsedData = {
        //     name: lines[0],
        //     description: [
        //       [Introduction.trim()],
        //       ...OtherInfo,
        //     ],
        //     color: !!lines[1] ? lines[1].replace('Color: ').trim() : genColor(5),
        //   }
        //   return val;
        // });
        res.data.data.forEach(val => {
          if (!val.Data) {
            val.parsedData = [];
            return;
          }
          val.parsedData = val.Data.split('===').filter(item => !!item).map(item => {
            const lines = item.split('\n');
            return {
              name: lines[0],
              color: !!lines[1] ? lines[1].replace('Color:', '').trim() : genColor(5),
              description: lines.slice(2, lines.length).map(l => {
                if (l.includes(':')) {
                  return l.split(':').map(li => li.trim());
                }
                const reg = /\s\s+/g;
                if (l.match(reg)) {
                  l = l.replace(reg, ':');
                  return l.split(':').map(li => li.trim());
                }
                return [l];
              }),
            }
          });
        })
      }
      return res.data;
    });
  }
}

export default new Info();
