import * as React from "react"
import Svg, { G, Path, Circle } from "react-native-svg"
import { fSize } from 'const';
import { useDynamicResponsiveValue } from 'react-quick-style-components';

function SvgComponent(props) {
  const rV = useDynamicResponsiveValue();
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={rV({ xs: 24, lg: 38 })}
      height={rV({ xs: 24, lg: 38 })}
      viewBox="0 0 41.235 41.239"
      {...props}
    >
      <G data-name="Group 1" fill="#fff">
        <Path
          data-name="Path 1"
          d="M35.181 6.042a20.614 20.614 0 00-30.45 27.725 7.888 7.888 0 01-3.3 3.662 1.934 1.934 0 00.55 3.644 10.655 10.655 0 001.63.122 12.309 12.309 0 007.254-2.406A20.612 20.612 0 0035.181 6.042zm-1.662 27.489a18.26 18.26 0 01-22.162 2.842 1.172 1.172 0 00-1.43.183.387.387 0 00-.1.07 10.066 10.066 0 01-6.216 2.215h-.005a11.543 11.543 0 003.54-4.7 1.2 1.2 0 00.061-.759 1.224 1.224 0 00-.288-.663 18.262 18.262 0 1126.6.811z"
        />
        <Circle
          data-name="Ellipse 3"
          cx={1.447}
          cy={1.447}
          r={1.447}
          transform="translate(19.16 19.172)"
        />
        <Circle
          data-name="Ellipse 4"
          cx={1.447}
          cy={1.447}
          r={1.447}
          transform="translate(26.623 19.172)"
        />
        <Circle
          data-name="Ellipse 5"
          cx={1.447}
          cy={1.447}
          r={1.447}
          transform="translate(11.697 19.172)"
        />
      </G>
    </Svg>
  )
}

export default SvgComponent
