import React, { useState } from 'react';
import  { Card, Input, Button } from './index';
import { ASSETS, COLOR, fSize, ICON, SCREEN_NAME } from 'const';
import { Col, Row, useDynamicResponsiveValue, Text, Scroll } from 'react-quick-style-components';

import Store from 'store';

const QuickMessage = () => {
  const rV = useDynamicResponsiveValue();
  // const [email, setEmail] = useState('')
  const [friendEmail, setFriendEmail] = useState('')
  const [message, setMessage] = useState('');
  const [{ }, mApi] = Store.Message.createStore();
  const [{ email }] = Store.User.createStore();

  const sendMessage = async () => {
    if (!friendEmail || !email || !message) return alert('Please fill all the form');
    const [res, err] = await mApi.sendMessage({
      subject: friendEmail,
      from: email,
      message: message,
    });
    alert('Thank you, your message has been sent');
    // setEmail('');
    setMessage('');
    setFriendEmail('');
    Store.Firebase.logEvent('Send Quick Message', { subject: friendEmail });
  }

  return (
    <Card title={"Quick Message"}>
      <Input marginBottom20 styleInput={{ height: 40, fontSize: fSize(rV, 16) }}
        value={email} placeholder="FROM:" onChangeText={(text) => {}}
      />
      <Input marginBottom20 styleInput={{ height: 40, fontSize: fSize(rV, 16) }}
        value={friendEmail} placeholder="SUBJECT:" onChangeText={(text) => setFriendEmail(text)}
      />
      <Input inputProps={{ multiline: true }} styleInput={{ height: 214, paddingTop: 16, fontSize: fSize(rV, 16) }}
        value={message} placeholder="MESSAGE:" onChangeText={(text) => setMessage(text)}
      />
      <Button marginTop20 width189 borderRadius10 alignSelf={rV({ xs: 'center', md: 'flex-end' })}
        title="SEND"
        onPress={sendMessage}
      />
    </Card>
  );
};

export default QuickMessage;