import "react-native-gesture-handler";
import React, { useState, useEffect } from "react";
import { Col, Img } from "react-quick-style-components";
import Navigation from "./src/navigation/Naviation";
import { ModalContainer } from "./src/components";
import {
  useFonts,
  PlayfairDisplay_400Regular,
  PlayfairDisplay_500Medium,
  PlayfairDisplay_600SemiBold,
  PlayfairDisplay_700Bold,
  PlayfairDisplay_800ExtraBold,
  PlayfairDisplay_900Black,
} from "@expo-google-fonts/playfair-display";
import { Entypo } from "@expo/vector-icons";
import { setupStyle } from "./src/const/styles";
import { DEVICE_INFO } from "./src/const";
import "./webfont";
import "./webfont_Playfair";
import Store from "./src/store";
import 'react-tippy/dist/tippy.css';
import './style.css';

Img.ImageError = () => (
  <Entypo name="image" size={24} color="rgba(0,0,0,0.3)" />
);

// setupStyle({
//   defaultFont: "PlayfairDisplay_400Regular",
//   medium: "PlayfairDisplay_500Medium",
//   bold: "PlayfairDisplay_700Bold",
//   semiBold: "PlayfairDisplay_600SemiBold",
//   thin: "PlayfairDisplay_400Regular",
//   light: "PlayfairDisplay_400Regular",
//   black: "PlayfairDisplay_900Black",
//   extraBold: "PlayfairDisplay_800ExtraBold",
// });
// Poppins, san-serif
setupStyle({
  defaultFont: "Poppins_400Regular",
  medium: "Poppins_500Medium",
  bold: "Poppins_700Bold",
  semiBold: "Poppins_600SemiBold",
  thin: "Poppins_200Thin",
  light: "Poppins_300Light",
  black: "Poppins_900Black",
  extraBold: "Poppins_800ExtraBold",
});

function AppWeb() {
  const [storeReady, setStoreReady] = useState(false);
  useEffect(() => {
    Store.Firebase.onReady().then(() => {
      if (
        Store.User.state.firebaseUser ||
        window.location.pathname === "/login" ||
        window.location.pathname === "/institutional-login-gsb" ||
        window.location.pathname === "/institutional-login-register-gsb" ||
        window.location.pathname === "/aml" ||
        window.location.pathname === "/product-cert" ||
        window.location.pathname === "/cert-generator" ||
        window.location.pathname === "/cert" 
      ) {
        setStoreReady(true);
      } else {
        window.location = "/login";
      }
    });
  }, []);
  if (!storeReady) {
    return <Col bgWhite />;
  }
  return (
    <ModalContainer>
      <Navigation />
    </ModalContainer>
  );
}

function AppMobile() {
  let [fontsLoaded] = useFonts({
    PlayfairDisplay_400Regular,
    PlayfairDisplay_500Medium,
    PlayfairDisplay_600SemiBold,
    PlayfairDisplay_700Bold,
    PlayfairDisplay_800ExtraBold,
    PlayfairDisplay_900Black,
  });
  if (!fontsLoaded) {
    return <Col bgWhite />;
  }
  return (
    <ModalContainer>
      <Navigation />
    </ModalContainer>
  );
}
console.log('20:50');
export default DEVICE_INFO.IS_WEB ? AppWeb : AppMobile;
