import React from 'react';
import { Col, Row, Scroll, Text, useDynamicResponsiveValue, usePropsStyle } from 'react-quick-style-components';
import { StyleSheet, ViewStyle } from 'react-native';
import { COLOR, fSize } from 'const';
import { useWindowDimensions } from 'react-native';

export interface IColProps {
  key: string, title?: string, flex?: number, width?: number, padding?: number, center?: boolean; noLeftBorder?: boolean,
}
interface ITableProps {
  columns: Array<IColProps>,
  data: Array<{ [key: string]: any }>,
  style?: ViewStyle,
  breakpointToScroll?: 'sm' | 'md' | 'lg' | 'xl',
  onPressAction?(item: any, index: number): void,
  btnTitle?: string,
  titleBlackBg?: boolean,
  [anyProp: string]: any,
}

const WIDTHS = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
}

const Table = (props: ITableProps) => {
  const propStype = usePropsStyle(props);
  const { width } = useWindowDimensions();
  const rV = useDynamicResponsiveValue();
  const { columns, data } = props;

  const isScroll = props.breakpointToScroll && width < WIDTHS[props.breakpointToScroll];

  const mainContent = () => {
    return (
      <Row stretch style={!isScroll ? propStype : undefined}>
        <Col flex1 style={styles.container}>
          <Row backgroundColor={props.titleBlackBg ? 'black' : '#E8E1C9'} stretch>
            {columns.map((col, i) => (
              <Col
                width={col.width} flex={col.flex} key={`col${i}`} paddingVertical={fSize(rV, 16)}
                borderLeftWidth={(!col.noLeftBorder && i !== 0) && 1} borderColor={props.titleBlackBg ? 'white' : COLOR.MAIN} paddingHorizontal={col.padding || fSize(rV, 10)}
                middle={col.center}
              >
                <Text body1 center color={props.titleBlackBg ? 'white' : undefined}>{col.title ? col.title.toUpperCase() : ''}</Text>
              </Col>
            ))}
          </Row>
          {data.map((row, i) => (
            <Row
              key={`row${i}`} stretch
              borderTopWidth={i !== 0 && 1} borderColor={COLOR.MAIN}
            >
              {columns.map((col, j) => (
                <Col
                  width={col.width} flex={col.flex} key={`cel${i}${j}`} paddingVertical={fSize(rV, 16)}
                  borderLeftWidth={(!col.noLeftBorder && j !== 0) && 1} borderColor={COLOR.MAIN} paddingHorizontal={col.padding || fSize(rV, 10)}
                  middle={col.center}
                >
                  <Text body1>{row[col.key]}</Text>
                </Col>
              ))}
            </Row>
          ))}
        </Col>
        {Boolean(props.btnTitle) &&
          <Col marginLeft={fSize(rV, 26)} justifyContent="space-around">
            <Col padding={fSize(rV, 10)}>
              <Text body1> </Text>
            </Col>
            {data.map((btn, i) => (
              <Col backgroundColor="black" padding={fSize(rV, 9)} key={`btn${i}`} onPress={() => props.onPressAction(btn, i)}>
                <Text body1 color={COLOR.WHITE}>{props.btnTitle}</Text>
              </Col>
            ))}
          </Col>
        }
      </Row>
    )
  }
  if (isScroll) {
    return (
      <Scroll horizontal style={isScroll ? propStype : undefined}>
        {mainContent()}
      </Scroll>
    );
  } else {
    return mainContent();
  }
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    overflow: 'hidden',
    borderColor: '#E8E1C9',
    borderWidth: 1,
  },
});

export default Table;
