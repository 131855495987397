export function computeChartArc(startAngle, endAngle, outerRadius, innerRadius) {
	startAngle = (startAngle * Math.PI) / 180
	endAngle = (endAngle * Math.PI) / 180
	const sinAlpha = Math.sin(startAngle)
	const cosAlpha = Math.cos(startAngle)
	const sinBeta = Math.sin(endAngle)
	const cosBeta = Math.cos(endAngle)
	const largeArc = endAngle - startAngle > Math.PI

	const P = {
		x: outerRadius + outerRadius * sinAlpha,
		y: outerRadius - outerRadius * cosAlpha,
	}

	const Q = {
		x: outerRadius + outerRadius * sinBeta,
		y: outerRadius - outerRadius * cosBeta,
	}

	const R = {
		x: outerRadius + innerRadius * sinBeta,
		y: outerRadius - innerRadius * cosBeta,
	}

	const S = {
		x: outerRadius + innerRadius * sinAlpha,
		y: outerRadius - innerRadius * cosAlpha,
	}

	return `M${P.x}, ${P.y} A${outerRadius},${outerRadius} 0 ${largeArc ? "1" : "0"} 1 ${Q.x},${Q.y} L${R.x},${
		R.y
	} A${innerRadius},${innerRadius} 0 ${largeArc ? "1" : "0"} 0 ${S.x},${S.y} Z`
}