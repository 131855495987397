import moment from 'moment-timezone';
// handle all time variable conversion, processing,...

class TimeHelper {
  tz='Europe/London'
  m = (...args) => moment(...args).tz(this.tz);
  format(t : any, f = 'DD/MM/YYYY', parsedInputFormat = undefined) {
    if (this.m(t, parsedInputFormat).isValid() === false) return t;
    return this.m(t, parsedInputFormat).format(f);
  }

  isAfter = (time, timeFormat = undefined) => {
    let now = this.m();
    let deadline = this.m(time, timeFormat);
    return now.isAfter(deadline);
  }
}

export default new TimeHelper();
