import React, { useState, useEffect } from 'react';
import { Col, Row, Text, Scroll, useDynamicResponsiveValue } from 'react-quick-style-components';
import { COLOR, DEVICE_INFO, SCREEN_NAME, fSize, ICON } from 'const';
import { LinearGradient } from 'expo-linear-gradient';
import { showModal, hideModal } from '../legacy_modal';
import Store from 'store';

interface IHeaderNotificationDropdownProps {
  navigation: {
    navigate: Function,
  },
}

const HeaderNotificationDropdown = ({ navigation } : IHeaderNotificationDropdownProps) => {
  const [{ listNoti, listReadNotification }, iApi] = Store.Info.createStore();

  const rV = useDynamicResponsiveValue();

  // const [listItems, setListItems] = useState([
  //   { didRead: false, title: 'Message from Vintage Acquisitions' },
  //   { didRead: false, title: '1 invoice' },
  //   { didRead: false, title: '2 new News Articles' },
  //   { didRead: false, title: 'Create a review' },
  // ]);

  useEffect(() => {
    iApi.getListEvents();
    iApi.getListNews();
    iApi.getListReadNotification();
  }, []);

  const renderItem = (val, i) => {
    const type = !!val['Event Name'] ? 'Event' : 'News';
    const didRead = !!listReadNotification.find(item => item['Type'] === type && item['Entity ID'] == val.id);
    return (
      <Row
        padding12
        key={'noti-dropdown-'+i}
        onPress={() => {
          hideModal();
          const type = !!val['Event Name'] ? 'Event' : 'News';
          iApi.markReadNoti(type, val.id);
          if (type === 'Event') return navigation.navigate(SCREEN_NAME.Events);
          if (type === 'News') return navigation.navigate(SCREEN_NAME.NewDetail, { id: val.id });
        }}
        onHoverStyle={{
          backgroundColor: 'rgba(0,0,0,0.05)',
        }}
      >
        <Col width18 marginRight14>
          {Boolean(!didRead) && <Col width18 height18 borderRadius9 backgroundColor="#BC0202" />}
        </Col>
        <Text fontSize={fSize(rV, 16)}>{val.Title || val['Event Name']}</Text>
      </Row>
    );
  }

	return (
    <Col bgWhite width270 activeOpacity={1} onPress={() => {}}>
      <Row justifyContent="flex-end" padding18 onPress={hideModal}>
        <ICON.Close width={14} height={14} />
      </Row>
      <Scroll height={400}>
        {listNoti.map(renderItem)}
        {/* {listNoti.sort((a, b) => a.didRead && !b.didRead ? 1 : -1).map(renderItem)} */}
      </Scroll>
    </Col>
	);
};

export const useHeaderNotificationDropdown = (navigation) => {
  const rV = useDynamicResponsiveValue();
  const headerHeight = fSize(rV, 100, { xs: 60, xl: 80, xxl: 80 });

	const showDropdown = (e) => {
    const right = e?.nativeEvent?.pageX ? DEVICE_INFO.WIDTH - e?.nativeEvent?.pageX - 135 : 70;
		showModal({
			component: (
				<HeaderNotificationDropdown navigation={navigation} />
			),
			modalProps: {
				style: {
					position: 'absolute',
					top: headerHeight,
					right: right,
				},
			},
			noWrapper: true,
			animation: 'pulse',
		})
	};

	return {
		showDropdown,
		hideDropdown: hideModal,
	};
};
