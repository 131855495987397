import React, { useEffect, useState, useRef } from 'react';
import { Col, Text, Row, useDynamicResponsiveValue } from 'react-quick-style-components';
import { useNavFunc, Reset } from 'navigation';
import { IScreen, ILocalFileUpload } from 'type';
import { HeatMap, Table, TableWithAction, Tabs } from 'components';
import { ASSETS, COLOR, fSize, ICON, SCREEN_NAME } from 'const';
import { Image, StyleSheet, useWindowDimensions } from 'react-native';
import { Footer, Header, Button, Input, ChatBox, Uploader, Dropdown, UploaderMultiple } from 'components';
import { VarHelper } from 'helpers';
import { TSingleOption } from 'components/Dropdown';
import Store from 'store'

const SPACE_UNIT = 50;

const AdminSyncData: IScreen = () => {
  const [{ firebaseUser }, uApi] = Store.User.createStore();
  const [{}, sApi] = Store.System.createStore();

  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [loading4, setLoading4] = useState(false);

  const ScreenContainer = React.Fragment;

  if (!firebaseUser || !firebaseUser.email) {
    return (
      <Reset to={SCREEN_NAME.Home} />
    )
  }

  const syncMasterData = async () => {
    if (loading1) return;
    setLoading1(true);
    const [res, err] = await sApi.syncMasterData();
    setLoading1(false);
    if (err) return alert(err);
    if (res.error) return alert(res.error);
    alert('Master Data synced successfully');
  }

  const syncDistilleryText = async () => {
    if (loading2) return;
    setLoading2(true);
    const [res, err] = await sApi.syncDistilleryText();
    setLoading2(false);
    if (err) return alert(err);
    if (res.error) return alert(res.error);
    alert('Distillery text synced successfully');
  }

  const syncTrilogyCharts = async () => {
    if (loading3) return;
    setLoading3(true);
    const [res, err] = await sApi.syncTrilogyCharts();
    setLoading3(false);
    if (err) return alert(err);
    if (res.error) return alert(res.error);
    alert('Trilogy charts synced successfully');
  }

  const syncInventory = async () => {
    if (loading4) return;
    setLoading4(true);
    const [res, err] = await sApi.syncInventory();
    setLoading4(false);
    if (err) return alert(err);
    if (res.error) return alert(res.error);
    alert('Inventory sheet synced successfully');
  }

  return (
    <Col flex1 backgroundColor={COLOR.BG}>
      <ScreenContainer >
        <Header />
        <Col height100p middle>
          <Col width300>
            <Button
              title={loading1 ? "Syncing..." : "Sync Master Data"}
              preset='main' paddingHorizontal15 marginTop20
              onPress={syncMasterData}
            />
            <Button
              title={loading2 ? "Syncing..." : "Sync Distillery Text"}
              preset='white' paddingHorizontal15 marginTop20
              onPress={syncDistilleryText}
            />
            <Button
              title={loading3 ? "Syncing..." : "Sync Trilogy Charts"}
              preset='white' paddingHorizontal15 marginTop20
              onPress={syncTrilogyCharts}
            />
            <Button
              title={loading4 ? "Syncing..." : "Sync Inventory"}
              preset='white' paddingHorizontal15 marginTop20
              onPress={syncInventory}
            />
          </Col>
        </Col>
        <Footer />
      </ScreenContainer>
      <ChatBox />
    </Col>
  );
};

AdminSyncData.routeInfo = {
  title: 'Sync Data',
  path: '/admin/sync-data',
};

export default AdminSyncData;