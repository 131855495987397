import React, { useState } from "react";
import { IScreen } from "type";
import { Input, Button, CertSVG, CertSVG2 } from "components";
import { COLOR } from "const";
import { StyleSheet } from "react-native";
import RequestBE from "store/RequestBE.utils";
import qs from 'qs';
import { saveAs } from 'file-saver';
import './CertGenerator.css';
import { useDynamicResponsiveValue, Col, Scroll, Row } from "react-quick-style-components";
import { BE_HOST } from "store/host";

const ProductCertificate: IScreen = () => {
  const rV = useDynamicResponsiveValue();
  const [isPrinting, setIsPrinting] = useState(false);
  const [page, setPage] = useState<"form" | "cert">("form");
  const [form, setForm] = useState({
    clientName: "",
    caskNumber: "",
    purchasedOn: "",
    onBehalf: "",
    quantity: "0"
  });
  const [loading, setLoading] = useState(false);

  const handleFormChange = (key: keyof typeof form) => (value: string) =>
    setForm((f) => ({ ...f, [key]: value }));

  const fetchProduct = async () => {
    try{
      setLoading(true);
      const res = await RequestBE.post(BE_HOST + '/hubspot/get-product', { caskNo: form.caskNumber });

      if (res.data) {
        setForm({
          ...form,
          ...res.data
        });
        setLoading(false);
      }
    }catch(err){
      setLoading(false);
      console.error(err);
    }
  };

  if (page === 'cert'){
    return (
      <div className="print-container">
        <div
          className="print-content"
        >
          <CertSVG2 data={form} />
        </div>
        <div className="button-container">
          <Button
            marginTop={rV({ xs: 30, xxxl: 59 })}
            title={isPrinting ? "Printing..." : "Print PDF"}
            width192
            alignSelf="center"
            onPress={async () => {
              setIsPrinting(true);
              try {
                const url = `https://api.vintageacquisitions.com/b/api/clients/gen-cert?${qs.stringify(form)}`;
                const res = await fetch(url);
                const blob = await res.blob();
                await saveAs(blob, 'certificate.pdf');
              } catch(err) {
                alert('An error has occured. Please try again');
              }
              setIsPrinting(false);
            }}
          />
        </div>
      </div>
    );
  }

  return (
    <Col flex1>
      <Scroll flex1 backgroundColor={COLOR.BG}>
        <Col
          width={rV({ xs: "90%", sm: "80%", md: "70%", lg: "60%", xl: "50%" })}
          alignSelf="center"
        >
          <Row
            style={[
              styles.contentBox,
              { paddingBottom: rV({ xs: 40, xxxl: 65 }), marginTop: 30, },
            ]}
            xs='100%'
            md='50%'
          >
            <Input
              label="Client Name"
              value={form.clientName}
              onChangeText={handleFormChange("clientName")}
              alignSelf="center"
              width="80%"
            />

            <Input
              marginTop10
              label="Cask Number"
              value={form.caskNumber}
              onChangeText={handleFormChange("caskNumber")}
              alignSelf="center"
              width="80%"
            />
            <Input
              marginTop10
              label="Purchased On:"
              value={form.purchasedOn}
              onChangeText={handleFormChange("purchasedOn")}
              alignSelf="center"
              width="80%"
            />
            <Input
              marginTop10
              label="On Behalf-Of:"
              value={form.onBehalf}
              onChangeText={handleFormChange("onBehalf")}
              alignSelf="center"
              width="80%"
            />
            <Input
              marginTop10
              label="Quantity:"
              value={form.quantity}
              onChangeText={handleFormChange("quantity")}
              alignSelf="center"
              width="80%"
            />
          </Row>
          <Button
            marginTop={rV({ xs: 30, xxxl: 59 })}
            title="Generate"
            width192
            alignSelf="center"
            style={loading && styles.disabledBtn}
            onPress={() => !loading && fetchProduct().then(() => setPage('cert'))}
          />
        </Col>
      </Scroll>
    </Col>
  )
};

ProductCertificate.routeInfo = {
  title: "Product Certificate",
  path: "/product-cert"
};

const styles = StyleSheet.create({
  contentBox: {
    paddingTop: 32,
    backgroundColor: COLOR.WHITE,
    borderColor: COLOR.BORDER,
    borderWidth: 1,
    borderTopLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
  disabledBtn: {
    backgroundColor: '#888',
    borderColor: '#888'
  }
});

export default ProductCertificate;
