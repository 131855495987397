import * as React from "react"
import Svg, { G, Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={35.218}
      height={35.64}
      viewBox="0 0 35.218 35.64"
      {...props}
    >
      <G data-name="Group 162">
        <Path
          data-name="Path 296"
          d="M34.787 33.105L24.772 22.97A14.13 14.13 0 0013.94-.003a14.108 14.108 0 000 28.215 13.772 13.772 0 008.76-3.142l10.013 10.135a1.454 1.454 0 002.075 0 1.5 1.5 0 00-.001-2.1zm-20.847-7.86a11.138 11.138 0 010-22.275 11.138 11.138 0 010 22.275z"
          fill="#fff"
        />
      </G>
    </Svg>
  )
}

export default SvgComponent
