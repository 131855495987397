import React, { useState, useEffect, useRef } from 'react';
import { Col, Text, Row, useDynamicResponsiveValue } from 'react-quick-style-components';
import { ChartMultiple } from '../index';
import { VarHelper } from 'helpers';
import { ASSETS, COLOR, fSize, ICON, SCREEN_NAME } from 'const';

import { REGIONS_DES } from './regionDes';
import { useWindowDimensions } from 'react-native'


const formatPieValue = ({ value, isMoneyValue, isPercent }) => {
  // console.log('formatPieValue', value);
  return isMoneyValue ? VarHelper.money(value)
    : isPercent ? `${value} %` : value;
}

const RegionChart = ({ data: regionPieChartData }) => {
  const [hoverRegionData, setHoverRegionData] = useState<any>();
  const rV = useDynamicResponsiveValue();
  const { width } = useWindowDimensions();
  const chartRef = useRef<{ getComputedData: Function }>();
  const onSideItemPress = (i) => {
    const data = chartRef.current?.getComputedData(regionPieChartData);
    setHoverRegionData(data[i]);
  }
  if (!regionPieChartData || regionPieChartData.length === 0) return null;

  const unitSize = rV({ xs: 5, lg: 10 });
  const equalColumnWidth = rV({ xs: width / 2 - unitSize * 2, md: (width / 2 - unitSize * 2) / 2, });
  // console.log('hoverRegionData', hoverRegionData);
  console.log('regionPieChartData', regionPieChartData);
  return (
    <Row xs='100%' md='50%' alignItems="flex-start">
      <Col paddingHorizontal10 paddingTop30>
        <Row xs='100%' md='any:any:1' marginBottom20 stretch marginHorizontal={-rV({ xs: 10, lg: 20 })}>
          <Col padding={unitSize} width={equalColumnWidth}>
            <ChartMultiple
              size={equalColumnWidth}
              ref={chartRef}
              data={regionPieChartData}
              // onHoverPart={setHoverRegionData}
              onPressPart={setHoverRegionData}
              initialActiveSetPart={setHoverRegionData}
              dummyClickable
            />
            {Boolean(hoverRegionData) && (
              <Col paddingTop30>
                <Text fontSize50 center color={hoverRegionData.color} bold>{Math.round(hoverRegionData?.percentageOnPortfolio * 100)} %</Text>
                <Text body1 center>{formatPieValue({ isMoneyValue: true, isPercent: false, value: hoverRegionData?.portfolioValue })}</Text>
              </Col>
            )}
          </Col>
          <Col padding={unitSize} width={equalColumnWidth} middle>
            <Col width="50%">
              <Text color={COLOR.MAIN} fontSize={fSize(rV, 20)} fontFamily='PlayfairDisplay_600SemiBold'>REGIONS</Text>
              <Row xs='100%' md='100%' xxl="100%" marginTop8>
                {regionPieChartData.map((item, i) => (
                  <Row key={`chart${i}`} marginTop10 onPress={() => onSideItemPress(i)}>
                    <Col width={25} height={25} borderRadius={3} backgroundColor={item.color} />
                    <Text marginLeft5 fontSize={fSize(rV, 16)}>{item.title} ({formatPieValue({ isMoneyValue: true, isPercent: false, value: item?.portfolioValue })})</Text>
                  </Row>
                ))}
              </Row>
            </Col>
          </Col>
        </Row>
      </Col>
      <Col paddingHorizontal10 paddingTop30>
        {hoverRegionData && (
          <>
            <Text fontSize30 color={hoverRegionData.color} fontFamily='PlayfairDisplay_600SemiBold'>{hoverRegionData.title}</Text>
            <Text body1 marginTop20>
              {REGIONS_DES[hoverRegionData.title]}
            </Text>
          </>
        )}
      </Col>
    </Row>
  );
}

export default RegionChart;