import React from "react"
import { COLOR, ICON, fSize } from 'const';
import { ViewStyle, StyleSheet } from 'react-native';
import { Col, Text, useDynamicResponsiveValue, Row, usePropsStyle } from 'react-quick-style-components';

interface IChatProps {
  style?: ViewStyle;
  onPress?: () => void
  [anyProp: string]: any;
}

const ChatBox = (props: IChatProps) => {
  const propStype = usePropsStyle(props);
  const rV = useDynamicResponsiveValue();
  const { style, onPress } = props;
  return null;
  return (
    <Col
      middle right={rV({ xs: 20, lg: 30 })} bottom={rV({ xs: 50, md: 60, lg: 130 })} 
      height={rV({ xs: 44, lg: 60 })} width={rV({ xs: 44, lg: 60 })} 
      borderRadius={rV({ xs: 22, lg: 30 })} 
      style={[styles.container, propStype, style]}
      onPress={onPress}
    >
     <ICON.IconChat />
    </Col>
  );
};

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    backgroundColor: 'black',
  },
});

export default ChatBox;
