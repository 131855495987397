import React, { useEffect } from 'react';
import { Col, Text, useDynamicResponsiveValue } from 'react-quick-style-components';
import { IScreen } from 'type';
import { Table } from 'components';
import { ASSETS, COLOR, fSize, ICON } from 'const';
import { Image, StyleSheet, useWindowDimensions } from 'react-native';
import { Footer, Header, ChatBox, Breadcrumbs } from 'components';
import { VarHelper } from 'helpers';
import Store from 'store'
import moment from 'moment';

const Invoices: IScreen = () => {
  const { height } = useWindowDimensions();
  const rV = useDynamicResponsiveValue()
  const bannerHeight = rV({ xs: 150, lg: 200, xxxl: 262 })

  const [{ listPages }, iApi] = Store.Info.createStore();
  const [{ invoices }, cApi] = Store.Client.createStore()

  useEffect(() => {
    iApi.getListPages();
    cApi.getInvoices()
  }, []);

  const pageData = listPages.find(val => val.Title === 'Invoices');
  const pageDataImages = VarHelper.imgApiStringifyGetAll(pageData?.Images);

  const ScreenContainer = React.Fragment;

  // console.log(invoices)

  return (
    <Col flex1 backgroundColor={COLOR.BG}>
      <ScreenContainer >
      <Header />
        <Col middle height={bannerHeight}>
        <Image source={pageDataImages.length > 0 ? { uri: pageDataImages[0] } : ASSETS.BANNER_ACCOUNT} style={[{ height: bannerHeight }, styles.banner]} />
          <Col zIndex99 center>
            <Text fontSize={fSize(rV, 52)} colorWhite>Invoices</Text>
            <Col alignSelf="center">
              <ICON.IcPattern />
            </Col>
          </Col>
        </Col>
        <Breadcrumbs
          title="Invoices"
          style={{
            marginLeft: rV({ xs: 4, lg: 40, xxxl: 60 }),
            marginBottom: 20,
          }}
        />
        <Col style={{ minHeight: height - 300, width: '80%', alignItems: 'center', alignSelf: 'center' }}>
          {invoices !== null ? (
            <Table
              columns={[
                { key: 'number', title: 'Invoice Number', width: 150, center: true, padding: 20 },
                { key: 'date', title: 'Date', width: 150, center: true, padding: 20 },
                { key: 'due', title: 'Due Date', width: 150, center: true, padding: 20 },
                { key: 'total', title: 'Total', width: 150, center: true, padding: 20 },
                { key: 'space', title: '', flex: 1, noLeftBorder: true },
                { key: 'status', title: 'Status', width: 100, center: true, noLeftBorder: true, padding: 20  },
              ]}
              data={invoices.map(invoice => ({
                number: invoice.number,
                total: `${invoice.amount} ${invoice.currencyCode}`,
                date: moment(invoice.date).format('DD MMM, YYYY'),
                due: moment(invoice.dueDate).format('DD MMM, YYYY'),
                status: invoice.status === "PAID" ?"Paid" : "Not Paid"
              }))}
              style={{ margin: 20 }}
            />
          ) : <Text center margin30>No Invoices Found</Text>}
        </Col>
        <Footer />
      </ScreenContainer>
      <ChatBox />
    </Col>
  );
};

Invoices.routeInfo = {
  title: 'Invoices',
  path: '/invoice',
};

const styles = StyleSheet.create({
  banner: {
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0
  },
})

export default Invoices;