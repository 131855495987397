import React, { useEffect } from 'react';
import { Col, Text, Scroll, useDynamicResponsiveValue } from 'react-quick-style-components';
import { useNavFunc } from 'navigation';
import { IScreen } from 'type';
import { Table, TableWithAction } from 'components';
import { ASSETS, COLOR, fSize, ICON, SCREEN_NAME } from 'const';
import { Image, StyleSheet, useWindowDimensions } from 'react-native';
import { Footer, Header, Card, Input, ChatBox } from 'components';
import Store from 'store';
import { VarHelper } from 'helpers';

const Addresses: IScreen = () => {
  const { width, height } = useWindowDimensions();
  const { route } = useNavFunc();
  const rV = useDynamicResponsiveValue()
  const bannerHeight = rV({ xs: 150, lg: 200, xxxl: 262 })

  const [{ listClients }, cApi] = Store.Client.createStore();
  const [{ email }] = Store.User.createStore();

  useEffect(() => {
    if (listClients.length === 0) cApi.getListClients();
  }, []);

  const clientInfo = (() => {
    if (listClients.length === 0 || !email) return;
    const findClient = listClients.find(val => val.Email === email);
    if (!findClient) return;
    return findClient;
  })();

  const [{ listPages }, iApi] = Store.Info.createStore();

  useEffect(() => {
    iApi.getListPages();
  }, []);

  const pageData = listPages.find(val => val.Title === 'Addresses');
  const pageDataImages = VarHelper.imgApiStringifyGetAll(pageData?.Images);

  const renderContainer = ({ children }) => {
    return (
      <Col>
        <Scroll height={height}>
          {children}
        </Scroll>
      </Col>
    );
  };

  const ScreenContainer = React.Fragment;

  return (
    <Col flex1 backgroundColor={COLOR.BG}>
      <ScreenContainer >
        <Header />
        <Col middle height={bannerHeight}>
        <Image source={pageDataImages.length > 0 ? { uri: pageDataImages[0] } : ASSETS.BANNER_ACCOUNT} style={[{ height: bannerHeight }, styles.banner]} />
          <Col zIndex99 center>
            <Text fontSize={fSize(rV, 52)} colorWhite>Addresses</Text>
            <Col alignSelf="center">
              <ICON.IcPattern />
            </Col>
          </Col>
        </Col>
        <Col style={{ minHeight: height - 300 }} marginVertical={rV({ xs: 16, lg: 30, xxxl: 40 })} paddingHorizontal={rV({ xs: 4, lg: 20, xxxl: 40 })}>
          {Boolean(!clientInfo) ? (
            <Text>No info</Text>
          ) : (
            <Card title="Addresses">
              <TableWithAction
                columns={[
                  { key: 'Customer', title: 'NAME', flex: 0.5 }, // center: true
                  { key: 'Address', title: 'ADDRESS LINE 1', flex: 2, padding: 20 },
                  { key: 'Telephone', title: 'TELEPHONE', flex: 0.8, padding: 20 },
                  { key: 'Email', title: 'EMAIL', flex: 1 },
                ]}
                data={[clientInfo]}
                onPressAction={(item, i) => alert(i)}
                breakpointToScroll="sm"
                style={{ margin: 20 }}
              />
            </Card>
          )}
        </Col>
        <Footer />
      </ScreenContainer>
      <ChatBox />
    </Col>
  );
};

Addresses.routeInfo = {
  title: 'Addresses',
  path: '/addresses',
};

const styles = StyleSheet.create({
  banner: {
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0
  },
})

export default Addresses;