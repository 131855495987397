import * as React from "react"
import Svg, { G, Path } from "react-native-svg";
import { fSize } from 'const';
import { useDynamicResponsiveValue } from 'react-quick-style-components';

function SvgComponent(props) {
  const rV = useDynamicResponsiveValue();
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={fSize(rV, 42)}
      height={fSize(rV, 42)}
      viewBox="0 0 42 42"
      {...props}
    >
      <G data-name="Group 91">
        <G data-name="Group 90" fill="#fff">
          <Path
            data-name="Path 159"
            d="M19.25 21.576V2.625a.875.875 0 00-.875-.875C8.242 1.75 0 10.778 0 21.875A20.148 20.148 0 0020.125 42c4.965 0 7.651-.9 11.056-3.7a.875.875 0 00.135-1.213zm.875 18.674A18.4 18.4 0 011.75 21.875c0-9.807 6.988-17.845 15.75-18.35v18.35a.87.87 0 00.185.537L29.4 37.478c-2.76 2.111-5.065 2.772-9.275 2.772z"
          />
          <Path
            data-name="Path 160"
            d="M21.875 21h19.25a.875.875 0 00.875-.875A20.148 20.148 0 0021.875 0 .875.875 0 0021 .875v19.25a.875.875 0 00.875.875zm.875-19.229A18.4 18.4 0 0140.229 19.25H22.75z"
          />
          <Path
            data-name="Path 161"
            d="M41.128 22.75h-17.5a.874.874 0 00-.681 1.424l11.375 14.082a.869.869 0 00.62.324h.061a.88.88 0 00.6-.234 20.2 20.2 0 006.4-14.723.875.875 0 00-.875-.873zm-6.05 13.657L25.46 24.5h14.768a18.463 18.463 0 01-5.152 11.907z"
          />
        </G>
      </G>
    </Svg>
  )
}

export default SvgComponent
