import React, { useEffect, useState } from "react";
import { ASSETS, COLOR, fSize, spacing } from "const";
import { StyleSheet, Image } from "react-native";
import {
  Col,
  Scroll,
  Text,
  Row,
  useDynamicResponsiveValue,
} from "react-quick-style-components";
import { Input, Button, CertSVG2 } from "components";
import { IScreen } from "type";
import qs from 'qs';
import { saveAs } from 'file-saver';

import './CertGenerator.css';

const CertGenerator: IScreen = () => {
  const rV = useDynamicResponsiveValue();
  const [isPrinting, setIsPrinting] = useState(false);
  const [message, setMessage] = useState(null);
  const [page, setPage] = useState<'form' | 'cert'>('form');
  const [form, setForm] = useState({
    clientName: "",
    quantity: "",
    caskType: "",
    caskNumber: "",
    ola: "",
    abv: "",
    ays: "",
    spirit: "",
    bondAt: "",
    purchasedOn: "",
  });

  const handleFormChange = (key: keyof typeof form) => (value: string) =>
    setForm((f) => ({ ...f, [key]: value }));

  const submitForm = async () => {
    if (
      !form.clientName ||
      !form.quantity ||
      !form.caskType ||
      !form.caskNumber ||
      !form.ola || 
      !form.abv || 
      !form.ays || 
      !form.spirit || 
      !form.bondAt || 
      !form.purchasedOn
    ) {
      setMessage("Please fill in all fields");
      return;
    }
    

  };

  if (page === 'cert')
  return (
    // <Col flex1>
    //   <Scroll flex1 backgroundColor={COLOR.BG}>
    //     <Col
    //       width={rV({ xs: "90%", sm: "80%", md: "70%", lg: "60%", xl: "50%" })}
    //       alignSelf="center"
    //       middle
    //     >
    //       <CertSVG data={form} />
    //     </Col>
    //     <Button
    //         marginTop={rV({ xs: 30, xxxl: 59 })}
    //         title="Print PDF"
    //         width192
    //         alignSelf="center"
    //         onPress={() => {
    //           window.print();
    //         }}
    //       />
    //   </Scroll>
    // </Col>

    // Use pure reactjs component here to use className and media query print
    <div className="print-container">
      <div
        className="print-content"
      >
        <CertSVG2 data={form} />
      </div>
      <div className="button-container">
        <Button
          marginTop={rV({ xs: 30, xxxl: 59 })}
          title={isPrinting ? "Printing..." : "Print PDF"}
          width192
          alignSelf="center"
          onPress={async () => {
            // window.print();
            // window.open(`/cert?${qs.stringify(form)}`)
            setIsPrinting(true);
            try {
              // const res = await fetch(`https://cms.vintageacquisitions.com/b/api/clients/gen-cert?${qs.stringify(form)}`);
              // const json = await res.json();
              // if (json.success) {
              //   window.open(json.data, '__blank');
              // } else {
              //   alert(json.error);
              // }
              const url = `https://api.vintageacquisitions.com/b/api/clients/gen-cert?${qs.stringify(form)}`;
              const res = await fetch(url);
              const blob = await res.blob();
              await saveAs(blob, 'certificate.pdf');
            } catch(err) {
              alert('An error has occured. Please try again');
            }
            setIsPrinting(false);
          }}
        />
      </div>
    </div>
  )

  if (page === 'form')
  return (
    <Col flex1>
      <Scroll flex1 backgroundColor={COLOR.BG}>
        <Col
          width={rV({ xs: "90%", sm: "80%", md: "70%", lg: "60%", xl: "50%" })}
          alignSelf="center"
        >
          <Row
            style={[
              styles.contentBox,
              { paddingBottom: rV({ xs: 40, xxxl: 65 }), marginTop: 30, },
            ]}
            xs='100%'
            md='50%'
          >
            <Input
              label="Client Name"
              value={form.clientName}
              onChangeText={handleFormChange("clientName")}
              alignSelf="center"
              width="80%"
            />

            <Input
              marginTop10
              label="Quantity"
              value={form.quantity}
              onChangeText={handleFormChange("quantity")}
              alignSelf="center"
              width="80%"
            />

            <Input
              marginTop10
              label="Cask Type"
              value={form.caskType}
              onChangeText={handleFormChange("caskType")}
              alignSelf="center"
              width="80%"
            />

            <Input
              marginTop10
              label="Cask Number"
              value={form.caskNumber}
              onChangeText={handleFormChange("caskNumber")}
              alignSelf="center"
              width="80%"
            />

            <Input
              marginTop10
              label="OLA"
              value={form.ola}
              onChangeText={handleFormChange("ola")}
              alignSelf="center"
              width="80%"
            />

            <Input
              marginTop10
              label="ABV"
              value={form.abv}
              onChangeText={handleFormChange("abv")}
              alignSelf="center"
              width="80%"
            />

            <Input
              marginTop10
              label="AYS"
              value={form.ays}
              onChangeText={handleFormChange("ays")}
              alignSelf="center"
              width="80%"
            />

            <Input
              marginTop10
              label="Spirit / Distillery"
              value={form.spirit}
              onChangeText={handleFormChange("spirit")}
              alignSelf="center"
              width="80%"
            />

            <Input
              marginTop10
              label="Stored Under Bond at:"
              value={form.bondAt}
              onChangeText={handleFormChange("bondAt")}
              alignSelf="center"
              width="80%"
            />

            <Input
              marginTop10
              label="Purchased On:"
              value={form.purchasedOn}
              onChangeText={handleFormChange("purchasedOn")}
              alignSelf="center"
              width="80%"
            />
          </Row>
          {message && (
            <Text center>{message}</Text>
          )}
          <Button
            marginTop={rV({ xs: 30, xxxl: 59 })}
            title="Generate"
            width192
            alignSelf="center"
            onPress={() => setPage('cert')}
          />
        </Col>
      </Scroll>
    </Col>
  );
};

CertGenerator.routeInfo = {
  title: "Certificate Generator",
  path: "/cert-generator",
};

const styles = StyleSheet.create({
  contentBox: {
    paddingTop: 32,
    backgroundColor: COLOR.WHITE,
    borderColor: COLOR.BORDER,
    borderWidth: 1,
    borderTopLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
});

export default CertGenerator;


