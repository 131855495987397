import * as React from "react"
import Svg, { G, Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={49.227}
      height={39.997}
      viewBox="0 0 49.227 39.997"
      {...props}
    >
      <G data-name="twitter (1)">
        <G data-name="Group 115">
          <Path
            data-name="Path 253"
            d="M49.227 4.735a21.041 21.041 0 01-5.815 1.594 10.035 10.035 0 004.44-5.578 20.168 20.168 0 01-6.4 2.443 10.091 10.091 0 00-17.457 6.9 10.392 10.392 0 00.234 2.3A28.565 28.565 0 013.427 1.84a10.095 10.095 0 003.1 13.488 9.966 9.966 0 01-4.56-1.243v.115a10.138 10.138 0 008.085 9.916 10.073 10.073 0 01-2.646.332 8.923 8.923 0 01-1.906-.176 10.188 10.188 0 009.43 7.03 20.278 20.278 0 01-12.513 4.3A18.9 18.9 0 010 35.468 28.411 28.411 0 0015.482 40c18.571 0 28.724-15.383 28.724-28.718 0-.446-.015-.877-.037-1.3a20.133 20.133 0 005.058-5.247z"
            fill={props.fill || "#fff"}
          />
        </G>
      </G>
    </Svg>
  )
}

export default SvgComponent
