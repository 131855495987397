import * as React from "react"
import Svg, { G, Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={44.979}
      height={38.5}
      viewBox="0 0 44.979 38.5"
      {...props}
    >
      <G
        data-name="Group 122"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth={3}
      >
        <Path data-name="Line 51" d="M1.5 1.5h41.979" />
        <Path data-name="Line 52" d="M1.5 19.25h41.979" />
        <Path data-name="Line 53" d="M1.5 37h41.979" />
      </G>
    </Svg>
  )
}

export default SvgComponent
