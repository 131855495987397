import React, { RefObject, memo, useState, createRef, useRef, useEffect, Fragment } from 'react';
import { Col, Row, Text, usePropsStyle, useDynamicResponsiveValue, Img } from 'react-quick-style-components';
import { useWindowDimensions, StyleSheet } from 'react-native';
import { DEVICE_INFO, fSize, ASSETS, ICON, COLOR, SCREEN_NAME } from 'const';
import { TextInput } from 'react-native-gesture-handler';
import { useNavFunc } from 'navigation';
import { headerNavs } from './headerNav';
import { useHeaderSideMenuDrawer } from './HeaderSideMenuDrawer';
import { useHeaderNotificationDropdown } from './HeaderNotificationDropdown';
import GlobalEvent, { useEvent } from 'js-events-listener/react';
import Store from 'store';
import { FontAwesome, FontAwesome5 } from '@expo/vector-icons';
import UseMemo from '../UseMemo';
import BellAnim from './BellAnim/BellAnim'

interface IHeaderProps {
  // props here
  searchKeyword?: string,
  [anyProp: string]: any,
}

let hardcodeLogo = {
  img: ASSETS.LOGO,
  ratio: 215/114,
  name: 'Home',
};

const Header = (props : IHeaderProps) => {
  const [{ user, token, name, firebaseUser }, uApi] = Store.User.createStore();
  const [{ haveUnreadNotification }, iApi] = Store.Info.createStore();
  const [{ saleManagerData, listClients }, cApi] = Store.Client.createStore();
  const { navigate, navigation, route, reset } = useNavFunc();
  const { index : indexRoute } = navigation.dangerouslyGetState() || {};
  const propStype = usePropsStyle(props);
  const rV = useDynamicResponsiveValue();
  const viewport = rV({ xs: 'xs', md: 'md', lg: 'lg', xl: 'xl', xxl: 'xxl', xxxl: 'xxxl' });
  // const MENU_FONT_SIZE = rV({ xs: 16, md: 14, lg: 16, xl: 20, xxl: 20, xxxl: 25 });
  // const MENU_FONT_SIZE = viewport === 'lg' ? 12 : fSize(rV, 25)
  const MENU_FONT_SIZE = 14
  // const { height } = useWindowDimensions();
  const [logoImage, setLogoImage] = useState(hardcodeLogo);

  // console.log('haveUnreadNotification', haveUnreadNotification);

  // useEffect(() => {
  //   console.log(route.name);
  //   const unsubscribe = navigation.addListener('focus', () => {
  //     if (route.name === SCREEN_NAME.Home) {
  //       setLogoImage({
  //         img: ASSETS.LOGO,
  //         ratio: 215/114,
  //         name: 'Home',
  //       });
  //     }
  //   });
  //   return unsubscribe;
    
  // }, [])

  useEffect(() => {
    iApi.getListNews();
    iApi.getListReadNotification();
  }, []);

  useEffect(() => {
    if (!!firebaseUser) {
      cApi.getSaleManagerData();
      cApi.getListClients();
    }
  }, [firebaseUser]);

  const [showSearch, setShowSearch] = useState(!!props.searchKeyword);
  const [searchKeyword, setSearchKeyword] = useState(props.searchKeyword || '');
  const [hasNoti, setHasNoti] = useState<boolean>(true);
  const isAuth = !!firebaseUser && !!firebaseUser.email;

  useEffect(() => {
    if (!!props.searchKeyword) {
      setShowSearch(true);
      setSearchKeyword(props.searchKeyword);
    }
  }, [props.searchKeyword]);

  useEvent('CHANGE_LOGO', (newLogo : any)=> {
    hardcodeLogo = newLogo;
    setLogoImage(newLogo);
  }, []);

  const headerHeight = fSize(rV, 100, { xs: 60, xl: 80, xxl: 80 });
  // the width of left content is aligned with login box when the search bar open
  const loginBoxWidthPercent = rV({ xs: 90, sm: 80, md: 70, lg: 60, xl: 50 });
  const leftBoxWidthPercent = (100 - loginBoxWidthPercent) / 2;
  const edgeSize = rV({ xs: '5%', md: fSize(rV, 70) });

  const searchRef = useRef<any>();

  const { showDrawer } = useHeaderSideMenuDrawer(navigation, route, logoImage, indexRoute);
  const { showDropdown } = useHeaderNotificationDropdown(navigation);

  useEvent('SHOW_SIDEBAR_MENU', () => {
    showDrawer();
  }, [showDrawer]);

  const toggleSearch = () => {
    setShowSearch(v => {
      const newValue = !v;
      if (newValue) {
        setTimeout(() => {
          searchRef.current && searchRef.current?.focus();
        }, 300);
      }
      return newValue;
    })
  }

  // console.log('myAm', myAm);
  const myAM = listClients.length > 0 ? listClients[0]['Account Manager'] : '';
  // const myAM = 'GSB';

  const  edgeSpace = (p = {}) => (
    <Row width={edgeSize} {...p} />
  );
  const logo = (p = {}) => (
    <Row bgMain {...p} onPress={() => reset(SCREEN_NAME.Home)}>
      <Img source={logoImage.img} padding5 width={ headerHeight * 0.6 * logoImage.ratio } height={headerHeight * 0.6} resizeMode="contain" />
      {myAM === 'GSB' && (
        <Img marginLeft={30} source={ASSETS.GSB_LOGO_WHITE} padding5 width={ headerHeight * 0.6 * logoImage.ratio } height={headerHeight * 0.6} resizeMode="contain" />
      )}
    </Row>
  );
  const searchBar = () => !showSearch ? null : (
    <Row width={loginBoxWidthPercent + '%'} middle backgroundColor='transparent'>
      <Row
        height={headerHeight * 0.5} borderRadius={headerHeight * 0.25} width='90%'
        paddingVertical5 paddingHorizontal10 bgWhite
      >
        <Img source={ASSETS.SEARCH} tintColor={COLOR.MAIN} height={headerHeight * 0.3} width={headerHeight * 0.3} />
        <TextInput
          ref={searchRef}
          value={searchKeyword} onChangeText={setSearchKeyword}
          style={styles.searchInput}
          placeholder=""
          placeholderTextColor={COLOR.FONT}
        />
      </Row>
    </Row>
  );
  const navs = (p = {}) => (
    <>
      {headerNavs().map((val, i) => (
        <Row onPress={() => navigate(val.route)} key={val.label} paddingHorizontal={fSize(rV, 43/2)} {...p}>
          <Text colorWhite bold fontSize={fSize(rV, 26)}>{val.label}</Text>
        </Row>
      ))}
    </>
  );
  const searchIcon = (p = {}) => (
    <Row onPress={toggleSearch} {...p}>
      {Boolean(showSearch) ? (
        <ICON.Close fill="white" width={fSize(rV, 41.98 * 0.7)} height={fSize(rV, 39 * 0.7)} />
      ) : (
        <ICON.Search width={fSize(rV, 41.98 * 0.7)} height={fSize(rV, 39 * 0.7)} />
      )}
    </Row>
  );
  const burgerIcon = (p = {}) => (
    <Row onPress={() => {
      showDrawer();
      Store.Firebase.logEvent('Show Side Menu', {});
    }} middle {...p}>
      <ICON.BurgerMenu width={fSize(rV, 41.98 * 0.7)} height={fSize(rV, 35.5 * 0.7)} />
    </Row>
  );
  const flex1Space = (p = {}) => (
    <Row flex1 {...p} />
  );

  const handleRemovePersonate = async () => {
    await uApi.removePersonate();
    window.location.reload();
  }

  const userInfo = (p = { withoutName: false }) => {
    const isPersonating = !!saleManagerData.account['Personate Email'];
    const clientName = (() => {
      if (!isPersonating) return '';
      const findClient = saleManagerData.clients.find(val => val.Email === saleManagerData.account['Personate Email']);
      return !!findClient ? findClient.Customer : saleManagerData.account['Personate Email']
    })();
    let welcomeName = isPersonating ? clientName : firebaseUser.displayName;
    if (welcomeName.includes('@')) welcomeName = welcomeName.split('@')[0];
    return (
      <Row middle paddingHorizontal={7.5}>
        {Boolean(!p.withoutName) && (
          <Col padding3 onPress={() => navigate(SCREEN_NAME.AccountDetail)} maxWidth={200}>
            <Text bold colorWhite paddingHorizontal={7.5} fontSize={12} numberOfLines={1}>Hi <Text bold colorWhite textDecorationLine={'underline'}>{welcomeName || ''}</Text></Text>
            {Boolean(isPersonating) && (
              <Col onPress={handleRemovePersonate}>
                <Text center colorWhite marginTop5 fontSize={10}>Exit client view mode</Text>
              </Col>
            )}
          </Col>
        )}
        <Col paddingRight={0} paddingLeft={10} onPress={(e) => {
          showDropdown(e);
          Store.Firebase.logEvent('Show Notification Dropdown', {});
        }}>
          {/* <ICON.IconNotification width={25} height={25} /> */}
          <UseMemo deps={[haveUnreadNotification]}>
            <BellAnim haveUnreadNotification={haveUnreadNotification} />
          </UseMemo>
          {/* {Boolean(haveUnreadNotification) && (
            <Col absolute top0 right={7.5} width={rV({ xs: 5, lg: 10 })} height={rV({ xs: 5, lg: 10 })} borderRadius={rV({ xs: 2.5, lg: 5 })} backgroundColor="#BC0202" />
          )} */}
        </Col>
        
      </Row>
    );
  };
  // const backButton = (p = {}) => !indexRoute ? null : (
  //   <Row paddingHorizontal5 marginLeft10 onPress={() => navigation.goBack()}>
  //     <Text colorWhite fontSize25>←</Text>
  //     <Text colorWhite fontSize={16}> Go back</Text>
  //   </Row>
  // );

  const backButton = (p = {}) => null

  const navMenu = (p = {}) => {
    return (
      <Row flex1 paddingHorizontal60>
        
        <Col marginLeft20 middle onPress={() => navigate(SCREEN_NAME.News)}>
          <Text colorWhite fontSize={MENU_FONT_SIZE} bold>News</Text>
        </Col>
        <Col marginLeft20 middle onPress={() => navigate(SCREEN_NAME.YourCasks)}>
          <Text colorWhite fontSize={MENU_FONT_SIZE} bold>Your Casks</Text>
        </Col>
        <Col marginLeft20 middle onPress={() => navigate(SCREEN_NAME.MyPlan, {})}>
          <Text colorWhite fontSize={MENU_FONT_SIZE} bold>My Plan</Text>
        </Col>
        {/* <Col marginLeft40 middle onPress={() => navigate(SCREEN_NAME.Collections, {})}>
          <Text colorWhite>Collections</Text>
        </Col> */}
        <Col marginLeft20 middle onPress={() => navigate(SCREEN_NAME.Certificates)}>
          <Text colorWhite fontSize={MENU_FONT_SIZE} bold>Certificates</Text>
        </Col>
        
        <Col marginLeft20 onPress={() => reset(SCREEN_NAME.Casks)}>
          <Text colorWhite fontSize={MENU_FONT_SIZE} bold>Portfolio</Text>
        </Col>
        <Col marginLeft20 onPress={() => navigate(SCREEN_NAME.ReferAFriend)}>
          <Text colorWhite fontSize={MENU_FONT_SIZE} bold>Refer a friend</Text>
        </Col>
      </Row>
    );
  }

  const renderArr = (arr, prefix = '') => arr.map((val, i) => <Fragment key={prefix + i}>{val}</Fragment>);

  const backgroundSearch = showSearch ? { backgroundColor: 'transparent' } : undefined;

  // console.count('rerender header');

  return (
    <Col width100p style={propStype} bgMain>
      {Boolean(DEVICE_INFO.IS_IOS) && <Col height={DEVICE_INFO.STATUS_BAR_HEIGHT} />}
      <Row height={headerHeight} stretch {...backgroundSearch}>
        {!isAuth ? renderArr([
            edgeSpace(),
            logo(),
            flex1Space(),
            edgeSpace(),
        ]) : renderArr(
          rV({
            xs: [
              showSearch ? null : edgeSpace(),
              showSearch ? null : logo(),
              searchBar(), flex1Space(),
              searchIcon(),
              !isAuth ? null : showSearch ? null : userInfo({ withoutName: true }),
              showSearch ? null : burgerIcon({ marginLeft: 10 }),
              edgeSpace(),
            ],
            md: [
              showSearch ? null : edgeSpace(),
              showSearch ? searchIcon({ width: leftBoxWidthPercent + '%', middle: true, bgMain: true }) : logo({ width: leftBoxWidthPercent + '%' }),
              searchBar(),
              flex1Space(),
              !showSearch ? searchIcon() : null,
              showSearch ? null : !isAuth ? null : userInfo(),
              !isAuth ? null : burgerIcon({ marginLeft: 10 }),
              edgeSpace(),
            ],
            lg: [
              showSearch ? null : edgeSpace(),
              logo(showSearch ? { width: leftBoxWidthPercent + '%', paddingLeft: edgeSize } : undefined),
              backButton(),
              isAuth ? searchBar() : null,
              showSearch ? flex1Space() : (isAuth ? navMenu() : null),
              // flex1Space(),
              searchIcon(),
              isAuth ? userInfo() : null,
              !isAuth ? null : burgerIcon({ marginLeft: 0 }),
              edgeSpace()
            ]
          })
        , viewport)}
      </Row>
    </Col>
  );
};

const styles = StyleSheet.create({
  searchInput: {
    flex: 1,
    height: '100%',
    marginLeft: 10,
  },
})

export default memo(Header);