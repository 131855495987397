import { COLOR, fSize } from 'const';
import React from 'react';
import { ViewStyle, StyleSheet, TextStyle } from 'react-native';
import { Col, Row, Text, useDynamicResponsiveValue, usePropsStyle } from 'react-quick-style-components';

interface IButtonProps {
  // props here
  title?: string
  onPress?: () => void
  preset?: 'main' | 'white'
  titleStyle?: TextStyle
  [anyProp: string]: any,
}

const Button = (props: IButtonProps) => {
  const { title, onPress, titleStyle, preset } = props;
  const propStyle = usePropsStyle(props);
  const rV = useDynamicResponsiveValue()
  return (
    <Col style={[styles.box, {
      height: rV({ xs: 40, xxxl: 52 }),
      backgroundColor: preset && preset === 'white' ? COLOR.WHITE : COLOR.MAIN,
      borderWidth: 1,
      borderColor: COLOR.MAIN
    }, propStyle]}
      onHoverStyle={{
        shadowColor: COLOR.MAIN,
        shadowOffset: {
          width: 1,
          height: 3
        },
        shadowRadius: 6,
      }}
      onPress={onPress}
    >
      <Text numberOfLines={1} fontSize={fSize(rV, 24)} color={preset && preset === 'white' ? COLOR.MAIN : COLOR.WHITE} style={titleStyle}>{title}</Text>
    </Col>
  );
};

const styles = StyleSheet.create({
  box: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
  },
})
export default Button;
