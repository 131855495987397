import { VarHelper } from 'helpers';
import React, { useRef, useState, forwardRef, useImperativeHandle } from 'react';
import { ILocalFileUpload } from 'type';

interface IUploaderProps {
  onChangeLocal(files: Array<ILocalFileUpload>): void,
}

const Uploader = forwardRef((props : IUploaderProps, ref) => {
  const inputRef = useRef(null);

  const convertToUrl = (file) : Promise<string> => new Promise((resolve) => {
    var reader = new FileReader();
    reader.onload = function (e) {
      resolve(e.target.result as string);
    }
    reader.readAsDataURL(file);
  });

  const onChange = async (e : React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files);
    if (!files || files.length === 0) return;
    const results = [];
    const promiseArr = files.map(async(file) => {
      try {
        const dataUrl = await convertToUrl(file);
        results.push({
          path: dataUrl,
          mime: file.type,
          name: file.name,
          raw: file,
        })
      } catch(err) {}
    });
    await Promise.all(promiseArr);

    props.onChangeLocal(results);

  };

  const openFileDialog = () => !!inputRef.current && inputRef.current.click();

  const uploadLocal = async ({ path, mime, name, raw }) => {
    return VarHelper.erria(async () => {
      if (!raw && !path) return '';
      if (!raw && !!path && path.includes('https://')) return path;
      
    });
  };

  useImperativeHandle(ref, () => ({
    openFileDialog,
    uploadLocal,
  }));

  return (
    <input type="file" style={{ display: 'none' }} ref={inputRef} onChange={onChange} multiple />
  );
});

export default Uploader;