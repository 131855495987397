import React, { useEffect, useState } from 'react';

import './LiquidEffect.css';

var myReuseableStylesheet = document.createElement('style'),
    addKeyFrames = null;

document.head.appendChild( myReuseableStylesheet );
if (CSS && CSS.supports && CSS.supports('animation: name')){
    // we can safely assume that the browser supports unprefixed version.
    addKeyFrames = function(name, frames){
      // @ts-ignore
        var pos = myReuseableStylesheet.length;
        myReuseableStylesheet.sheet.insertRule(
            "@keyframes " + name + "{" + frames + "}", pos);
    }
} else {
    addKeyFrames = function(name, frames){
        // Ugly and terrible, but users with this terrible of a browser
        // *cough* IE *cough* don't deserve a fast site
        var str = name + "{" + frames + "}",
        // @ts-ignore
            pos = myReuseableStylesheet.length;
        myReuseableStylesheet.sheet.insertRule("@-webkit-keyframes " + str, pos);
        myReuseableStylesheet.sheet.insertRule("@keyframes " + str, pos+1);
    }
}

const LiquidEffect = ({ width, height, mask, percent }) => {
  const [show, setShow] = useState(false);
  const showImage = () => {
    setShow(true);
  }

  useEffect(() => {
    const element = document.querySelector('.liquid-effect-container-child-effect') as HTMLElement;
    element.addEventListener("webkitAnimationEnd", showImage,false);
    element.addEventListener("animationend", showImage,false);
    element.addEventListener("oanimationend", showImage,false);

    if (element) {
      element.addEventListener("webkitAnimationEnd", showImage,false);
      element.addEventListener("animationend", showImage,false);
      element.addEventListener("oanimationend", showImage,false);
      const animationName = `fill-${Date.now()}`;

      addKeyFrames(
        animationName,
        `
        from {
          top: ${height}px;
          transform: translateX(-50%) rotate(0deg);
        }
        to {
          top: ${height * (1-percent)}px;
          transform: translateX(-50%) rotate(360deg);
        }
        `
      );
      element.style.animation = `${animationName} ${percent < 0.5 ? 4 : 7}s ease-in-out`;
    }
  
    return () => {
      if (element) {
        element.removeEventListener("webkitAnimationEnd", showImage);
        element.removeEventListener("animationend", showImage);
        element.removeEventListener("oanimationend", showImage);
      }
    };
  }, []);

  return (
    <div
      className="liquid-effect-container"
      style={{
        width, height,
        WebkitMaskImage: `url(${mask})`,
        maskImage: `url(${mask})`,
        maskPosition: 'center center',
        WebkitMaskPosition: 'center center',
        maskRepeat: 'no-repeat',
        WebkitMaskRepeat: 'no-repeat',
        maskSize: '100% 100%',
        WebkitMaskSize: '100% 100%',
      }}
    >
      <img src={mask} style={{ opacity: 0.3, width: '100%', height: '100%' }} />
      <div
        className='liquid-effect-container-child liquid-effect-container-child-effect'
        style={{
          top: height * (1-percent),
        }}
      />

      {!!show && (
        <div style={{ position: 'absolute', left: 0, right: 0, bottom: 0, height: height * percent, overflow: 'hidden' }}>
          <img
            src={mask}
            style={{
              opacity: 1, width: width, height: height, position: 'absolute',
              bottom: 0, left: 0, right: 0,
            }}
          />
        </div>
      )}
    </div>
  )
};

export default LiquidEffect;
