import React, { useEffect } from 'react';
import { Col, Text, Scroll, useDynamicResponsiveValue } from 'react-quick-style-components';
import { useNavFunc } from 'navigation';
import { IScreen } from 'type';
import { Table, TableWithAction, Tabs, Breadcrumbs } from 'components';
import { ASSETS, COLOR, fSize, ICON, SCREEN_NAME } from 'const';
import { Image, StyleSheet, useWindowDimensions } from 'react-native';
import { Footer, Header, Card, Input, ChatBox } from 'components';
import { useState } from 'react';
import moment from 'moment';
import Store from 'store';
import { VarHelper, TimeHelper } from 'helpers';

const PortfolioDetails: IScreen = () => {
  const { reset } = useNavFunc();
  useEffect(() => {
    reset(SCREEN_NAME.YourCasks);
  }, []);
  return null;
};

PortfolioDetails.routeInfo = {
  title: 'Portfolio Details',
  path: '/portfolio-details',
};

const styles = StyleSheet.create({
  banner: {
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0
  },
})

export default PortfolioDetails;