import * as DeviceInfo from 'expo-device';
import { Platform, Dimensions } from 'react-native';
import { getBottomSpace, getStatusBarHeight } from 'react-native-iphone-x-helper';

export const DEVICE_INFO = {
  IS_IOS: Platform.OS === 'ios',
  IS_ANDROID: Platform.OS === 'android',
  IS_WEB: Platform.OS === 'web',
  MODEL: DeviceInfo.modelName,
  WIDTH: Dimensions.get('window').width,
  HEIGHT: Dimensions.get('window').height,
  BOTTOM_SPACE: getBottomSpace(),
  STATUS_BAR_HEIGHT: getStatusBarHeight(),
};

Dimensions.addEventListener('change', () => {
  DEVICE_INFO.HEIGHT = Dimensions.get('window').height;
  DEVICE_INFO.WIDTH = Dimensions.get('window').width;
});
