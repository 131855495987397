import React from 'react';
import { StyleSheet, Image } from 'react-native';
import { Text, Row, Col } from 'react-quick-style-components';
import { COLOR } from 'const';
import { MaterialIcons } from '@expo/vector-icons';
// import Button from '../Button';
import { showModal, hideModal } from './Modal';

// TODO Upate Button component
const Button = (props) =>  <Col bgMain middle width100p height40 onPress={props.onPress}><Text colorWhite bold>{props.text}</Text></Col>

const PopupContent = ({ title, buttons, customRenderDescrip }) => {
  let padding = 0
  if (buttons.length < 2) { padding = 0 } else { padding = 10 }
  return (
    <Col shadow style={[styles.container]}>
      {Boolean(!!customRenderDescrip) ? customRenderDescrip() : <Text style={styles.descrip}>{title}</Text>}
      <Row style={styles.containerButton}>
        {Boolean(buttons) && buttons.map((item, index) => {
          return (
            <Col
              key={index}
              style={{
                flex: 1,
                paddingRight: index !== 0 ? 0 : padding
              }}
            >
              <Button
                text={item.text}
                onPress={item.onPress}
                solid={item.solid === false ? false : true}
                outline={item.solid ? false : true}
                small
                textStyle={!!item.title && item.title.length >= 6 ? { fontSize: 14 } : undefined}
              />
            </Col>
          )
        })}
      </Row>
    </Col>
  )
}

const Popup = {
  show: (message, buttons = undefined, onCloseModal = () => { }) => {
    const hideModalWithCallback = (callback) => () => {
      hideModal();
      typeof callback === 'function' && callback();
    }

    showModal({
      component: (
        <PopupContent
          title={message}
          buttons={!!buttons
            ? buttons.map(val => ({ ...val, onPress: hideModalWithCallback(val.onPress) }))
            : [
              { text: 'OK', solid: true, onPress: hideModalWithCallback(onCloseModal) }
            ]
          }
          customRenderDescrip={null}
        />
      )
    })
  },
  showError: (error, buttons = undefined, onCloseModal = () => { }) => {
    const hideModalWithCallback = (callback) => () => {
      hideModal();
      typeof callback === 'function' && callback();
    }

    showModal({
      component: (
        <PopupContent
          title={error}
          buttons={!!buttons
            ? buttons.map(val => ({ ...val, onPress: hideModalWithCallback(val.onPress) }))
            : [
              { text: 'OK', onPress: hideModalWithCallback(onCloseModal) }
            ]
          }
          customRenderDescrip={() => (
            <>
              <MaterialIcons name="error" size={24} color={COLOR.FONT} style={{ alignSelf: 'center' }} />
              <Text style={styles.descrip}>{error}</Text>
            </>
          )}
        />
      )
    })
  },
  checkAndShowError: (res, err) => false,
}

Popup.checkAndShowError = (res, err) => {
  // let message = err ? String(err) : (!res.status && res.message) ? res.message : '';
  console.log(res, err);
  let message = (() => {
    if (err) return String(err);
    if (!res.status && res.message) {
      let messageStr = res.message;
      if (res.error) {
        for (let key in res.error) {
          messageStr += `\n${res.error[key].join(', ')}`;
        }
      }
      return messageStr;
    }
    return '';
  })();
  if (message) {
    Popup.showError(message);
    return true;
  }
  return false;
};


const styles = StyleSheet.create({
  container: {
    paddingVertical: 15,
    paddingHorizontal: 20,
    backgroundColor: 'white',
    borderRadius: 10,
    width: 300,
    alignSelf: 'center'
  },
  title: {
    fontSize: 22,
    textAlign: 'left',
  },
  descrip: {
    textAlign: 'center',
    paddingTop: 20,
  },
  containerButton: {
    paddingTop: 35,
  },
  iconError: {
    width: 78,
    height: 78,
    marginTop: 25,
    alignSelf: 'center',
  }
});


export default Popup;