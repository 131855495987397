
// https://docs.google.com/spreadsheets/d/1kJXT3u7Z02HjFv5ejp2tZPMmTwtUft7B_Pd0Fe_XSxU/edit#gid=994750381
export const DISTILLERY_DES = {
 Aberargie: [
   ['OS grid reference', 'NO163158'],
   ['Council area', 'Perth and Kinross'],
   ['Lieutenancy area', 'Perth and Kinross'],
   ['Country', 'Scotland'],
   ['Sovereign state', 'United Kingdom'],
   ['Post town', 'PERTH'],
   ['Postcode district', 'PH2'],
   ['Dialling code', '01738'],
 ],
 Aberfeldy: [
  ['Owner', 'Bacardi'],
  ['Founded', '1896'],
  ['Status', 'Operational'],
  ['Water source', 'Pitilie Burn'],
  ['No. of stills', '2 wash stills'],
  ['2 spirit stills', '[1]'],
  ['Capacity', '3,500,000 litres'],
 ],
 Aberlour: [
   ['Aberlour is a distillery of Speyside single malt Scotch whisky, in Aberlour, Strathspey, Scotland, at the confluence of the Lour Burn and River Spey near Ben Rinnes. In Speyside whisky region at the confluence of the rivers Lour and Spey near Ben Rinnes, Moray.'],
   ['Owner', 'Chivas Brothers Ltd. (Pernod Ricard S.A., since 1974[1])'],
   ['Founded', '1879'],
   ['Founder', 'James Fleming'],
   ['Status', 'active'],
 ],
 'Abhainn Dearg': [
   ['Location', 'Uig, Isle of Lewis'],
   ['Coordinates', '58°10′15″N 7°02′41″WCoordinates: 58°10′15″N 7°02′41″W'],
   ['Founded', ' 2008 [1]'],
   ['Abhainn Dearg'],
   ['Type', 'Single malt, Scotch'],
   ['Age(s)', 'newmake spirit, 3 years'],
   ['Cask type(s)', 'American White Oak, Ex-Bourbon Casks (Main) Ex Sherry Casks'],
 ],
 'Ailsa Bay': [
    [`Since 2007, the site has also incorporated the Ailsa Bay Distillery. The opening was attended by Prince Charles in 2009.[3]

    In March 2019, Ailsa Bay released a new brand of scotch whisky which was protected with blockchain technology to prevent counterfeiting.`]
 ],
 'Allt-A-Bhainne': [
   [`Allt-A-Bhainne (Scottish Gaelic: Allt a' Bhainne meaning 'milk burn') is a Scotch whisky distillery in the Speyside Region of Scotland.[1] It is located just down the road from the well-known whisky town of Dufftown.`]
 ],
 
  Annandale: [
   ['Location', 'Annandale, Dumfries and Galloway'],
   ['Owner', 'Annandale Distillery Company'],
   ['Status', 'Active'],
  ],

  Arbikie: [

  ],
  Ardbeg: [
    [`The Ardbeg distillery has been producing whisky since 1798, and began commercial production in 1815.[4] Like most Scottish distilleries, for most of its history, its whisky was produced for use in blended whisky, rather than as a single malt. By 1886 the distillery produced 300,000 gallons of whisky per year, and employed 60 workers`],
    ['Owner', 'Louis Vuitton Moët Hennessy'],
    ['Founded', '1815'],
    ['Status', 'Active'],
    ['Water source', 'Loch Uigeadail'],
    ['No. of stills', '1 wash (18,000 L), 1 spirit (17,000 L)[1]'],
    ['Capacity', '1,250,000 litres'],
    ['Ardbeg'],
    ['Type', 'Single Malt Scotch Whisky'],
  ],
  Ardmore: [
    [`Ardmore distillery is a single malt Scotch whisky distillery, located in the village of Kennethmont, Aberdeenshire. The distillery is owned and operated by Beam Suntory, an American subsidiary of Suntory Holdings of Osaka, Japan.`],
    ['Location', 'Kennethmont, Aberdeenshire'],
    ['Owner', 'Beam Suntory'],
    ['Founded', '1898'],
    ['Status', 'Operational'],
    ['Water source', 'Springs on Knockandy Hill'],
    ['No. of stills', 'Four wash stills (15,000 each) Four spirit stills (15,000 each)[1]'],
    ['Capacity', '5,530,000 litres'],
  ],
  Ardnahoe: [
    [`Plans for the distillery was submitted to Argyll and Bute Council in January 2016. By October 2017, the distillery was under construction, with the foundations completed. The distillery initially was built with a £12m investment from Hunter Laing & Company, a Scottish family-owned whisky business. The site covers 4 acres and the management team is led by Stewart Hunter Laing and his sons Scott and Andrew.`],
  ],
  Ardnamurchan: [
    [`Ardnamurchan distillery is a Scotch whisky distillery on the Ardnamurchan peninsular of Lochaber in the Highlands, Scotland.[1][2] The distillery is considered one of the remotest distilleries in Scotland and takes its name from the peninsular.[1][3][4]

    The distillery was built and is owned by Adelphi, an independent bottling company`]
  ],
  Arran: [
    ['Location', 'Lochranza, Isle of Arran'],
    ['Owner', 'Isle of Arran Distillers Ltd'],
    ['Founded', '1995'],
    ['Status', 'Operational'],
    ['Water source', 'Loch na Davie'],
  ],
  Auchentoshan: [
    [`
    The distillery is also known as "Glasgow's Malt Whisky" due to its proximity to Glasgow and "the breakfast whisky" due to its sweet and delicate nature.[6] Auchentoshan is located at the foot of the Kilpatrick Hills on the outskirts of Clydebank in West Dunbartonshire near the Erskine Bridge.[4] It is one of six malt whisky distilleries in the Scottish Lowlands`],
    ['Location', 'Dalmuir'],
    ['Owner', 'Morrison Bowmore Distillers'],
    ['Founded', '1823[1]'],
    ['Status', 'Operational'],
    ['Water source', 'Loch Katrine (Production) & Loch Cochno (Cooling'],
  ],
  Auchroisk: [
    [`The Auchroisk distillery is a whisky distillery in Banffshire, speyside Scotland. It was established after Dorie's Well was discovered and found to have water similar to the water used by the Glen Spey distillery, which was used by Justerini & Brooks to produce malt whisky for their blends.`],
    ['Location', 'Mulben, Banffshire'],
    ['Owner', 'Diageo'],
    ['Founded', '1972'],
    ['Founder', 'Justerini & Brooks'],
    ['Status', 'active'],
    ['Water source', `Dorie's Well`],
  ],
  Aultmore: [
    [`Aultmore is a whisky distillery in Keith, Banffshire, producing an eponymous single malt whisky. Its name is a derived from the phrase An t-Allt Mòr, Gaelic for big burn, referring to its water source the Auchinderran burn.[2]

    It was founded in 1895 by Alexander Edward, then owner of the Benrinnes distillery.[3] The distillery was originally powered by a waterwheel, and was soon adapted to use a steam engine instead,[2] which ran day and night for 70 years, apart from maintenance.
    Screen reader support enabled.`],
    ['Location', 'Keith, Banffshire'],
    ['Owner', 'John Dewar & Sons Ltd (Bacardi)'],
    ['Founded', '1895'],
    ['Founder', 'Alexander Edward'],
    ['Status', 'Open'],
    ['Water source', 'Auchinderran burn'],
  ],
  Balblair: [
    [`Ardmore distillery is a single malt Scotch whisky distillery, located in the village of Kennethmont, Aberdeenshire. The distillery is owned and operated by Beam Suntory, an American subsidiary of Suntory Holdings of Osaka, Japan.        Edderton`],
    ['Owner', 'InterBev (Inver House Distillers)'],
    ['Founded', '1790'],
    ['Status', 'Active'],
    ['Water source', 'The Allt Dearg'],
  ],
  Ballindalloch: [
    [`Ballindalloch distillery is a Scotch whisky distillery located on the estate grounds.[5] With the assistance of a £1.2 million grant from the Scottish Government, the owners of the castle set up a distillery on an estate farm.[5] The distillery commenced production in September 2014.[5] The spirit safe dates to 1863 and was gifted to the distillery by the adjacent Cragganmore distillery also on the castle estate.[5]`]
  ],
  Balmenach: [
    [`Balmenach Distillery was established in 1824 by James McGregor, from a family of farmers and illicit distillers who resided in Tomintoul`],
    ['Founded', '1824'],
    ['Status', 'Active'],
    ['Water source', 'Spring on the Cromdale Hills'],
  ],
  Balvenie: [
    [`In early 1892 work began to convert an 18th-century mansion (Balvenie New House) into a distillery.[2] The building took fifteen months to complete, and on 1 May 1893, the first distillation took place at the Balvenie Distillery.        Dufftown`],
    ['Owner', 'William Grant & Sons'],
    ['Founded', '1892'],
    ['Status', 'Operational'],
    ['Water source', 'Robbie Dubh'],
  ],
  Barra: [
    [`The Isle of Barra Distillery at Borve, Barra, Scotland is a planned future whisky distillery on Barra.[1] Barra Distillery was originally founded in 2008, although wind turbines to power the distillery were installed in 2012 [2] the distillery buildings are still to be constructed. The distillery has become a Community Benefit Society,[3] a vehicle to direct profits to support social and economic development in the community of Barra & Vatersay via a charitable trust. Shares are available to purchase, and it is expected that construction will commence before 2022.`],
    ['Owner', 'Uisge Beatha nan Eilean'],
    ['Founded', '2008'],
    ['Status', 'In construction'],
  ],
  'Ben Nevis': [
    [`Ben Nevis distillery is a distillery in Scotland that distills Ben Nevis 10 Years Old and Ben Nevis 21 Years Old whisky. It is at Lochy Bridge in Fort William and sits just at the base of Ben Nevis, the highest mountain in the British Isles, which rises to 1,345 m (4,413 ft) above sea level. `],
    ['Owner', 'Nikka'],
    ['Founded', '1825'],
    ['Status', 'Operational'],
    ['Water source', `Allt a'Mhuilinn`],
  ],
  Benriach: [
    [`The BenRiach distillery is a single malt Scotch whisky distillery in the Speyside area of Scotland. It is currently owned by Brown-Forman Corporation after being purchased on 1 June 2016 as a subsidiary[2] from the BenRiach Distillery Company Limited, formed by two South African funding partners, Geoff Bell and Wayne Keiswetter, and Scotch whisky expert Billy Walker.        Moray, Scotland`],
    ['Owner', 'Brown-Forman'],
    ['Founded', '1898'],
    ['Status', 'Active'],
    ['Water source ', 'Burnside Springs'],
  ],
  Benrinnes: [
    [`Benrinnes is a malt whisky distillery in Aberlour producing an eponymous whisky. It was founded in 1826, and is still active. The distillery employed a unique partial triple distillation process until 2007.Location  AberlourCoordinates: 57.44188°N 3.23686°W`],
    ['Founded', '1826'],
    ['Founder', 'John Innes'],
    ['Status', 'Open'],
    ['Water source', 'Scurran Burn and Rowantree Burn'],
  ],
  Benromach: [
    [`Benromach is a Speyside distillery founded by Duncan McCallum and F.W. Brickman in 1898 and currently owned and run by Gordon and Macphail of Elgin. It is situated near Forres in Morayshire and is fed with spring water from the Chapelton Springs in the Romach Hills beside Forres.`],
    ['Owner', 'Gordon & MacPhail'],
    ['Founded', '1898'],
    ['Status', 'Operational'],
    ['Water source', 'Chapelton Springs in the Romach Hills'],
  ],
  Bladnoch: [
    [`Bladnoch Distillery is a Single malt Scotch whisky distillery in south west Scotland. It is one of six remaining Lowland distilleries, located at Bladnoch, near Wigtown, Dumfries and Galloway. The distillery is situated on the banks of the River Bladnoch, and is the most southerly whisky distillery in Scotland.`],
    ['Location', 'Wigtown, Galloway'],
    ['Owner', 'David Prior'],
    ['Founded', '1817'],
    ['Status', 'Operational'],
    ['Water source', 'River Bladnoch'],
  ],
  Glenrothes: [
    [`The Glenrothes is a Speyside single malt Scotch whisky. It is made at the Glenrothes distillery located in the town of Rothes in the heart of the Speyside region of Scotland. The distillery sits beside the Burn of Rothes, hidden in a glen on the edge of the town `],
    ['Location', 'rothes'],
    ['Owner', 'The Edrington Group'],
    ['Founded', '1879'],
    ['Status', 'Operational'],
  ],
  ['Blair Athol']: [
    [`Blair Athol Distillery is a distillery in Scotland that distills Blair Athol single malt whisky. It is used in Bell's whisky, and is also normally available in a 12-year-old bottling.[2] The distillery is located on the south edge of Pitlochry in Perthshire, near the River Tummel. The distillery closed down in 1932. The mothballed distillery was bought by Arthur Bell and Sons, but didn't open again until it was rebuilt in 1949.[3] In 1973 the distillery expanded, adding two further stills to the previous two`],
    ['Location', 'Pitlochry'],
    ['Owner', 'Diageo'],
    ['Founded', '1798'],
    ['Status', 'Operational'],
    ['Water source', 'Allt Dour Burn'],
    ['No. of stills', '2 wash stills (13,000 liters), 2 spirit stills (12,000 liters)[1]'],
    ['Capacity', '2,500,000 litres'],
    ['Mothballed', 'Soon after 1798 to 1825, 1932 to 1949'],
  ],
  Borders: [
    [`The Borders Distillery, also known as The Three Stills Company,[1] is a malt whisky, gin and vodka production facility in the Scottish Borders town of Hawick. The distillery produces malt whisky, William Kerrs Gin.[2] and vodka and has a visitor centre on Commercial Road.         Location        Hawick, Scottish Borders, Scotland`],
    ['Coordinates', '55.42452°N 2.78887°WCoordinates: 55.42452°N 2.78887°W'],
    ['Founded', '2018'],
    ['Founder', 'George Tait, Tony Roberts, Tim Carton, John Fordyce'],
    ['Status', 'active'],
    ['Water source', 'Allt Dour Burn'],
    ['No. of stills', '2 Wash stills and 2 Spirit Stills'],
  ],
  ['Royal Brackla']: [
    [`Royal Brackla distillery is a Highland Scotch whisky distillery on the Cawdor Estate, near Nairn in Scotland. The distillery is operated by John Dewar & Sons Ltd for Bacardi`],
    ['Location', 'Cawdor Estate, near Nairn, Highland'],
    ['Owner', 'Bacardi'],
    ['Founded', '1812'],
    ['Status', 'Active'],
    ['Water source', 'Cawdor Burn, Cursack Springs and Airfield supply'],
    ['No. of stills', '2 wash stills, 2 spirit stills'],
    ['Capacity', '3,500,000 litres (770,000 imp gal)'],
  ],
  Braeval: [
    [`Braeval distillery is a distiller of Scotch whisky in Chapeltown, Banffshire, Scotland. Founded in 1973 as Braes of Glenlivet, the distillery is owned by Chivas Brothers. The name was changed to avoid confusion with an unrelated single mal`],
    ['Location', 'Chapeltown, Banffshire, Scotland'],
    ['Owner', 'Chivas Brothers'],
    ['Founded', '1973'],
    ['Status', 'Active'],
    ['No. of stills', '2 wash 4 spirit[1]'],
    ['Mothballed', '2002-2008'],
  ],
  Bruichladdich: [
    [`All the distillery’s whiskies are sold as single malts, with those designated Bruichladdich being unpeated, those designated Port Charlotte being heavily peated and those designated Octomore being super-heavily peated. Octomore is considered to be 'the most heavily peated single malt whisky in the world`],
    ['Location', 'Rhinns of the isle of Islay, Scotland'],
    ['Founded', '1881'],
    ['Status', 'Operational'],
    ['Water source', 'Bruichladdich loch (mash), burn (cooling), and Octomore spring (bottling)'],
    ['No. of stills', '2 wash (12,000 liters), 2 spirit (11,000 liters), 1 Lomond (adjustable)[1]'],
    ['Capacity', '1,500,000 litres'],
    ['Mothballed', '1907–1918, 1929–1935, 1941–1945, 1994–2000']
  ],
  Bunnahabhain: [
    [`The Bunnahabhain is one of the milder single malt Islay whiskies available and its taste varies greatly from other spirits to be found on the island of Islay, off the west coast of Scotland.`],
    ['Location', 'Islay'],
    ['Founded', '1881'],
    ['No. of stills', '2 wash (30,000 litres), 2 spirit (15,000 litres)'],
  ],
  'Caol Ila': [
    [`Caol Ila is derived from Gaelic Caol Ìle for "Sound of Islay" (lit. "Islay Strait") in reference to the distillery's location overlooking the strait between Islay and Jura.`],
    ['Location', 'Port Askaig'],
    ['Owner', 'Diageo'],
    ['Founded', '1846'],
    ['Status', 'Operational'],
    ['No. of stills', '3 wash (19,000 L), 3 spirit (12,000 L)[1]'],
    ['Capacity', '6,500,000 L'],
    ['Mothballed', '1930-1937, 1942-1945'],
  ],
  Cardhu: [
    [`Cardhu is a Speyside distillery near Archiestown, Moray, Scotland, founded in 1824 by whisky smuggler John Cumming and his wife Helen.[1] The distillery is now run by Diageo and the distillery's Scotch whisky makes up an important part of the Johnnie Walker blended whiskies. The word "Cardhu" derives from the Scots Gaelic Carn Dubh, meaning "Black Rock"`],
    ['Location', 'Archiestown, Moray, Scotland'],
    ['Coordinates', '57°28′13.7″N 3°21′00.2″WCoordinates: 57°28′13.7″N 3°21′00.2″W'],
    ['Owner', 'Diageo'],
    ['Founded', '1824'],
    ['Founder', 'John & Helen Cumming'],
  ],
  Clydeside: [
    [`Clydeside distillery is a Scotch whisky distillery in Glasgow, Scotland.[1][2][3] When production began in 2017, Clydeside was the first active distillery in Glasgow city centre for over 100 years.`],
  ],
  Clynelish: [
    [`Clynelish Distillery is a distillery near Brora, Sutherland in the Highlands of Scotland.`],
    ['Location', 'Brora, Sutherland'],
    ['Owner', 'Diageo'],
    ['Founded', '1967'],
    ['Status', 'Operational'],
    ['Water source', 'Clynelish burn'],
    ['No. of stills', 'Three wash, three spirit'],
    ['Capacity', '4,800,000 litres'],
  ],
  Cragganmore: [
    [`Cragganmore is a Scotch whisky distillery situated in the village of Ballindalloch in Banffshire, Scotland.

    The distillery was founded in 1869 by John Smith on land leased from Sir George Macpherson-Grant. The site was chosen by Smith both for its proximity to the waters of the Craggan burn and because it was close to the Strathspey Railway. Smith was an experienced distiller, having already been manager of the Macallan, Glenlivet, Glenfarclas and Wishaw distilleries`],
    ['Owner', 'Diageo'],
    ['Founded', '1869'],
    ['Status', 'Active'],
    ['Water source', 'The Craggan Burn'],
    ['No. of stills', '2 wash stills, 2 spirit stills'],
    ['Capacity', '1,520,000 litres'],
  ],
  Craigellachie: [
    [`The Craigellachie distillery is a single malt Scotch whisky distillery in Aberlour, Banffshire near the village of Craigellachie at the centre of the Speyside whisky producing area of Scotland. The name Craigellachie means 'rocky hill' and refers to the cliff that overlooks the Spey across from The Macallan distillery.`],
    ['Location', 'Aberlour, Banffshire'],
    ['Owner', 'Bacardi'],
    ['Founded', '1891'],
    ['Status', 'Operational'],
    ['Water source', 'Local'],
    ['No. of stills', '2 wash stills, 2 spirit stills'],
    ['Capacity', '4,000,000 litres of alcohol per annum'],
  ],
  Daftmill: [
    [`Daftmill Distillery is a single malt Scotch whisky distillery, located in a converted barn at Daftmill Farm in the Howe of Fife.
    The distillery was granted a licence by HMRC in 2005 to produce whisky and the first was produced on 16 December 2005`],
    ['Location', 'Cupar, Fife'],
    ['Coordinates', '56°17′50.10″N 3°06′14.34″WCoordinates: 56°17′50.10″N 3°06′14.34″W'],
    ['Founded', '2005'],
    ['Status', 'Active'],
    ['Water source', 'Cupar / Daftmill spring'],
    ['No. of stills', '1 wash still, 1 spirit still'],
    ['Capacity', '2,500 litre wash still / 1,500 litre spirit still'],
  ],
  Dailuaine: [
    [`Dailuaine (Scottish Gaelic: Dail Uaine, [t̪alˈuəɲə], "Green Meadow") is a single malt whisky distillery in Charlestown-of-Aberlour, Strathspey, Scotland.`],
    ['Owner', 'Diageo'],
    ['Founded', '1852'],
    ['Status', 'Active'],
    ['Water source', 'The Bailliemullich Burn'],
    ['No. of stills', '3 wash stills (18.700 liter), 3 spirit stills(20.500 liter)[1]'],
    ['Capacity', '3,370,000 litres'],
  ],
  Dalmore: [
    [`The Dalmore distillery is located in Alness, Scotland, which is 20 miles (32 km) north of Inverness. It sits on the banks of the Cromarty Firth overlooking the Black Isle, the "big meadowland", from which it takes its name.

    The Dalmore distillery is owned and operated by Whyte & Mackay, which is owned by Philippines based Emperador Inc`],
    ['Location', 'Alness, Scotland'],
    ['Owner', 'Whyte & Mackay'],
    ['Founded', '1839'],
    ['Status', 'Operational'],
    ['Water source', 'Alness river'],
  ],
  Dalwhinnie: [
    [`Dalwhinnie distillery, situated in the Highland village of Dalwhinnie in Scotland, produces Single Malt Scotch Whisky. Dalwhinnie refers to its own whisky as a Highland Whisky, as the Speyside region is wholly within the Highlands and so Speyside distilleries can be legally labelled as either Highland or Speyside under SWA regulations`],
    ['Location', 'Dalwhinnie'],
    ['Owner', 'Diageo'],
    ['Founded', '1898'],
    ['Founder', 'John Grant, George Sellar and Alexander Mackenzie'],
    ['Architect', 'Charles Chree Doig'],
    ['Status', 'Active'],
    ['Water source', 'Allt an t-Sluic'],
  ],
  Deanston: [
    [`Deanston distillery is a Single Malt Scotch whisky distillery located on the banks of the River Teith,[2] eight miles from the historic town of Stirling, at the gateway to the dramatic Loch Lomond & Trossachs National Park. It is the largest distillery owned by Scotch whisky producer Distell Group Limited, who also own Bunnahabhain Distillery on the Isle of Islay and Tobermory distillery on the Isle of Mull.`],
    ['Owner', 'Distell Group Limited'],
    ['Founded', '1965'],
    ['Status', 'active'],
    ['Water source', 'River Teith'],
    ['No. of stills', '2 wash stills 20.000l, 2 spirit stills 17.000l[1]'],
    ['Capacity', '3,000,000 litres'],
  ],
  Deeside: [
    [`One of Scotland’s new wave of experimental ‘craft’ whisky distilleries, Deeside is one of just a few Scottish whisky distilleries to be established by a brewery. Its cosy home within Banchory’s Deeside Brewery is fitting – the distillery draws on its brewers’ experience with varying barley roasts and alternative grains including rye and maize.`]
  ],
  Dufftown: [
    [`Dufftown distillery is a distiller of Scotch whisky in Dufftown, Banffshire, Scotland.


    Dufftown Distillery Built originally in 1896.
    Founded in 1895 as "Dufftown-Glenlivet Distillery", the distillery is currently owned by Diageo. The distillery operates six stills and has a capacity of 4,000,000 litres (880,000 imperial gallons; 1,100,000 US gallons) per year`]
  ],
  'Eden Mill': [
    [`Eden Mill St Andrews is a specialist independent microbrewery and distillery based in Guardbridge, Scotland, about 3 miles (5 km) north-west of St Andrews. It is located on a 38-acre (15 ha) site owned by the University of St Andrews. Brewing began in July 2012 and the brewery was "on track to lift turnover from £500,000 in its first full year to around £2 million in 2014".[1]

    Eden Mill became Scotland's first combined brewery and distillery`],
    ['Location', 'Guardbridge, Fife'],
    ['Founded', '2012'],
    ['Status', 'Active'],
    ['No. of stills', '3 Alembic Copper Pot Stills'],
    ['Capacity', '0.06 MLPA'],
  ],
  Edradour: [
    [`Edradour distillery (Scottish Gaelic: Eadar Dhà Dhobhar, "between two rivers")[2] is a Highland single malt whisky distillery based in Pitlochry, Perthshire. Its owners claim it to be the smallest distillery in Scotland. It has been owned by the Signatory Vintage Scotch Whisky Company since 2002, and was previously owned by Pernod Ricard.[3][4] Signatory, founded in 1988, is primarily an independent bottler and is based in Pitlochry.`],
    ['Owner', 'Signatory Vintage Scotch Whisky Company'],
    ['Founded', '1825'],
    ['Status', 'Active'],
    ['Water source', 'Ben Vrackie Springs'],
    ['No. of stills', '1 wash still 4200 L, 1 spirit still 2200 L[1]'],
    ['Capacity', '95,000 litres'],
  ],
  Fettercairn: [
    [`Fettercairn is a whisky distillery in Fettercairn. Situated under the Grampian foothills in the Howe of Mearns, Fettercairn town’s name is loosely based on the phrase "the foot of the mountain".
    Fettercairn is owned by Whyte & Mackay Distillers.`],
    ['Location', 'FettercairnCoordinates: 56.8535°N 2.5825°W'],
    ['Owner', 'Whyte & Mackay'],
    ['Founded', '1824'],
    ['Founder', 'Sir Alexander Ramsay'],
    ['Status', 'Open'],
    ['Water source', 'Sources on the Grampian mountains'],
    ['No. of stills', 'Two wash (12,800 L), two spirit (12,800 L)[1] and 11 Washbacks'],
    ['Capacity', '2.2 million litres of pure alcohol per year [approx.]'],
  ],
  Glenallachie: [
    [`The Glenallachie Distillery is a malt whisky distillery on Speyside at Aberlour founded in 1967, which sits at the foot of Ben Rinnes. Previously, the distillery mainly produced whisky for blends however has since been relaunched as a distillery known for its fruity, single malt expressions`],
    ['Location', 'AberlourCoordinates: 57.45518°N 3.22871°W'],
    ['Owner', 'The GlenAllachie Distillers Co Limited'],
    ['Founded', '1967'],
    ['Founder', 'Mackinlay McPherson'],
    ['Architect', 'W. Delme Evans'],
    ['Status', 'Open'],
    ['Water source', 'Sources on Ben Rinnes'],
    ['No. of stills', 'Two wash, two spirit'],
    ['Capacity', '4,000,000 litres of pure alcohol per year'],
  ],
  Glenburgie: [
    [`Glenburgie, also known as Glenburgie-Glenlivet, Glen Burgie or Glenburry[2] is a whisky distillery near Alves, Moray`],
    ['Location', 'Alves, Moray'],
    ['Owner', 'Pernod Ricard'],
    ['Founded', '1810'],
    ['Status', 'Open'],
    ['No. of stills', '3 wash (11,750 L),[1] 3 spirit (15,000 L)'],
    ['Capacity', '4,200,000 litres of pure alcohol per year'],
  ],
  Glencadam: [
    [`The Glencadam distillery is a distillery in Brechin, Angus, Scotland that produces single malt Scotch whisky. The distillery is owned by Angus Dundee plc and produces one malt whisky, with the remainder of production sold to blenders or used within Angus Dundee plc for use in blended whisky brands`],
    ['Location', 'Brechin, Angus'],
    ['Owner', 'Angus Dundee plc'],
    ['Founded', '1825'],
    ['Status', 'Operational'],
    ['Water source', 'River South Esk'],
    ['No. of stills', '1 wash (14,000 L)[1], 1 spirit (14,000 L)'],
    ['Capacity', '1,500,000 litres/per annum'],
  ],
  Glendronach: [
    [`Glendronach distillery is a Scottish whisky distillery located near Forgue, by Huntly, Aberdeenshire, in the Highland whisky district.[2] It was owned by the BenRiach Distillery Company Ltd.

    The distillery was founded in 1826 by James Allardes (referred to often as Allardice) as the second distillery to apply for a licence to legally produce whisky under the Excise Act of 1823,[3] which passed three years earlier and which allowed for the distilling of Whisky in Scotland`],
    ['Owner', 'Brown-Forman'],
    ['Founded', '1826'],
    ['Status', 'Active'],
    ['Water source', 'Dronac Burn'],
    ['No. of stills', '2 wash (13,635 L),[1] 2 spirit (6,800 L)'],
    ['Capacity', '1,300,000 litres per annum']

  ],
  Glendullan: [
    [`Glendullan is a single malt Scotch whisky, produced at the Glendullan Distillery in the Speyside region. Glendullan is owned by Diageo. `],
    ['Location', 'Dufftown'],
    ['Owner', 'Diageo'],
    ['Founded', '1897'],
    ['Status', 'Operational'],
    ['Age(s)', 'Twelve years, Rare and Fine Malts- Twenty-Three Years Distilled in 1974'],
  ],
  'Glen Elgin': [
    [`Glen Elgin Distillery nestles quietly in the heart of Speyside. Situated approximately three miles (4.8 km) south of Elgin on the road to Rothes, it was the last distillery to be built during the boom years of the 1890s. The architect, Charles Doig, stated it would be the last one built in Speyside for 50 years.`],
    ['Owner', 'Diageo'],
    ['Founded', '1898'],
    ['Status', 'Active'],
    ['Water source', 'Millbuies Loch Springs'],
    ['No. of stills', '3 wash stills (7000 L), 3 spirit stills (8000 L)[1]'],
    ['Capacity', '1,830,000'],
  ],
  Glenfarclas: [
    [`Glenfarclas distillery is a Speyside whisky distillery in Ballindalloch, Scotland. Glenfarclas translates as meaning valley of the green grass. The distillery is owned and run by the Grant family.[1] The distillery has six stills which are the largest on Speyside[2] and are heated directly by gas burners.

    The distillery has a production capacity of around 3.5 million litres of spirit per year. Normally four stills are used for production with two kept in reserve.`],
    ['Owner', 'J. & G. Grant'],
    ['Founded', '1836'],
    ['Status', 'Active'],
    ['Water source', 'Ben Rinnes'],
    ['No. of stills', '3 wash stills, 3 spirit stills'],
    ['Capacity', '3,000,000 litres'],
  ],
  Glenfiddich: [
    [`Glenfiddich (Scottish English: [ɡlɛnˈfɪdɪç][1]) is a Speyside single malt Scotch whisky produced by William Grant & Sons in the Scottish burgh of Dufftown in Moray. Glenfiddich means "valley of the deer" in Scottish Gaelic, which is why the Glenfiddich logo is a stag.[1][2]

    Glenfiddich is the world's best-selling single-malt whisky[3] and also the most awarded at the International Spirits Challenge`],
    ['Location', 'Dufftown, Moray, Scotland'],
    ['Owner', 'William Grant & Sons'],
    ['Founded', '1886'],
    ['Status', 'Operational'],
    ['Water source', 'The Robbie Dhu Spring'],
    ['No. of stills', '32 spirit stills'],
    ['Capacity', '13,000,000 litres'],
    ['Website', 'glenfiddich.com'],
  ],
  'Glen Garioch': [
    [`Glen Garioch distillery (pronounced "Geery" in the Doric dialect of Aberdeen) is one of the oldest whisky distilleries in Scotland, dating back to 1797.`],
    ['Location', 'Aberdeenshire'],
    ['Owner', 'Morrison Bowmore Distillers'],
    ['Founded', '1797'],
    ['Status', 'Operational'],
    ['Water source', 'The Silent Spring of Coutens Farm'],
    ['No. of stills', '2 wash (20,000 + 10,000 L), 2 spirit (11,000 L)[1]'],
  ],
  Glenglassaugh: [
    [`The Glenglassaugh distillery is a malt scotch whisky distillery which restarted production in November 2008 after being acquired by an independent investment group. Following a complete refurbishment by the new owners the distillery was re-opened on 24 November 2008 by the First Minister for Scotland Alex Salmond.`],
    ['Location', 'Portsoy, Banffshire'],
    ['Owner', 'Brown-Forman'],
    ['Founded', '1875'],
    ['Status', 'Operational as of November 2008'],
    ['Capacity', '1,000,000 litres/per annum'],
  ],
  Glengoyne: [
    [`
    Glengoyne Distillery is a whisky distillery continuously in operation since its founding in 1833 at Dumgoyne, north of Glasgow, Scotland. Glengoyne is unique in producing Highland single malt whisky matured in the Lowlands.`],
    ['Location', 'Dumgoyne'],
    ['Owner', ' Ian Macleod Distillers Ltd'],
    ['Founded', '1833[1]'],
    ['Status', 'Operational'],
    ['Water source', 'Stream water from Dumgoyne Hill[2]'],
    ['No. of stills', '1 wash still[1], 2 spirit stills[1]'],
    ['Capacity', ' 1,100,000 litres[1]'],
  ],
  'Glen Grant': [
    [`Glen Grant is a distillery founded in 1840 in Rothes, Speyside, that produces single malt Scotch whisky. Previously owned by Chivas Brothers Ltd, best known for their Chivas Regal blended scotch whisky, Glen Grant was purchased[2] by the Italian company Campari Group in December 2005. It is the biggest selling single malt Scotch whisky in Italy`],
    ['Owner', 'Gruppo Campari'],
    ['Founded', '1840'],
    ['Status', 'Active'],
    ['Water source', 'The Glen Grant burn'],
    ['No. of stills', '4 wash stills (15,000 L), 4 spirit stills (9,600 L)[1]'],
    ['Capacity', '5,900,000 litres'],
  ],
  Glengyle: [
    [`Glengyle distillery is a long-established Scotch whisky production centre in Campbeltown, Argyll & Bute, that closed down in 1925. In 2000 it was totally re-planned as the first new Scottish distillery of the millennium. Its single malts are bottled under the name Kilkerran.`],
  ],
  'Glen Keith': [
    [`Glen Keith is a distillery built by and owned by Chivas Brothers (part of the Pernod Ricard group since 2001) within eyesight of their centre piece distillery, the Strathisla Distillery.`],
    ['Location', 'Keith, Moray'],
    ['Owner', 'Chivas Brothers'],
    ['Founded', '1957'],
    ['Status', 'Under redevelopment'],
    ['Water source', 'Balloch Hill'],
    ['No. of stills', '3 wash stills, 3 spirit stills'],
    ['Capacity', '3,500,000 Litres per annum (former)'],
  ],
  Glenkinchie: [
    [`Glenkinchie Distillery is a Scotch single malt whisky distillery in East Lothian, Scotland. It is one of the six distilleries in the Lowland region.[1] It is owned by the drinks giant Diageo.`],
    ['Location', 'East Lothian'],
    ['Owner', 'Diageo'],
    ['Founded', '1837'],
    ['Founder', 'George and John Rate'],
    ['Status', 'active'],
    ['Capacity', '2,700,000 litres'],
  ],
  Glenlivet: [
    [`The Glenlivet distillery is a distillery near Ballindalloch in Moray, Scotland that produces single malt Scotch whisky. It is the oldest legal distillery in the parish of Glenlivet, and the production place of the Scottish whisky of the same name. It was founded in 1824 and has operated almost continuously since`],
    ['Location', 'Moray'],
    ['Owner', 'Pernod Ricard'],
    ['Founded', '1824'],
    ['Status', 'Operational'],
    ['Water source', `Josie's Well (yielding 3,500 imp gal or 16,000 L of pure cold water per hour), which is supplemented by the Blairfindy Well.`],
    ['No. of stills', '14 copper pot stills (7 Wash @ 15,000 L, 7 Spirit @ 10,000 L)'],
    ['Capacity', '10,500,000-Lpa (liters of pure alcohol)'],

  ],
  Glenlossie: [
    [`Mannochmore distillery is a Scottish Whisky distillery near Elgin. The distillery was founded in 1971 by John Haig & Co. Between 1985 and 1989, production was suspended and the distillery closed; it was then reopened and in 1992 started producing a Mannochmore single malt.`],
    ['Location', 'Elgin'],
    ['Owner', 'United Vintners (Diageo)'],
    ['Founded', '1971'],
    ['Founder', 'John Haig & Co.'],
    ['Status', 'active'],
    ['Water source', 'Bardon Burn'],
    ['No. of stills', '3 Wash stills: each 14,400 L, 3 Spirit stills: each 17,000 L'],
  ],
  Glenmorangie: [
    [`is a distillery in Tain, Ross-shire, Scotland, that produces single malt Scotch whisky. The distillery is owned by The Glenmorangie Company Ltd (a subsidiary of Louis Vuitton Moët Hennessy), whose main product is the range of Glenmorangie single malt whisky. Glenmorangie is categorised as a Highland distillery and boasts the tallest stills in Scotland.`],
    ['Location', 'Ross-shire'],
    ['Coordinates', '57°49′33.2″N 4°4′40.8″WCoordinates: 57°49′33.2″N 4°4′40.8″W'],
    ['Owner', 'Louis Vuitton Moët Hennessy'],
    ['Founded', '1843[1]'],
    ['Status', 'Operational'],
    ['Water source', 'Tarlogie Springs in the Tarlogie Hills'],
    ['No. of stills', '6 wash stills'],
    ['Capacity', '6,000,000 litres per annum'],
  ],
  'Glen Moray': [
    [`Glen Moray distillery is a Speyside distillery producing single malt scotch whisky. Situated on the banks of the River Lossie in Elgin, Moray the distillery started production in September 1897. It was sold in 2008 by the Glenmorangie Company Ltd. to La Martiniquaise.`],
  ],
  'Glen Ord': [
    [`The Singleton of Glen Ord is a whisky distillery in the Scottish Highlands and is the only remaining single malt scotch whisky distillery on the Black Isle.[4] They produce a 12 year old, 15 year old and 18 year old single malt that's available for export only to Southeast Asia.`],
    ['Location', 'Ross-shire'],
    ['Owner', 'Diageo'],
    ['Founded', '1838[1]'],
    ['Status', 'Operational'],
    ['Water source', 'Loch nam Bonnach, Loch nan Eun form the White Burn'],
    ['No. of stills', '7 wash (17,000 L), 7 spirit (16,000 L)[2]'],
    ['Capacity', '11,500,000 litres'],
  ],
  'Glen Scotia': [
    [`Glen Scotia distillery or sometimes affectionately known as The Scotia or Old Scotia is a distillery that is a producer of single malt Scotch whisky. The distillery was founded in 1832 and is one of just three distilleries left in Campbeltown, the smallest whisky region`],
    ['Owner', 'Loch Lomond Group'],
    ['Founded', '1832'],
    ['Status', 'Operational'],
    ['Water source', 'Crosshill Loch'],
    ['No. of stills', '1 wash (16,000 L), 1 spirit (12,000 L)[2]'],
    ['Capacity', '600,000 litres'],

  ],
  'Glen Spey': [
    [`The Glen Spey distillery is a single malt Scotch whisky distillery in Rothes, Moray at the centre of the Speyside whisky producing area of Scotland.

    Glen Spey distillery was built in 1878 by James Stuart & Co. under the name 'Mill of Rothes'. It actually started its life as an oatmeal mill - founder James Stuart was a corn merchant before he ventured into the whisky world. `],
  ],
  Glentauchers: [
    [`Glentauchers distillery is a Speyside Scottish whisky distillery, founded in 1897. The building was designed by John Alcock, and overseen by Charles Doig & Son. It starting producing when James Buchanan Co. Ltd. and three members of Glentauchers Distillery Co. joined hands with Glentauchers a year after that. It was mothballed by United Distillers in 1985, and sold to Allied Distillers in 1989.`],
    ['Location', 'Mulben, Keith, Scotland'],
    ['Coordinates', '57°32′3″N 3°3′1″WCoordinates: 57°32′3″N 3°3′1″W'],
    ['Founded', '1897'],
    ['Capacity', '4,500,000 litres (990,000 imp gal)'],
    ['Mothballed', '1985–1992'],
  ],
  Glenturret: [
    [`The Glenturret Distillery is located two miles north west of Crieff in Perthshire, Scotland.[1] on the banks of the Turret River. The distillery is hidden in the glen and its secluded location may have contributed to its early history as the site of several illicit bothy stills`],
    ['Location', 'Perthshire'],
    ['Owner', 'Lalique Group SA'],
    ['Founded', '1763'],
    ['Status', 'Operational'],
    ['Water source', 'Loch Turret in Glenturret'],
    ['No. of stills', '1 wash still, 1 spirit still'],
    ['Capacity', '340,000 litres/per annum'],
  ],
  GlenWyvis: [
    [`Glenwyvis is a Scotch whisky and gin distillery located in Dingwall, Scotland.[1]

    The distillery is owned by a group of crowdfunders and is entirely powered by renewable energies like hydro, wind, solar, and chip biomass heating systems. Its name comes from the idea of carrying on old traditions of the past and contemplates two previous distilleries of the area, the Ben Wyvis in Dingwall and the Glenskiach in Evanton.`],
    ['Location', 'Dingwall'],
    ['Founded', '2015'],
    ['Founder', 'John Mckenzie'],
    ['Status', 'Operational'],
    ['No. of stills', '2 spirit stills'],
  ],
  Harris: [
    [`The Isle of Harris Distillery began with a simple idea.

    The inspiration came from Anderson 'Burr' Bakewell, who has been bound to this island through a long connection with nearby Scarp, and many decades of life spent among the landscapes of Harris and people of our community.
    
    He believed that the rare and elusive spirit of this island could be captured in a bottle and shared with the world.`],
    
  ],
  'Highland Park': [
    [`Highland Park distillery is the northernmost single malt Scotch whisky distillery in Scotland,[1] located in Kirkwall on the Orkney Islands`],
    ['Location', 'Kirkwall, Orkney'],
    ['Owner', 'Edrington Group'],
    ['Founded', '1798'],
    ['Status', 'Operational'],
    ['No. of stills', '2 wash, 2 spirit'],
    ['Capacity', '2,500,000 litres'],
  ],
  Holyrood: [
    [`Holyrood is a modern distillery in the heart of historic Edinburgh. Inspired by our surroundings, and never afraid to experiment, they are dedicated to shaping the spirit of tomorrow.

    With considerable experience in the whisky industry, their founders had a vision to create a new kind of distillery, one that would reflect the dynamism and diversity of present day Edinburgh.`],
  ],
  Inchgower: [
    [`Inchgower distillery is a whisky distillery producing a single malt of the same name located on the outskirts of Buckie, Moray, Scotland.

    The distillery was built in 1871 to replace Tochineal Distillery but liquidated in 1903. Buckie Council purchased the concern in 1936 and ownership was transferred to Arthur Bell & Sons Ltd in 1938 and indeed to this very day the Bell's logo is used in the advertising of Inchgower`],
    ['Owner', 'Diageo'],
    ['Founded', '1871'],
    ['Status', 'Active'],
    ['Water source', 'Menduff Hills springs'],
    ['No. of stills', '2 wash stills, 2 spirit stills'],
    ['Capacity', '1,990,000 litres'],
  ],
  Jura: [
    [`Jura distillery is a Scotch whisky distillery on the island of Jura in the Inner Hebrides off the West Coast of Scotland. The distillery is owned by Glasgow founded and based Whyte and Mackay, which in turn is owned by Philippines-based Emperador Inc.`],
    ['Location', 'Jura'],
    ['Owner', 'Whyte & Mackay'],
    ['Founded', '1810'],
  ],
  Kilchoman: [
    [`The Kilchoman Distillery (pronounced Kil-ho-man)[1] is a distillery that produces single malt Scotch whisky on Islay, an island of the Inner Hebrides. Kilchoman Distillery is in the northwest of the island, close to Machir Bay. Kilchoman was founded by Anthony Wills and remains an independent, family run distillery.`],
    ['Location', 'Islay'],
    ['Owner', 'Anthony Wills'],
    ['Founded', '2005'],
    ['Status', 'Operational'],
    ['No. of stills', '2 wash, 2 spirit'],
    ['Capacity', '221,000 L (49,000 imp gal)'],
  ],
  Kingsbarns: [
    [`Kingsbarns Distillery and Visitor Centre opened in November 2014 and began filling barrels of spirit the following March. It was founded by a local golf caddie who wished to convert a historic and semi-derelict farm-steading into a distillery.

    Kingsbarns pot stills were hand-made at Forsyths in Rothes, Speyside. Kingsbarns new make spirit has been bottled giving visitors and curious whisky drinkers a unique chance to sample the spirit before it matures. `],
  ],
  Kininvie: [
    [`The Kininvie distillery is a distillery in Dufftown, Moray, which produces single malt Scotch whisky. The distillery is owned by William Grant & Sons and the product from the distillery is used in their blended malt and blended whisky brands.`],
    ['Location', 'Dufftown, Moray'],
    ['Owner', 'William Grant & Sons'],
    ['Founded', '1990'],
    ['Status', 'Open'],
    ['Water source', 'Conval Hills'],
    ['No. of stills', '3 wash stills, 6 spirit stills'],
  ],
  Knockando: [
    [`Knockando distillery is a single malt Scotch whisky distillery, located in Knockando, Moray, in the Strathspey whisky-producing area of Scotland.
    
    Knockando Distillery was built by John Tytler Thomson in 1898, and is named after the village in which it stands.`],
    ['Location', 'Knockando, Moray'],
    ['Owner', 'Diageo'],
    ['Founded', '1898'],
    ['Water source', 'The Cardnach Spring'],
    ['No. of stills', '2 wash stills, 2 spirit stills'],
    ['Capacity', '1,800,000 litres/per annum'],
  ],
  Knockdhu: [
    [`The Knockdhu distillery is a whisky distillery in Knock, Banffshire owned by Inver House Distillers Limited.`],
    ['Location', 'Knock, Banffshire'],
    ['Owner', 'Inver House'],
    ['Founded', '1894'],
    ['Status', 'Operational'],
    ['Water source', 'Spring water from Knock Hill'],
    ['No. of stills', '1 wash still, 1 spirit still'],
    ['Capacity', '1,700,000 litres per annum'],
  ],
  Lagg: [
    [`While Arran’s classic whisky style is unpeated, the distillery at Lochranza distils a small amount of peated malt every year for its Machrie Moor expression. In opening Lagg distillery on the south side of the island, Isle of Arran Distillers will shift all production of peated whisky to its new site, a distillery that will be dedicated to the exploration of peat.

    Lagg single malt itself will be a heavily peated style, made using barley with a phenol content of 50ppm. While all barley will be malted on the mainland, the peat used to dry the barley will be sourced from all across Scotland, perhaps even the world, as Lagg explores the impact of peat terroir on whisky flavour.`]
  ],
  Lagavulin: [
    [`Lagavulin distillery is a malt whisky distillery in the village of Lagavulin on the south of the island of Islay, Scotland.[1] It distills spirit that is destined to become Islay single malt Scotch whisky.`],
    ['Location', 'Islay'],
    ['Owner', 'Diageo'],
    ['Founded', '1816'],
    ['Status', 'Operational'],
    ['Water source', 'Solum Lochs'],
  ],
  Laphroaig: [
    [`The Laphroaig distillery was established in 1815 by Donald and Alexander Johnston.[1] The Johnstons who founded Laphroaig were from the Clan Donald and are likely to be from the MacIain of Ardnamurchan branch of the clan. The family anglicised their name to Johnston.[1] The last member of the Johnston family to run the distillery was Ian Hunter, a nephew of Sandy Johnston, who died childless in 1954 and left the distillery to one of his managers, Bessie Williamson.`],
    ['Location', 'Port Ellen'],
    ['Owner', 'Beam Suntory'],
    ['Founded', '1815[1]'],
    ['Status', 'Operational'],
    ['Water source', 'The Kilbride Dam'],
    ['No. of stills', '3 wash, 4 spirit[2]'],
    ['Capacity', '3,300,000 litres[3]'],
  ],
  Leven: [
    [`

    This experimental micro distillery is not open for the public. It is situated within the Leven bottling plant of Diageo in Fife.
    
    
    There are two different types of mills: four steel roller mill and and hammer mill for both malted barley and grain. At present there is no column still in operation. The semi lauter mash tun may also be operated as a mash cooking vessel. The two pot stills are largely smaller copies of the Royal Lochnagar pot stills in the Highlands.
    
    
    Leven experimental distillery is also the only licensed pot still distillery in Scotland without a spirit safe as it is used only for experimental distillation processes.`],

  ],
  'Lindores Abbey': [
    [`After a break of 523 years, spirit is once again flowing from the copper stills at Lindores Abbey.

    With Lindores Abbey’s widely recognised links to the earliest written reference to Scotch Whisky and learning as much as we can from the great Scottish distillers around us, past and present, we are bringing a very modern whisky-making approach to this ancient site.`]
  ],
  Linkwood: [
    [`The Linkwood Distillery is a whisky distillery in Elgin, in the Speyside region of Scotland. It is owned by the British drinks giant Diageo. The distillery was built in 1821 by Peter Brown with two stills, and started production in 1825 with a capacity of 4,500 litres per year.  The distillery was operated by James Walker until Browns death in 1868, after which the distillery went under the control of his son, William Brown.`],
    ['Location', 'Elgin'],
    ['Coordinates', '57.635448°N 3.286238°W'],
    ['Owner', 'Diageo'],
    ['Founded', '1821'],
    ['Status', 'Operational'],
    ['Water source', 'springs near Milbuies Loch'],
    ['No. of stills', '3 wash, 3 spirit (1 wash, 1 spirit in Linkwood A, 2 wash 2 spirit in Linkwood B)'],
    ['Capacity', '2,500,000 litres'],
    ['Mothballed', '1941 to 1945, 1985 to 1990'],
  ],
  'Loch Lomond': [
    [`The Loch Lomond distillery is a Highland Single Malt Scotch whisky distillery in Alexandria, Scotland, near Loch Lomond.`],
    ['Owner', 'Loch Lomond Distillery Company'],
    ['Founded', '1964'],
    ['Status', 'Active'],
    ['No. of stills', '8 Pot Stills & 3 Column Stills'],
  ],
  'Royal Lochnagar': [
    [`Royal Lochnagar distillery is a single malt Scotch whisky distillery based on the Abergeldie Estate on Royal Deeside, in the Highland whisky-producing area of Scotland. It close to the mountain Lochnagar.`],
    ['Owner', 'Diageo'],
    ['Founded', '1845'],
    ['Status', 'Active'],
  ],
  'Longmorn': [
    [`Longmorn distillery is a single malt Scotch whisky distillery in Longmorn, Moray, within the Strathspey whisky producing area of Scotland.`],
    ['Location', 'Longmorn, Moray'],
    ['Owner', 'Pernod Ricard'],
    ['Founded', '1893'],
    ['Status', 'Operational'],
    ['Water source', 'Local Springs'],
    ['No. of stills', '4 wash stills, 4 spirit stills'],
    ['Capacity', '3,500,000 litres per annum'],
  ],
  'Macallan': [
    [`The Macallan distillery is a single malt Scotch whisky distillery in Craigellachie, Moray, Scotland. The Macallan Distillers Ltd is a wholly owned subsidiary of the Edrington Group, which purchased the brand from Highland Distillers in 1999.`],
    [`Location`, `Easter Elchies, Craigellachie, Moray, Scotland`],
    [`Coordinates`, `57°29′3.74″N 3°12′27.28″W`],
    [`Owner`, `Edrington Group`],
    [`Founded`, `1824`],
    [`Status`, `Operational`],
    [`No. of stills`, `12 wash | 24 spirit[citation needed]`],
  ],
  'Macduff': [
    [`Macduff distillery is a single malt Scotch whisky distillery in Macduff in the Highland whisky producing area of Scotland.The Macduff Distillery Company was founded in 1962. During its history, the distillery's official name has fluctuated between Glen Deveron and MacDuff with official bottlings mostly having been released under the former.`],
    [`Location`, `Macduff`],
    [`Owner`, `Bacardi`],
    [`Founded`, `1960`],
    [`Status`, `Operational`],
    [`Water source`, `Local`],
    [`No. of stills`, `2 wash stills, 3 spirit stills`],
    [`Capacity`, `2,400,000 litres of alcohol per annum`],
  ],
  'Mannochmore': [
    [`Mannochmore distillery is a Scottish Whisky distillery near Elgin. `],
    [`Location`, `Elgin`],
    [`Owner`, `United Vintners (Diageo)`],
    [`Founded`, `1971`],
    [`Founder`, `John Haig & Co.`],
    [`Status`, `active`],
    [`Water source`, `Bardon Burn`],
  ],
  'Miltonduff': [
    [`Situated six miles southwest of Elgin is Pluscarden Abbey. Initially a Priory, it was founded by King Alexander II in 1230. The Miltonduff distillery is said to be situated on the site of the Abbey's meal mill and one of the original stones from the Abbey is still proudly on display.

    The Miltonduff expressions bottled by G&M demonstrate the extreme versatility of this excellent Speysider: rich spicy, dark honey notes can be found in the Cask Strength Collection of bottlings, whilst The Distillery Labels collection showcases a lighter, more floral side to the distillery.
    
    One other fascinating fact is that From 1967 until 1981, Miltonduff also produced another single malt called Mosstowie, using a Lomond still.`],
  ],
  'Mortlach': [
    [`Mortlach distillery is a distiller of Scotch whisky in Dufftown, Moray, Scotland. Founded in 1823, the distillery is currently owned by Diageo.[1] The whisky is a key component in several Johnnie Walker bottlings.,[2] while Diageo also markets four Mortlach single malts.`],
    [`Owner`, `Diageo`],
    [`Founded`, `1823`],
    [`Status`, `Active`],
    [`No. of stills`, `3 wash stills, 3 spirit stills`],
    [`Capacity`, `3,700,000 litres`],
  ],
  'Nc’nean': [
    [`A young, independent whisky distillery, Nc’nean started distilling an organic single malt whisky here on Drimnin Estate in March 2017.`],
  ],
  'Oban': [
    [`Oban distillery is owned by Diageo. It has only two pot stills, making it one of the smallest in Scotland, producing a whisky that has been described as having a "West Highland" flavour that falls between the dry, smoky style of the Scottish islands and the lighter, sweeter malts of the Highlands`],
    [`Location`, `Oban`],
    [`Owner`, `Diageo`],
    [`Founded`, `1794`],
    [`Founder`, `John and Hugh Stevenson`],
    [`Status`, `Operational`],
    [`Water source`, `Two lochs in Ardconnel`],
    [`No. of stills`, `1 wash, 1 spirit`],
    [`Capacity`, `670,000 litres per year`],
  ],
  'Pulteney': [
    [`The Pulteney Distillery is a malt whisky production and aging facility in the Pulteneytown area of Wick, Caithness, in the Highland area of Scotland. The distillery produces the Old Pulteney single malt whisky at a number of ages and has a visitor centre in Huddart Street.`],
    [`Owner`, `InterBev (Inver House Distillers)`],
    [`Founded`, `1826`],
    [`Status`, `Active`],
    [`No. of stills`, `1 wash still, 1 spirit still`],
    [`Capacity`, `1,000,000 litres`],
  ],
  'Raasay': [
    [`Isle of Raasay Distillery is a Scotch whisky distillery on the Inner Hebridean Isle of Raasay in Northwest Scotland.[1] The distillery is owned by R&B Distillers and was the first legal distillery on the Isle of Raasay when it opened in 2014`],
    [`Industry`, `Distillery`],
    [`Founded`, `2014`],
    [`Founder`, `Bill Dobbie & Alasdair Day`],
    [`Headquarters`, `Isle of Raasay, United Kingdom`],
    [`Products`, `Distilled and blended liquors`],
  ],
  'Roseisle': [
    [`Roseisle is a distillery of Speyside single malt Scotch whisky, in Roseisle, near Elgin, Morayshire, in the Strathspey region of Scotland.

    The distillery is owned by multinational drinks company Diageo.
    
    The distillery opened in 2010 and is the largest-ever built at 3,000 sq m and a cost of £40million`],
  ],
  'Scapa': [
    [`Speyburn Distillery in Rothes, Moray, Scotland, was founded in 1897 by John Hopkins & Company for the sum of £17,000. The site was chosen by John Hopkins himself for its unpolluted water supply from the Granty Burn, a minor tributary of the River Spey.`],
    [`Owner`, `InterBev (Inver House Distillers)`],
    [`Founded`, `1897`],
    [`Status`, `Active`],
    [`Water source`, `Granty Burn`],
    [`No. of stills`, `1 wash still, 1 spirit still`],
    [`Capacity`, `1,000,000 litres`],
  ],
  'Speyburn': [
    [`Speyburn Distillery in Rothes, Moray, Scotland, was founded in 1897 by John Hopkins & Company for the sum of £17,000. The site was chosen by John Hopkins himself for its unpolluted water supply from the Granty Burn, a minor tributary of the River Spey. Hopkins appointed the famous distillery architect Charles C Doig to design the distillery and to this day Speyburn has its classic pagoda ventilator, a hallmark of Doig's design. `],
    [`Owner`, `InterBev (Inver House Distillers)`],
    [`Founded`, `1897`],
    [`Status`, `Active`],
    [`Water source`, `Granty Burn`],
    [`No. of stills`, `1 wash still, 1 spirit still`],
    [`Capacity`, `1,000,000 litres`],
    [`Website`, `www.speyburn.com`],
  ],
  'Speyside': [
    [`The Speyside distillery is a Scotch whisky distillery near the hamlet of Drumguish in the Speyside region of Scotland, close to the village of Kingussie. It currently produces the SPEY single malt, BEINN DUBH Single malt and BYRON'S Hand crafted gin. The distillery was originally founded from an old Barley Mill built in the 1760s. Purchased in 1962 by George Christie it was built by hand by George Christie and a stone mason Alex Fairlie over the next 2 decades, and was finished in 1987. It took another three years until whisky production started in 1990. `],
  ],
  'Springbank': [
    [`The Springbank distillery is a family-owned single malt whisky distillery on the Kintyre Peninsula in western Scotland. It is owned by J & A Mitchell & Company, which also owns the Glengyle Distillery, the oldest independent bottler, William Cadenheads, and several blended scotch labels.`],
    [`Owner`, `J&A Mitchell & Company`],
    [`Founded`, `1828`],
    [`Status`, `Operational`],
    [`Water source`, `Crosshill Loch`],
    [`No. of stills`, `1 wash, 2 spirit`],
    [`Capacity`, `750,000 litres yearly`],
  ],
  'Strathearn': [
    [`Strathearn Distillery is Scotland’s original craft spirits distillery being founded in 2013 by Tony Reeman-Clark. It is the forerunner of the new wave of distilleries currently opening in Scotland. We had some very humble beginnings, which first meant finding a suitable site. We found this in a converted farm steading at Bachilton, just outside Methven, between Crieff and the historic whisky town of Perth. We are in deepest Perthshire, surrounded by the beautiful Strathearn countryside, tucked away in our own little corner of Scotland.`],
  ],
  'Strathisla': [
    [`Strathisla distillery is the oldest continuously operating distillery in the Scottish Highlands.`],
    [`Location`, `Keith, Moray`],
    [`Owner`, `Chivas Brothers`],
    [`Founded`, `1786`],
    [`Status`, `Operational`],
    [`Water source`, `The Broomhill Spring.`],
    [`No. of stills`, `2 wash stills, 2 spirit stills`],
    [`Capacity`, `2,400,000 Litres per annum`],
  ],
  'Strathmill': [
    [`Strathmill is one of three distilleries within the Moray town of Keith at the heart of "Malt Whisky Country". Unlike Keith's other two distilleries of Strathisla and Glen Keith, however, Strathmill is not owned by Chivas (part of the Pernod Ricard group) but since 1997 by Diageo who operate it for the production of blends such as J&B whisky.`],
    [`Location`, `Keith, Moray`],
    [`Owner`, `Diageo`],
    [`Founded`, `1891`],
    [`Status`, `Operational`],
    [`Water source`, `Local springs.`],
    [`No. of stills`, `2 wash stills, 2 spirit stills`],
    [`Capacity`, `1,800,000 Litres per annum`],
  ],
  'Talisker': [
    [`Talisker distillery is an island single malt Scotch whisky distillery based in Carbost, Scotland on the Minginish Peninsula on the Isle of Skye. The distillery is operated by Diageo, and Talisker's 10 year old whisky has been nominated as part of their Classic Malts series.`],
    [`Location`, `Carbost, Isle of Skye`],
    [`Owner`, `Diageo`],
    [`Founded`, `1830`],
    [`Status`, `Active`],
    [`Water source`, `Carbost / Cnoc nan Speireag (Hawk Hill)`],
    [`No. of stills`, `2 Wash stills,3 Spirit stills`],
  ],
  'Tamdhu': [
    [`Tamdhu distillery is a single malt Scotch Speyside whisky distillery, located in the village of Knockando in Banffshire, Scotland.[1] Tamdhu comes from Gaelic for "little dark hill".
    The distillery was founded in 1896 by a group of assemblers, wishing to engage in the production of their own whisky. The first malt whisky was distilled and casked in 1897. It quickly passed on into the hands of Highland Distillers. `],
    [`Location`, `Knockando in Banffshire, Scotland[1]`],
    [`Owner`, ` Ian MacLeod Distillers[2]`],
    [`Founded`, `1897`],
    [`Status`, `Operational`],
    [`No. of stills`, `3 wash, 3 spirit`],
    [`Capacity`, `4,500,000 litres`],
  ],
  'Tamnavulin': [
    [`The Tamnavulin distillery is a producer of single malt Scotch whisky that was founded in 1966.`],
    [`Location`, `Tomnavoulin, Ballindalloch, Banffshire, Scotland, United Kingdom`],
    [`Owner`, ` Emperador Inc.`],
    [`Founded`, `1966`],
    [`Status`, `Operational`],
    [`Water source`, `Springs in Easterton`],
    [`No. of stills`, `3 wash, 3 spirit`],
    [`Capacity`, `4,000,000 litres/year`],
  ],
  'Teaninich': [
    [`Teaninich is a whisky distillery in Alness. It was founded and built in 1817 by Hugh Munro on his estate of Teaninich Castle.[1] Despite an initial difficulty of procuring barley whisky owing to a high demand from illegal distilleries, by 1830 Teaninich produced 30 times more spirit than it did at its founding.`],
    [`Location`, `TeaninichCoordinates: 57.691583°N 4.260639°W`],
    [`Owner`, `Diageo`],
    [`Founded`, `1817`],
    [`Founder`, `Hugh Munro`],
    [`Status`, `Open`],
    [`Water source`, `Dairywell spring`],
    [`No. of stills`, `3 wash, 3 spirit`],
    [`Mothballed`, `1939 to 1946, 1985 to 1991`],
  ],
  'Tobermory': [
    [`Tobermory distillery is a Scotch whisky distillery situated on the Hebridean island of Mull, Scotland in the town of Tobermory. The distillery, which was formerly known as Ledaig, was founded in 1798 and has changed hands several times, having undergone a number of periods of closure.`],
    [`Owner`, `Burn Stewart (Distell)`],
    [`Founded`, `1798`],
    [`Founder`, `John Sinclair`],
    [`Status`, `active`],
    [`No. of stills`, `4 Wash stills, 4 Spirit stills`],
  ],
  'Tomatin': [
    [`Tomatin distillery is a single malt Scotch whisky distillery in the village of Tomatin. Its whisky is classified as being from the Highland region, as it is 25 minutes south of Inverness.
    
    Although it is thought that whisky has been distilled on the site since the 16th century, when cattle drivers would buy from a local still,[1] the distillery was not established until 1897, under the name of Tomatin Spey Distillery Co Ltd.`],
    [`Location`, `Inverness`],
    [`Owner`, `Takara Shuzo Corp.`],
    [`Founded`, `1897`],
    [`Status`, `Operational`],
    [`Water source`, `Alt-na-Frith Burn`],
    [`No. of stills`, `6 wash, 6 spirit`],
    [`Capacity`, `5,050,000 litres per year`],
  ],
  'Tomintoul': [
    [`The Tomintoul-Glenlivet Distillery is a distillery in Kirkmichael, between Ballindalloch and Tomintoul in the Speyside region of Scotland, producing malt whisky for blends and bottled as single malts. The distillery was founded in 1964. It doubled in capacity when two extra stills were installed in 1974, and started bottling single malt whisky in that year.`],
    [`Location`, `Kirkmichael, near TomintoulCoordinates: 57.312012°N 3.412366°W`],
    [`Owner`, `Angus Dundee`],
    [`Founded`, `1964`],
    [`Founder`, `Hey & Macleod and W. and S. Strong`],
    [`Status`, `Open`],
    [`Water source`, `Ballantruan spring`],
    [`No. of stills`, `Two wash, two spirit`],
    [`Capacity`, `3,300,000 litres of pure alcohol per year`],
  ],
  'Torabhaig': [
    [`The Distillery at Torabhaig is the second ever licenced Single Malt Scotch Whisky distillery on the Isle of Skye. The Excise Act of 1823 first sanctioned legal whisky distilling in Scotland almost 200 years ago, with the first licence on Skye being granted not long thereafter, so one could say it was about time.

      Single Malt Scotch Whisky is inextricably linked to its place of origin, perhaps more so than any other product. The water, the land, the climate, all play a role in the character of the whisky. All these factors are also vitally important in planning a new distillery. Simply put, you cannot just build a distillery anywhere.`],
  ],
  'Tormore': [
    [`Tormore distillery is a Speyside single malt Scotch whisky distillery located approximately 1 km south of the River Spey. Its water source is the Achvochkie Burn.`],
    [`Owner`, `Pernod Ricard (Chivas Brothers)`],
    [`Founded`, `1958`],
    [`Status`, `Active`],
    [`Water source`, `Achvochkie Burn`],
    [`No. of stills`, `Achvochkie Burn`],
    [`Capacity`, `3,700,000 litres`],
  ],
  'Tullibardine': [
    [`Tullibardine distillery is a Scottish distillery since 1949, producing a single malt whisky. The whisky distillery is located in Blackford, Perth and Kinross, close to the Ochil Hills and the Danny Burn, their main water sources.`],
    [`Location`, `Blackford, Perthshire`],
    [`Owner`, `Picard Vins & Spiritueux`],
    [`Founded`, `1949`],
    [`Status`, `Operational`],
    [`Water source`, `Ochil Hills, Danny Burn`],
    [`No. of stills`, `2 wash, 2 spirit`],
    [`Capacity`, `2,700,000 litres`],
  ],
  'Wolfburn': [
    [`Wolfburn distillery is a Scotch whisky distillery in Thurso, Caithness, Scotland. After ceasing production in the 1860s it reopened in 2013.`],
    [`Location`, `Thurso, Caithness`],
    [`Founded`, `1822 (closed c. 1877 – reopened 2013)`],
    [`Status`, `Operational`],
    [`Water source`, `Wolf Burn`],
    [`No. of stills`, `1 wash, 1 spirit`],
    [`Capacity`, `115,000 litres`],
    [`Cask type(s)`, `American Oak Bourbon, Oak Quarter Cask, Bourbon Hogsheads, Oloroso sherry`],
  ],
}