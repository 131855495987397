import React, { useEffect, useMemo, useState } from 'react';
import Store from 'store';

export const useCommission = () => {  
  const [{ listClients }, cApi] = Store.Client.createStore();
  const [{ email }] = Store.User.createStore();
  useEffect(() => {
    if (listClients.length === 0) cApi.getListClients();
    cApi.getListAvailableCasks();
  }, []);

  const { portfolioValue } = (() => {
    if (listClients.length === 0 || !email) return { portfolioValue: 0 };
    const filterClient = listClients.filter(val => val.Email === email);
    if (filterClient.length > 0) return filterClient.reduce((acc, val) => {
      return {
        portfolioValue: acc.portfolioValue + +val['Portfolio'],
      }
    }, { portfolioValue: 0 });
    return {
      portfolioValue: 0,
    };
  })();

  const comission = [
    { displayValue: 10, name: 'Barrel', range: [0, 25000] },
    { displayValue: 8.5, name: 'Hogshead', range: [25001, 100000] },
    { displayValue: 7, name: 'Butt', range: [100001, 250000] },
    { displayValue: 5.5, name: 'Puncheon', range: [250001, Infinity] },
  ];

  const { myCommision, nextCommision, myIndex } = (() => {
    let myIndex = 0;
    for (let i = 0; i < comission.length; i++) {
      if (comission[i].range[0] < portfolioValue && comission[i].range[1] > portfolioValue) {
        myIndex = i;
        break;
      }
    }
    return {
      myIndex,
      myCommision: comission[myIndex],
      nextCommision: myIndex === comission.length - 1 ? null : comission[myIndex + 1],
    };
  })();

  return {
    portfolioValue,
    myCommision,
    nextCommision,
    myIndex,
    comission,
  }

}