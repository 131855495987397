import * as React from "react"
import Svg, { G, Path } from "react-native-svg";
import { fSize } from 'const';
import { useDynamicResponsiveValue } from 'react-quick-style-components';

function SvgComponent(props) {
  const rV = useDynamicResponsiveValue();
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={fSize(rV, 40.789)}
      height={fSize(rV, 38.081)}
      viewBox="0 0 40.789 38.081"
      {...props}
    >
      <G fill="#fff">
        <Path
          data-name="Path 162"
          d="M39.594 8.285h-9.082v-7.09A1.2 1.2 0 0029.317 0H1.195A1.2 1.2 0 000 1.195v30.911a5.982 5.982 0 005.975 5.975h28.839a5.982 5.982 0 005.975-5.975V9.48a1.2 1.2 0 00-1.195-1.195zM5.975 35.691a3.589 3.589 0 01-3.585-3.585V2.39h25.732c0 31.585-.015 29.829.027 30.279a5.956 5.956 0 001.17 3.021zM38.4 32.106a3.589 3.589 0 01-3.585 3.585H34.1a3.589 3.589 0 01-3.585-3.585V10.675H38.4z"
        />
        <Path
          data-name="Path 163"
          d="M24.218 5.736H6.373a1.195 1.195 0 000 2.39h17.845a1.195 1.195 0 000-2.39z"
        />
        <Path
          data-name="Path 164"
          d="M24.218 10.835H6.373a1.195 1.195 0 000 2.39h17.845a1.195 1.195 0 100-2.39z"
        />
        <Path
          data-name="Path 165"
          d="M24.218 29.955H6.373a1.195 1.195 0 100 2.39h17.845a1.195 1.195 0 100-2.39z"
        />
        <Path
          data-name="Path 166"
          d="M24.219 15.933h-8.923a1.2 1.2 0 00-1.195 1.195v8.923a1.2 1.2 0 001.195 1.2h8.923a1.2 1.2 0 001.2-1.2v-8.923a1.2 1.2 0 00-1.2-1.195zm-1.195 8.923h-6.533v-6.533h6.533z"
        />
        <Path
          data-name="Path 167"
          d="M6.373 20.235h3.824a1.195 1.195 0 100-2.39H6.373a1.195 1.195 0 100 2.39z"
        />
        <Path
          data-name="Path 168"
          d="M6.373 25.334h3.824a1.195 1.195 0 100-2.39H6.373a1.195 1.195 0 100 2.39z"
        />
      </G>
    </Svg>
  )
}

export default SvgComponent
