import React, { useEffect, useState, useCallback } from 'react';
import { Col, Row, Text, useDynamicResponsiveValue } from 'react-quick-style-components';
import { Input, Button, Header } from 'components'
import { ASSETS, COLOR, fSize, SCREEN_NAME } from 'const';
import { StyleSheet, Image } from 'react-native';
import { IScreen } from 'type';
import GlobalEvent from 'js-events-listener/react';
import { useNavFunc } from 'navigation';
const SPACE_UNIT = 50;
import Store from 'store';

const RegisterGSB: IScreen = () => {
  const { reset } = useNavFunc();
  const [{}, uApi] = Store.User.createStore();
  const [{}, cApi] = Store.Client.createStore();
  const rV = useDynamicResponsiveValue();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [postcode, setPostcode] = useState('');
  const [membershipNumber, setMembershipNumber] = useState('');

  const dataInput = [
    { placeholder: 'Name', value: name, onChageText: setName },
    { placeholder: 'Email Address', value: email, onChageText: setEmail },
    { placeholder: 'Password', value: password, onChageText: setPassword, isPassword: true },
    { placeholder: 'Confirm Password', value: confirmPassword, onChageText: setConfirmPassword, isPassword: true },
    // { placeholder: 'Address Line 1', value: addressLine1, onChageText: setAddressLine1 },
    // { placeholder: 'Address Line 2', value: addressLine2, onChageText: setAddressLine2 },
    // { placeholder: 'Postcode', value: postcode, onChageText: setPostcode },
    // { placeholder: 'Membership Number', value: membershipNumber, onChageText: setMembershipNumber },
  ]

  const handleRegister = async () => {
    // GlobalEvent.emit('LOGIN', undefined);
    // reset(SCREEN_NAME.Home);
    if (!name || !email || !password || !confirmPassword) {
      return alert('Please fill all the required form');
    }
    if (password !== confirmPassword) {
      return alert('The passwords dont match');
    }
    const [res0, err0] = await cApi.checkEmailVerified(email);
    if (err0) return alert(err0);
    if (!res0) {
      return alert('Your email is not approved');
    }
    const [res, err] = await uApi.firebaseCreateUser({
      email, password, name,
    });
    if (err) return alert(err);
    alert('Register successfully, please log in now');
    reset(SCREEN_NAME.LoginGSB);
  };

  return (
    <Col flex1>
      <Header />
      <Col flex1 backgroundColor={COLOR.BG} middle>
        <Col width={rV({ xs: '90%', sm: '80%', md: '70%', lg: '70%', xl: '70%' })} >
          <Col style={[styles.contentBox, { paddingBottom: rV({ xs: 40, xxxl: 65 }) }]} >
            <Row width='85%' alignSelf='center' justifyContent='space-between'>
              <Image source={ASSETS.LOGIN_LOGO_DARK} style={{ width: 306 * 0.8 , height: 156 * 0.8, resizeMode: 'contain' }} />
              <Image source={ASSETS.GSB_LOGO_COLOR} style={{ width: 306 * 0.5 , height: 156 * 0.5, resizeMode: 'contain' }} />
            </Row>
            <Text textAlign="center" bold fontSize={fSize(rV, 50)} colorMain paddingTop16 paddingBottom={rV({ xs: 16, lg: 36, xxxl: 46 })}>REGISTER</Text>
            <Col >
              <Row xs='100%' md='50%' xxxl='50%'>
                {dataInput.map((item, index) => {
                  return (
                    <Input marginHorizontal={rV({ xs: SPACE_UNIT / 3, lg: SPACE_UNIT / 2, xxxl: SPACE_UNIT })} marginTop0 marginBottom={rV({ xs: SPACE_UNIT / 3, lg: SPACE_UNIT / 2, xxxl: SPACE_UNIT })}
                      key={`id_${index}`} placeholder={item.placeholder}
                      value={item.value} onChangeText={(text) => item.onChageText(text)}
                      inputProps={{ secureTextEntry: item.isPassword }}
                    />
                  )
                })}
              </Row>
            </Col>
            <Button
              alignSelf={rV({ xs: 'center', md: 'flex-end' })}
              marginRight={rV({ xs: 0, sm: SPACE_UNIT / 3, lg: SPACE_UNIT / 2, xxxl: SPACE_UNIT })}
              marginTop={rV({ xs: 30, xxxl: 44 })}
              title="REGISTER" width192 onPress={handleRegister} />
          </Col>
        </Col>
      </Col>
    </Col>
  );
};

RegisterGSB.routeInfo = {
  title: 'Register',
  path: '/institutional-login-register-gsb',
}

const styles = StyleSheet.create({
  contentBox: {
    marginVertical: 30,
    paddingTop: 32,
    backgroundColor: COLOR.WHITE,
    borderColor: COLOR.BORDER,
    borderWidth: 1,
    borderTopLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
})
export default RegisterGSB;

