const randomColor = require('randomcolor');
const Color = require('color2');

export const COLOR = {
  FONT: '#000000',
  MAIN: '#000000',
  BROWN: '#C6B477',
  HOVER: '#A29464',
  WHITE: '#FFFFFF',
  BG: '#FFFFFF',
  // BG: '#F3F0E9',
  BORDER: 'rgba(112, 112,112, 0.3)',

  // for chart
  PURPLE: '#A9408E',
  GREEN: '#99C01D',
  DARKPINK: '#ED0E36',
  YELLOW: '#FFCC0C',
  BLUE: '#009ED8',
  GRAY_NONE: '#EDE9DE',
};

const arr = [COLOR.BLUE, COLOR.YELLOW, COLOR.DARKPINK, COLOR.GREEN, COLOR.PURPLE]

export const genColor = (index) => {
  if (!!arr[index]) return arr[index];
  return randomColor();
}

export const genInactiveColor = (colorStr) => {
  const color = Color(colorStr);
  return color.lighten(0.9);
}