import TestIcon from './TestIcon';
import Facebook from './icons/facebook';
import Twitter from './icons/twitter';
import Instagram from './icons/instagram';
import Youtube from './icons/youtube';
import IconNotification from './IconNotification';
import IcPattern from './icons/icPattern';
import Search from './Search';
import BurgerMenu from './BurgerMenu';
import Close from './Close';
import BgDrawer from './BgDrawer';
import IcArrowDown from './icons/icArrowDown';
import IcArrowUp from './icons/icArrowUp';
import IcPlus from './icons/icPlus';
import Email from './icons/email';
import IconChat from './IconChat';
import IconPie from './IconPie';
import IconPaper from './IconPaper';
// SVG ICON
export const ICON = {
  TestIcon,
  IconNotification,
  Facebook,
  Twitter,
  Instagram,
  Youtube,
  IcPattern,
  Search,
  BurgerMenu,
  Close,
  BgDrawer,
  IcArrowDown,
  IcArrowUp,
  IcPlus,
  Email,
  IconChat,
  IconPie,
  IconPaper
};

// PNG or JPG
export const ASSETS = {
  LOGO: require('./logo.png'),
  LOGO2: require('./images/logo2.png'),
  LOGO3: require('./images/logo3.png'),
  LOGO4: require('./images/logo4.png'),
  LOGO_VWG: require('./images/vwg.png'),
  LOGO_LOGIN: require('./images/logo-login.png'),
  IMG_CASKS: require('./images/img_casks.png'),
  IMG_CASKS_HEADER: require('./images/img_casks_header.png'),
  IMG_WHITESWIRL: require('./images/whiteswirl.png'),
  BANNER_NEWS: require('./images/banner-news.png'),
  DEFAULT_AVATAR: require('./images/default-avatar.png'),
  SEARCH: require('./images/search.png'),
  IC_USER_EVENT: require('./images/ic_user_event.png'),
  BANNER_ACCOUNT: require('./images/banner-account.png'),
  IMG_ADDRESS: require('./images/img_address.png'),
  IMG_LOGOUT: require('./images/img_logout.png'),
  IMG_ORDER: require('./images/img_order.png'),
  IMG_PAYMENT: require('./images/img_payment.png'),
  IMG_ACCOUNT: require('./images/img_account.png'),
  // HD: Header Dropdown
  HD_DASHBOARD: require('./header-dropdown/dashboard.png'),
  HD_DASHBOARD_2: require('./va-icons/dashboard.svg'),
  HD_INVESTMENT: require('./header-dropdown/investment.png'),
  HD_INVESTMENT_2: require('./va-icons/save-money.svg'),
  HD_INVOICES: require('./header-dropdown/invoices.png'),
  HD_INVOICES_2: require('./va-icons/invoices.svg'),
  HD_NEWS: require('./header-dropdown/news.png'),
  HD_NEWS_2: require('./va-icons/newspaper.svg'),
  HD_EVENT: require('./header-dropdown/event.png'),
  HD_ORDER: require('./header-dropdown/shopping.png'),
  HD_ADDRESS: require('./header-dropdown/address.png'),
  HD_PAYMENT: require('./header-dropdown/credit-card.png'),
  HD_REVIEW: require('./header-dropdown/review.png'),
  HD_REVIEW_2: require('./va-icons/review.svg'),
  HD_ACCOUNT: require('./header-dropdown/account.png'),
  HD_ACCOUNT_2: require('./va-icons/user (1).svg'),
  HD_LOGOUT: require('./header-dropdown/logout.png'),
  HD_LOGOUT_2: require('./va-icons/logout.svg'),
  HD_AUNCTION: require('./header-dropdown/auction.png'),
  // HD_AUNCTION_2: require('./va-icons/auction.png'),
  HD_SELLER: require('./header-dropdown/seller.png'),

  IMG_ROYAL: require('./images/logo_royal.png'),
  IMG_ERINGTON: require('./images/logo_edrington.png'),
  IMG_REGIONS: require('./images/logo_regions.png'),
  IMG_PORTFOLIO: require('./images/img_portfolio.png'),
  IMG_ONECASK: require('./images/onecask.png'),
  IMG_PORTFOLIO_SINGLE: require('./images/portfolio_single.png'),

  EXCEL_SAMPLE: require('./Book1.xlsx'),
  ONECASK: require('./onecask-min.png'),

  NEWLOGO: require('./new-logo.png'),
  WHISKY_LOGO: require('./whisky-logo.png'),
  CASK_BG: require('./images/Cask_BG.png'),

  SQUARE_PORTFOLIO: require('./dashboard-square/square-portfolio.jpg'),
  SQUARE_REGIONS: require('./dashboard-square/square-region.jpg'),
  SQUARE_DISTILERY: require('./dashboard-square/square-distilery.jpg'),
  SQUARE_EVENTS: require('./dashboard-square/square-events.jpg'),
  SQUARE_CASK: require('./dashboard-square/square-casks.jpg'),
  
  VINTAGE_ACTION_HOUSE: require('./dashboard-square/vintage_action_house.png'),
  VINTAGE_BOTTLERS: require('./dashboard-square/vintage_bottlers.png'),
  VINTAGE_WHISKY_CLUB: require('./dashboard-square/vintage_whisky_club.png'),
  VINTAGE_WHISKY_GROUP: require('./images/VWG_BANNER.png'),

  REFER_A_FRIEND: require('./images/refer-a-friend.jpeg'),

  LOGIN_LOGO_DARK: require('./images/va-dark.svg'),

  // GSB
  GSB_LOGO_COLOR: require('./images/thumbnail_GSB_RGB_4x.png'),
  GSB_LOGO_WHITE: require('./images/thumbnail_GSB_RGB_4x_white.png'),
};
