import React, { useEffect, useState } from 'react';
import { Col, Text, Row, useDynamicResponsiveValue, Scroll } from 'react-quick-style-components';
import { useNavFunc } from 'navigation';
import { IScreen } from 'type';
import { ASSETS, COLOR, fSize, ICON, SCREEN_NAME } from 'const';
import { Image, StyleSheet, useWindowDimensions } from 'react-native';
import { Footer, Header, Button, Input, Dropdown, ChatBox } from 'components';
import { TSingleOption } from 'components/Dropdown';
import { VarHelper } from 'helpers';
const SPACE_UNIT = 50;

import Store from 'store';

const AccountDetail: IScreen = () => {
  const { navigate } = useNavFunc();
  const { width, height } = useWindowDimensions();
  const rV = useDynamicResponsiveValue()
  const bannerHeight = rV({ xs: 150, lg: 200, xxxl: 262 })

  const [{ firebaseUser }, uApi] = Store.User.createStore();
  const [{ listClients }, cApi] = Store.Client.createStore();

  // console.log('user', firebaseUser);

  const [userId, setUserId] = useState(0);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  // const [addressLine1, setAddressLine1] = useState('');
  // const [addressLine2, setAddressLine2] = useState('');
  const [country, setCountry] = useState('');
  const [postcode, setPostcode] = useState('');
  // const [phoneNumber, setPhoneNumber] = useState('');
  const [dob, setDob] = useState('');
  const [email, setEmail] = useState('');
  const [memberShipNumber, setMemberShipNumber] = useState('');
  const [value, setValue] = useState<TSingleOption>()
  const dataInput = [
    { placeholder: 'First Name', value: firstName, onChageText: setFirstName },
    { placeholder: 'Last Name', value: lastName, onChageText: setLastName },
    // { placeholder: 'Address Line 1', value: addressLine1, onChageText: setAddressLine1 },
    // { placeholder: 'Address Line 2', value: addressLine2, onChageText: setAddressLine2 },
    // { placeholder: 'Country', value: country, onChageText: setCountry },
    // { placeholder: 'Postcode', value: postcode, onChageText: setPostcode },
    // { placeholder: 'Telephone number', value: phoneNumber, onChageText: setPhoneNumber },
    // { placeholder: 'DOB', value: dob, onChageText: setDob },
    { placeholder: 'Email Address', value: email, onChageText: setEmail, readOnly: true, },
    // { placeholder: 'Membership Number', value: memberShipNumber, onChageText: setMemberShipNumber, readOnly: true, },
  ]

  const [{ listPages }, iApi] = Store.Info.createStore();

  useEffect(() => {
    iApi.getListPages();
    cApi.getListClients();
  }, []);

  useEffect(() => {
    if (listClients.length === 0) return;
    const mmbshpNo = listClients[0]['Mmbshp No'];
    if (mmbshpNo) setMemberShipNumber(mmbshpNo);
  }, [listClients]);

  const pageData = listPages.find(val => val.Title === 'Account Details');
  const pageDataImages = VarHelper.imgApiStringifyGetAll(pageData?.Images);

  useEffect(() => {
    if (firebaseUser?.email) setEmail(firebaseUser.email);
    // if (firebaseUser.phoneNumber) setPhoneNumber(firebaseUser.phoneNumber);
    if (firebaseUser.displayName) {
      const parts = firebaseUser.displayName.split(' ');
      if (parts.length >= 2) {
        setFirstName(parts[0]);
        setLastName(firebaseUser.displayName.replace(parts[0], '').replace(/\s/g, '').trim());
      } else {
        setFirstName(firebaseUser.displayName);
      }
    }
    uApi.getMyUser().then(mUser => {
      if (!mUser[0]) return;
      console.log('mUser', mUser[0]);
      setUserId(mUser[0].id);
      // setAddressLine1(mUser[0]['Address Line 1'] || '');
      // setAddressLine2(mUser[0]['Address Line 2'] || '');
      // setCountry(mUser[0]['Country'] || '');
      // setPostcode(mUser[0]['Postcode'] || '');
      // setPhoneNumber(mUser[0]['Telephone Number'] || '');
      // setDob(mUser[0]['DOB'] || '???');
    })
  }, [firebaseUser]);

  const handleSaveAccount = () => {
    if (!firstName || !lastName) {
      return alert('Please fill in First name and Last name');
    }
    if (typeof firebaseUser.updateProfile === 'function') {
      firebaseUser.updateProfile({
        displayName: `${firstName} ${lastName}`,
      })
    }
    if (userId) {
      uApi.updateMyUser(userId, {
        'Name': `${firstName} ${lastName}`,
        // 'Country': country,
        // 'Post Code': postcode,
        // 'DOB': dob === '???' ? '' : dob,
      });
    }
    alert('Update successfully');
  };

  const ScreenContainer = React.Fragment;
  return (
    <Col flex1 backgroundColor={COLOR.BG}>
      <ScreenContainer >
      <Header />
        <Col middle height={bannerHeight}>
          <Image source={pageDataImages.length > 0 ? { uri: pageDataImages[0] } : ASSETS.BANNER_ACCOUNT} style={[{ height: bannerHeight }, styles.banner]} />
          <Col zIndex99 center>
            <Text fontSize={fSize(rV, 52)} colorWhite>Account Details</Text>
            <Col alignSelf="center">
              <ICON.IcPattern />
            </Col>
          </Col>
        </Col>
        <Col style={{ minHeight: height - 300 }} middle marginVertical={rV({ xs: 16, lg: 30, xxxl: 40 })}>
          <Col width={rV({ xs: '90%', sm: '80%', md: '70%', lg: '70%', xl: '70%' })} >
            <Col style={[styles.contentBox, { paddingBottom: rV({ xs: 40, xxxl: 65 }) }]} >
              <Col >
                <Row xs='100%' md='50%' xxxl='50%'>
                  {dataInput.map((item, index) => {
                    return (
                      <Input marginHorizontal={rV({ xs: SPACE_UNIT / 3, lg: SPACE_UNIT / 2, xxxl: SPACE_UNIT })} marginTop0 marginBottom={rV({ xs: SPACE_UNIT / 3, lg: SPACE_UNIT / 2, xxxl: SPACE_UNIT })}
                        label={item.placeholder}  
                      key={`id_${index}`} placeholder={""}
                        value={item.value} onChangeText={(text) => item.onChageText(text)}
                        inputProps={{
                          editable: !item.readOnly,
                        }}
                      />
                    )
                  })}
                </Row>
                {/* <Dropdown
                  marginHorizontal={rV({ xs: SPACE_UNIT / 3, lg: SPACE_UNIT / 2, xxxl: SPACE_UNIT })}
                  value={value}
                  placeholder="What would you like to hear about?"
                  options={
                    [
                      { value: 1, label: '1' },
                      { value: 2, label: '2' },
                      { value: 3, label: '3' },
                      { value: 4, label: '4' },
                      { value: 5, label: '5' },
                      { value: 6, label: '6' },
                      { value: 7, label: '7' },
                      { value: 8, label: '8' },
                      { value: 9, label: '9' },
                      { value: 10, label: '10' },
                      { value: 11, label: '11' },
                    ]}
                  onChange={(data) => setValue(data)}
                /> */}
              </Col>
              <Row justifyContent="flex-end" xs='100%' md='any:any'>
                <Button
                  width={rV({ xs: '100%', md: 250 })} borderRadius={10}
                  preset="white"
                  marginRight={rV({ xs: 0, sm: SPACE_UNIT / 3, lg: SPACE_UNIT / 2, xxxl: SPACE_UNIT })}
                  marginTop={rV({ xs: 45, xxxl: 82 })}
                  title="Change Password" onPress={() => navigate(SCREEN_NAME.ChangePassword)} />
                <Button
                  width={rV({ xs: '100%', md: 250 })} backgroundColor="black" borderRadius={10}
                  marginRight={rV({ xs: 0, sm: SPACE_UNIT / 3, lg: SPACE_UNIT / 2, xxxl: SPACE_UNIT })}
                  marginTop={rV({ xs: 45, xxxl: 82 })}
                  title="Save" onPress={handleSaveAccount} />
              </Row>
              
            </Col>
          </Col>
        </Col>
        <Footer />
      </ScreenContainer>
      <ChatBox />
    </Col>
  );
};

AccountDetail.routeInfo = {
  title: 'Account Detail',
  path: '/account-detail',
};

const styles = StyleSheet.create({
  boxRightItem: {
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    shadowColor: 'rgba(0, 0, 0, 0.16)',
    shadowOffset: {
      width: 0,
      height: 3
    },
    shadowRadius: 6,
  },
  banner: {
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0
  },
  dot: {
    width: 8,
    height: 8,
    borderRadius: 4,
    backgroundColor: COLOR.MAIN,
    marginHorizontal: 10,
  },
  contentBox: {
    paddingTop: 32,
    backgroundColor: COLOR.WHITE,
    borderColor: COLOR.MAIN,
    borderWidth: 1,
    borderRadius: 23,
  },
  dropdown: {
    flex: 1,
    backgroundColor: COLOR.BG,
    color: COLOR.MAIN,
    padding: 0,
    justifyContent: 'center',
  }
})
export default AccountDetail;


