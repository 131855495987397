import React, { useEffect, useState } from 'react';
import { Col, Text, Scroll, useDynamicResponsiveValue } from 'react-quick-style-components';
import { useNavFunc, Reset } from 'navigation';
import { IScreen } from 'type';
import { HeatMap, Table, TableWithAction, Tabs } from 'components';
import { ASSETS, COLOR, fSize, ICON, SCREEN_NAME } from 'const';
import { Image, StyleSheet, useWindowDimensions } from 'react-native';
import { Footer, Header, Card, Input, ChatBox } from 'components';
import { VarHelper } from 'helpers';
import Store from 'store'

const AdminHeatMap: IScreen = () => {
  const [{ firebaseUser }, uApi] = Store.User.createStore();
  const rV = useDynamicResponsiveValue()

  const ScreenContainer = React.Fragment;

  if (!firebaseUser || !firebaseUser.email) {
    return (
      <Reset to={SCREEN_NAME.Home} />
    )
  }

  return (
    <Col flex1 backgroundColor={COLOR.BG}>
      <ScreenContainer >
        <Header />
          <HeatMap />
        <Footer />
      </ScreenContainer>
      <ChatBox />
    </Col>
  );
};

AdminHeatMap.routeInfo = {
  title: 'Heat Map',
  path: '/admin/heat-map',
};

const styles = StyleSheet.create({
  banner: {
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0
  },
})

export default AdminHeatMap;