import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={57.125}
      height={39.997}
      viewBox="0 0 57.125 39.997"
      {...props}
    >
      <Path
        d="M55.948 6.258a7.156 7.156 0 00-5.035-5.035C46.443 0 28.562 0 28.562 0S10.681 0 6.211 1.177a7.3 7.3 0 00-5.035 5.081C0 10.728 0 19.998 0 19.998s0 9.317 1.176 13.74a7.158 7.158 0 005.035 5.035c4.517 1.223 22.351 1.223 22.351 1.223s17.881 0 22.351-1.177a7.157 7.157 0 005.036-5.037c1.176-4.47 1.176-13.74 1.176-13.74s.047-9.314-1.177-13.784zM22.869 28.562V11.434l14.869 8.564zm0 0"
        fill={props.fill || "#fff"}
      />
    </Svg>
  )
}

export default SvgComponent
