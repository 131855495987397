import React, { useState, useEffect } from 'react';
import { Col, Text, Row, useDynamicResponsiveValue, Scroll } from 'react-quick-style-components';
import { useNavFunc } from 'navigation';
import { IScreen } from 'type';
import { ASSETS, COLOR, fSize, ICON, SCREEN_NAME } from 'const';
import { Image, StyleSheet, View, useWindowDimensions } from 'react-native';
import { Footer, Header, ChatBox, Breadcrumbs } from 'components';
import Store from 'store';
import { TimeHelper, VarHelper } from 'helpers';
import moment from 'moment';

const News: IScreen = () => {
  const [{ listNews, listPages }, iApi] = Store.Info.createStore();
  const { navigate, reset } = useNavFunc();
  const { width, height } = useWindowDimensions();
  const rV = useDynamicResponsiveValue()
  const bannerHeight = rV({ xs: 150, lg: 200, xxxl: 262 })
  const imageHeight = rV({ xs: 130, lg: 160, xxxl: 250 })
  const dotSize = rV({ xs: 4, lg: 6, xxxl: 8 })
  const [data, setData] = useState([
    {
      image: 'https://vtv1.mediacdn.vn/thumb_w/640/2020/6/8/ruou-vang-phap-nuoc-rua-tay-15915829199661310244878.jpg',
      title: 'STORIES BY GORDAN',
      desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris ni…. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris ni….',
      name: 'John Doe',
      time: '14/7/2021'
    },
    {
      image: 'https://vtv1.mediacdn.vn/thumb_w/640/2020/6/8/ruou-vang-phap-nuoc-rua-tay-15915829199661310244878.jpg',
      title: 'STORIES BY GORDAN',
      desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris ni…. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris ni….',
      name: 'John Doe',
      time: '14/7/2021'
    },
  ])

  useEffect(() => {
    iApi.getListNews();
    iApi.getListPages();
  }, []);

  const pageData = listPages.find(val => val.Title === 'News');
  const pageDataImages = VarHelper.imgApiStringifyGetAll(pageData?.Images);

  const renderContainer = ({ children }) => {
    return (
      <Col>
        <Scroll height={height}>
          {children}
        </Scroll>
      </Col>
    );
  };

  const ScreenContainer = React.Fragment;

  return (
    <Col flex1 backgroundColor={COLOR.BG}>
      <ScreenContainer>
        <Header />
        <Col middle height={bannerHeight}>
          <Image source={pageDataImages.length > 0 ? { uri: pageDataImages[0] } : ASSETS.BANNER_ACCOUNT} style={[{ height: bannerHeight }, styles.banner]} />
          <Col zIndex99 center>
            <Text fontSize={fSize(rV, 52)} colorWhite fontFamily='PlayfairDisplay_600SemiBold' marginBottom={10}>News</Text>
            <ICON.IcPattern />
          </Col>
        </Col>
        <Col style={{ minHeight: height - 300 }} alignSelf="center" width={rV({ xs: '90%', lg: '85%', xxxl: '70%' })} paddingTop={rV({ xs: 20, sm: 30, lg: 30, xxxl: 63 })}>
          <Breadcrumbs
            title="News"
            style={{
              marginLeft: 0,
              marginBottom: 20,
            }}
          />
          {listNews && listNews.length > 0 && listNews.map((item, index) => {
            return (
              <Col onPress={() => {
                window.location.pathname = `/news/${item.id}`;
              }} key={`id_${index}`} style={{ flexDirection: 'row', height: imageHeight, marginBottom: 90 }} >
                <Image source={{
                  uri: item.Image
                }}
                  style={{
                    height: imageHeight,
                    width: imageHeight
                  }}
                />
                <Col style={styles.boxRightItem} bgWhite flex1 paddingHorizontal={rV({ xs: 12, lg: 45, xxxl: 67 })} paddingVertical={rV({ xs: 15, xxxl: 25 })}>
                  <Text fontFamily="PlayfairDisplay_600SemiBold" fontSize={fSize(rV, 30)} lineHeight={fSize(rV, 35)} paddingBottom={rV({ xs: 6, lg: 8, xxxl: 14 })} colorMain>{item.Title}</Text>
                  <Text body1 numberOfLines={3} ellipsizeMode="tail" colorMain>{item.Description.replace('Read more', '')}</Text>
                  <Row absolute left={rV({ xs: 12, lg: 45, xxxl: 67 })} bottom20 right={rV({ xs: 12, lg: 45, xxxl: 67 })} justifyContent="space-between">
                    <Text bold>READ MORE</Text>
                    <Row alignItems="center">
                      {/* <Text fontSize={fSize(rV, 20)} colorMain>{item.Title}</Text> */}
                      <Col width={dotSize} height={dotSize} borderRadius={dotSize / 2} bgMain marginHorizontal={dotSize} />
                      <Text body1 colorMain>{moment(item.created_at).format('DD/MM/YYYY')}</Text>
                    </Row>
                  </Row>
                </Col>
              </Col>
            )
          })}
        </Col>
        <Footer />
      </ScreenContainer>
      <ChatBox />
    </Col>
  );
};

News.routeInfo = {
  title: 'News',
  path: '/news',
};

const styles = StyleSheet.create({
  boxRightItem: {
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    shadowColor: 'rgba(0, 0, 0, 0.16)',
    shadowOffset: {
      width: 0,
      height: 3
    },
    shadowRadius: 6,
  },
  banner: {
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0
  },
  dot: {
    width: 8,
    height: 8,
    borderRadius: 4,
    backgroundColor: COLOR.MAIN,
    marginHorizontal: 10,
  }
})
export default News;


