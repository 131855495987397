import React, { DOMElement, useEffect, useState } from 'react';
import { Col, Row, Text, usePropsStyle } from 'react-quick-style-components';
import Svg, { Path, G } from "react-native-svg";
import { Platform } from 'react-native';
import { computeChartArc } from './computeChartArc';
import { COLOR } from 'const';

interface IChartSingleProps {
  // props here
  size: number | string,
  data: { value: number, title: string, color?: string, percent: number },
  bgColor?: string,
  onHoverPart?: any,
  onPressPart?: any,
  [anyProp: string]: any,
}

const ChartSingle = (props: IChartSingleProps) => {
  const [id, setId] = useState('chart-single-' + new Date().getTime());
  const propStype = usePropsStyle(props);

  const dummyData = {
    ...props.data,
    startAngle: 0,
    endAngle: 360 - 360 * props.data.percent / 100,
  };
  const computeData = {
    ...props.data,
    startAngle: dummyData.endAngle,
    endAngle: 360,
  };

  useEffect(() => {
    if (Platform.OS !== 'web') return;
    const arr = document.querySelectorAll(`#${id} .chart-single-part`);
    console.log('arr', arr);
    if (arr.length === 0) return;
    arr.forEach((el: any) => {
      el.onmouseenter = () => {
        el.style.opacity = 0.8;
        !!props.onHoverPart && props.onHoverPart(computeData);
      };
      el.onmouseleave = () => {
        el.style.opacity = 1;
      }
      el.onclick = () => {
        !!props.onPressPart && props.onPressPart(computeData);
      }
    });
  }, [id, props.data]);

  return (
    <Col width={props.size} height={props.size} backgroundColor={props.bgColor || 'white'} style={propStype} middle>
      <Col absoluteFill middle>
        <Svg
          // @ts-ignore
          xmlns="http://www.w3.org/2000/svg"
          width="88%"
          height="88%"
          viewBox="0 0 280 280"
          stroke={props.bgColor || "white"}
        >
          <Path
            fill={"#EDDEEE"}
            d={computeChartArc(dummyData.startAngle, dummyData.endAngle, 140, 60)}
            // @ts-ignore
            className="chart-single-part"
          />
        </Svg>
      </Col>
      <Col absoluteFill middle>
        <Svg
          // @ts-ignore
          xmlns="http://www.w3.org/2000/svg"
          width="90%"
          height="90%"
          viewBox="0 0 280 280"
          stroke={props.bgColor || "white"}
          id={id}
        >
          <Path
            fill={computeData.color}
            d={computeChartArc(computeData.startAngle, computeData.endAngle, 140, 55)}
            // @ts-ignore
            className="chart-single-part"
          />
        </Svg>
      </Col>
    </Col>
  );
};

export default ChartSingle;
