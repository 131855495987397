import React, { useEffect, useMemo } from 'react';
import { Col, Text, Scroll, useDynamicResponsiveValue, Row } from 'react-quick-style-components';
import { useNavFunc } from 'navigation';
import { IScreen } from 'type';
import { Table, TableWithAction, Tabs, Breadcrumbs, Button } from 'components';
import { ASSETS, COLOR, fSize, ICON, SCREEN_NAME } from 'const';
import { Image, StyleSheet, useWindowDimensions } from 'react-native';
import { Footer, Header, Card, Input, ChatBox } from 'components';
import { useState } from 'react';
import Store from 'store';
import { VarHelper, TimeHelper } from 'helpers';
import Select from 'react-select';

import _ from 'lodash';

const ManageClients: IScreen = () => {
  const { width, height } = useWindowDimensions();
  const { route, navigate } = useNavFunc();
  const { tab: initialTab } = route.params || {};
  const [tab, selectTab] = useState('1');
  const [selectedClient, setSelectedClient] = useState();

  const [{ saleManagerData }, cApi] = Store.Client.createStore();
  const [{}, uApi] = Store.User.createStore();
  const [{ }, mApi] = Store.Message.createStore();
  const rV = useDynamicResponsiveValue()
  const bannerHeight = rV({ xs: 150, lg: 200, xxxl: 262 })

  const [{ listPages }, iApi] = Store.Info.createStore();

  useEffect(() => {
    iApi.getListPages();
  }, []);

  const pageData = listPages.find(val => val.Title === 'Portfolio Details');
  const pageDataImages = VarHelper.imgApiStringifyGetAll(pageData?.Images);

  const listTabs = [
    { id: '1', title: 'CASKS INFORMATION' },
  ];

  useEffect(() => {
    const findId = listTabs.find(val => val.title === initialTab);
    if (!findId) return;
    selectTab(findId.id);
  }, [initialTab]);

  const clientOptions = useMemo(() => {
    return _.uniqWith(saleManagerData.clients.map(val => ({
      label: `${val.Customer} - No. of Casks: ${saleManagerData.clients.filter(item => item.Email === val.Email).length}`,
      value: val.Email,
    })), _.isEqual);
  }, [saleManagerData]);

  const filterClient = !!saleManagerData && saleManagerData.clients.length > 0
    ? saleManagerData.clients.filter(val => val.Email === (selectedClient?.value || saleManagerData.clients[0].Email))
    : [];

  console.log('filterClient', filterClient);
  const renderTab = () => {
    switch (tab) {
      case '1':
        return (
          <Col>
            {!!filterClient && filterClient.length === 0 ? (
              <Col>
                <Text>No record found</Text>
              </Col>
            ) : (
              <Table
                columns={Object.keys(filterClient[0]).filter(val => !['created_at', 'updated_at', 'id', 'Customer', 'Address', 'Telephone', 'Email'].includes(val)).map(val => ({
                  key: val, title: val === 'Portfolio' ? 'Portfolio' : val, padding: 5
                })).map(val => ({ ...val, center: true, width: 180, height: 40, padding: 10 }))}
                data={filterClient.map(val => ({
                  ...val,
                  ['Purchased Date']: TimeHelper.format(val['Purchased Date']),
                  ['AYS']: TimeHelper.format(val['AYS']),
                  ['Unit Price']: VarHelper.money(val['Unit Price']),
                  ['Portfolio']: VarHelper.money(val['Portfolio']),
                }))}
                breakpointToScroll="all"
                style={{ margin: 20 }}
              />
            )}
          </Col>
        );
        return null;
    }
  }

  const ScreenContainer = React.Fragment;

  const handlePressPersonate = async () => {
    const Email = selectedClient.value;
    const [res, err] = await uApi.personate(Email);
    if (err) return alert(err);
    if (res.error) return alert(res.error);
    if (res.success) {
      // cApi.setSaleManagerData({
      //   ...saleManagerData,
      //   account: {
      //     ...saleManagerData.account,
      //     'Personate Email': Email,
      //   }
      // })
      // navigate(SCREEN_NAME.AccountSummary);
      window.location.pathname = '/';
    }
  }

  return (
    <Col flex1 backgroundColor={COLOR.BG}>
      <ScreenContainer >
        <Header />
        <Col middle height={bannerHeight}>
          <Image source={pageDataImages.length > 0 ? { uri: pageDataImages[0] } : ASSETS.BANNER_ACCOUNT} style={[{ height: bannerHeight }, styles.banner]} />
          <Col zIndex99 center>
            <Text fontSize={fSize(rV, 52)} colorWhite>Manage Clients</Text>
            <Col alignSelf="center">
              <ICON.IcPattern />
            </Col>
          </Col>
        </Col>
        <Col style={{ minHeight: height - 300 }} marginVertical={rV({ xs: 16, lg: 30, xxxl: 40 })} paddingHorizontal={rV({ xs: 4, lg: 20, xxxl: 40 })}>
          <Breadcrumbs
            title="Manage Clients"
            style={{
              marginLeft: rV({ xs: 4, lg: 20, xxxl: 40 }),
              marginBottom: 20,
            }}
          />
          <Row marginVertical30 marginHorizontal20 md="any:any" xs="100%" justifyContent="space-between">
            <Col width={rV({ xs: '100%', md: 500 })}>
              <Select
                placeholder="Choose a client"
                options={clientOptions}
                value={selectedClient}
                onChange={setSelectedClient}
                menuPortalTarget={document.querySelector('body')}
              />
            </Col>
            {Boolean(!!selectedClient) && (
              <Col>
                <Button title="Client View" width250 onPress={handlePressPersonate} />
              </Col>
            )}
          </Row>
        </Col>
        <Footer />
      </ScreenContainer>
      <ChatBox />
    </Col>
  );
};

ManageClients.routeInfo = {
  title: 'Manage Clients',
  path: '/manage-clients',
};

const styles = StyleSheet.create({
  banner: {
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0
  },
})

export default ManageClients;