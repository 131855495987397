import * as React from "react"
import Svg, { G, Circle, Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={25}
      height={25}
      viewBox="0 0 25 25"
      {...props}
    >
      <G data-name="Group 123" transform="translate(-1327 -915)">
        <Circle
          data-name="Ellipse 21"
          cx={12.5}
          cy={12.5}
          r={12.5}
          transform="translate(1327 915)"
        />
        <Path
          data-name="Line 62"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeWidth={2}
          d="M1339.57 920.082v14.978"
        />
        <Path
          data-name="Line 63"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeWidth={2}
          d="M1347.059 927.57h-14.978"
        />
      </G>
    </Svg>
  )
}

export default SvgComponent
