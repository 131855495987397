import GlobalEvent, { useGlobalState } from 'js-events-listener/react';
import { useEffect } from 'react';
import Request from './Request.utils';
import RequestBE from './RequestBE.utils';
import Persist, { PersistReady } from './Persist.utils';
import { HOST } from './host';
import { genColor } from 'const';
import { IUser } from 'type';
import { VarHelper } from 'helpers';
// import { initializeApp } from "firebase/app";
// import { getAnalytics, logEvent as firebaseLogEvent, setCurrentScreen } from "firebase/analytics";

import firebase from "firebase";

import UserStore from './User.Store';

interface ISetters {
  [additionSetter: string]: (v : any) => void,
}



const firebaseConfig = {
  apiKey: "AIzaSyCbpfpZ59lvSaiyY-4LkTXwr19_lw8CTyw",
  authDomain: "vingate-acquisition.firebaseapp.com",
  projectId: "vingate-acquisition",
  storageBucket: "vingate-acquisition.appspot.com",
  messagingSenderId: "1024311062460",
  appId: "1:1024311062460:web:0929687e3d16437668c5b9",
  measurementId: "G-QLWNL315V2"
};

class Info extends PersistReady {

  constructor() {
    super();
    this.init();
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    firebase.auth().onAuthStateChanged((user) => {
      // console.log('onAuthStateChanged', user);
      // if (user) {
      //   UserStore.updateState({ firebaseUser: user });
      // }
      !!UserStore.setters.setReady ? UserStore.setters.setReady(true) : UserStore.updateState({ ready: true });
      !!UserStore.setters.setFirebaseUser ? UserStore.setters.setFirebaseUser(user) : UserStore.updateState({ firebaseUser: user });
      if (user) {
        window.heap?.addUserProperties({'Name': user.displayName});
        RequestBE.getToken = async () => {
          const idToken = await user.getIdToken();
          return idToken;
        };
      }
      this.makeReady();
    });
  }

  state : any = {
    
  };

  setters : ISetters = {};

  updateState(obj, allowUndefined = true) {
    for (let key in obj) {
      if (allowUndefined || (obj[key] !== null && obj[key] !== undefined)) this.state[key] = obj[key];
    }
  }

  createStore() {
    return [
      {  },
      {
        
      }
    ];
  }

  analytics;

  init = () => {
    firebase.initializeApp(firebaseConfig);
    firebase.analytics();
  }

  logEvent = async (eventName, data) => {
    const { email } = UserStore.state;
    firebase.analytics()?.logEvent(eventName, data);
    console.log('email', email);
    // if (!!email) {
    //   await Request.post(HOST + '/User Events', {
    //     Email: email,
    //     Action: eventName,
    //     Data: data,
    //   });
    // }
  }

  setCurrentScreen = async (name) => {
    firebase.analytics()?.setCurrentScreen(this.analytics, name);
  }
}

export default new Info();
