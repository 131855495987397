import * as React from "react"
import Svg, { G, Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={28.336}
      height={28.335}
      viewBox="0 0 28.336 28.335"
      {...props}
    >
      <G data-name="Group 3">
        <G data-name="Group 2" fill={props.fill || "#c6b477"}>
          <Path
            data-name="Path 2"
            d="M28.33 10.833a.446.446 0 00-.019-.1.473.473 0 00-.027-.081.424.424 0 00-.047-.072.445.445 0 00-.063-.073c-.008-.007-.011-.016-.019-.022l-4.542-3.524V3.305a1.417 1.417 0 00-1.414-1.417h-5.121L15.029.296a1.4 1.4 0 00-1.722 0l-2.05 1.591H6.138a1.417 1.417 0 00-1.417 1.418v3.656l-4.54 3.527c-.008.007-.011.016-.019.022a.443.443 0 00-.063.073.422.422 0 00-.047.072.466.466 0 00-.027.081.448.448 0 00-.019.094c0 .01-.006.018-.006.028v16.061a1.4 1.4 0 00.277.834s0 .009.007.013.01.008.015.013a1.41 1.41 0 001.117.557h25.5a1.41 1.41 0 001.12-.559s.009-.006.013-.011 0-.009.007-.013a1.4 1.4 0 00.277-.834V10.861c.003-.01-.002-.018-.003-.028zM13.884 1.042a.453.453 0 01.563 0l1.09.846h-2.738zM1.534 27.396l12.35-9.6a.453.453 0 01.563 0l12.352 9.6zm25.857-.736l-12.363-9.6a1.4 1.4 0 00-1.722 0L.943 26.655V11.581l7.739 6.01a.473.473 0 10.579-.747l-7.862-6.106 3.322-2.582v3.65a.472.472 0 00.945 0v-8.5a.472.472 0 01.472-.472h16.061a.472.472 0 01.472.472v8.5a.472.472 0 10.945 0v-3.65l3.323 2.582-7.88 6.117a.472.472 0 10.579.746l7.753-6.02v15.074z"
          />
          <Path
            data-name="Path 3"
            d="M19.835 11.333V9.439a5.667 5.667 0 10-5.667 5.667.472.472 0 100-.945 4.723 4.723 0 114.723-4.723v1.889a.945.945 0 01-1.889 0V9.439a.472.472 0 00-.945 0 1.889 1.889 0 11-1.889-1.889.472.472 0 100-.945 2.834 2.834 0 101.909 4.922 1.884 1.884 0 003.758-.2z"
          />
        </G>
      </G>
    </Svg>
  )
}

export default SvgComponent
