import { initQuickStyle } from 'react-quick-style-components';
import { COLOR } from './colors';
import { PixelRatio, Dimensions, StyleSheet } from 'react-native';

const {
  width: SCREEN_WIDTH,
  height: SCREEN_HEIGHT,
} = Dimensions.get('window');
// based on iphone 8's scale
const scale = SCREEN_WIDTH / 375;

export const setupStyle = ({ defaultFont, bold, semiBold, light, medium, extraBold, thin, black }) => {
  initQuickStyle.setFontStyle({
    fontFamily: defaultFont,
    color: COLOR.FONT,
  });
  
  
  initQuickStyle.setStyleHooks({
    
  });
  initQuickStyle.setAdditionStyles({
    bold: {
      fontFamily: bold,
    },
    semiBold: {
      fontFamily: semiBold,
    },
    extraBold: {
      fontFamily: extraBold,
    },
    black: {
      fontFamily: black,
    },
    thin: {
      fontFamily: thin,
    },
    light: {
      fontFamily: light,
    },
    medium: {
      fontFamily: medium,
    },
    center: {
      textAlign: 'center',
    },
    shadow: {
      shadowColor: 'rgba(0,0,0,0.1)',
      shadowOffset: {
        width: 1,
        height: 1
      },
      shadowOpacity: 1,
      shadowRadius: 8,
      elevation: 2,
    },
    body1: {
      fontSize: 14,
      lineHeight: 18,
    },
    body2: {
      fontSize: 20,
      lineHeight: 26,
    }
  });

  initQuickStyle.setMainColor(COLOR.MAIN);
}

export const STYLES = StyleSheet.create({
  all100p: {
    width: '100%',
    height: '100%',
  },
});

type CustomValue = { xs?: any, md?: any, lg?: any, xl?: any, xxl?: any, xxxl?: any };

export const fSize = (rV, designedValue, customValue : CustomValue = {}) => {
  const shouldUseCustomValue = (breakpoint) => typeof customValue[breakpoint] === 'number';
  return rV({
    xs: shouldUseCustomValue('xs') ? customValue.xs : designedValue * 0.5,
    md: shouldUseCustomValue('md') ? customValue.md : customValue.md || designedValue * 0.6,
    lg: shouldUseCustomValue('lg') ? customValue.lg : customValue.lg || designedValue * 0.6,
    xl: shouldUseCustomValue('xl') ? customValue.xl : customValue.xl || designedValue * 0.7,
    xxl: shouldUseCustomValue('xxl') ? customValue.xxl : customValue.xxl || designedValue * 0.8,
    xxxl: shouldUseCustomValue('xxxl') ? customValue.xxxl : customValue.xxxl || designedValue,
  })
};

export const bodyTextSize = 14;

export const spacing = [0, 4, 8, 12, 16, 20, 24, 28, 32, 36, 40, 44, 48, 52, 56, 60, 64, 68, 72, 76, 80, 84, 88, 92, 96, 100];
