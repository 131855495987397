import React, { useState, useEffect } from 'react';
import { StyleSheet, Image, View, useWindowDimensions } from 'react-native';
import { Col, Row, useDynamicResponsiveValue, Text, Scroll } from 'react-quick-style-components';
import { useNavFunc } from 'navigation';
import { IScreen } from 'type';
import { ASSETS, COLOR, fSize, ICON, SCREEN_NAME } from 'const';
import { Footer, Header, Card, ChartMultiple, ChartSingle } from "components";
import { BlurView } from 'expo-blur';
import { Ionicons } from '@expo/vector-icons';
import { VarHelper } from 'helpers';
import Store from 'store';
import _ from 'lodash';

const Portfolio: IScreen = () => {
  const { navigate } = useNavFunc();
  const { width, height } = useWindowDimensions();
  const rV = useDynamicResponsiveValue();
  const BLUR_WIDTH = rV({ xs: (width - 80) / 2, md: (width - 110) / 3, lg: (width - 200) / 5 });
  const [currChart, setCurrChart] = useState({
    value: 20,
    percent: 14,
    title: 'Speyside',
    color: COLOR.PURPLE
  });
  const [chartMode, setChartMode] = useState<'multiple' | 'single'>('multiple');
  const [{ listPages }, iApi] = Store.Info.createStore();
  const [{ portfolioPieChartData, listClients }, cApi] = Store.Client.createStore();
  const [{ email }] = Store.User.createStore();

  useEffect(() => {
    iApi.getListPages();
    if (portfolioPieChartData.length === 0) cApi.getListClients();
  }, []);

  const pageData = listPages.find(val => val.Title === 'Portfolio');
  const pageDataImages = VarHelper.imgApiStringifyGetAll(pageData?.Images);

  const updateCurrChart = ({ title, color, percent, value }) => {
    setCurrChart({
      title,
      color,
      value: value,
      percent: percent.toFixed(0),
    });
  };

  const switchToSingleChartView = ({ title, color, percent, value }) => {
    Store.Firebase.logEvent('View Single Chart', { title, percent: percent.toFixed(0), value });
    setCurrChart({
      title,
      color,
      value,
      percent: percent.toFixed(0),
    });
    setChartMode('single');
  };

  const listDistilleries = _.uniqWith(listClients.filter(val => val.Email === email).map(val => val.Distillery), _.isEqual);

  return (
    <Col flex1 backgroundColor={COLOR.BG}>
      <Header />
      <Col flex1>
        <Row>
          <Col onPress={() => navigate(SCREEN_NAME.Home)}>
            <Text marginLeft={rV({ xs: 30, md: 40, lg: 50 })} marginTop20 color={COLOR.FONT} fontSize={fSize(rV, 26)}>
              Portal Home
            </Text>
          </Col>
          <Text marginLeft8 marginTop20 color={COLOR.FONT} fontSize={fSize(rV, 26)}>
            {`> Portfolio`}
          </Text>
          <Col flex1></Col>
          <Col
            onPress={chartMode === 'single' ? () => setChartMode('multiple') : () => navigate(SCREEN_NAME.GroupPortal)}
            marginRight={rV({ xs: 30, md: 40, lg: 50 })} marginTop20 paddingVertical10 paddingHorizontal5 backgroundColor="black" borderRadius6
          >
            <Text bold colorWhite fontSize={fSize(rV, 18)}>PORTFOLIO {chartMode === 'single' ? 'OVERVIEW' : 'HOME'}</Text>
          </Col>
        </Row>
        <Text marginLeft={rV({ xs: 30, md: 40, lg: 50 })} marginTop20 color={COLOR.FONT} fontSize={fSize(rV, 20)}>
          {pageData?.Description}
        </Text>
        <Row
          style={{ flexWrap: 'wrap' }}
          marginBottom={10}
          marginTop30
          marginHorizontal={rV({ xs: 25, md: 35, lg: 45 })}
        >
          <Col overflowH marginHorizontal={rV({ xs: 5, lg: 10 })} marginBottom10 backgroundColor="#132C3E">
            <Image source={pageDataImages[0] ? { uri: pageDataImages[0] } : ASSETS.IMG_ERINGTON} style={{ width: BLUR_WIDTH, height: BLUR_WIDTH / 1.2, resizeMode: 'cover' }} />
          </Col>
          <Col overflowH marginHorizontal={rV({ xs: 5, lg: 10 })} marginBottom10 backgroundColor="black">
            <Image source={pageDataImages[1] ? { uri: pageDataImages[1] } : ASSETS.IMG_ROYAL} style={{ width: BLUR_WIDTH, height: BLUR_WIDTH / 1.2, resizeMode: 'cover' }} />
          </Col>
          <Col overflowH marginHorizontal={rV({ xs: 5, lg: 10 })} marginBottom10>
            <Image source={pageDataImages[2] ? { uri: pageDataImages[2] } : ASSETS.IMG_PAYMENT} style={{ width: BLUR_WIDTH, height: BLUR_WIDTH / 1.2, resizeMode: 'cover' }} />
            <BlurView intensity={30} style={[StyleSheet.absoluteFill, styles.nonBlurredContent]}>
              <Text bold color={COLOR.WHITE} fontSize={fSize(rV, 37)}>
                DISTILLERIES
              </Text>
            </BlurView>
          </Col>
          <Col overflowH marginHorizontal={rV({ xs: 5, lg: 10 })} marginBottom10 backgroundColor={COLOR.MAIN}>
            <Col width={BLUR_WIDTH} height={BLUR_WIDTH / 1.2}></Col>
            {/* <Image source={ASSETS.IMG_REGIONS} style={{ width: BLUR_WIDTH, height: BLUR_WIDTH / 1.2, resizeMode: 'cover' }} /> */}
            <Col style={[StyleSheet.absoluteFill, styles.nonBlurredContent]}>
              <Text bold color={COLOR.WHITE} fontSize={fSize(rV, 37)}>
                REGIONS
              </Text>
            </Col>
          </Col>
          <Col overflowH marginHorizontal={rV({ xs: 5, lg: 10 })} marginBottom10>
            <Image source={pageDataImages[3] ? { uri: pageDataImages[3] } : ASSETS.IMG_LOGOUT} style={{ width: BLUR_WIDTH, height: BLUR_WIDTH / 1.2, resizeMode: 'cover' }} />
            <BlurView intensity={30} style={[StyleSheet.absoluteFill, styles.nonBlurredContent]}>
              <Text bold color={COLOR.WHITE} fontSize={fSize(rV, 26)}>
                ?????
              </Text>
            </BlurView>
          </Col>
        </Row>
        <Card
          titleBarBgColor={chartMode === 'single' ? currChart.color : undefined} marginHorizontal={rV({ xs: 30, md: 50, lg: 30 })}
          title={chartMode === 'single' ? `PORTFOLIO BY PERCENTAGE - ${currChart.title.toUpperCase()}` : "PORTFOLIO"} marginBottom10 iconRigth={<ICON.IconPie />}
        >
          {Boolean(chartMode === 'multiple') ? (
            <Row xs='100%' lg='1:1' marginHorizontal={rV({ xs: 20, md: 30, lg: 30 })} alignItems='flex-start'>
              <Row middle style={{ alignItems: 'flex-start' }}>
                <ChartMultiple
                  data={portfolioPieChartData}
                  size={rV({ xs: width / 2, md: width / 2, lg: width / 3 })}
                  onHoverPart={updateCurrChart}
                  onPressPart={switchToSingleChartView}
                />
                <Col marginHorizontal={rV({ xs: 20, lg: 30 })} style={{ alignItems: 'center', marginTop: rV({ xs: 40, md: 50, lg: 100 }) }}>
                  <Text bold color={currChart.color} fontSize={fSize(rV, 70)}>
                    {`${currChart.percent}%`}
                  </Text>
                  <Text color={COLOR.FONT} fontSize={fSize(rV, 20)}>
                    {`${currChart.title}`}
                  </Text>
                </Col>
              </Row>
              <Col>
                {/* <Image source={ASSETS.IMG_PORTFOLIO} style={{ width: rV({ xs: width / 2, md: 300, lg: width / 3 }), height: rV({ xs: width / 2.4, md: 300, lg: width / 3 }), resizeMode: 'contain' }} /> */}
                <Col width="50%" alignSelf="center">
                  <Text bold marginBottom20>THE REGIONS</Text>
                  {portfolioPieChartData.map((item, i) => (
                    <Row key={`chart${i}`} marginTop10>
                      <Col width={25} height={25} borderRadius={3} backgroundColor={item.color} />
                      <Text marginLeft15 fontSize={fSize(rV, 16)}>{item.title} ({VarHelper.money(Math.round(item.value))})</Text>
                    </Row>
                  ))}
                  {/* <Row marginTop20>
                    <Col borderRadius4 width20 height20 backgroundColor={COLOR.BLUE} />
                    <Text marginLeft15>Islands</Text>
                  </Row>
                  <Row marginTop20>
                    <Col borderRadius4 width20 height20 backgroundColor={COLOR.YELLOW} />
                    <Text marginLeft15>Campbeltown</Text>
                  </Row>

                  <Col marginVertical20 height1 backgroundColor={COLOR.BORDER} />

                  <Text>DISTILLERIES</Text>
                  <Row marginTop20>
                    <Col borderRadius4 width20 height20 backgroundColor={COLOR.DARKPINK} />
                    <Text marginLeft15>Lowlands</Text>
                  </Row>
                  <Row marginTop20>
                    <Col borderRadius4 width20 height20 backgroundColor={COLOR.PURPLE} />
                    <Text marginLeft15>Speyside</Text>
                  </Row>

                  <Col marginVertical20 height1 backgroundColor={COLOR.BORDER} />

                  <Text>CASK TYPE</Text>
                  <Row marginTop20>
                    <Col borderRadius4 width20 height20 backgroundColor={COLOR.GREEN} />
                    <Text marginLeft15>Highlands</Text>
                  </Row> */}
                </Col>
              </Col>
            </Row>
          ) : (
            <Row xs='100%' lg='1:1' marginHorizontal={rV({ xs: 20, md: 30, lg: 30 })} alignItems="flex-start">
              <Row middle style={{ alignItems: 'flex-start' }}>
                <ChartSingle
                  data={currChart}
                  size={rV({ xs: width / 2, md: width / 2, lg: width / 3 })}
                />
                <Col marginHorizontal={rV({ xs: 20, lg: 30 })} style={{ alignItems: 'center', marginTop: rV({ xs: 40, md: 50, lg: 100 }) }}>
                  <Text color={COLOR.FONT} fontSize={fSize(rV, 14)}>
                    {`Your ${currChart.title} Portfolio is`}
                  </Text>
                  <Text bold color={currChart.color} fontSize={fSize(rV, 70)}>
                    {`${currChart.percent}%`}
                  </Text>
                  <Text color={COLOR.FONT} fontSize={fSize(rV, 20)}>
                    {`COMPLETED`}
                  </Text>
                </Col>
                <Col absolute top={rV({ xs : -10, md: 0 })} left={rV({ xs : -20, md: 0 })} padding4 onPress={() => setChartMode('multiple')}>
                  <Ionicons name="arrow-back-outline" size={32} color={currChart.color} />
                </Col>
              </Row>
              <Col padding16>
                {/* <Image source={ASSETS.IMG_PORTFOLIO_SINGLE} style={{ width: rV({ xs: width / 2, md: 300, lg: width / 3 }), height: rV({ xs: width / 2.4, md: 300, lg: width / 3 }), resizeMode: 'contain' }} />
                <Text fontSize={12} center>Note: this is just a graphic for layout demo purpose</Text> */}
                <Text bold fontSize={fSize(rV, 24)} marginTop20>THE REGIONS</Text>

                <Text fontSize={fSize(rV, 20)} marginTop10>{currChart.title}</Text>

                <Col height1 marginVertical={24} backgroundColor="rgba(0,0,0,0.1)" />

                <Text bold fontSize={fSize(rV, 24)} marginTop20>DISTILLERIES</Text>

                <Row marginTop10>
                  <Col height30 width30 marginRight10 backgroundColor={currChart.color} />
                  <Text>You still need {100 - currChart.percent}% to complete this part of your portfolio</Text>
                </Row>
                <Col>
                  {listDistilleries.map((val, i) => (
                    <Text key={'i'+i} marginTop10>• {val}</Text>
                  ))}
                </Col>
              </Col>
            </Row>
          )}
        </Card>
      </Col>
      <Footer />
    </Col>
  );
};

Portfolio.routeInfo = {
  title: 'Portfolio',
  path: '/portfolio',
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  blurredImage: {
    width: 192,
    height: 192,
  },
  nonBlurredContent: {
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default Portfolio;