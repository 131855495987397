import * as React from "react"
import Svg, { G, Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={16.979}
      height={9.196}
      viewBox="0 0 16.979 9.196"
      {...props}
    >
      <G data-name="Group 5" fill="none" stroke="#707070" strokeLinecap="round">
        <Path data-name="Line 35" d="M.707.707l7.782 7.782" />
        <Path data-name="Line 36" d="M16.271.707L8.489 8.489" />
      </G>
    </Svg>
  )
}

export default SvgComponent
