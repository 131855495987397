import * as React from "react"
import Svg, { G, Path } from "react-native-svg"
import { fSize } from 'const';
import { useDynamicResponsiveValue } from 'react-quick-style-components';

function SvgComponent(props) {
  const rV = useDynamicResponsiveValue();
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={fSize(rV, 32.888)}
      height={fSize(rV, 37.419)}
      viewBox="0 0 32.888 37.419"
      {...props}
    >
      <G fill="#fff">
        <Path
          data-name="Path 169"
          d="M27.772 19.211v-3.5a11.348 11.348 0 00-8.039-10.839V3.289a3.289 3.289 0 00-6.578 0v1.583a11.348 11.348 0 00-8.039 10.841v3.5A17.213 17.213 0 01.301 31.179a1.1 1.1 0 00.791 1.855h9.977a5.482 5.482 0 0010.742 0h9.98a1.1 1.1 0 00.791-1.855 17.213 17.213 0 01-4.81-11.968zM15.348 3.289a1.1 1.1 0 012.193 0v1.149a11.367 11.367 0 00-2.2 0zm1.1 31.938a3.294 3.294 0 01-3.1-2.193h6.2a3.294 3.294 0 01-3.104 2.192zM3.459 30.841a19.383 19.383 0 003.849-11.63v-3.5a9.136 9.136 0 1118.271 0v3.5a19.382 19.382 0 003.849 11.63z"
        />
        <Path
          data-name="Path 170"
          d="M30.695 15.713a1.1 1.1 0 002.193 0 16.336 16.336 0 00-4.816-11.628 1.1 1.1 0 00-1.55 1.55 14.158 14.158 0 014.173 10.078z"
        />
        <Path
          data-name="Path 171"
          d="M1.1 16.809a1.1 1.1 0 001.1-1.1A14.158 14.158 0 016.374 5.632a1.1 1.1 0 00-1.55-1.55A16.336 16.336 0 000 15.713a1.1 1.1 0 001.1 1.096z"
        />
      </G>
    </Svg>
  )
}

export default SvgComponent