import { useEffect } from 'react';
import { useNavigation } from '@react-navigation/native';

const Reset = ({ to, timeout = 0 }) => {
  const navigation = useNavigation();
  useEffect(() => {
    setTimeout(() => {
      navigation.reset({
        index: 0,
        routes: [{ name: to }],
      });
    }, timeout)
  }, []);
  return null;
};

export default Reset;
