import { Input } from 'components';
import { COLOR, ICON } from 'const';
import React, { useEffect, useRef, useState } from 'react';
import { FlatList } from 'react-native';
import { ScrollView, StyleSheet } from 'react-native';
import { LayoutAnimation } from 'react-native';
import { Col, Row, Text, usePropsStyle } from 'react-quick-style-components';

export type TSingleOption = {
  value: any;
  label: string;
  [key: string]: any,
};

export interface IDropdownProps {
  label?: string;
  labelInside?: string;
  options: Array<TSingleOption>;
  value?: TSingleOption;
  placeholder?: string;
  onChange(value: TSingleOption): void;
  onFocus?(): void,
  onBlur?(): void,
  [anyProp: string]: any,
}

const getInputValue = (propsValue: TSingleOption): string => {
  if (!propsValue) return '';
  if (typeof propsValue === 'string') return propsValue;
  if (typeof propsValue === 'object' && propsValue != null) return propsValue.label;
};

const OptionComponent = ({ item, onPress }) => {
  return (
    <Row onHoverStyle={{ backgroundColor: COLOR.HOVER }} style={styles.optionComponent} onPress={onPress}>
      <Text fontSize15>{getInputValue(item)}</Text>
    </Row>
  );
};


const Dropdown = (props: IDropdownProps) => {
  const propStyle = usePropsStyle(props);
  const [state, setState] = useState({
    opened: false,
    searchKeyword: '',
    isSearching: false,
  });

  const toggleDropdown = () => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    setState({ ...state, opened: !state.opened })
  };

  const closeDropdown = () => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    setState({ ...state, opened: false });
  };

  const onFocusInput = () => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    setState({ ...state, opened: true, isSearching: true });
  };

  const onSelectOption = (item) => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    setState({ ...state, opened: false, searchKeyword: '', isSearching: false, });
    props.onChange(item);
    !!inputRef.current && inputRef.current.blur();
  };

  const searchResultOptions = state.searchKeyword ? props.options.filter(val => {
    if (typeof val === 'object' && !!val) return val.label.toLowerCase().includes(state.searchKeyword.toLowerCase());
  }) : props.options;

  const inputRef = useRef(null);

  useEffect(() => {
    if (state.opened) {
      typeof props.onFocus === 'function' && props.onFocus();
    } else {
      typeof props.onBlur === 'function' && props.onBlur();
    }
  }, [state.opened]);


  return (
    <Col style={propStyle}>
      <Row backgroundColor={COLOR.BG} paddingRight16 >
        <Input
          flex1
          value={state.isSearching ? state.searchKeyword : getInputValue(props.value)}
          onChangeText={(keyword) => setState({ ...state, searchKeyword: keyword, opened: true })}
          placeholder={props.placeholder}
          inputProps={{
            onFocus: onFocusInput,
            editable: props.options.length <= 10 ? false : true,
          }}
          label={props.label}
          labelInside={props.labelInside}
          ref={ref => inputRef.current = ref}
        />
        {state.opened ?
          <Col padding5 onPress={closeDropdown}>
            <ICON.IcArrowUp />
          </Col>
          :
          <Col padding5 onPress={toggleDropdown}>
            <ICON.IcArrowDown />
          </Col>
        }
      </Row>
      <Col height={state.opened ? 50 * Math.min(searchResultOptions.length || 1, 4) : 0} marginBottom12 marginTop8 >
        {Boolean(searchResultOptions.length === 0) ? (
          <ScrollView bounces={false}>
            <OptionComponent item="Nothing found" onPress={closeDropdown} />
          </ScrollView>
        ) : (
          <FlatList
            data={searchResultOptions}
            renderItem={({ item }) => <OptionComponent item={item} onPress={() => onSelectOption(item)} />}
            keyExtractor={(item, index) => "dropdown-item-" + index}
            keyboardShouldPersistTaps="always"
          />
        )}
      </Col>

    </Col>
  );
};

const styles = StyleSheet.create({
  optionComponent: {
    height: 48,
    borderRadius: 4,
    backgroundColor: COLOR.BG,
    borderColor: COLOR.MAIN,
    borderWidth: 0.5,
    paddingHorizontal: 16,
    marginBottom: 2,
  },
});


export default Dropdown;
