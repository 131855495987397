import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Col, Text, Row, useDynamicResponsiveValue } from 'react-quick-style-components';
import { ChartMultiple } from '../index';
import { VarHelper } from 'helpers';
import { ASSETS, COLOR, fSize, ICON, SCREEN_NAME } from 'const';
import { useNavFunc } from 'navigation';
import Store from 'store';
import PortfolioValueChart from './PortfolioValueChart';
import RegionChart from './RegionChart';
import DistilleryChart from './DistilleryChart';
import EdringtonTrilogyChart from './EdringtonTrilogyChart';
import RoyalTrilogyChart from './RoyalTrilogyChart';
import LegacyCollectionChart from './LegacyCollectionChart';
import CustomTrilogyChart from './CustomTrilogyChart';
import { LEGACY_COLLECTION_DES } from './legacyCollectionDes';

const TAB = {
  PORTFOLIO_VALUE: 'PORTFOLIO VALUE',
  REGIONS: 'REGIONS',
  DISTILLERIES: 'DISTILLERIES',
  // EDRINGTON_TRILOGY: 'EDRINGTON TRILOGY',
  // ROYAL_TRILOGY: 'ROYAL TRILOGY',
  // OWNER_COLLECTION: 'OWNER COLLECTION',
  // LEGACY_COLLECTION: 'LEGACY COLLECTION',
  // AROMA_COLLECTION: 'AROMA COLLECTION',
}

const CaskIOwn = forwardRef((props, ref) => {
  const [{
    regionPieChartData, portfolioPieChartData, distilleryPieChartData,
    edringtonTrilogyPieChartData,
    royalTrilogyPieChartData,
    legacyCollectionPieChartData,
    listClients
  }, cApi] = Store.Client.createStore();
  const [{}, iApi] = Store.Info.createStore();
  const [activeTab, setActiveTab] = useState(TAB.PORTFOLIO_VALUE);
  const [chartTypeData, setChartTypeData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const [res, err] = await iApi.getCaskIOwnChartType();
      if (err) {
        console.log(err);
        return;
      }
      if (!!res && !!res.data) {
        console.log('GET CASK I OWN CHART TYPE', res);
        setChartTypeData(res.data);
      }
    };

    getData();
  }, []);

  const tabArr = Object.keys(TAB).map(val => TAB[val]);

  useImperativeHandle(ref, () => ({
    setActiveTab,
  }));

  const customTrilogyChartType = chartTypeData.find(val => (val.Name || '').toUpperCase() === activeTab);
  console.info('customTrilogyChartType', customTrilogyChartType);

  return (
    <Col>
      <Row style={{ flexWrap: 'wrap' }} borderBottomWidth1 borderBottomColor='rgba(0,0,0,0.2)'>
        {tabArr.map(val => (
          <Col
            key={val} paddingHorizontal10 paddingVertical5
            onPress={val === activeTab ? undefined : () => setActiveTab(val)}
          >
            <Text bold={val === activeTab}>{val}</Text>
          </Col>
        ))}
        {!!chartTypeData && Array.isArray(chartTypeData) && chartTypeData.map((val, valI) => {
          const name = (val.Name || '').toUpperCase();
          return (
            <Col
              key={'customTrilogi-'+name+valI} paddingHorizontal10 paddingVertical5
              onPress={name === activeTab ? undefined : () => setActiveTab(name)}
            >
              <Text bold={name === activeTab}>{name}</Text>
            </Col>
          );
        })}
      </Row>
      {activeTab === TAB.PORTFOLIO_VALUE ? <PortfolioValueChart data={portfolioPieChartData} />
      : activeTab === TAB.REGIONS ? <RegionChart data={regionPieChartData} />
      : activeTab === TAB.DISTILLERIES ? <DistilleryChart data={distilleryPieChartData} />
      : customTrilogyChartType ? <CustomTrilogyChart data={customTrilogyChartType} listClients={listClients} /> : null
      }
      {/* {activeTab === TAB.EDRINGTON_TRILOGY && <EdringtonTrilogyChart data={edringtonTrilogyPieChartData} />}
      {activeTab === TAB.ROYAL_TRILOGY && <RoyalTrilogyChart data={royalTrilogyPieChartData} />}
      {activeTab === TAB.LEGACY_COLLECTION && <LegacyCollectionChart data={legacyCollectionPieChartData} />} */}

    </Col>
  );
});

export default CaskIOwn;