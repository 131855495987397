import React, { useEffect, useState } from "react";
import { StyleSheet, Image } from "react-native";
import {
  Col,
  Text,
  useDynamicResponsiveValue,
} from "react-quick-style-components";
import Select from "react-select";

import { ASSETS, COLOR, fSize, spacing } from "const";
import RequestBE from "store/RequestBE.utils";
import { Input, Button } from "components";
import { IScreen } from "type";
import { BE_HOST } from "store/host";

const KYCForm: IScreen = () => {
  const rV = useDynamicResponsiveValue();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [user, setUser] = useState(null);
  const [message, setMessage] = useState(null);
  const [done, setDone] = useState(false);
  const [form, setForm] = useState({
    firstname: "",
    lastname: "",
    dob: "",
    phone: "",
    country: null,
    trader: { value: "No", label: "No" },
    wowgr: "",
  });

  useEffect(() => {
    if (user) {
      setForm((f) => ({
        ...f,
        firstname: user.firstname ?? f.firstname,
        lastname: user.lastname ?? f.lastname,
        phone: user.phone ?? f.phone,
      }));
    }
  }, [user]);

  const handleFormChange = (key: keyof typeof form) => async (value: string) =>
    setForm((f) => ({ ...f, [key]: value }));

  const submitForm = async () => {
    setLoading(true);
    if (
      !form.firstname ||
      !form.lastname ||
      !form.dob ||
      !form.phone ||
      !form.country
    ) {
      setMessage("Please fill in all fields");
      setLoading(false);
      return;
    }

    if (form.dob) {
      let valid = true;
      const dateParts = form.dob.split("/");

      if (dateParts.length !== 3) {
        valid = false;
      }

      const [day, month, year] = dateParts.map((p) => parseInt(p, 10));

      const date = new Date(year, month - 1, day);

      const age = ~~((Date.now() - date.getTime()) / 31557600000);

      if (age < 18 || age > 120) {
        valid = false;
      }

      if (!valid) {
        setMessage("Please enter a valid date");
        setLoading(false);
        return;
      }

      try {
        const res = await RequestBE.post(BE_HOST + "/hubspot/kyc", {
          id: user.hs_object_id,
          first_name: form.firstname,
          last_name: form.lastname,
          dob: form.dob,
          email: user.email,
          mobile: form.phone,
          country_code: form.country.value,
          trader: form.trader.value,
          wowgr: form.wowgr,
        });

        if (res.data.success) {
          setDone(true);
        } else throw Error(res.data.message || res.data);
      } catch (err) {
        console.error(err);
        setMessage(
          typeof err.message === "string"
            ? err.message
            : "Something went wrong, please try again"
        );
      } finally {
        setLoading(false);
      }
    }
  };

  const findUser = async () => {
    if (!email) return;
    setMessage(null);
    setLoading(true);
    try {
      const res = await RequestBE.post(BE_HOST + "/hubspot/get-user", {
        email,
      });

      if ("message" in res.data) {
        setMessage(res.data.message);
      } else {
        setUser(res.data);
      }
    } catch (err) {
      console.error(err);
      setMessage("Something went wrong, please try again");
    } finally {
      setLoading(false);
    }
  };

  const formMargin = rV({
    xs: spacing[4],
    md: spacing[8],
    lg: spacing[12],
    xl: spacing[16],
  });

  const selectStyles = {
    container: (base) => ({
      ...base,
      borderRadius: 0,
      maxWidth: 450,
    }),
    input: (base) => ({
      ...base,
      height: 45,
      margin: 0,
      padding: 0,
      borderRadius: 0,
    }),
    control: (base, state) => ({
      ...base,
      borderColor: state.isFocused ? "#4d4d4d" : "transparent",
      borderRadius: 0,
      ":focus": {
        outline: "none !important",
      },
    }),
    valueContainer: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 16,
      paddingRight: 16,
      backgroundColor: "#f5f5f5",
      borderRadius: 0,
    }),
    indicatorsContainer: (base) => ({
      ...base,
      backgroundColor: "#f5f5f5",
    }),
    singleValue: (base) => ({
      ...base,
      fontSize: fSize(rV, 24),
    }),
    placeholder: (base) => ({
      ...base,
      fontSize: fSize(rV, 24),
    }),
    menu: (base) => ({
      ...base,
      position: "absolute",
      zIndex: 10000000000,
    }),
  };

  return (
    <Col flex1>
      <Col flex1 backgroundColor={COLOR.BG} middle>
        <Col
          width={rV({ xs: "90%", sm: "80%", md: "70%", lg: "60%", xl: "50%" })}
        >
          <Col
            style={[
              styles.contentBox,
              { paddingBottom: rV({ xs: 40, xxxl: 65 }) },
            ]}
          >
            {done ? (
              <Text
                textAlign="center"
                bold
                fontSize={fSize(rV, 50)}
                colorMain
                marginBottom16
                marginHorizontal={formMargin}
              >
                Thank you, we will get in touch with you shortly
              </Text>
            ) : user !== null ? (
              <>
                <Text
                  textAlign="center"
                  bold
                  fontSize={fSize(rV, 50)}
                  colorMain
                  marginBottom16
                >
                  Hello, {user.firstname}
                </Text>
                <Col marginHorizontal={formMargin} marginBottom16>
                  <Input
                    label="First name"
                    value={form.firstname}
                    onChangeText={handleFormChange("firstname")}
                    alignSelf="center"
                    width="80%"
                  />
                </Col>
                <Col marginHorizontal={formMargin} marginBottom16>
                  <Input
                    label="Last name"
                    value={form.lastname}
                    onChangeText={handleFormChange("lastname")}
                    alignSelf="center"
                    width="80%"
                  />
                </Col>
                <Col
                  marginHorizontal={formMargin}
                  marginBottom16
                  style={{ alignItems: "center" }}
                  zIndex={1000}
                >
                  <Col width="80%" zIndex={1000}>
                    <Text fontSize={fSize(rV, 24)} colorMain paddingBottom16>
                      Country of Residence
                    </Text>
                    <Select
                      styles={selectStyles}
                      value={form.country}
                      options={countries.map((x) => ({
                        label: x.name,
                        value: x.let3,
                      }))}
                      onChange={(country) =>
                        setForm((f) => ({ ...f, country }))
                      }
                    />
                  </Col>
                </Col>
                <Col
                  marginHorizontal={formMargin}
                  marginBottom16
                  style={{ alignItems: "center" }}
                  zIndex={500}
                >
                  <Col width="80%" zIndex={500}>
                    <Text fontSize={fSize(rV, 24)} colorMain paddingBottom16>
                      Are you a revenue trader?
                    </Text>
                    <Select
                      styles={selectStyles}
                      value={form.trader}
                      options={[
                        {
                          label: "Yes",
                          value: "Yes",
                        },
                        {
                          label: "No",
                          value: "No",
                        },
                      ]}
                      onChange={(trader) => setForm((f) => ({ ...f, trader }))}
                    />
                  </Col>
                </Col>
                {form.trader.value === "Yes" && (
                  <Col marginHorizontal={formMargin} marginBottom16>
                    <Input
                      label="Please add your WOWGR Number"
                      value={form.wowgr}
                      onChangeText={handleFormChange("wowgr")}
                      alignSelf="center"
                      width="80%"
                    />
                  </Col>
                )}
                <Col marginHorizontal={formMargin} marginBottom16>
                  <Input
                    label="Date of Birth"
                    value={form.dob}
                    onChangeText={handleFormChange("dob")}
                    alignSelf="center"
                    width="80%"
                    placeholder="DD/MM/YYYY"
                  />
                </Col>
                <Col marginHorizontal={formMargin} marginBottom16>
                  <Input
                    label="Mobile Number"
                    value={form.phone}
                    onChangeText={handleFormChange("phone")}
                    alignSelf="center"
                    width="80%"
                  />
                </Col>
                {message && (
                  <Col paddingVertical16 marginHorizontal={formMargin}>
                    <Text width="80%" alignSelf="center">
                      {message}
                    </Text>
                  </Col>
                )}
                <Button
                  marginTop={rV({ xs: 30, xxxl: 59 })}
                  title={loading ? "Loading..." : "Register"}
                  width192
                  alignSelf="center"
                  onPress={submitForm}
                />
              </>
            ) : (
              <>
                <Col alignItems="center">
                  <Image
                    source={ASSETS.LOGIN_LOGO_DARK}
                    style={{
                      width: 306 * 0.8,
                      height: 156 * 0.8,
                      resizeMode: "contain",
                    }}
                  />
                </Col>
                <Text
                  textAlign="center"
                  bold
                  fontSize={fSize(rV, 50)}
                  colorMain
                  paddingVertical16
                >
                  REGISTER
                </Text>
                <Col marginHorizontal={formMargin}>
                  <Input
                    label="Email Address"
                    value={email}
                    onChangeText={(text: string) => setEmail(text)}
                    alignSelf="center"
                    width="80%"
                  />
                </Col>
                {message && (
                  <Col paddingVertical16 marginHorizontal={formMargin}>
                    <Text width="80%" alignSelf="center">
                      {message}
                    </Text>
                  </Col>
                )}
                <Button
                  marginTop={rV({ xs: 30, xxxl: 59 })}
                  title={loading ? "Loading..." : "Next"}
                  width192
                  alignSelf="center"
                  onPress={findUser}
                />
              </>
            )}
          </Col>
        </Col>
      </Col>
    </Col>
  );
};

KYCForm.routeInfo = {
  title: "Welcome to Vintage Acquisitions",
  path: "/aml",
};

const styles = StyleSheet.create({
  contentBox: {
    paddingTop: 32,
    backgroundColor: COLOR.WHITE,
    borderColor: COLOR.BORDER,
    borderWidth: 1,
    borderTopLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
});

export default KYCForm;

const countries = [
  { name: "Afghanistan", let2: " AF", let3: "AFG" },
  { name: "Albania", let2: "AL", let3: "ALB" },
  { name: "Algeria", let2: "DZ", let3: "DZA" },
  { name: "American Samoa", let2: "AS", let3: "ASM" },
  { name: "Andorra", let2: "AD", let3: "AND" },
  { name: "Angola", let2: "AO", let3: "AGO" },
  { name: "Anguilla", let2: "AI", let3: "AIA" },
  { name: "Antigua and Barbuda", let2: "AG", let3: "ATG" },
  { name: "Argentina", let2: "AR", let3: "ARG" },
  { name: "Armenia", let2: "AM", let3: "ARM" },
  { name: "Aruba", let2: "AW", let3: "ABW" },
  { name: "Australia", let2: "AU", let3: "AUS" },
  { name: "Austria", let2: "AT", let3: "AUT" },
  { name: "Azerbaijan", let2: "AZ", let3: "AZE" },
  { name: "Bahamas", let2: "BS", let3: "BHS" },
  { name: "Bahrain", let2: "BH", let3: "BHR" },
  { name: "Bangladesh", let2: "BD", let3: "BGD" },
  { name: "Barbados", let2: "BB", let3: "BRB" },
  { name: "Belarus", let2: "BY", let3: "BLR" },
  { name: "Belgium", let2: "BE", let3: "BEL" },
  { name: "Belize", let2: "BZ", let3: "BLZ" },
  { name: "Benin", let2: "BJ", let3: "BEN" },
  { name: "Bermuda", let2: "BM", let3: "BMU" },
  { name: "Bhutan", let2: "BT", let3: "BTN" },
  { name: "Bolivia", let2: "BO", let3: "BOL" },
  { name: "Bosnia and Herzegovina", let2: "BA", let3: "BIH" },
  { name: "Botswana", let2: "BW", let3: "BWA" },
  { name: "Brazil", let2: "BR", let3: "BRA" },
  { name: "British Virgin Islands", let2: "VG", let3: "VGB" },
  { name: "Brunei Darussalam", let2: "BN", let3: "BRN" },
  { name: "Bulgaria", let2: "BG", let3: "BGR" },
  { name: "Burkina Faso", let2: "BF", let3: "BFA" },
  { name: "Burundi", let2: "BI", let3: "BDI" },
  { name: "Cambodia", let2: "KH", let3: "KHM" },
  { name: "Cameroon", let2: "CM", let3: "CMR" },
  { name: "Canada", let2: "CA", let3: "CAN" },
  { name: "Cape Verde", let2: "CV", let3: "CPV" },
  { name: "Central African Republic", let2: "CF", let3: "CAF" },
  { name: "Chad", let2: "TD", let3: "TCD" },
  { name: "Chile", let2: "CL", let3: "CHL" },
  { name: "China", let2: "CN", let3: "CHN" },
  { name: "Hong Kong", let2: "HK", let3: " \n         HKG" },
  { name: "Macao", let2: "MO", let3: "MAC" },
  { name: "Colombia", let2: "CO", let3: "COL" },
  { name: "Comoros", let2: "KM", let3: "COM" },
  { name: "Congo", let2: "CG", let3: "COG" },
  { name: "Costa Rica", let2: "CR", let3: "CRI" },
  { name: "Côte d'Ivoire", let2: "CI", let3: "CIV" },
  { name: "Croatia", let2: "HR", let3: "HRV" },
  { name: "Cuba", let2: "CU", let3: "CUB" },
  { name: "Cyprus", let2: "CY", let3: "CYP" },
  { name: "Czech Republic", let2: "CZ", let3: "CZE" },
  { name: "Denmark", let2: "DK", let3: "DNK" },
  { name: "Djibouti", let2: "DJ", let3: "DJI" },
  { name: "Dominica", let2: "DM", let3: "DMA" },
  { name: "Dominican Republic", let2: "DO", let3: "DOM" },
  { name: "Ecuador", let2: "EC", let3: "ECU" },
  { name: "Egypt", let2: "EG", let3: "EGY" },
  { name: "El Salvador", let2: "SV", let3: "SLV" },
  { name: "Equatorial Guinea", let2: "GQ", let3: "GNQ" },
  { name: "Eritrea", let2: "ER", let3: "ERI" },
  { name: "Estonia", let2: "EE", let3: "EST" },
  { name: "Ethiopia", let2: "ET", let3: "ETH" },
  { name: "Faroe Islands", let2: "FO", let3: " \n      FRO" },
  { name: "Fiji", let2: "FJ", let3: "FJI" },
  { name: "Finland", let2: "FI", let3: "FIN" },
  { name: "France", let2: "FR", let3: "FRA" },
  { name: "French Guiana", let2: "GF", let3: "GUF" },
  { name: "French Polynesia", let2: "PF", let3: "PYF" },
  { name: "Gabon", let2: "GA", let3: "GAB" },
  { name: "Gambia", let2: "GM", let3: "GMB" },
  { name: "Georgia", let2: "GE", let3: "GEO" },
  { name: "Germany", let2: "DE", let3: "DEU" },
  { name: "Ghana", let2: "GH", let3: "GHA" },
  { name: "Greece", let2: "GR", let3: "GRC" },
  { name: "Greenland", let2: "GL", let3: "GRL" },
  { name: "Grenada", let2: "GD", let3: "GRD" },
  { name: "Guadeloupe", let2: "GP", let3: "GLP" },
  { name: "Guam", let2: "GU", let3: "GUM" },
  { name: "Guatemala", let2: "GT", let3: "GTM" },
  { name: "Guinea-Bissau", let2: "GW", let3: "GNB" },
  { name: "Haiti", let2: "HT", let3: "HTI" },
  { name: "Honduras", let2: "HN", let3: "HND" },
  { name: "Iceland", let2: "IS", let3: "ISL" },
  { name: "Indonesia", let2: "ID", let3: "IDN" },
  { name: "Iraq", let2: "IQ", let3: "IRQ" },
  { name: "Ireland", let2: "IE", let3: "IRL" },
  { name: "Italy", let2: "IT", let3: "ITA" },
  { name: "Japan", let2: "JP", let3: "JPN" },
  { name: "Jordan", let2: "JO", let3: "JOR" },
  { name: "Kazakhstan", let2: "KZ", let3: "KAZ" },
  { name: "Kenya", let2: "KE", let3: "KEN" },
  { name: "Kiribati", let2: "KI", let3: "KIR" },
  { name: "Korea", let2: "KP", let3: "PRK" },
  { name: "Korea", let2: "KR", let3: "KOR" },
  { name: "Kuwait", let2: "KW", let3: "KWT" },
  { name: "Kyrgyzstan", let2: "KG", let3: "KGZ" },
  { name: "Lao PDR", let2: "LA", let3: "LAO" },
  { name: "Latvia", let2: "LV", let3: "LVA" },
  { name: "Lebanon", let2: "LB", let3: "LBN" },
  { name: "Lesotho", let2: "LS", let3: "LSO" },
  { name: "Liberia", let2: "LR", let3: "LBR" },
  { name: "Libya", let2: "LY", let3: "LBY" },
  { name: "Liechtenstein", let2: "LI", let3: "LIE" },
  { name: "Lithuania", let2: "LT", let3: "LTU" },
  { name: "Luxembourg", let2: "LU", let3: "LUX" },
  { name: "Madagascar", let2: "MG", let3: "MDG" },
  { name: "Malawi", let2: "MW", let3: "MWI" },
  { name: "Malaysia", let2: "MY", let3: "MYS" },
  { name: "Maldives", let2: "MV", let3: "MDV" },
  { name: "Mali", let2: "ML", let3: "MLI" },
  { name: "Malta", let2: "MT", let3: "MLT" },
  { name: "Marshall Islands", let2: "MH", let3: "MHL" },
  { name: "Martinique", let2: "MQ", let3: "MTQ" },
  { name: "Mauritania", let2: "MR", let3: "MRT" },
  { name: "Mauritius", let2: "MU", let3: "MUS" },
  { name: "Mexico", let2: "MX", let3: "MEX" },
  { name: "Micronesia, Federated States of", let2: "FM", let3: "FSM" },
  { name: "Moldova", let2: "MD", let3: "MDA" },
  { name: "Monaco", let2: "MC", let3: "MCO" },
  { name: "Mongolia", let2: "MN", let3: "MNG" },
  { name: "Montenegro", let2: "ME", let3: "MNE" },
  { name: "Montserrat", let2: "MS", let3: "MSR" },
  { name: "Morocco", let2: "MA", let3: "MAR" },
  { name: "Mozambique", let2: "MZ", let3: "MOZ" },
  { name: "Myanmar", let2: "MM", let3: "MMR" },
  { name: "Namibia", let2: "NA", let3: "NAM" },
  { name: "Nauru", let2: "NR", let3: "NRU" },
  { name: "Nepal", let2: "NP", let3: "NPL" },
  { name: "Netherlands", let2: "NL", let3: "NLD" },
  { name: "Netherlands Antilles", let2: "AN", let3: "ANT" },
  { name: "New Caledonia", let2: "NC", let3: "NCL" },
  { name: "New Zealand", let2: "NZ", let3: "NZL" },
  { name: "Nicaragua", let2: "NI", let3: "NIC" },
  { name: "Niger", let2: "NE", let3: "NER" },
  { name: "Nigeria", let2: "NG", let3: "NGA" },
  { name: "Northern Mariana Islands", let2: "MP", let3: "MNP" },
  { name: "Norway", let2: "NO", let3: "NOR" },
  { name: "Oman", let2: "OM", let3: "OMN" },
  { name: "Pakistan", let2: "PK", let3: "PAK" },
  { name: "Palau", let2: "PW", let3: "PLW" },
  { name: "Palestinian Territory", let2: "PS", let3: " \n      PSE" },
  { name: "Panama", let2: "PA", let3: "PAN" },
  { name: "Papua New Guinea", let2: "PG", let3: "PNG" },
  { name: "Paraguay", let2: "PY", let3: "PRY" },
  { name: "Peru", let2: "PE", let3: "PER" },
  { name: "Philippines", let2: "PH", let3: "PHL" },
  { name: "Pitcairn", let2: "PN", let3: "PCN" },
  { name: "Poland", let2: "PL", let3: "POL" },
  { name: "Portugal", let2: "PT", let3: "PRT" },
  { name: "Puerto Rico", let2: "PR", let3: "PRI" },
  { name: "Qatar", let2: "QA", let3: "QAT" },
  { name: "Réunion", let2: "RE", let3: "REU" },
  { name: "Romania", let2: "RO", let3: "ROU" },
  { name: "Russian Federation", let2: "RU", let3: "RUS" },
  { name: "Rwanda", let2: "RW", let3: "RWA" },
  { name: "Saint Kitts and Nevis", let2: "KN", let3: "KNA" },
  { name: "Saint Lucia", let2: "LC", let3: "LCA" },
  { name: "Saint Vincent and Grenadines", let2: "VC", let3: "VCT" },
  { name: "Samoa", let2: "WS", let3: "WSM" },
  { name: "San Marino", let2: "SM", let3: "SMR" },
  { name: "Sao Tome and Principe", let2: "ST", let3: "STP" },
  { name: "Saudi Arabia", let2: "SA", let3: "SAU" },
  { name: "Senegal", let2: "SN", let3: "SEN" },
  { name: "Serbia", let2: "RS", let3: "SRB" },
  { name: "Seychelles", let2: "SC", let3: "SYC" },
  { name: "Sierra Leone", let2: "SL", let3: "SLE" },
  { name: "Singapore", let2: "SG", let3: "SGP" },
  { name: "Slovakia", let2: "SK", let3: "SVK" },
  { name: "Slovenia", let2: "SI", let3: "SVN" },
  { name: "Solomon Islands", let2: "SB", let3: "SLB" },
  { name: "Somalia", let2: "SO", let3: "SOM" },
  { name: "South Africa", let2: "ZA", let3: "ZAF" },
  { name: "Spain", let2: "ES", let3: "ESP" },
  { name: "Sri Lanka", let2: "LK", let3: "LKA" },
  { name: "Sudan", let2: "SD", let3: "SDN" },
  { name: "Suriname", let2: "SR", let3: "SUR" },
  { name: "Swaziland", let2: "SZ", let3: "SWZ" },
  { name: "Sweden", let2: "SE", let3: "SWE" },
  { name: "Switzerland", let2: "CH", let3: "CHE" },
  { name: "Syrian Arab Republic", let2: "SY", let3: "SYR" },
  { name: "Tajikistan", let2: "TJ", let3: "TJK" },
  { name: "Tanzania", let2: "TZ", let3: "TZA" },
  { name: "Thailand", let2: "TH", let3: "THA" },
  { name: "Timor-Leste", let2: "TL", let3: "TLS" },
  { name: "Togo", let2: "TG", let3: "TGO" },
  { name: "Tonga", let2: "TO", let3: "TON" },
  { name: "Trinidad and Tobago", let2: "TT", let3: "TTO" },
  { name: "Tunisia", let2: "TN", let3: "TUN" },
  { name: "Turkey", let2: "TR", let3: "TUR" },
  { name: "Turkmenistan", let2: "TM", let3: "TKM" },
  { name: "Tuvalu", let2: "TV", let3: "TUV" },
  { name: "Uganda", let2: "UG", let3: "UGA" },
  { name: "Ukraine", let2: "UA", let3: "UKR" },
  { name: "United Arab Emirates", let2: "AE", let3: "ARE" },
  { name: "United Kingdom", let2: "GB", let3: "GBR" },
  { name: "United States of America", let2: "US", let3: "USA" },
  { name: "Uruguay", let2: "UY", let3: "URY" },
  { name: "Uzbekistan", let2: "UZ", let3: "UZB" },
  { name: "Vanuatu", let2: "VU", let3: "VUT" },
  { name: "Venezuela", let2: "VE", let3: "VEN" },
  { name: "Viet Nam", let2: "VN", let3: "VNM" },
  { name: "Virgin Islands, US", let2: "VI", let3: "VIR" },
  { name: "Yemen", let2: "YE", let3: "YEM" },
  { name: "Zambia", let2: "ZM", let3: "ZMB" },
  { name: "Zimbabwe", let2: "ZW", let3: "ZWE" },
];
