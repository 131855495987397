import React, { useEffect, useMemo, useRef } from 'react';
import { Col, Text, useDynamicResponsiveValue, Row } from 'react-quick-style-components';
import { useNavFunc } from '../navigation';
import { IScreen } from '../type';
import { ASSETS, COLOR, fSize, ICON, SCREEN_NAME } from '../const';
import { Image, StyleSheet, useWindowDimensions } from 'react-native';
import { Breadcrumbs, Card, CaskIOwn, ChatBox, Footer, Header, LiquidEffect as LiquidEffectHome, Table, TableWithAction } from '../components';
import Store from '../store';
import _ from 'lodash';
import { TimeHelper, VarHelper } from '../helpers';
import { useCommission } from './Casks.useCommission';

let XLSX;

const Home: IScreen = () => {
  const [{ email, name }] = Store.User.createStore();
  const [{ }, mApi] = Store.Message.createStore();
  const [{ listNews, listPages }, iApi] = Store.Info.createStore();

  const [{ listDocs, listAvailableCasks, listClients }, cApi] = Store.Client.createStore();

  const { navigate, reset } = useNavFunc();
  const { width, height } = useWindowDimensions();
  const rV = useDynamicResponsiveValue();
  const BLUR_WIDTH = rV({ xs: (width - 80) / 2, md: (width - 160) / 3 });
  const BLUR_HEIGHT = rV({ xs: (width - 80) / 2, md: (width - 160) * 0.5 / 3 });
  const ITEM_WIDTH = rV({ xs: (width - 30), lg: (width - 110) / 3 });
  const ROW_MARGIN = rV({ xs: 10, md: 35, lg: 45 });
  const COLUMN_MARGIN = rV({ xs: 5, lg: 10 });

  useEffect(() => {
    if (!XLSX) {
      import('xlsx').then(xlsx => {
        XLSX = xlsx;
      });
    }
  }, []);

  useEffect(() => {
    iApi.getListEvents();
    iApi.getListNews();
    cApi.getListClients();
    cApi.getListAvailableCasks();
    iApi.getListPages();
    cApi.getListDocs();
  }, []);

  const chartRef = useRef<{ setActiveTab: Function }>();

  const pageData = listPages.find(val => val.Title === 'Account Summary');
  const pageDataImages = VarHelper.imgApiStringifyGetAll(pageData?.Images);

  const myAM = listClients.length > 0 ? listClients[0]['Account Manager'] : '';

  const messageAdmin = async (item) => {
    // console.log('item', item);
    // console.log('myAM', myAM);
    // { Region: 'Highlands', Cask Type: 'Barel', Age: 5, Price: '5-10' }
    const [res, err] = await mApi.sendMessage({
      subject: 'Request cask info',
      from: email,
      message: `${name} (${email}) has requested info about this cask: Region ${item.Region} | type ${item['Cask Type']} | Age ${item.Age} | Price ${item.Price}`,
      am: myAM,
    });
    alert('Thank you, your request has been sent');
  }

  const ScreenContainer = React.Fragment;

  const { portfolioValue, comission, myCommision, myIndex } = useCommission();

  const listDistilleries = _.uniqWith(listClients.filter(val => val.Email === email && !!val['Distillery']).map(val => val.Distillery), _.isEqual);
  const filterClient = listClients.filter(val => val.Email === email).sort((a, b) => a['Region'] < b['Region'] ? -1 : 1);

  const exportExcel = () => {
    var wb = XLSX.utils.book_new();
    var ws = XLSX.utils.json_to_sheet([
      {
        Name: 'Portfolio',
        Value: VarHelper.money(portfolioValue),
      },
      {
        Name: 'No. of casks I own',
        Value: filterClient.length,
      },
      {
        Name: 'Your Distilleries',
        Value: listDistilleries.length,
      },
    ]);
    XLSX.utils.book_append_sheet(wb, ws, "Account Summary");

    const yourCasks = filterClient.map(val => ({
      ...val,
      ['AYS']: TimeHelper.format(val['AYS']),
      ['Cask price']: VarHelper.money(val['Unit Price']),
      ['Portfolio']: VarHelper.money(val['Portfolio']),
      ['Purchased Date']: TimeHelper.format(val['Purchased Date']),
      ['Cask No']: val['Cask NO'],
      ['RLA']: val['OLA/RLA'],
      // ['KYC']: val['KYC'],
      'Recommended Re-gauge Date': TimeHelper.format(val['Recommended Re-gauge Date']),
    }))
    var ws2 = XLSX.utils.json_to_sheet(yourCasks);
    XLSX.utils.book_append_sheet(wb, ws2, "Your Casks");

    var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    saveAs(new Blob([wbout], { type: "application/octet-stream" }), `export-account-summary-${new Date().getTime()}.xlsx`);
  };

  const renderRow = (arrayOfComp) => {
    return (
      <Row
        style={{ flexWrap: 'wrap' }}
        marginBottom={10}
        marginTop20 stretch={rV({ xs: undefined, md: true })}
        marginHorizontal={ROW_MARGIN}
      >
        {arrayOfComp}
      </Row>
    )
  }

  const renderCasksIOwn = () => {
    return (
      <Card
        title={"Casks I own"}
        width={rV({ xs: ITEM_WIDTH - 20, lg: ITEM_WIDTH * 3 - 10 })}
        marginHorizontal={COLUMN_MARGIN} marginBottom10 iconRigth={<ICON.IconPie />}
      >
        <CaskIOwn ref={chartRef} />
      </Card>
    )
  }

  const renderYourCask = () => {
    return (
        <Col justifyContent='center' width={rV({ xs: ITEM_WIDTH, lg: ITEM_WIDTH * 3 - 10 })}
          marginHorizontal={COLUMN_MARGIN} marginBottom10>
          {/* <Card title={"Your Casks"}>
            
          </Card> */}
          {!!filterClient && filterClient.length === 0 ? (
            <Col>
              <Text body1>No record found</Text>
            </Col>
          ) : (
            <>
              <Text fontSize={fSize(rV, 52)} marginBottom20>
                {'Your Casks'}
              </Text>

              <Table
                // columns={[
                //   // 'Spirit', 'Distillery', 'Region', 'AYS', 'Cask NO', 'Cask Type',
                //   // 'OLA/RLA', 'ABV', 'Cask price',
                //   // 'Location',
                //   // 'Purchased Date',
                //   // 'Recommended Re-gauge',
                //   'Cask NO',
                //   'Spirit',
                //   'Purchase Date',
                //   'Distillery',
                //   'OLA',
                //   'RLA',
                //   'ABV',
                //   'Location',
                // ].map(val => ({
                //   key: val, title: val, padding: 5
                // })).map(val => ({ ...val, center: true, width: 150, height: 40, padding: 10 }))}
                columns={[
                  { key: 'Spirit', title: 'Spirit', flex: 1, center: true },
                  { key: 'Distillery', title: 'Distillery', flex: 1, center: true },
                  { key: 'Region', title: 'Region', flex: 1, center: true },
                  { key: 'AYS', title: 'AYS', flex: 1, center: true },
                  { key: 'Cask Type', title: 'Cask Type', flex: 1, center: true },
                  { key: 'OLA', title: 'OLA', flex: 1, center: true },
                  { key: 'RLA', title: 'RLA', flex: 1, center: true },
                  { key: 'ABV', title: 'ABV', flex: 1, center: true },
                  { key: 'Portfolio', title: 'Price', flex: 1, center: true },
                  { key: 'Purchase Date', title: 'Purchase Date', flex: 1, center: true },
                  { key: 'Cask NO', title: 'Cask NO', flex: 1, center: true, showInDetail: true },
                  { key: 'Location', title: 'Location', flex: 1, center: true, showInDetail: true },
                  { key: 'Age', title: 'Age', showInDetail: true },
                  { key: 'Cask Price', title: 'Cask Price', showInDetail: true },
                  { key: 'Brand Owner', title: 'Brand Owner', showInDetail: true },
                  { key: 'Recommended Re-gauge Date', title: 'Recommended Re-gauge Date', showInDetail: true },
                  { key: 'Invested', title: 'Invested', showInDetail: true },
                  { key: 'Investment Tier', title: 'Investment Tier', showInDetail: true },
                ]}
                withoutNumberColumn
                data={filterClient.map(val => ({
                  ...val,
                  // ['Spirit']: () => (
                  //   <Col>
                  //     <Text>{val['Spirit']}</Text>
                  //     <Text bold color="#AD8555">£{val['Portfolio']}</Text>
                  //   </Col>
                  // ),
                  ['Spirit']: val['Spirit'],
                  ['AYS']: TimeHelper.format(val['AYS']),
                  ['Cask Price']: VarHelper.money(val['Portfolio']),
                  ['Portfolio']: VarHelper.money(val['Portfolio']),
                  ['Invested']: VarHelper.money(val['Invested']),
                  ['Purchase Date']: TimeHelper.format(val['Purchase Date']),
                  ['Cask NO']: val['Cask No'],
                  ['OLA/RLA']: val['OLA/RLA'],
                  // ['KYC']: () => (
                  //   <a href={val['KYC Link']}>{val['KYC']}</a>
                  // ),
                  ['Location']: val['Location'],
                  'Recommended Re-gauge Date': TimeHelper.format(val['Recommended Re-gauge Date']),
                }))}
                // breakpointToScroll="all"
              />
            </>
          )}
        </Col>
    )
  }

  const renderAvailableCasks = () => {
    return (
      <Col width={rV({ xs: ITEM_WIDTH - 20, lg: ITEM_WIDTH * 3 - 10 })}
        marginHorizontal={COLUMN_MARGIN} marginBottom10>
        <Card title={"Available Casks - Subject to being sold"} height100p>
          <TableWithAction
            titleBlackBg
            columns={[
              { key: 'Distillery', title: 'DISTILLERY', flex: 1, center: true },
              { key: 'Region', title: 'REGION', flex: 1, center: true },
              { key: 'Cask Type', title: 'CASK TYPE', flex: 1, center: true },
              { key: 'Cask number', title: 'CASK#', flex: 1, center: true },
              { key: 'Age', title: 'AGE', flex: 1, center: true },
              { key: 'Price', title: 'CASK PRICE', flex: 1, center: true },
            ]}
            data={listAvailableCasks}
            onPressAction={(item, i) => messageAdmin(item)}
            breakpointToScroll="sm"
            btnTitle="Request Info"
          />
        </Card>

      </Col>
    )
  }

  const scrollToChart = (activeTab) => {
    const yourCasksList = document.getElementById('yourCasksList').offsetTop;
    window.scrollTo({ left: 0, top: yourCasksList + 80 });
  }

  const renderCask = () => {
    if (listClients.length === 0 || !email) return null;
    const targetPercent = myCommision.range[1] === Infinity ? 1 : (portfolioValue - myCommision.range[0]) / (myCommision.range[1] - myCommision.range[0])
    const caskWidth = width * 0.115 * 150 / 198;
    const caskHeight = width * 0.115;
    const circleSize = rV({ xs: 30, md: width * 0.045});
    const val = myCommision;
    return (
      <Row alignItems="flex-end" justifyContent='center'>
        <Col marginTop30 middle>
          <Col marginBottom={-40} borderWidth2 borderColor={"white"} absolute top={-30} zIndex10 width={circleSize} height={circleSize} borderRadius={circleSize / 2} backgroundColor={"#A37A49"} middle>
            <Text colorWhite bold fontSize={rV({ xs: 10, md: 20 })}>{val.displayValue}%</Text>
          </Col>
          <Col width={caskWidth} height={caskHeight}>
            <LiquidEffectHome
              width={caskWidth} height={caskHeight} mask={ASSETS.ONECASK}
              percent={targetPercent}
            />
          </Col>
        </Col>
      </Row>
    );
  }

  const renderSquare = ({ onPress, image, title, value, style }: { onPress: () => void, image: any, title: string, value: any, style?: any}) => {
    return (
      <Col onPress={onPress} borderRadius10 overflowH marginHorizontal={rV({ xs: 5, md: 10 })} marginBottom10>
        <Image source={image} style={{ width: BLUR_WIDTH - 1, height: BLUR_HEIGHT, resizeMode: 'cover' }} />
        <Col absoluteFill style={style}>
          <Text height40 marginTop={BLUR_HEIGHT / 3.5} center color={COLOR.WHITE} fontSize={fSize(rV, 26)}>
            {title}
          </Text>
          <Row minHeight40 justifyContent="center">
            <Text
              center bold color={COLOR.WHITE}
              fontSize={fSize(rV, 50)}
              // fontSize={String(value).length <= 5 ? fSize(rV, 50) : fSize(rV, 30)}
            >
              {value}
            </Text>
          </Row>
        </Col>
      </Col>
    );
  }

  return (
    <Col flex1 backgroundColor={COLOR.BG}>
      <ScreenContainer>
        <Header />
        <Col flex1>
          {/* <Row>
            <Col onPress={() => navigate(SCREEN_NAME.Home)}>
              <Text marginLeft={rV({ xs: 30, md: 40, lg: 50 })} marginTop20 color={COLOR.FONT} body1>
                Portal Home
              </Text>
            </Col>
            <Text marginLeft8 marginTop20 color={COLOR.FONT} body1>
              {`> Account Summary`}
            </Text>
          </Row> */}
          <Row justifyContent="space-between">
            <Breadcrumbs
              title="Account Summary"
              style={{
                marginLeft: ROW_MARGIN + COLUMN_MARGIN,
              }}
            />
            <Col marginTop20 marginRight={ROW_MARGIN + COLUMN_MARGIN} onPress={exportExcel}>
              <Text>Export Data</Text>
            </Col>
          </Row>
          <Row
            style={{ flexWrap: 'wrap', justifyContent: 'center'}}
            marginBottom={10}
            marginTop20
          >
            <Col margin={10} width={BLUR_WIDTH - 1} onPress={() => {window.open("https://vintagewhiskyclub.com/")}}>
              <Image source={ASSETS.VINTAGE_WHISKY_CLUB} style={{ height: BLUR_WIDTH * 85/586, resizeMode: 'contain' }} />
            </Col>
            <Col margin={10} width={BLUR_WIDTH - 1} onPress={() => {window.open("https://vintagebottlersltd.com/")}}>
              <Image source={ASSETS.VINTAGE_BOTTLERS} style={{ height: BLUR_WIDTH * 85/586, resizeMode: 'contain' }} />
            </Col>
            <Col margin={10} width={BLUR_WIDTH - 1} onPress={() => {window.open("https://vintagewhiskygroup.com/")}}>
              <Image source={ASSETS.VINTAGE_WHISKY_GROUP} style={{ height: BLUR_WIDTH * 85/586, resizeMode: 'contain' }} />
            </Col>
          </Row>
          <Row
            marginHorizontal={ROW_MARGIN}
          >
            <Text marginLeft16 marginTop15 fontSize={fSize(rV, 42)} fontFamily='PlayfairDisplay_500Medium'>Account Summary</Text>
          </Row>
          
          <Row
            style={{ flexWrap: 'wrap', justifyContent: 'center' }}
            marginBottom={10}
            marginTop20
            marginHorizontal={ROW_MARGIN}
          >
            {renderSquare({
              onPress: () => {
                navigate(SCREEN_NAME.YourCasks);
              },
              image: pageDataImages[0] ? { uri: pageDataImages[0] } : ASSETS.SQUARE_PORTFOLIO,
              title: 'Invested',
              value: VarHelper.money(portfolioValue),
              style: {  }
            })}
            <Col onPress={() => reset(SCREEN_NAME.Casks)} borderRadius10 overflowH marginHorizontal={rV({ xs: 5, md: 10 })} marginBottom10>
              <Image source={pageDataImages[1] ? { uri: pageDataImages[1] } : ASSETS.SQUARE_CASK} style={{ width: BLUR_WIDTH - 1, height: BLUR_HEIGHT, resizeMode: 'cover' }} />
              <Row absoluteFill marginLeft={rV({ xs: 10, xl: 54 })} marginRight={rV({ xs: 10, xl: 40 })} style={{ justifyContent: 'space-between'}} >
                <Col style={{ alignItems: 'flex-start'}}>
                  <Text height40 center color={COLOR.WHITE} fontSize={fSize(rV, 26)}>
                    {'Investment Tier'}
                  </Text>
                  <Row minHeight40 justifyContent="center">
                    <Text center bold color={COLOR.WHITE} fontSize={String(filterClient.length).length <= 5 ? fSize(rV, 50) : fSize(rV, 30)}>
                      {/* {filterClient.length} */}
                      {comission[myIndex].name}
                    </Text>
                  </Row>
                </Col>
                <Col style={{ alignItems: 'flex-end'}}>
                  {useMemo(() => renderCask(), [listClients]) }
                </Col>
              </Row>
            </Col>
            {renderSquare({
              onPress: () => navigate(SCREEN_NAME.CasksIOwn, { tab: 'DISTILLERIES' }),
              image: pageDataImages[2] ? { uri: pageDataImages[2] } : ASSETS.SQUARE_DISTILERY,
              title: 'Your Distilleries',
              value: listDistilleries.length,
            })}
          </Row>
          <div id='yourCasksList'>

            {/* {renderRow([
              // renderCasksIOwn(),
              renderYourCask(),

            ])} */}
          </div>
        </Col>
        <Footer />
      </ScreenContainer>
      <ChatBox />
    </Col>
  );
};

Home.routeInfo = {
  title: 'Home',
  path: '/',
};

const styles = StyleSheet.create({
  banner: {
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0
  },
})

export default Home;

function saveAs(arg0: Blob, arg1: string) {
  throw new Error('Function not implemented.');
}
