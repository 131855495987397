import User from './User.Store';
import Info from './Info.Store';
import Client from './Client.Store';
import Message from './Message.Store';
import Firebase from './Firebase.Store';
import System from './System.Store';

const Store = {
  User,
  Info,
  Client,
  Message,
  Firebase,
  System,
};

// @ts-ignore
window.Store = Store;

export default Store;
