import React from 'react';
import { Col, Row, Text, useDynamicResponsiveValue, usePropsStyle } from 'react-quick-style-components';
import { useNavFunc } from 'navigation';
import { COLOR, SCREEN_NAME } from 'const';
import GlobalEvent from 'js-events-listener/react';

const Breadcrumbs = ({ title, screen, style } : { title: string, screen?: string, style?: any, }) => {
  const { navigate, navigation } = useNavFunc();
  // const { index } = navigation.dangerouslyGetState();
  const rV = useDynamicResponsiveValue();

  const showMenu = () => {
    GlobalEvent.emit('SHOW_SIDEBAR_MENU', undefined);
  };

  return (
    <Row style={style}>
      <Col onPress={() => navigate(SCREEN_NAME.Home)}>
        <Text marginTop20 color={COLOR.FONT} body1>
          Portal Home
        </Text>
      </Col>
      <Col onPress={showMenu}>
        <Text marginLeft8 marginTop20 color={COLOR.FONT} fontSize={15} letterSpacing={0.3}>{`> ...`}</Text>
      </Col>
      <Col onPress={!screen ? undefined : () => navigate(screen)}>
        <Text marginLeft8 marginTop20 color={COLOR.FONT} fontSize={15} letterSpacing={0.3}>
          {`> ${title}`}
        </Text>
      </Col>
    </Row>
  );
};

export default Breadcrumbs;