import React from "react"
import { COLOR, fSize } from 'const';
import { ViewStyle, StyleSheet } from 'react-native';
import { Col, Text, useDynamicResponsiveValue, Row, usePropsStyle } from 'react-quick-style-components';

interface ICardProps {
  title: string;
  children?: any;
  iconRigth?: any;
  iconRightOnPress?: any;
  style?: ViewStyle;
  titleBarBgColor?: string;
  [anyProp: string]: any;
}

const Card = (props: ICardProps) => {
  const propStype = usePropsStyle(props);
  const rV = useDynamicResponsiveValue();
  const { title, iconRigth, children, style, titleBarBgColor, iconRightOnPress } = props;
  return (
    <Col style={[styles.container, propStype, style]}>
      <Row backgroundColor={titleBarBgColor || COLOR.MAIN} paddingHorizontal={rV({ xs: 15, lg: 30 })} paddingVertical={rV({ xs: 15, lg: 20 })}>
        <Text color="white" fontSize={fSize(rV, 26)} semiBold flex1 numberOfLines={1}>
          {title}
        </Text>
        {!!iconRigth &&
          <Col onPress={iconRightOnPress}>
            {iconRigth}
          </Col>
        }
      </Row>
      <Col flex1 paddingHorizontal={rV({ xs: 15, lg: 30 })} paddingVertical={rV({ xs: 10, lg: 20 })}>
        {children}
      </Col>
    </Col>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    overflow: 'hidden',
    borderColor: 'rgba(112, 112, 112, 0.3)',
    borderWidth: 1,
  },
});

export default Card;
