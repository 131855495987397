import GlobalEvent, { useGlobalState } from 'js-events-listener/react';
import { useEffect } from 'react';
import Request from './Request.utils';
import RequestBE from './RequestBE.utils';
import Persist, { PersistReady } from './Persist.utils';
import { BE_HOST, HOST } from './host';
import { IUser } from 'type';
import { VarHelper } from 'helpers';
import firebase from "firebase";

interface ISetters {
  setUser?(v : any): void,
  setToken?(v : any): void,
  setEmail?(v : any): void,
  setName?(v : any): void,
  setReady?(v : any): void,
  [additionSetter: string]: (v : any) => void,
}

class User extends PersistReady {

  constructor() {
    super();
    this.getInitialData();
  }

  state : any = {
    ready: false,
    user: {},
    email: '',
    token: '',
    name: '',
    firebaseUser: {},
  };

  setters : ISetters = {};

  updateState(obj, allowUndefined = true) {
    for (let key in obj) {
      if (allowUndefined || (obj[key] !== null && obj[key] !== undefined)) this.state[key] = obj[key];
    }
  }

  getInitialData = async () => {
    let [user, token, email, name] = await Promise.all([
      Persist.sync('userStore_user', 'object'),
      Persist.sync('userStore_token', 'string'),
      Persist.sync('userStore_email', 'string'),
      Persist.sync('userStore_name', 'string'),
    ]);
    this.updateState({ user, token, email, name }, false);
    console.log('token', token);
    if (!token) {
      await this.singIn({ email: 'anonymous@devserver.london', password: 'Anonymous@123' });
      this.makeReady();
      return;
    }
    Request.setToken(token);
    const [res, err] = await this.getMyUserInfo();
    if (err) this.onNotAuthen();
    if (!this.state.token) {
      await this.singIn({ email: 'anonymous@devserver.london', password: 'Anonymous@123' });
    } 
    this.makeReady(); // await onReady();
  }

  createStore() {
    const [user, setUser] = useGlobalState<IUser>(this.state.user, 'userStore_user');
    const [token, setToken] = useGlobalState<string>(this.state.token, 'userStore_token');
    const [email, setEmail] = useGlobalState<string>(this.state.email, 'userStore_email');
    const [name, setName] = useGlobalState<string>(this.state.name, 'userStore_name');
    const [ready, setReady] = useGlobalState(this.state.ready, 'user_store_ready');
    const [firebaseUser, setFirebaseUser] = useGlobalState(this.state.firebaseUser, 'user_store_firebaseUser');

    if (!this.setters.setUser) this.setters.setUser = setUser;
    if (!this.setters.setToken) this.setters.setToken = setToken;
    if (!this.setters.setEmail) this.setters.setEmail = setEmail;
    if (!this.setters.setName) this.setters.setName = setName;
    if (!this.setters.setReady) this.setters.setReady = setReady;
    if (!this.setters.setFirebaseUser) this.setters.setFirebaseUser = setFirebaseUser;

    useEffect(() => {
      // console.log({ user, token });
      this.updateState({ user, token, email, name, ready, firebaseUser });
      Persist.save(user, 'userStore_user');
      Persist.save(token, 'userStore_token');
      Persist.save(email, 'userStore_email');
      Persist.save(name, 'userStore_name');
    }, [user, token, email, name, ready, firebaseUser]);

    return [
      { user, token, email, name, ready, firebaseUser },
      {
        setEmail,
        setName,
        singIn: this.singIn,
        logout: this.logout,
        firebaseCreateUser: this.firebaseCreateUser,
        firebaseLogin: this.firebaseLogin,
        checkAdmin: this.checkAdmin,
        getMyUser: this.getMyUser,
        updateMyUser: this.updateMyUser,
        masterLogin: this.masterLogin,
        getUploadUrl: this.getUploadUrl,
        performUpload: this.performUpload,
        addAssetRecord: this.addAssetRecord,
        sendPasswordResetEmail: this.sendPasswordResetEmail,
        personate: this.personate,
        removePersonate: this.removePersonate,
      }
    ];
  }

  singIn = async ({ email, password }) => {
    return VarHelper.erria(async () => {
      const res = await Request.post('http://cms.vintageacquisitions.com/auth/signin', { email, password });
      if (res.data.token) {
        Request.setToken(res.data.token);
        this.setters.setToken(res.data.token);
        this.getMyUserInfo();
      }
      return res.data;
    });
  }

  logout = async () => {
    try {
      await RequestBE.post(BE_HOST+ '/users/logout', {});
    } catch(err) {}
    firebase.auth().signOut();
  }

  onNotAuthen = () => {
    if (this.setters.setUser && this.setters.setToken) {
      this.setters.setUser({});
      this.setters.setToken('');
    } else {
      this.updateState({
        user: {},
        token: '',
      });
    }
  }

  onAuthen = (userData) => {
    if (this.setters.setUser) this.setters.setUser(userData);
    else this.updateState({ user: userData });
  };

  getMyUserInfo = async () => {
    return VarHelper.erria(async () => {
      const res = await Request.get('https://cms.vintageacquisitions.com/user/me', {});
      if (res.data.roles && res.data.roles.guest && Object.keys(res.data.roles).length === 1) {
        console.log('here');
        this.onNotAuthen();
      } else if (!res.data.msg) {
        this.onAuthen(res.data);
      } else {
        this.onNotAuthen();
      }
      return res.data;
    });
    
  }

  firebaseCreateUser = async ({ email, password, name }) => {
    return VarHelper.erria(async () => {
      const userCredential = await firebase.auth().createUserWithEmailAndPassword(email, password);
      const { user } = userCredential;
      const displayName = name;
      if (!user.displayName) {
        user.updateProfile({
          displayName,
        })
      }
      return user;
    });
  }

  firebaseLogin = async ({ email, password }) => {
    return VarHelper.erria(async () => {
      const userCredential = await firebase.auth().signInWithEmailAndPassword(email, password);
      const { user } = userCredential;
      this.updateState({ firebaseUser: user, email });
      if (this.setters.setEmail) this.setters.setEmail(email);
      if (this.setters.setFirebaseUser) this.setters.setFirebaseUser(user);
      window.heap?.identify(email);
      return user;
    });
  }

  checkAdmin = async (email) => {
    return VarHelper.erria(async () => {
      const res = await RequestBE.post(BE_HOST + '/users/check-admin', { });
      if (!!res.data.success && !!res.data.data) {
        return true;
      }
      return false;
    });
  }

  getMyUser = async (email = this.state.email) => {
    return VarHelper.erria(async () => {
      const res = await Request.get(HOST + '/User/findOne', { where: `(Email,eq,${email})` });
      if (!!res.data && !res.data.msg) {
        return res.data;
      }
      return undefined;
    });
  }

  updateMyUser = async (id, data) => {
    return VarHelper.erria(async () => {
      const res = await Request.put(HOST + '/User/'+id, data);
      if (!!res.data && !res.data.msg) {
        return res.data;
      }
      return undefined;
    });
  }

  masterLogin = async ({ email, password }) => {
    return VarHelper.erria(async () => {
      
    });
  }

  getUploadUrl = async ({ contentType, serverSidePath }) => {
    return VarHelper.erria(async () => {
      const res = await RequestBE.post(BE_HOST + '/users/me/get-upload-url', {
        serverSidePath,
        contentType,
      });
      return res.data;
    });
  }

  performUpload = async ({ signedUrl, file, contentType }) => {
    return VarHelper.erria(async () => {
      const res = await fetch(signedUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': contentType,
        },
        body: file,
      });
      console.log('performUpload RES STATUS', res.status);
      if (res.status === 403) throw new Error('Upload failed');
      const text = await res.text();
      return text;
    });
  }


  addAssetRecord = async ({ type, path, email }) => {
    return VarHelper.erria(async () => {
      const res = await RequestBE.post(BE_HOST + '/clients/add-assets', {
        type, path, email,
      });
      return res.data;
    })
  }

  sendPasswordResetEmail = async (email) => {
    return VarHelper.erria(async () => {
      firebase.auth().sendPasswordResetEmail(email, {
        // handleCodeInApp: true,
        url: window.location.host.includes('localhost') ? 'http://localhost:19006' : 'http://portal.vintageacquisitions.com',
      });
    });
  }

  personate = async (Email) => {
    return VarHelper.erria(async () => {
      const res = await RequestBE.post(BE_HOST + '/users/personate', {
        Email,
      });
      return res.data;
    });
  }

  removePersonate = async () => {
    return VarHelper.erria(async () => {
      const res = await RequestBE.post(BE_HOST + '/users/remove-personate', {});
      return res.data;
    });
  }

  adminResetUserPassword = (uid, newPassword) => {
    return VarHelper.erria(async () => {
      const res = await RequestBE.post(`${BE_HOST}/user/admin-reset-user-password`, { uid, newPassword });
      console.log(res.data);
      return res.data;
    });
  }
}

export default new User();
