import React, { useState, useEffect, useRef } from 'react';
import { Col, Text, Row, useDynamicResponsiveValue } from 'react-quick-style-components';
import { ChartMultiple } from '../index';
import { VarHelper } from 'helpers';
import { ASSETS, COLOR, fSize, ICON, SCREEN_NAME } from 'const';
import { useWindowDimensions } from 'react-native'

const formatPieValue = ({ value, isMoneyValue, isPercent }) => {
  return isMoneyValue ? VarHelper.money(value)
    : isPercent ? `${value} %` : value;
}
const RegionChart = ({ regionPieChartData, title, isMoneyValue, isPercent, noDislayValue }) => {
  const [hoverRegionData, setHoverRegionData] = useState<any>();
  const rV = useDynamicResponsiveValue();
  const { width } = useWindowDimensions();
  const chartRef = useRef<{ getComputedData: Function }>();
  if (!regionPieChartData || regionPieChartData.length === 0) return null;

  const unitSize = rV({ xs: 5, lg: 10 });
  const equalColumnWidth = rV({ xs: width / 2 - unitSize * 2, md: (width / 2 - unitSize * 2) / 2, });
  const onSideItemPress = (i) => {
    const data = chartRef.current?.getComputedData(regionPieChartData);
    setHoverRegionData(data[i]);
  }
  return (
    <Row xs='100%' md='any:any:1' marginBottom20 stretch>
      <Col padding={unitSize} width={equalColumnWidth}>
        <ChartMultiple
          ref={chartRef}
          size={equalColumnWidth}
          data={regionPieChartData}
          // onHoverPart={noHover ? undefined : setHoverRegionData}
          onPressPart={setHoverRegionData}
          initialActiveSetPart={setHoverRegionData}
          dummyClickable
        />
        {Boolean(hoverRegionData) && (
          <Col middle>
            <Text fontSize40 color={hoverRegionData.color} bold>{hoverRegionData?.formatedPercent} %</Text>
            <Text fontSize={18}>{formatPieValue({ isMoneyValue, isPercent, value: hoverRegionData?.value })}</Text>
          </Col>
        )}
      </Col>
      <Col padding={unitSize} width={equalColumnWidth} middle>
        <Col width="50%">
          <Text color={COLOR.MAIN} fontSize={fSize(rV, 20)} fontFamily="PlayfairDisplay_600SemiBold">{title}</Text>
          <Row xs='100%' md='100%' xxl="100%" marginTop8>
            {regionPieChartData.map((item, i) => (
              <Row key={`chart${i}`} marginTop10 onPress={() => onSideItemPress(i)}>
                <Col width={25} height={25} borderRadius={3} backgroundColor={item.color} marginRight10 />
                {noDislayValue ? (
                  <Text marginLeft5 fontSize={fSize(rV, 16)}>{item.title}</Text>
                ) : (
                  <Text marginLeft5 fontSize={fSize(rV, 16)}>{item.title} ({formatPieValue({ isMoneyValue, isPercent, value: item?.value })})</Text>
                )}
              </Row>
            ))}
          </Row>
        </Col>
      </Col>
    </Row>
  );
}

const PortfolioValueChart = ({ data: portfolioPieChartData }) => {
  console.log('portfolioPieChartData', portfolioPieChartData);
  const total = (() => {
    let res = 0;
    portfolioPieChartData.forEach(val => {
      res += val.value;
    });
    return res;
  })();
  return (
    <Row xs='100%' md='1:any:1' alignItems="flex-start">
      <Col paddingHorizontal10 paddingTop30>
        <RegionChart noDislayValue={false} isPercent={false} isMoneyValue={true} title="PORTFOLIO VALUES" regionPieChartData={portfolioPieChartData} />
      </Col>
      <Col width30 />
      <Col paddingHorizontal10 paddingTop30>
        <Text fontFamily="PlayfairDisplay_600SemiBold" fontSize20>PORTFOLIO</Text>
        <Text marginTop20 body1>
          Here you will be able to see the total value of your Whisky portfolio just based on the casks you have in each region and other collections. Please use the tabs above to find out what is needed in each category to complete these collections.
        </Text>
        <Col marginTop40>
          <Text fontSize20 fontFamily="PlayfairDisplay_600SemiBold">TOTAL PORTFOLIO</Text>
          <Text bold fontSize40>{VarHelper.money(total)}</Text>
        </Col>
      </Col>
    </Row>
  );
}

export default PortfolioValueChart;