import * as React from "react"
import Svg, { G, Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={45.243}
      height={45.243}
      viewBox="0 0 45.243 45.243"
      {...props}
    >
      <G
        data-name="Group 118"
        fill="none"
        stroke={props.fill || "#000"}
        strokeLinecap="round"
        strokeWidth={3}
      >
        <Path data-name="Line 56" d="M2.121 2.121l41 41" />
        <Path data-name="Line 57" d="M43.121 2.121l-41 41" />
      </G>
    </Svg>
  )
}

export default SvgComponent
