import GlobalEvent, { useGlobalState } from 'js-events-listener/react';
import { useEffect } from 'react';
import Request from './Request.utils';
import RequestBE from './RequestBE.utils';
import Persist, { PersistReady } from './Persist.utils';
import { HOST, BE_HOST } from './host';
import { IUser } from 'type';
import { VarHelper } from 'helpers';
import UserStore from './User.Store';

interface ISetters {
  setListNews?(v : any): void,
  setListEvents?(v : any): void,
  setListPages?(v : any): void,
  [additionSetter: string]: (v : any) => void,
}

class System extends PersistReady {

  constructor() {
    super();
  }

  state : any = {
    
  };

  setters : ISetters = {};

  updateState(obj, allowUndefined = true) {
    for (let key in obj) {
      if (allowUndefined || (obj[key] !== null && obj[key] !== undefined)) this.state[key] = obj[key];
    }
  }

  createStore() {

    return [
      {  },
      {
        syncTrilogyCharts: this.syncTrilogyCharts,
        syncMasterData: this.syncMasterData,
        syncDistilleryText: this.syncDistilleryText,
        syncInventory: this.syncInventory,
      }
    ];
  }

  syncMasterData = async () => {
    return VarHelper.erria(async () => {
      const res = await RequestBE.post(BE_HOST + '/system/sync-master-data', {});
      return res.data;
    });
  }

  syncDistilleryText = async () => {
    return VarHelper.erria(async () => {
      const res = await RequestBE.post(BE_HOST + '/system/sync-distillery-text', {});
      return res.data;
    });
  }

  syncTrilogyCharts = async () => {
    return VarHelper.erria(async () => {
      const res = await RequestBE.post(BE_HOST + '/system/sync-trilogy-charts', {});
      return res.data;
    });
  }

  syncInventory = async () => {
    // return VarHelper.erria(async () => {
    //   const res = await RequestBE.post(BE_HOST + '/hubspot/sync-products-sheet-inventory', {});
    //   return res.data;
    // });
    return VarHelper.erria(async () => {
      const res = await RequestBE.post(BE_HOST + '/system/sync-inventory', {});
      return res.data;
    });
  }
}

export default new System();
