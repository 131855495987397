import GlobalEvent, { useGlobalState } from 'js-events-listener/react';
import { useEffect, useMemo } from 'react';
import Request from './Request.utils';
import RequestBE from './RequestBE.utils';
import Persist, { PersistReady } from './Persist.utils';
import { BE_HOST } from './host';
import { COLOR, genColor, genInactiveColor } from 'const';
import { IUser } from 'type';
import { VarHelper } from 'helpers';
import UserStore from './User.Store';
import _ from 'lodash';

interface ISetters {
  setListClients?: (v : any) => void,
  setListCerts?: (v : any) => void,
  setListDocs?: (v : any) => void,
  setListAvailableCasks?: (v : any) => void,
  setListPortfolioStrategy?: (v : any) => void,
  setInvoices?: (v : any) => void,
  setSaleManagerData?: (v : any) => void,
  [additionSetter: string]: (v : any) => void,
}

class Info extends PersistReady {

  constructor() {
    super();
  }

  state : any = {
    listClients: [],
    listCerts: [],
    listDocs: [],
    listAvailableCasks: [],
    listPortfolioStrategy: [],
    collectionPieChartData: {},
    invoices: [],
    saleManagerData: {
      account: {},
      clients: [],
    },
    // myAM: '',
  };

  setters : ISetters = {};

  updateState(obj, allowUndefined = true) {
    for (let key in obj) {
      if (allowUndefined || (obj[key] !== null && obj[key] !== undefined)) this.state[key] = obj[key];
    }
  }

  createStore() {
    const [listClients, setListClients] = useGlobalState<Array<any>>(this.state.listClients, 'clientStore_clients');
    const [listCerts, setListCerts] = useGlobalState<Array<any>>(this.state.listCerts, 'clientStore_certs');
    const [listDocs, setListDocs] = useGlobalState<Array<any>>(this.state.listDocs, 'clientStore_docs');
    const [listAvailableCasks, setListAvailableCasks] = useGlobalState<Array<any>>(this.state.listAvailableCasks, 'clientStore_listAvailableCasks');
    const [listPortfolioStrategy, setListPortfolioStrategy] = useGlobalState<Array<any>>(this.state.listPortfolioStrategy, 'clientStore_listPortfolioStrategy');
    const [collectionPieChartData, setCollectionPieChartData] = useGlobalState<Array<any>>(this.state.collectionPieChartData, 'clientStore_collectionPieChartData');
    const [invoices, setInvoices] = useGlobalState<Array<any>>(this.state.invoices, 'clientStore_invoices');
    const [saleManagerData, setSaleManagerData] = useGlobalState<{ account: any, clients: Array<any> }>(this.state.saleManagerData, 'clientStore_saleManagerData');
    const [myAM, setMyAM] = useGlobalState<string>(this.state.myAM, 'clientStore_myAM');

    if (!this.setters.setListClients) this.setters.setListClients = setListClients;
    if (!this.setters.setListCerts) this.setters.setListCerts = setListCerts;
    if (!this.setters.setListDocs) this.setters.setListDocs = setListDocs;
    if (!this.setters.setListAvailableCasks) this.setters.setListAvailableCasks = setListAvailableCasks;
    if (!this.setters.setListPortfolioStrategy) this.setters.setListPortfolioStrategy = setListPortfolioStrategy;
    if (!this.setters.setCollectionPieChartData) this.setters.setCollectionPieChartData = setCollectionPieChartData;
    if (!this.setters.setInvoices) this.setters.setInvoices = setInvoices;
    if (!this.setters.setSaleManagerData) this.setters.setSaleManagerData = setSaleManagerData;

    useEffect(() => {
      this.updateState({
        listClients, listCerts, listDocs, listAvailableCasks, listPortfolioStrategy, collectionPieChartData, invoices, saleManagerData,
      });
    }, [listClients, listCerts, listDocs, listAvailableCasks, listPortfolioStrategy, collectionPieChartData, invoices, saleManagerData]);

    const regionPieChartData = useMemo(() => this.makeRegionPieChartData(listClients), [listClients]);
    const portfolioPieChartData = useMemo(() => this.makeRegionPorfolioChartData(listClients), [listClients]);
    const distilleryPieChartData = useMemo(() => this.makeDistilleryChartData(listClients), [listClients]);
    const edringtonTrilogyPieChartData = useMemo(() => this.makeEdringtonTrilogyChartData(listClients), [listClients]);
    const royalTrilogyPieChartData = useMemo(() => this.makeRoyalTrilogyChartData(listClients), [listClients]);
    const legacyCollectionPieChartData = useMemo(() => this.makeLegacyCollectionChartData(listClients), [listClients]);
    return [
      {
        listClients, listCerts, listDocs, listAvailableCasks, listPortfolioStrategy,
        regionPieChartData,
        portfolioPieChartData,
        collectionPieChartData,
        distilleryPieChartData,
        edringtonTrilogyPieChartData,
        royalTrilogyPieChartData,
        legacyCollectionPieChartData,
        invoices, saleManagerData,
        myAM,
      },
      {
        setSaleManagerData,
        checkEmailVerified: this.checkEmailVerified,
        getListClients: this.getListClients,
        getListCerts: this.getListCerts,
        getListDocs: this.getListDocs,
        importData: this.importData,
        getListAvailableCasks: this.getListAvailableCasks,
        getListPortfolioStrategy: this.getListPortfolioStrategy,
        getListCacheLocation: this.getListCacheLocation,
        getListAllClients: this.getListAllClients,
        computeMyComission: this.computeMyComission,
        getInvoices: this.getInvoices,
        genAssetsLink: this.genAssetsLink,
        getSaleManagerData: this.getSaleManagerData,
        getPersonalObjectives: this.getPersonalObjectives,
        upsertPeronalObjectives: this.upsertPeronalObjectives,
        getKeyQuestions: this.getKeyQuestions,
        upsertKeyQuestions: this.upsertKeyQuestions,
      }
    ];
  }

  makeRegionPieChartData = (listClients) => {
    const clientData = listClients.filter(val => !!val['Region'] && val.Email === UserStore.state.email);
    console.log('clientData makeRegionPieChartData', clientData)
    const sumUnitPrice = (arr) => !arr || arr.length === 0 ? 0 : arr.reduce((a,b) => ({ ...a, 'Portfolio': (+a['Portfolio'] || 0) + (+b['Portfolio'] || 0) }))['Portfolio'];
    const totalUnitPrice = sumUnitPrice(clientData);
    // console.log('totalUnitPrice makeRegionPieChartData', totalUnitPrice);
    const regions = clientData.map(val => val['Region']);
    const regionsUnique = _.uniqWith(regions, _.isEqual);
    // console.log('regionsUnique', regionsUnique);
    // const listAcceptRegions = [
    //   'Speyside', 'Islands', 'Highlands', 'Islay', 'Campbeltown', 'Lowland'
    // ];
    const listAccepted = [
      { title: 'Speyside', color: '#189BD8' },
      { title: 'Islands', color: '#A53D8F' },
      { title: 'Highland', color: '#9CC21D' },
      { title: 'Islay', color: '#EA5D12' },
      { title: 'Campbeltown', color: '#FFCF02' },
      { title: 'Lowland', color: '#E4153A' },
    ]
    let data = listAccepted.map((val, i) => ({
      ...val,
      value: 100/6, isDummy: true, percentageOnPortfolio: 0,
      portfolioValue: 0,
    }));
    regionsUnique.forEach(r => {
      // console.log('region r', r);
      const findIndex = listAccepted.findIndex(val => val.title === r);
      if (findIndex === -1) return;
      // data[findIndex].color = genColor(findIndex + 1);
      data[findIndex].isDummy = false;
      data[findIndex].portfolioValue = sumUnitPrice(clientData.filter(val => val['Region'] === r))
      // console.log(clientData.filter(val => val['Region'] === r), 'region r', r);
      data[findIndex].percentageOnPortfolio = data[findIndex].portfolioValue / totalUnitPrice;
    });
    // console.log('makeRegionPieChartData', data);
    return data;
  }

  makeRegionPorfolioChartData = (listClients) => {
    const regions = listClients.filter(val => !!val['Portfolio'] && !!val['Region'] && val.Email === UserStore.state.email);
    let data = [];
    const colors = [
      { title: 'Speyside', color: '#189BD8' },
      { title: 'Islands', color: '#A53D8F' },
      { title: 'Highland', color: '#9CC21D' },
      { title: 'Islay', color: '#EA5D12' },
      { title: 'Campbeltown', color: '#FFCF02' },
      { title: 'Lowland', color: '#E4153A' },
    ];
    regions.forEach(r => {
      const findIndex = data.findIndex(v => v.title === r['Region']);
      if (findIndex === -1) {
        data.push({
          value: +r['Portfolio'] || 0,
          title: r['Region'],
          color: (() => {
            const findColor = colors.find(item => item.title === r['Region'].trim());
            return !!findColor ? findColor.color : genColor(data.length);
          })(),
        })
      } else {
        data[findIndex].value += +r['Portfolio'] || 0;
      }
    });
    // console.log('makeRegionPorfolioChartData', data);
    return data;
  }

  getListAllClients = async () => {
    return VarHelper.erria(async () => {
      const res = await RequestBE.get(BE_HOST + '/clients/all-data', {});
      return res.data;
    });
  }

  getListClients = async (email = UserStore.state.email) => {
    // return VarHelper.erria(async () => {
    //   const res = await Request.get(HOST + '/Clients', { where: `(Email,eq,${email})` });
    //   if (!!res.data && !res.data.msg) {
    //     !!this.setters.setListClients && this.setters.setListClients(res.data);
    //   }
    //   return res.data;
    // });
    return VarHelper.erria(async () => {
      const res = await RequestBE.get(BE_HOST + '/clients/my-portfolio-data', {});
      if (!!res.data && res.data.success !== false) {
        const listWithEmail = res.data.map(val => ({ ...val, Email: email }));
        !!this.setters.setListClients && this.setters.setListClients(listWithEmail);
      }
      return res.data;
    });
  }

  checkEmailVerified = async (email) => {
    return VarHelper.erria(async () => {
      const res = await RequestBE.post(BE_HOST + '/clients/check', { email });
      console.log(res)
      if (!!res.data && res.data.hasOwnProperty('verified')) {
        return res.data.verified;
      } else {
        throw Error('could not check')
      }
    });
  }

  importData = async (arr) => {
    return VarHelper.erria(async () => {
      
    });
  }

  getListCerts = async () => {
    // return VarHelper.erria(async () => {
    //   if (this.state.listCerts.length !== 0) return this.state.listCerts;
    //   const res = await Request.get(HOST + '/Certificates', {});
    //   if (!!res.data && !res.data.msg) {
    //     !!this.setters.setListCerts && this.setters.setListCerts(res.data);
    //   }
    //   return res.data;
    // });
    return VarHelper.erria(async () => {
      const res = await RequestBE.get(BE_HOST + '/clients/my-certs', { limit: 2000 });
      if (!!res.data && res.data.success !== false) {
        const listWithEmail = res.data.map(val => ({ ...val, 'Client Email': UserStore.state.email }));
        !!this.setters.setListCerts && this.setters.setListCerts(listWithEmail);
      }
      return res.data;
    });
  }

  getListDocs = async () => {
    // return VarHelper.erria(async () => {
    //   if (this.state.listDocs.length !== 0) return this.state.listDocs;
    //   const res = await Request.get(HOST + '/Documents', {});
    //   if (!!res.data && !res.data.msg) {
    //     !!this.setters.setListDocs && this.setters.setListDocs(res.data);
    //   }
    //   return res.data;
    // });
    return VarHelper.erria(async () => {
      const res = await RequestBE.get(BE_HOST + '/clients/my-documents', { limit: 2000 });
      if (!!res.data && res.data.success !== false) {
        const listWithEmail = res.data.map(val => ({ ...val, 'Client Email': UserStore.state.email }));
        !!this.setters.setListDocs && this.setters.setListDocs(listWithEmail);
      }
      return res.data;
    });
  }

  getListAvailableCasks = async () => {
    return VarHelper.erria(async () => {
      const res = await RequestBE.get(BE_HOST + '/clients/available-casks', { limit: 2000 });
      if (!!res.data && !res.data.msg && Array.isArray(res.data)) {
        !!this.setters.setListAvailableCasks && this.setters.setListAvailableCasks(res.data);
      }
      return res.data;
    });
  }

  getListPortfolioStrategy = async () => {
    return VarHelper.erria(async () => {
      const res = await RequestBE.get(BE_HOST + '/clients/portfolio-strategies', {});
      if (!!res.data && !res.data.msg) {
        !!this.setters.setListPortfolioStrategy && this.setters.setListPortfolioStrategy(res.data);
      }
      return res.data;
    });
  }

  getListCacheLocation = async () => {
    return VarHelper.erria(async () => {
      const res = await RequestBE.get(BE_HOST + '/clients/cache-location', { limit: 2000 });
      return res.data;
    });
  }

  getInvoices = async () => {
    return VarHelper.erria(async () => {
      const res = await RequestBE.get(BE_HOST + '/invoices', {});
      if (!!res.data && res.data.success) {
        !!this.setters.setInvoices && this.setters.setInvoices(res.data.data.invoices);
      } else {
        console.error(res.data)
        !!this.setters.setInvoices && this.setters.setInvoices(null);
      }
    })
  }

  makeDistilleryChartData = (listClients) => {
    const distilleries = listClients.filter(val => val.Email === UserStore.state.email).map(val => val['Distillery']).filter(val => !!val);
    const distilleriesUnique = _.uniqWith(distilleries, _.isEqual);

    // const regionColors = [
    //   { title: 'Speyside', color: '#189BD8' },
    //   { title: 'Islands', color: '#A53D8F' },
    //   { title: 'Highlands', color: '#9CC21D' },
    //   { title: 'Islay', color: '#EA5D12' },
    //   { title: 'Campbeltown', color: '#FFCF02' },
    //   { title: 'Lowland', color: '#E4153A' },
    // ];

    // const genColorDistillery = (dis, i) => {
    //   const findItem = listClients.find(val => val.Email === UserStore.state.email && val['Distillery'] === dis);
    //   if (!findItem) return genColor(i);
    //   const findRegion = regionColors.find(val => val.title === findItem['Region']);
    //   if (!findRegion) return genColor(i);
    //   return findRegion.color
    // }
    let data = distilleriesUnique.map((val, i) => ({
      // color: genColorDistillery(val, i),
      title: val,
      value: distilleries.filter(item => item === val).length, isDummy: false,
    }));
    return data;
  }

  computeMyComission = (listClients = this.state.listClients, email = UserStore.state.email) => {
    const comission = [
      { displayValue: 10, name: 'Barrel', range: [0, 25000] },
      { displayValue: 8.5, name: 'Butt', range: [25001, 100000] },
      { displayValue: 7, name: 'Hogshead', range: [100001, 250000] },
      { displayValue: 5.5, name: 'Punchon', range: [2500001, Infinity] },
    ];
    
    const { portfolioValue } = (() => {
      if (listClients.length === 0 || !email) return { portfolioValue: 0 };
      const filterClient = listClients.filter(val => val.Email === email);
      // console.log('filterClient', filterClient);
      // if (filterClient.length > 0) return {
      //   portfolioValue: +filterClient[0]['Portfolio'],
      // }
      if (filterClient.length > 0) return filterClient.reduce((acc, val) => {
        return {
          portfolioValue: acc.portfolioValue + +val['Portfolio'],
        }
      }, { portfolioValue: 0 });
      return {
        portfolioValue: 0,
      };
    })();

    const { myCommision, nextCommision } = (() => {
      let myIndex = 0;
      for (let i=0; i<comission.length; i++) {
        if (comission[i].range[0] < portfolioValue && comission[i].range[1] > portfolioValue) {
          myIndex = i;
          break;
        }
      }
      return {
        myCommision: comission[myIndex],
        nextCommision: myIndex === comission.length - 1 ? null : comission[myIndex + 1],
      };
    })();

    return { myCommision, nextCommision };
  }

  makeEdringtonTrilogyChartData = (listClients) => {
    const distilleries = listClients.filter(val => val.Email === UserStore.state.email).map(val => val['Distillery']).filter(val => !!val);
    const distilleriesUnique = _.uniqWith(distilleries, _.isEqual);
    const listAccept = [
      { title: 'Macallan', color: COLOR.BLUE },
      { title: 'Highland Park', color: COLOR.PURPLE },
      { title: 'The Glenrothes', color: COLOR.BLUE },
      // 'Macallan', 'Highland Park', 'The Glenrothes'
    ];
    let data = listAccept.map((val, i) => {
      // const isActive = distilleriesUnique.filter(item => item === val).length > 0
      return {
        color: val.color, title: val.title, value: 10,
        percentageOnPortfolio: distilleriesUnique.filter(item => item === val).length / 3, isDummy: false,
      }
    });
    return data;
  }

  makeRoyalTrilogyChartData = (listClients) => {
    const distilleries = listClients.filter(val => val.Email === UserStore.state.email).map(val => val['Distillery']).filter(val => !!val);
    const distilleriesUnique = _.uniqWith(distilleries, _.isEqual);
    const listAccept = [
      'Royal Brackla', 'Royal Lochnagar', 'Glenury Royal'
    ];
    let data = listAccept.map((val, i) => {
      // const isActive = distilleriesUnique.filter(item => item === val).length > 0
      return {
        color: COLOR.GREEN, title: val, value: 10,
        percentageOnPortfolio: distilleriesUnique.filter(item => item === val).length / 3, isDummy: false,
      }
    });
    return data;
  }

  makeLegacyCollectionChartData = (listClients) => {
    const list = listClients.filter(val => val.Email === UserStore.state.email).map(val => val['Legacy Collection']).filter(val => !!val);
    const listUnique = _.uniqWith(list, _.isEqual);
    const listAccept = [
      'Birthdays', 'Anniversaries', 'Children', 'Grandchildren', 'Weddings', 'Christenings', 'Bar Mitzvah'
    ];
    let data = listAccept.map((val, i) => {
      // const isActive = listUnique.filter(item => item === val).length > 0
      return {
        color: genColor(i), title: val, value: 10,
        percentageOnPortfolio: listUnique.filter(item => item === val).length / 3, isDummy: false,
      }
    });
    return data;
  }

  genAssetsLink = async ({ type, id }) => {
    return VarHelper.erria(async () => {
      const res = await RequestBE.post(BE_HOST + '/clients/get-asset-link', { type, id });
      return res.data;
    })
  }

  getSaleManagerData = async () => {
    return VarHelper.erria(async () => {
      const res = await RequestBE.get(BE_HOST + '/manager/all-clients-data', { });
      if (res.data.data) {
        if(this.setters.setSaleManagerData) this.setters.setSaleManagerData(res.data.data);
        else this.updateState({ saleManagerData: res.data.data });
      }
      return res.data;
    })
  }

  getPersonalObjectives = async () => {
    return VarHelper.erria(async () => {
      const res = await RequestBE.get(BE_HOST + '/clients/personal-objectives', {});
      if (res.data.length === 0) return null;
      return res.data[0];
    });
  }
  upsertPeronalObjectives = async (data) => {
    return VarHelper.erria(async () => {
      const res = await RequestBE.post(BE_HOST + '/clients/personal-objectives', data);
      return res.data;
    });
  };

  getKeyQuestions = async () => {
    return VarHelper.erria(async () => {
      const res = await RequestBE.get(BE_HOST + '/clients/key-questions', {});
      return res.data;
    });
  }
  upsertKeyQuestions = async (data) => {
    return VarHelper.erria(async () => {
      const res = await RequestBE.post(BE_HOST + '/clients/key-questions', data);
      return res.data;
    });
  };
}

export default new Info();
