import React, { useState, useEffect, useRef } from 'react';
import { Col, Text, Row, useDynamicResponsiveValue } from 'react-quick-style-components';
import { ChartMultiple } from '../index';
import { COLOR, fSize } from 'const';
import Store from 'store';
import { useWindowDimensions } from 'react-native';
import { DISTILLERY_DES } from './distilleryDes';

const makeTrilogyChartData = (listClients, parsedData) => {
  if (!parsedData) return [];
  const distilleries = listClients
    .filter(val => val.Email === Store.User.state.email)
    .map(val => val['Distillery'].trim())
    .filter(val => !!val && parsedData.map(x => x.name).includes(val));

  console.log('distilleries', distilleries)

  const listAccept = parsedData.map(val => {
    return {
      title: val.name,
      color: val.color,
    };
  });
  const data = listAccept.map((val, i) => {
    // const isActive = distilleriesUnique.filter(item => item === val).length > 0
    console.log(val.title)
    return {
      color: val.color, title: val.title, value: 10, isDummy: false,
      percentageOnPortfolio: distilleries.length > 0 ? distilleries.filter(item => item === val.title).length / distilleries.length : 0,
    }
  });
  return data;
}

const CustomTrilogyChart = ({ data, listClients }) => {
  const [hoverRegionData, setHoverRegionData] = useState<any>();
  const [trilogyPieChartData, setTrilogyPieChartData] = useState<any[]>();

  const rV = useDynamicResponsiveValue();
  const { width } = useWindowDimensions();
  const chartRef = useRef<{ getComputedData: Function }>();
  const onSideItemPress = (i) => {
    const data = chartRef.current?.getComputedData(trilogyPieChartData);
    setHoverRegionData(data[i]);
  }

  useEffect(() => {
    const newData =  makeTrilogyChartData(listClients, data.parsedData);
    console.info('newData', newData);
    setTrilogyPieChartData(newData)
  }, [data.Name]);

  if (!trilogyPieChartData || trilogyPieChartData.length === 0) return null;
  const unitSize = rV({ xs: 5, lg: 10 });
  const equalColumnWidth = rV({ xs: width / 2 - unitSize * 2, md: (width / 2 - unitSize * 2) / 2, });

  const displayDistellery = String(hoverRegionData?.title).trim();
  const displayDistelleryData = (() => {
    const find = data.parsedData.find(val => val.name === displayDistellery);
    if (!find) return DISTILLERY_DES[displayDistellery];
    return find.description;
  })();

  return (
    <Row xs='100%' md='50%' alignItems="flex-start">
      <Col paddingHorizontal10 paddingTop30>
        <Row xs='100%' md='any:any:1' marginBottom20 stretch marginHorizontal={-rV({ xs: 10, lg: 20 })}>
          <Col padding={unitSize} width={equalColumnWidth}>
            <ChartMultiple
              size={equalColumnWidth}
              data={trilogyPieChartData}
              // onHoverPart={setHoverRegionData}
              onPressPart={setHoverRegionData}
              initialActiveSetPart={setHoverRegionData}
              dummyClickable
              ref={chartRef}
            />
            {Boolean(hoverRegionData) && (
              <Col paddingTop30>
                <Text fontSize50 center color={hoverRegionData.color} bold>{Math.round(hoverRegionData?.percentageOnPortfolio * 100)} %</Text>
              </Col>
            )}
          </Col>
          <Col padding={unitSize} width={equalColumnWidth} middle>
            <Col width="50%">
              <Text color={COLOR.MAIN} fontSize={fSize(rV, 20)}>{(data.Name || '').toUpperCase()}</Text>
              <Row xs='100%' md='100%' xxl="100%" marginTop8>
                {trilogyPieChartData.map((item, i) => (
                  <Row key={`chart${i}`} marginTop10 onPress={() => onSideItemPress(i)}>
                    <Col width={25} height={25} borderRadius={3} backgroundColor={item.color} />
                    <Text marginLeft5 fontSize={fSize(rV, 16)}>{item.title}</Text>
                  </Row>
                ))}
              </Row>
            </Col>
          </Col>
        </Row>
      </Col>
      <Col paddingHorizontal10 paddingTop30>
        {hoverRegionData && (
          <>
            <Text fontSize30 bold color={hoverRegionData.color}>{displayDistellery}</Text>
            {!!displayDistelleryData && (
              <Col marginTop20>
                {displayDistelleryData.map((row, i) => {
                  return row.length === 1 ? (
                    <Text key={'row-' + i} marginTop10 body1>
                      {row[0]}
                    </Text>
                  ) : (
                    <Text key={'row-' + i} marginTop10 body1>
                      <Text body1 bold>{row[0]}</Text>: {row[1]}
                    </Text>
                  );
                })}
              </Col>
            )}
          </>
        )}
      </Col>
    </Row>
  );
}

export default CustomTrilogyChart;