import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={39.997}
      viewBox="0 0 20 39.997"
      {...props}
    >
      <Path
        data-name="facebook (2)"
        d="M16.349 6.641H20V.282A47.15 47.15 0 0014.68 0C9.413 0 5.813 3.311 5.813 9.4V15H0v7.109h5.813V40h7.123V22.11h5.575l.881-7.11h-6.461v-4.9c0-2.055.555-3.461 3.418-3.461z"
        fill={props.fill || "#fff"}
      />
    </Svg>
  )
}

export default SvgComponent
