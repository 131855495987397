import React, { useMemo } from 'react';
import { Col, Text } from 'react-quick-style-components';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import * as ScreenObjects from 'screens';
import { IScreen } from 'type';
import Store from 'store';
// specify all navigation here
const Stack = createStackNavigator();

export const navigationRef = React.createRef<any>();

const Navigation = () => {

  const { linking, screenArr } = (() => {
    const screenUrls = {};
    const screenArr = [];
    Object.keys(ScreenObjects).forEach(screenName => {
      const screenObj: IScreen = ScreenObjects[screenName];
      screenArr.push({ name: screenName, component: screenObj, title: screenObj.routeInfo.title });
      if (!screenUrls[screenName]) {
        screenUrls[screenName] = screenObj.routeInfo.path;
      }
    });
    return {
      linking: screenUrls,
      screenArr,
    }
  })();

  return (
    <NavigationContainer
      linking={{ config: { screens: linking }, prefixes: [] }}
      documentTitle={{
        formatter: (options, route) =>
          `${options?.title ?? route?.name} - Vintage Acquisitions`,
      }}
      fallback={<Text>Loading...</Text>}
      ref={navigationRef}
      onStateChange={(state) => {
        Store.Firebase.logEvent('Page View', {
          name: navigationRef.current?.getCurrentRoute().name || '',
          url: window.location.pathname,
        })
        Store.Firebase.setCurrentScreen(navigationRef.current?.getCurrentRoute().name);
      }}
    >
      <Stack.Navigator screenOptions={{ header: () => null }}>

        {useMemo(() => {
          return screenArr.map((val, i) => (
            <Stack.Screen key={'screen-' + val + i} name={val.name} component={val.component} options={{ title: val.title }} />
          ))
        }, [])}
      </Stack.Navigator>
    </NavigationContainer>
  );
};

export default Navigation;
