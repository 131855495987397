import React, { useEffect } from 'react';
import { StyleSheet, Image, useWindowDimensions } from 'react-native';
import { Col, Row, useDynamicResponsiveValue, Text, Scroll } from 'react-quick-style-components';
import { useNavFunc } from 'navigation';
import { IScreen } from 'type';
import { ASSETS, DEVICE_INFO, COLOR, fSize } from 'const';
import { Footer, Header } from "components"
import Store from 'store';
import { TimeHelper, VarHelper } from 'helpers';
import { ActivityIndicator } from 'react-native';
import moment from 'moment';

const NewDetail: IScreen = () => {
  const { route } = useNavFunc();
  const { id }: any = route.params || {};
  const [{ }, iApi] = Store.Info.createStore();
  const [newsDetail, setNewsDetail] = React.useState<any>(undefined);
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    iApi.getNewsDetail(id).then((res) => {
      console.log('res', res);
      setNewsDetail(res[0]);
      setTimeout(() => {
        setLoading(false);
      }, 4000);
    })
  }, []);

  return (
    <Col flex1 backgroundColor={COLOR.BG}>
      <Col flex1>
        <Header />
        {/* <Col middle>
          <Image source={pageDataImages.length > 0 ? { uri: pageDataImages[0] } : ASSETS.IMG_CASKS_HEADER} style={{ width: '100%', height: rV({ xs: DEVICE_INFO.WIDTH / 3.6, lg: DEVICE_INFO.WIDTH / 7.3 }), resizeMode: 'cover' }} />
          <Col middle style={{ position: 'absolute' }}>
            <Text color={COLOR.WHITE} fontSize={fSize(rV, 52)} marginBottom10 fontFamily='PlayfairDisplay_600SemiBold' marginBottom={10}>
              News
            </Text>
            <Image source={ASSETS.IMG_WHITESWIRL} style={{ width: fSize(rV, 146), height: fSize(rV, 21), resizeMode: 'contain' }} />
          </Col>
        </Col> */}
        <Scroll flex1>
          {newsDetail?.link && (
            <iframe
              src={`${newsDetail?.link}?portal_embed=1`}
              style={{ width: '100%', height: '100%', border: 'none' }}
              onLoad={() => {
                setLoading(false);
              }}
            />
          )}
        </Scroll>
        {loading && (
          <Col absoluteFill bgWhite middle>
            <ActivityIndicator size='large' color={COLOR.MAIN} />
          </Col>
        )}
        <Footer />
      </Col>
    </Col>
  );
};

NewDetail.routeInfo = {
  title: 'News Detail',
  path: '/news/:id',
};

export default NewDetail;