import React from 'react';
import { Col, Text, Scroll, useDynamicResponsiveValue } from 'react-quick-style-components';
import { useWindowDimensions } from 'react-native';
import { Header, Footer } from 'components';
import { useNavFunc } from 'navigation';
import { IScreen } from 'type';
import { SCREEN_NAME, fSize } from 'const';

import * as ScreenObject from './index';

const Home: IScreen = () => {
  const rV = useDynamicResponsiveValue();
  const { height } = useWindowDimensions();
  const { navigation } = useNavFunc();
  const headerHeight = fSize(rV, 147, { xs: 60, xl: 80, xxl: 80 });
  const renderContainer = ({ children }) => {
    return (
      <Col flex1>
        <Scroll height={height}>
          {children}
        </Scroll>
      </Col>
    );
  };

  const ScreenContainer = React.Fragment;
  const screenArr = Object.keys(ScreenObject).map(screenName => ({ screenName, title: ScreenObject[screenName].routeInfo.title, component: ScreenObject[screenName] }))

  return (
    <Col>
      <ScreenContainer>
        <Header />
        <Col style={{ minHeight: height - 150 }}>
          {screenArr.map((val, i) => (
            <Col
              onPress={() => navigation.navigate(val.screenName)}
              paddingVertical10 key={val.title} marginTop5
            >
              <Text center>{val.title}</Text>
            </Col>
          ))}
        </Col>
        <Footer />
      </ScreenContainer>
      {/* ABSOLUTE ICON */}
      <Col absolute bottom30 right30 width30 height30 backgroundColor="red" />
    </Col>
  );
};

Home.routeInfo = {
  title: 'List Screens',
  path: '/list-screen',
};

export default Home;