import React, { useState } from 'react';
import { Col, Text, useDynamicResponsiveValue } from 'react-quick-style-components';
import { Input, Button, Header } from 'components'
import { ASSETS, COLOR, fSize, SCREEN_NAME, spacing } from 'const';
import { StyleSheet, Image } from 'react-native';
import { IScreen } from 'type';
import { useNavFunc } from 'navigation';
import GlobalEvent from 'js-events-listener/react';
import Store from 'store';

import firebase from "firebase";

const ChangePassword: IScreen = () => {
  const { reset, navigate } = useNavFunc();
  const rV = useDynamicResponsiveValue();
  

  const [{ name : savedName, email: savedEmail }, uApi] = Store.User.createStore();

  const [currentPass, setCurrentPass] = useState('');
  const [newPass, setNewPass] = useState('');
  const [confirmPass, setConfirmPass] = useState('');

  const submit = async () => {
    if (!newPass || !currentPass || !confirmPass) return alert('Please fill all the fields');
    if (newPass !== confirmPass) return alert('Confirm password does not match');

    const cred = firebase.auth.EmailAuthProvider.credential(firebase.auth().currentUser.email, currentPass);
    try {
      await firebase.auth().currentUser.reauthenticateWithCredential(cred);
      await firebase.auth().currentUser.updatePassword(newPass);
      alert('Your password has been updated');
      reset(SCREEN_NAME.Home);
    } catch(err) {
      return alert(String(err));
    }
  };

  return (
    <Col flex1>
      <Header />
      <Col flex1 backgroundColor={COLOR.BG} middle>
        <Col width={rV({ xs: '90%', sm: '80%', md: '70%', lg: '60%', xl: '50%' })} >
          <Col style={[styles.contentBox, { paddingBottom: rV({ xs: 40, xxxl: 65 }) }]} >
            <Text textAlign="center" bold fontSize={fSize(rV, 30)} colorMain paddingVertical16>Change Password</Text>
            <Col marginHorizontal={rV({ xs: spacing[4], md: spacing[8], lg: spacing[12], xl: spacing[16] })}>

              <Input
                label="Your current password" inputProps={{ secureTextEntry: true }}
                value={currentPass} onChangeText={setCurrentPass} marginBottom5
              />
              <Input
                label="New password" inputProps={{ secureTextEntry: true }}
                value={newPass} onChangeText={(text) => setNewPass(text)} marginBottom5
              />
              <Input
                label="Confirm new password" inputProps={{ secureTextEntry: true }}
                value={confirmPass} onChangeText={(text) => setConfirmPass(text)}
              />
            </Col>
            <Button
              marginTop={rV({ xs: 30, xxxl: 59 })}
              title="UPDATE" width192 alignSelf="center" onPress={submit} />
          </Col>
        </Col>
      </Col>
    </Col>
  );
};

ChangePassword.routeInfo = {
  title: 'Change Password',
  path: '/change-password',
}

const styles = StyleSheet.create({
  contentBox: {
    paddingTop: 32,
    backgroundColor: COLOR.WHITE,
    borderColor: COLOR.BORDER,
    borderWidth: 1,
    borderTopLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
})
export default ChangePassword;

