import React, { useState, useEffect, memo } from 'react';

import Lottie from 'react-lottie-player';
import lottieJson from './bell.json';

const BellAnim = ({ haveUnreadNotification }) => {
  const [play, setPlay] = useState(false);
  // const [goTo, setGoTo] = useState(haveUnreadNotification ? 200 : 0);

  useEffect(() => {
    if (haveUnreadNotification) {
      // setGoTo(200);
      setPlay(true);
    } else {
      // setGoTo(0);
      setPlay(false);
    }
  }, [haveUnreadNotification]);

  // console.count('rerender bell');

  return (
    <Lottie
      loop={false}
      animationData={lottieJson}
      play={play}
      goTo={haveUnreadNotification ? undefined : 0}
      segments={[0, 200]}
      style={{ width: 70, height: 70 }}
    />
  );
};

export default memo(BellAnim);
