import React, { RefObject, useImperativeHandle, forwardRef } from "react"
import { COLOR, fSize } from 'const';
import { TextInput, TextInputProps, ViewStyle, StyleSheet, useWindowDimensions, TextStyle } from 'react-native';
import { Col, Text, usePropsStyle, Row, useDynamicResponsiveValue } from 'react-quick-style-components';
export type ITextInputRefProps = {
  focus?(): void
  blur?(): void
}

import './Input.css'

export interface IInputProps extends TextInputProps {
  value: string;
  onChangeText(newValue: any): void;
  placeholder: string;
  inputProps?: TextInputProps;
  onRef?(ref: any): any;
  label?: string;
  labelInside?: string;
  inputHeight?: any;
  styleInput?: TextStyle;
  [anyProp: string]: any;
}

export const Input = forwardRef<ITextInputRefProps, IInputProps>(
  (props, ref) => {
    const propStyle = usePropsStyle(props);
    const { value, onChangeText, placeholder, inputProps, label, labelInside, styleInput } = props;
    useImperativeHandle(ref, () => ({
      focus: () => inputRef?.current?.focus(),
      blur: () => inputRef?.current?.blur(),
    }))
    const rV = useDynamicResponsiveValue();

    const inputRef: RefObject<TextInput> = React.createRef<TextInput>()

    return (
      <Col style={[styles.container, propStyle]}>
        {Boolean(label) && <Text fontSize={fSize(rV, 24)} colorMain paddingBottom16 >{label}</Text>}
        <Row backgroundColor={COLOR.BG} alignItems="center">
          {Boolean(labelInside) && <Text fontSize={fSize(rV, 20)} colorMain paddingLeft18 >{labelInside}</Text>}
          <TextInput
            // @ts-ignore
            dataSet={{ element: 'text-input' }}
            ref={inputRef}
            value={String(value)}
            onChangeText={onChangeText}
            style={[styles.input, {
              height: rV({ xs: 45, xxxl: 75 }),
              fontSize: fSize(rV, 24),
            }, styleInput]}
            underlineColorAndroid="transparent"
            placeholderTextColor={COLOR.MAIN}
            placeholder={placeholder}
            {...inputProps}
          />
        </Row>
      </Col>
    )
  },
)

const styles = StyleSheet.create({
  container: {
    backgroundColor: COLOR.WHITE,
  },
  input: {
    flex: 1,
    backgroundColor: '#E8E1C9',
    paddingHorizontal: 16,
    color: COLOR.MAIN,
    padding: 0,
    fontFamily: 'PlayfairDisplay_400Regular',
    fontSize: 14,
    lineHeight: 22,
  },
});

