import React, { useState, useEffect } from 'react';
import { Col, Text, Row, useDynamicResponsiveValue, Scroll } from 'react-quick-style-components';
import { IScreen } from 'type';
import { ASSETS, COLOR, fSize, ICON } from 'const';
import { Image, StyleSheet, View } from 'react-native';
import { Footer, Header, Input, Button, Breadcrumbs } from 'components';
import { TimeHelper, VarHelper } from 'helpers';

import Store from 'store';

const ContactUs: IScreen = () => {
  const rV = useDynamicResponsiveValue()
  const bannerHeight = rV({ xs: 150, lg: 200, xxxl: 262 })
  const imageHeight = rV({ xs: 400, md: 500, xxxl: 800 })
  const imageWidth = rV({ sm: 375, lg: 500, xxxl: 770 })
  const [message, setMessage] = useState('')

  const [{ }, mApi] = Store.Message.createStore();
  const [{ listClients }, cApi] = Store.Client.createStore();
  const [{ listPages }, iApi] = Store.Info.createStore();
  const [{ email, name, firebaseUser }] = Store.User.createStore();

  useEffect(() => {
    iApi.getListPages();
    cApi.getListClients();
  }, []);

  const pageData = listPages.find(val => val.Title === 'Contact Us');
  const pageDataImages = VarHelper.imgApiStringifyGetAll(pageData?.Images);

  const myAM = listClients.length > 0 ? listClients[0]['Account Manager'] : '';

  const send = async () => {
    if (!message) return alert('Please fill all the form');
    const [res, err] = await mApi.sendMessageToAdmin({
      message,
      AM: myAM,
      name: firebaseUser.displayName || name,
    });
    alert('Thank you, your message has been sent');
    setMessage('');
  };

  return (
    <Col flex1 backgroundColor={COLOR.BG}>
      <Header />
      <Scroll flex1 >
        <Col middle height={bannerHeight}>
          <Image source={pageDataImages.length > 0 ? { uri: pageDataImages[0] } : ASSETS.BANNER_ACCOUNT} style={[{ height: bannerHeight }, styles.banner]} />
          <Col zIndex99 center>
            <Text fontSize={fSize(rV, 52)} colorWhite>Contact Us</Text>
            <Col alignSelf="center">
              <ICON.IcPattern />
            </Col>
          </Col>
        </Col>
        <Col flex1 middle marginVertical={rV({ xs: 16, lg: 30, xxxl: 40 })}>
          <Col width={rV({ xs: '90%', sm: '90%', md: '90%', lg: '90%', xl: '90%' })} >
          <Breadcrumbs
            title="Contact Us"
            style={{
              marginLeft: 0,
              marginBottom: 20,
              alignSelf: 'flex-start',
            }}
          />
            <Row xs="100%" md="any:1" style={[styles.contentBox, { borderRadius: rV({ xs: 0, sm: 10 }) }]} >
              <Col>
                <Image source={{ uri: ASSETS.REFER_A_FRIEND }}
                  style={[styles.image, {
                    borderRadius: rV({ xs: 0, sm: 10 }), borderRightWidth: rV({ xs: 0, sm: 1 }),
                    width: imageWidth, height: imageHeight,
                  }]}
                />
                {/* <Col absolute bottom30 right0 left40 bgMain padding20 alignItems="flex-end">
                  <Text fontSize18 bold colorWhite textAlign="right">"Friendship is the only cement that will ever hold the world together"</Text>
                  <Text marginTop10 fontSize13 colorWhite fontStyle="italic">Woodrow T Wilson</Text>
                </Col> */}
              </Col>
              <Col bgWhite flex1>
                <Row height63 >
                  <Col height63 flex1 middle >
                    <ICON.Email />
                  </Col>
                  <Col height63 flex4 backgroundColor="#EDEDED" />
                </Row>
                <Col paddingLeft={rV({ xs: 16, sm: 30 })} paddingRight={rV({ xs: 16, sm: 44 })}>

                  <Text fontSize20 bold paddingTop={rV({ xs: 16, sm: 29 })} marginBottom5>
                    Contact Us
                  </Text>
                  {/* <Text body1 marginBottom20>Have a drink on us?</Text> */}
                  <Input marginBottom20 styleInput={{ height: 40, fontSize: fSize(rV, 16) }} inputProps={{ placeholderTextColor: '#707070',  editable: false }}
                    value={email} placeholder="Your Email" onChangeText={(text) => {}}
                  />
                  <Input inputProps={{ multiline: true, placeholderTextColor: '#707070' }} styleInput={{ height: 214, paddingTop: 16, fontSize: fSize(rV, 16) }}
                    value={message} placeholder="Message" onChangeText={(text) => setMessage(text)}
                  />
                  <Button
                    marginTop20 marginBottom20 width189 borderRadius10 alignSelf={rV({ xs: 'center', md: 'flex-end' })}
                    title="Send" onPress={send}
                  />
                </Col>
              </Col>
            </Row>
          </Col>
        </Col>
      </Scroll>
      <Footer />
    </Col>
  );
};

ContactUs.routeInfo = {
  title: 'Contact us',
  path: '/contact-us',
};

const styles = StyleSheet.create({
  contentBox: {
    overflow: 'hidden',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: "flex-start",
    borderWidth: 1,
    borderColor: COLOR.BORDER,
    backgroundColor: 'white'
  },
  banner: {
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0
  },
  dropdown: {
    flex: 1,
    backgroundColor: COLOR.BG,
    color: COLOR.MAIN,
    padding: 0,
    justifyContent: 'center',
  },
  image: {
    borderColor: COLOR.BORDER,
    resizeMode: 'cover'
  }
})
export default ContactUs;


