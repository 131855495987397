import React, { useEffect } from 'react';
import { useNavFunc } from 'navigation';
import { SCREEN_NAME } from 'const';
import { IScreen } from 'type';
import GlobalEvent from 'js-events-listener';
import Store from 'store';

const Logout : IScreen = () => {
  const { reset } = useNavFunc();
  const [{ }, uApi] = Store.User.createStore();
  useEffect(() => {
    uApi.logout();
    setTimeout(() => {
      reset(SCREEN_NAME.Login);
    }, 300);
  }, []);
  return null;
};

Logout.routeInfo = {
  title: 'Logout',
  path: '/logout',
}

export default Logout;