import React, { DOMElement, useEffect, useState, useImperativeHandle, forwardRef } from 'react';
import { Col, Row, Text, usePropsStyle } from 'react-quick-style-components';
import Svg, { Path } from "react-native-svg";
import { Platform } from 'react-native';
import { computeChartArc } from './computeChartArc';
import { COLOR, genInactiveColor } from 'const';

interface IChartMultipleProps {
  // props here
  size: number | string,
  data: Array<{ value: number, title: string, color?: string, isDummy?: boolean }>,
  bgColor?: string,
  onHoverPart?: any,
  onPressPart?: any,
  initialActiveSetPart?: any,
  dummyClickable?: boolean,
  [anyProp: string]: any,
}

const ChartMultiple = forwardRef((props : IChartMultipleProps, ref) => {
  const [id, setId] = useState('chart-multiple-'+ new Date().getTime());
  const propStype = usePropsStyle(props);

  const getComputedData = (data) => {
    const totalValue = data.length === 0 ? 0 : data.reduce((a, b) => ({ ...a, value: a.value + b.value })).value;
    let res = [];
    
    let startAngle = 0;

    let sumPercent = 0;
    data.forEach((val, i) => {
      
      const percent = val.value * 100 / totalValue; 
      const angle = 360 * val.value / totalValue;
      let formatedPercent = percent.toFixed(2);
      if (i !== data.length - 1) sumPercent += +formatedPercent;
      else {
        formatedPercent = (100 - sumPercent).toFixed(2);
      }
      res[i] = {
        ...val,
        percent,
        formatedPercent,
        angle,
        startAngle: startAngle,
        endAngle: startAngle + angle,
      };
      startAngle = res[i].endAngle;
    });

    if (res.length === 1) {
      res[0].startAngle = 0;
      res[0].endAngle = 360;
    }

    return res;
  }

  const computeData = getComputedData(props.data);

  useImperativeHandle(ref, () => ({
    getComputedData,
  }));

  useEffect(() => {
    if (Platform.OS !== 'web') return;
    const arr = document.querySelectorAll(`#${id} .chart-multiple-part`);
    if (arr.length === 0) return;
    let runInitial = false;
    arr.forEach((el : any, elIndex) => {
      const isDummy = el.getAttribute('data-dummy') === '1';
      const index = el.getAttribute('data-index');
      if (!isDummy && !runInitial && props.initialActiveSetPart) {
        runInitial = true;
        props.initialActiveSetPart(computeData[index]);
      }
      if (elIndex === arr.length - 1 && !runInitial && isDummy) {
        // all are dummies, so focus the first part
        const focusIndex = arr[0].getAttribute('data-index');
        props.initialActiveSetPart(computeData[focusIndex]);
      }
      el.onmouseenter = () => {
        el.style.opacity = 0.8;
        if (!isDummy && !!props.onHoverPart) {
          props.onHoverPart(computeData[index]);
        }
      };
      el.onmouseleave = () => {
        el.style.opacity = 1;
      }
      el.onclick = () => {
        if (!isDummy && !props.dummyClickable) return;
        if (!props.onPressPart) return
        console.log('onPressPart', computeData[index]);
        props.onPressPart(computeData[index]);
      }
    });
  }, [id, props.data]);

  return (
    <Col width={props.size} height={props.size} backgroundColor={props.bgColor || 'white'} style={propStype} middle>
      {computeData.length === 1 ? (
        <Col width={"90%"} height={"90%"} borderRadius={"100%"} backgroundColor={computeData[0].color} middle>
          <Col width={"50%"} height={"50%"} borderRadius={"100%"} bgWhite></Col>
        </Col>
      ) : (
        <Svg
        // @ts-ignore
        xmlns="http://www.w3.org/2000/svg"
        width="90%"
        height="90%"
        viewBox="0 0 280 280"
        stroke={props.bgColor || "white"}
        strokeWidth="13"
        id={id}
      >
        {computeData.map((val, i) => {
          // console.log(computeChartArc(val.startAngle, val.endAngle, 140, 60));
          return (
            <Path
              fill={val.isDummy ? genInactiveColor(val.color) : val.color}
              key={i}
              data-dummy={val.isDummy ? '1' : '0'}
              data-index={i}
              d={computeChartArc(val.startAngle, val.endAngle, 140, 60)}
              // @ts-ignore
              className="chart-multiple-part"
            />
          );
        })}
      </Svg>
      )}
      
    </Col>
  );
});

export default ChartMultiple;
