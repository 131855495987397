import GlobalEvent, { useGlobalState } from 'js-events-listener/react';
import { useEffect } from 'react';
import Request from './Request.utils';
import RequestBE from './RequestBE.utils';
import Persist, { PersistReady } from './Persist.utils';
import { HOST, BE_HOST } from './host';
import { IUser } from 'type';
import { VarHelper } from 'helpers';
import UserStore from './User.Store';

interface ISetters {
  setListNews?(v : any): void,
  setListEvents?(v : any): void,
  setListPages?(v : any): void,
  [additionSetter: string]: (v : any) => void,
}

class Message extends PersistReady {

  constructor() {
    super();
  }

  state : any = {
    
  };

  setters : ISetters = {};

  updateState(obj, allowUndefined = true) {
    for (let key in obj) {
      if (allowUndefined || (obj[key] !== null && obj[key] !== undefined)) this.state[key] = obj[key];
    }
  }

  createStore() {

    return [
      {  },
      {
        sendMessage: this.sendMessage,
        sendMessageToAdmin: this.sendMessageToAdmin,
        referAFriend: this.referAFriend,
      }
    ];
  }

  _handleAccessDenied(msg) {
    if (!msg) return;
    if (msg.includes('Access Denied')) {
      UserStore.logout();
    }
  }

  sendMessage = async ({ subject, message, from, am }) => {
    return VarHelper.erria(async () => {
      const res = await RequestBE.post(BE_HOST + '/available-casks/request-info', {
        "Subject": subject,
        "Message": message,
        "From": from,
        "AM": am,
      });
      return res.data;
    });
  }

  sendMessageToAdmin = async ({ message, AM, name }) => {
    return VarHelper.erria(async () => {
      const res = await RequestBE.post(BE_HOST + '/users/send-message-to-admin', {
        message, AM, name
      });
      return res.data;
    });
  }

  referAFriend = async ({ email, message }) => {
    return VarHelper.erria(async () => {
      const res = await RequestBE.post(BE_HOST + '/users/refer-a-friend', {
        Email: email,
        Message: message, 
      });
      return res.data;
    });
  }
}

export default new Message();
