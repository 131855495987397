import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      data-name="instagram (1)"
      xmlns="http://www.w3.org/2000/svg"
      width={40.497}
      height={40.497}
      viewBox="0 0 40.497 40.497"
      {...props}
    >
      <Path
        data-name="Path 254"
        d="M29.535 0H10.962A10.974 10.974 0 000 10.962v18.573A10.974 10.974 0 0010.962 40.5h18.573A10.974 10.974 0 0040.5 29.535V10.962A10.974 10.974 0 0029.535 0zm8.588 29.535a8.6 8.6 0 01-8.588 8.588H10.962a8.6 8.6 0 01-8.588-8.588V10.962a8.6 8.6 0 018.588-8.588h18.573a8.6 8.6 0 018.588 8.588zm0 0"
        fill={props.fill || "#fff"}
      />
      <Path
        data-name="Path 255"
        d="M20.248 9.171a11.073 11.073 0 1011.073 11.073A11.085 11.085 0 0020.248 9.171zm0 19.772a8.7 8.7 0 118.7-8.7 8.709 8.709 0 01-8.7 8.704zm0 0"
        fill={props.fill || "#fff"}
      />
      <Path
        data-name="Path 256"
        d="M31.587 5.243a3.272 3.272 0 103.272 3.272 3.276 3.276 0 00-3.272-3.272zm0 4.17a.9.9 0 11.9-.9.9.9 0 01-.9.9zm0 0"
        fill={props.fill || "#fff"}
      />
    </Svg>
  )
}

export default SvgComponent
