import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Col, Text, Row, useDynamicResponsiveValue } from 'react-quick-style-components';
import { ChartMultiple } from '../index';
import { VarHelper } from 'helpers';
import { ASSETS, COLOR, fSize, ICON, SCREEN_NAME } from 'const';
import { useNavFunc } from 'navigation';
import Store from 'store';
import PortfolioValueChart from './PortfolioValueChart';
import CustomTrilogyChart from './CustomTrilogyChart';
import VintageBottlersCollection from './VintageBottlersCollection';

const TAB = {
  VINTAGE_BOTTLERS_COLLECTION: 'VINTAGE BOTTLERS COLLECTION',
}

const CollectionsComponent = forwardRef((props, ref) => {
  const [{
    listClients
  }, cApi] = Store.Client.createStore();
  const [{}, iApi] = Store.Info.createStore();
  const [activeTab, setActiveTab] = useState(TAB.VINTAGE_BOTTLERS_COLLECTION);
  const [chartTypeData, setChartTypeData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const [res, err] = await iApi.getCaskIOwnChartType();
      if (err) {
        console.log(err);
        return;
      }
      if (!!res && !!res.data) {
        console.log('GET CASK I OWN CHART TYPE', res);
        setChartTypeData(res.data.filter(val => val.parsedData.length > 0));
        // setActiveTab(res.data?.[0]?.Name.toUpperCase());
      }
    };

    getData();
  }, []);


  useImperativeHandle(ref, () => ({
    setActiveTab,
  }));

  const tabArr = Object.keys(TAB).map(val => TAB[val]);

  const customTrilogyChartType = chartTypeData.find(val => (val.Name || '').toUpperCase() === activeTab);
  console.info('customTrilogyChartType', customTrilogyChartType);

  return (
    <Col>
      <Row style={{ flexWrap: 'wrap' }} borderBottomWidth1 borderBottomColor='rgba(0,0,0,0.2)'>
        {!!chartTypeData && Array.isArray(chartTypeData) && chartTypeData.map((val, valI) => {
          const name = (val.Name || '').toUpperCase();
          return (
            <Col
              key={'customTrilogi-'+name+valI} paddingHorizontal10 paddingVertical5
              onPress={name === activeTab ? undefined : () => setActiveTab(name)}
            >
              <Text bold={name === activeTab}>{name}</Text>
            </Col>
          );
        })}
        {tabArr.map(val => (
          <Col
            key={val} paddingHorizontal10 paddingVertical5
            onPress={val === activeTab ? undefined : () => setActiveTab(val)}
          >
            <Text bold={val === activeTab}>{val}</Text>
          </Col>
        ))}
      </Row>
      {activeTab === TAB.VINTAGE_BOTTLERS_COLLECTION ? <VintageBottlersCollection />
      : customTrilogyChartType ? <CustomTrilogyChart data={customTrilogyChartType} listClients={listClients} /> : null
      }

    </Col>
  );
});

export default CollectionsComponent;