import React from 'react';
import { Col, Text, Scroll, useDynamicResponsiveValue } from 'react-quick-style-components';
import { useWindowDimensions } from 'react-native';
import { Header, ChartMultiple, ChartSingle } from 'components';
import { useNavFunc } from 'navigation';
import { IScreen } from 'type';
import { SCREEN_NAME, fSize, COLOR } from 'const';

import * as ScreenObject from './index';

const Home: IScreen = () => {
  const rV = useDynamicResponsiveValue();
  const { height } = useWindowDimensions();
  const { navigation } = useNavFunc();
  const headerHeight = fSize(rV, 147, { xs: 60, xl: 80, xxl: 80 });
  const renderContainer = ({ children }) => {
    return (
      <Col flex1>
        <Scroll height={height - headerHeight}>
          {children}
        </Scroll>
      </Col>
    );
  };

  const ScreenContainer = React.Fragment;
  const screenArr = Object.keys(ScreenObject).map(screenName => ({ screenName, title: ScreenObject[screenName].routeInfo.title, component:  ScreenObject[screenName] }))

  return (
    <Col flex1>
      <Header />
      <ScreenContainer>
        <Col margin20>
          <ChartMultiple
            data={[
              { value: 60, title: 'ABC', color: COLOR.MAIN, isDummy: true },
              { value: 20, title: 'DEF', color: COLOR.BLUE },
              { value: 20, title: 'DEF', color: COLOR.YELLOW },
              { value: 20, title: 'DEF', color: COLOR.DARKPINK },
              { value: 20, title: 'DEF', color: COLOR.GREEN },
              { value: 20, title: 'DEF', color: COLOR.PURPLE },
            ]}
            size={400}
            bgColor="#F2F2F2"
            onHoverPart={(partData) => console.log('hover part', partData)}
            onPressPart={(partData) => console.log('press part', partData)}
          />
        </Col>
        <Col margin20>
          <ChartSingle
            size={400}
            bgColor="#F2F2F2"
            data={{
              value: 20,
              title: 'DEF',
              color: COLOR.PURPLE,
              percent: 100 * 20/160,
            }}
            onHoverPart={(partData) => console.log('hover single part', partData)}
            onPressPart={(partData) => console.log('press single part', partData)}
          />
        </Col>
      </ScreenContainer>
    </Col>
  );
};

Home.routeInfo = {
  title: 'Test chart',
  path: '/test-chart',
};

export default Home;