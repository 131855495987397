import React, { useEffect } from 'react';
import { Col, Text, Row, useDynamicResponsiveValue, Scroll } from 'react-quick-style-components';
import { IScreen } from 'type';
import { ASSETS, COLOR, fSize, ICON } from 'const';
import { Image, StyleSheet, useWindowDimensions } from 'react-native';
import { Footer, Header, TableWithAction, ChatBox } from 'components';
import { VarHelper } from 'helpers';
import Store from 'store'

const BecomeASeller: IScreen = () => {
  const { width, height } = useWindowDimensions();
  const rV = useDynamicResponsiveValue();
  const bannerHeight = rV({ xs: 150, lg: 200, xxxl: 262 })
  const iconSize = rV({ xs: 15, lg: 20, xxxl: 25 })

  const [{ listPages }, iApi] = Store.Info.createStore();

  useEffect(() => {
    iApi.getListPages();
  }, []);

  const pageData = listPages.find(val => val.Title === 'Become a seller');
  const pageDataImages = VarHelper.imgApiStringifyGetAll(pageData?.Images);

  const renderContainer = ({ children }) => {
    return (
      <Col>
        <Scroll height={height}>
          {children}
        </Scroll>
      </Col>
    );
  };

  const ScreenContainer = React.Fragment;

  return (
    <Col flex1 backgroundColor={COLOR.BG}>
      <ScreenContainer >
        <Header />
        <Col middle height={bannerHeight}>
        <Image source={pageDataImages.length > 0 ? { uri: pageDataImages[0] } : ASSETS.BANNER_ACCOUNT} style={[{ height: bannerHeight }, styles.banner]} />
          <Col zIndex99 center>
            <Text fontSize={fSize(rV, 52)} colorWhite>Become a seller</Text>
            <Col alignSelf="center">
              <ICON.IcPattern />
            </Col>
          </Col>
        </Col>
        <Col style={{ minHeight: height - 300 }} middle marginVertical={rV({ xs: 16, lg: 30, xxxl: 40 })}>
          <Col width={rV({ xs: '90%', sm: '90%', md: '90%', lg: '90%', xl: '90%' })} >
            <Col >
              <Col style={[styles.contentBox, { paddingHorizontal: rV({ xs: 30, lg: 40, xxl: 50 }), paddingTop: 39, paddingBottom: rV({ xs: 40, xxxl: 65 }) }]} >
                <Text fontSize={fSize(rV, 30)} bold colorMain center>Do you want to become a seller/buyer?</Text>
                <Text paddingTop24 paddingBottom={rV({ xs: 30, md: 100, lg: 200, xxl: 419 })} fontSize={fSize(rV, 18)} center>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
                </Text>
              </Col>
            </Col>
          </Col>
        </Col>
        <Footer />
      </ScreenContainer>
      <ChatBox />
    </Col>
  );
};

BecomeASeller.routeInfo = {
  title: 'Become a Seller',
  path: '/become-a-seller',
};

const styles = StyleSheet.create({
  boxRightItem: {
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    shadowColor: 'rgba(0, 0, 0, 0.16)',
    shadowOffset: {
      width: 0,
      height: 3
    },
    shadowRadius: 6,
  },
  banner: {
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0
  },
  dot: {
    width: 8,
    height: 8,
    borderRadius: 4,
    backgroundColor: COLOR.MAIN,
    marginHorizontal: 10,
  },
  contentBox: {
    backgroundColor: COLOR.WHITE,
    borderColor: COLOR.BORDER,
    borderWidth: 1,
    borderTopLeftRadius: 10,
    borderBottomRightRadius: 10,
    overflow: 'hidden',
  },
  dropdown: {
    flex: 1,
    backgroundColor: COLOR.BG,
    color: COLOR.MAIN,
    padding: 0,
    justifyContent: 'center',
  }
})
export default BecomeASeller;


