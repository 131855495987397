import React, { useState, useEffect } from 'react';
import { COLOR } from 'const';
import { Col, Row, Text, Img } from 'react-quick-style-components';
import { Button } from 'components';
import Store from 'store';

interface IWPProduct {
  id: number,
  name: string,
  permalink: string,
  description: string,
  short_description: string,
  images: Array<{
    src: string,
  }>,
  price: string,
}

const VintageBottlersCollection = () => {
  const [{ invoices, listClients }, cApi] = Store.Client.createStore();
  const [listProducts, setListProducts] = useState<Array<IWPProduct>>([]);
  const [interest, setInterest] = useState();
  const [{ }, mApi] = Store.Message.createStore();
  const [{  name, firebaseUser }] = Store.User.createStore();

  useEffect(() => {
    const getData = async () => {
      const url = 'https://vintagebottlersltd.com/wp-json/wc/v3/products?consumer_key=ck_ae5d9ad28c8dc39418755d3951df641542bb1331&consumer_secret=cs_e88e562bf67e2f50ae24c1e726ccf154fa806ce0';
      const res = await fetch(url);
      const json = await res.json();
      if (Array.isArray(json)) setListProducts(json);
    };
    getData();
    cApi.getInvoices();
    cApi.getListClients();
  }, []);

  const myAM = listClients.length > 0 ? listClients[0]['Account Manager'] : '';

  const registerYourInterest = async value => {
    setInterest(value);
    await mApi.sendMessageToAdmin({
      message: 'Register Interest - ' + value,
      AM: myAM,
      name: firebaseUser.displayName || name,
    });
    alert('We have received your registed interest. We will be in touch soon. Thank you.');
  };
  return (
    <Row stretch flex1>
      <Col flex1 marginTop30>
        <Col paddingHorizontal={30}>
          <Text fontSize={20}>BOTTLE PORTFOLIO</Text>
          <Col marginTop={20}>
            {invoices.map((invoice, iI) => (
              <Col key={invoice.id}>
                {invoice.raw.lineItems.map((item, i) => (
                  <Text marginTop5>{item.description.split('\n').slice(0,2)}</Text>
                ))}
              </Col>
            ))}
          </Col>
          
        </Col>
      </Col>
      <Col flex1 borderLeftWidth={1} borderLeftColor={COLOR.BORDER} marginTop30>
        <Col paddingHorizontal={30}>
          <Text fontSize={20}>AVAILABLE PRODUCTS</Text>
          <Row
            xs='100%'
            md='1:.'
            marginLeft={-10}
            marginRight={-10}
            marginTop={20}
          >
            {listProducts.map((p, pI) => (
              <Col
                key={'p'+pI}
                width='100%'
                padding={10}
              >
                <Img
                  source={{ uri: p.images[0].src }}
                  style={{
                    width: '100%',
                    height: 300,
                  }}
                />
                <Col height={60}>
                  <Text center marginTop20 numberOfLines={2}>{p.name}</Text>
                </Col>
                <Text bold center marginTop5>£{p.price}</Text>
                <Col
                  style={{
                    marginTop: 20,
                    backgroundColor: '#bc9d72',
                    width: '50%',
                    alignSelf: 'center',
                    padding: 10,
                  }}
                  onPress={() => {
                    window.open(p.permalink, '_blank');
                  }}
                >
                  <Text colorWhite center>BUY NOW</Text>
                </Col>
              </Col>
            ))}
          </Row>
        </Col>
        <Row marginLeft={30} paddingLeft={30} backgroundColor='black' height={50} marginTop={20}>
          <Text colorWhite fontSize={20}>REGISTER YOUR INTEREST</Text>
        </Row>
        <Col marginLeft={30} paddingLeft={30}>
          <Row marginTop={20}>
            <Row flex1 height50
              onPress={() => registerYourInterest('Port Charlotte - 21 Years Old')}
            >
              <Col
                width35
                height35
                borderWidth2
                borderColor='#bc9d72'
                backgroundColor={interest === 'Port Charlotte - 21 Years Old' ? '#bc9d72' : undefined}
              />
              <Text marginLeft10>Port Charlotte - 21 Years Old</Text>
            </Row>
            <Row flex1 height50
              onPress={() => registerYourInterest('Macallan - 33 Years Old')}
            >
              <Col
                width35
                height35
                borderWidth2
                borderColor='#bc9d72'
                backgroundColor={interest === 'Macallan - 33 Years Old' ? '#bc9d72' : undefined}
              />
              <Text marginLeft10>Macallan - 33 Years Old</Text>
            </Row>
          </Row>
        </Col>
      </Col>
    </Row>
  );
};

export default VintageBottlersCollection;