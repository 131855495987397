import React, { useEffect, useState, useRef } from 'react';
import { Col, Text, Scroll, useDynamicResponsiveValue } from 'react-quick-style-components';
import { useNavFunc } from 'navigation';
import { IScreen } from 'type';
import { Table, TableWithAction, Tabs, CaskIOwn, Breadcrumbs } from 'components';
import { ASSETS, COLOR, fSize, ICON, SCREEN_NAME } from 'const';
import { Image, StyleSheet, useWindowDimensions } from 'react-native';
import { Footer, Header, Card, Input, ChatBox } from 'components';
import { VarHelper } from 'helpers';
import Store from 'store'

const CasksIOwn: IScreen = () => {
  const { reset } = useNavFunc();
  // const { tab } = route.params || {};
  // const { width, height } = useWindowDimensions();
  // const rV = useDynamicResponsiveValue()
  // const bannerHeight = rV({ xs: 150, lg: 200, xxxl: 262 })
  // const [{ listClients }, cApi] = Store.Client.createStore();
  // const [{ listPages }, iApi] = Store.Info.createStore();

  // useEffect(() => {
  //   iApi.getListPages();
  //   cApi.getListClients();
  // }, []);

  // const chartRef = useRef<{ setActiveTab: Function }>();

  // useEffect(() => {
  //   if (tab && chartRef.current?.setActiveTab) chartRef.current?.setActiveTab(tab);
  // }, [tab]);

  // const pageData = listPages.find(val => val.Title === 'Casks I Own');
  // const pageDataImages = VarHelper.imgApiStringifyGetAll(pageData?.Images);

  // const ScreenContainer = React.Fragment;

  useEffect(() => {
    reset(SCREEN_NAME.MyPlan);
  }, [])

  return null;

  // return (
  //   <Col flex1 backgroundColor={COLOR.BG}>
  //     <ScreenContainer >
  //       <Header />
  //       <Col middle height={bannerHeight}>
  //       <Image source={pageDataImages.length > 0 ? { uri: pageDataImages[0] } : ASSETS.BANNER_ACCOUNT} style={[{ height: bannerHeight }, styles.banner]} />
  //         <Col zIndex99 center>
  //           <Text fontSize={fSize(rV, 52)} colorWhite>Casks I Own</Text>
  //           <Col alignSelf="center">
  //             <ICON.IcPattern />
  //           </Col>
  //         </Col>
  //       </Col>
  //       <Col style={{ minHeight: height - 300 }} marginVertical={rV({ xs: 16, lg: 30, xxxl: 40 })} paddingHorizontal={rV({ xs: 4, lg: 20, xxxl: 40 })}>
  //         <Breadcrumbs title="Casks I Own" style={{ marginLeft: 10, marginBottom: 20, }} />
  //         <CaskIOwn ref={chartRef} />
  //       </Col>
  //       <Footer />
  //     </ScreenContainer>
  //     <ChatBox />
  //   </Col>
  // );
};

CasksIOwn.routeInfo = {
  title: 'Casks I Own',
  path: '/casks-i-own',
};

const styles = StyleSheet.create({
  banner: {
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0
  },
})

export default CasksIOwn;