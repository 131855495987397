import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Col, Text, Scroll, useDynamicResponsiveValue, Row, Img } from 'react-quick-style-components';
import { useNavFunc, Reset } from 'navigation';
import { ILocalFileUpload, IScreen } from 'type';
import { ASSETS, COLOR, fSize, ICON, SCREEN_NAME } from 'const';
import { Image, StyleSheet } from 'react-native';
import { Footer, Header, Button, Uploader } from 'components';
import * as Animatable from 'react-native-animatable';
import GlobalEvent from 'js-events-listener';
import Store from 'store';
import { TimeHelper, VarHelper } from 'helpers';
import XLSX from 'xlsx';
import moment from 'moment';

// const Excel = require('exceljs/dist/es5');

const ImportData: IScreen = () => {
  const { route, navigate } = useNavFunc();
  const rV = useDynamicResponsiveValue()

  const [{ user, token, email }] = Store.User.createStore();
  const [{ }, cApi] = Store.Client.createStore();
  const [localFile, setLocalFile] = useState<ILocalFileUpload>();
  const uploadRef = useRef<{ openFileDialog: Function }>();

  const downloadSampleExcel = () => {
    window.open('https://docs.google.com/spreadsheets/d/1BxLSmLmTrhYKPNHUBvxJq8J4xFACBtocmsZp-126z-s/edit#gid=0', '_blank');
  }

  const startImport = useCallback(async () => {
    // const binary = await filePickerRef.current?.openDialogAndWaitForBinary();
    const getBinary = () => new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.onload = function (e) {
        // console.log('onload')
        var data = e.target.result;
        resolve(data);
      };
      reader.onerror = function (ex) {
        reject(ex);
      };
      reader.readAsBinaryString(localFile.raw as any);
    });

    const binary = await getBinary();
    const workbook = new window.ExcelJS.Workbook();
    await workbook.xlsx.load(binary);
    var worksheet = workbook.worksheets[0];
    let items = [];

    const getCellValueFromRow = (rowData) => (column, log = false) => {
      const value = rowData.getCell(column).value;
      const hyperlink = rowData.getCell(column).hyperlink;
      if (log) {
        console.log(rowData.getCell(column));
      }
      if (!value) return '';
      if (!!hyperlink) return value.hyperlink;
      if (typeof value === 'string' || typeof value === 'number') return value;
      if (!!value.formula && value.formula.indexOf('hyperlink') === 0) {
        const reg = /hyperlink\(".*",/g;
        const match = value.formula.match(reg);
        if (match && match[0]) {
          const foo = match[0].slice("hyperlink(\"".length, match[0].length)
          const bar = foo.slice(0, foo.length -2);
          return bar;
        }
      }
      if (!!value.formula && !!value.result) return value.result;
      return '';
    }

    const columns = []; // { number, name }

    for (let row = 1; row < 1500; row++) {
      const rowData = worksheet.getRow(row);
      const getCellValue = getCellValueFromRow(rowData);

      if (row === 1) {
        let j = 1;
        while(!!getCellValue(j)) {
          columns.push({ number: j, name: getCellValue(j) });
          j++;
        }
        continue;
      }

      const item = {};

      columns.forEach(val => {
        // const log = val.name === "Docs";
        const log = false;
        item[val.name] = getCellValue(val.number, log);
      });

      items.push(item);
    }

    items = items.filter(val => !!val['Spirit']);

    console.log('items', items);

    items.forEach((val, i) => {
      if (!val.Name && (items[i-1] && items[i-1].Name)) {
        items[i].Name = items[i-1].Name;
        items[i].Address = items[i-1].Address;
        items[i].Telephone = items[i-1].Telephone;
        items[i].Email = items[i-1].Email;
        items[i].AM = items[i-1].AM;
        items[i]['Portfolio Value'] = items[i-1]['Portfolio Value'];
        items[i]['Tier'] = items[i-1]['Tier'];
        items[i]['Bcknd %'] = items[i-1]['Bcknd %'];
        items[i]['Mmbshp No'] = items[i-1]['Mmbshp No'];
      }
    });

    console.log('items', items);

    const [res, err] = await cApi.importData(items.map(val => {
      return {
        "Customer": val.Name,
        "Address": val.Address,
        "Telephone": val.Telephone,
        "Email": val.Email,
        "AM": val.AM,
        "Portfolio": String(val['Portfolio Value']),
        "Tier": val.Tier,
        "Bcknd": val['Bcknd %'],
        "Mmbshp No": val["Mmbshp No"],
        "KYC": val["KYC"],
        "Split": val["Spirit"],
        "Distillery": val["Distillery"],
        "Cask Type": val["Cask Type "],
        "Cask No": val["Cask No. "],
        "Region": val.Region,
        "Purchased Date": val["Purchased Date"],
        "AYS": String(val.AYS),
        "ABV": typeof val.ABV === 'number' ? `${(val.ABV * 100).toFixed(2)} %` : val.ABV,
        "OLA/RLA": val["OLA/RLA"],
        "Latest Regauge": val["Latest Regauge"],
        "Currently Stored": val["Currently Stored "],
        "Unit Price": String(val['Unit Price']),
        "Docs": val["Docs"],
        "Sample Requests": val[ "Sample Requests"],
        "Notes": val["Notes"],
        "HC Certs sent": val["HC Certs sent"],
      };
    }));
    if (err) return alert(String(err));
    if (res.msg) return alert(String(res.msg));
    return alert('Successfully executed');

  }, [localFile]);

  if (!token || email !== 'hb@devserver.london') return (
    <Reset to={SCREEN_NAME.Login} timeout={0} />
  )

  return (
    <Col flex1 backgroundColor={COLOR.BG}>
      <Header />
      <Scroll flex1 >
        <Col flex1 middle>
          <Text>Download sample excel file: <Text bold onPress={downloadSampleExcel}>Book1.xlsx</Text></Text>

          <Button
            title="Upload excel file"
            preset='white' paddingHorizontal15 marginTop20
            onPress={() => uploadRef.current?.openFileDialog()}
          />

          {Boolean(localFile && localFile.path) && (
            <Button
              title={"Import " + localFile.name}
              preset='main' paddingHorizontal15 marginTop20
              onPress={startImport}
            />
          )}

          <Uploader ref={uploadRef} onChangeLocal={setLocalFile} />
        </Col>
      </Scroll>
    </Col>
  );
};

ImportData.routeInfo = {
  title: 'Import Data',
  path: '/import-data',
};

const styles = StyleSheet.create({
  banner: {
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0
  },
})

export default ImportData;