import React, { useState } from 'react';
import { Col, Row, Scroll, Text, useDynamicResponsiveValue, usePropsStyle } from 'react-quick-style-components';
import { StyleSheet, ViewStyle, Platform, TextStyle } from 'react-native';
import { COLOR, fSize } from 'const';
import { useWindowDimensions } from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import Tooltip from './Tooltip';

(() => {
  if (Platform.OS !== 'web') return;
  const style = document.createElement('style')
  style.textContent = `
    ::-webkit-scrollbar {
      height: 5px;
    }
    *:hover::-webkit-scrollbar {
      height: 10px;
    }
  `;
  document.head.append(style);
})();

export interface IColProps {
  key: string,
  title?: string,
  flex?: number, width?: number, padding?: number, center?: boolean, noLeftBorder?: boolean, justifyContent?: string,
  tooltip?: string,
  showInDetail?: boolean,
}

interface ITableProps {
  columns: Array<IColProps>,
  data: Array<{ [key: string]: any }>,
  style?: ViewStyle,
  breakpointToScroll?: 'sm' | 'md' | 'lg' | 'xl' | 'all',
  titleStyle?: TextStyle,
  withoutNumberColumn?: boolean,
  [anyProp: string]: any,
}

const WIDTHS = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  all: 3000,
}

const Table = (props: ITableProps) => {
  const propStype = usePropsStyle(props);
  const { width } = useWindowDimensions();
  const rV = useDynamicResponsiveValue();
  const breakpoint = rV({ xs: 'xs', md: 'md' });
  const { columns, data } = props;
  const [activeIndex, setActiveIndex] = useState(-1);

  const isScroll = props.breakpointToScroll && width < WIDTHS[props.breakpointToScroll];
  const hasMoreButton = columns.some(v => v.showInDetail);

  const textStyle = {
    fontSize: 15,
    letterSpacing: 0.3,
  }

  const mainContent = () => {
    return (
      <Col style={!isScroll ? [styles.container, propStype] : styles.container}>
        <Row backgroundColor={'#171C27'} paddingVertical={fSize(rV, 10)} paddingHorizontal={fSize(rV, 10)} stretch>
          {!props.withoutNumberColumn && (
            <Col
              width={59}
              borderRightWidth={1}
              borderColor={COLOR.WHITE} paddingHorizontal={5}
              middle justifyContent={'flex-start'}
            >
              <Text center color="white" {...textStyle} style={props.titleStyle}>No.</Text>
            </Col>
          )}
          {columns.slice(0, breakpoint === 'xs' ? 3 : columns.length).filter(v => !v.showInDetail).map((col, i) => (
            <Col
              width={col.width} flex={col.flex} key={`col${i}`} paddingVertical={5}
              borderLeftWidth={i === 0 ? 0 : 1}
              // borderLeftWidth={0} 
              borderColor={COLOR.WHITE} paddingHorizontal={col.padding || 5}
              middle={col.center} justifyContent={col.justifyContent}
            >
              {col.tooltip ? <Tooltip title={col.tooltip} >
                <Text {...textStyle} center color="white" style={props.titleStyle}>{col.title || ''}</Text>
              </Tooltip> : (
                <Text {...textStyle} center color="white" style={props.titleStyle}>{col.title || ''}</Text>
              )}
            </Col>
          ))}
          {hasMoreButton && (
            <Col
              width={59}
              paddingHorizontal={5}
              borderLeftWidth={1}
              borderColor={COLOR.WHITE}
              middle justifyContent={'flex-start'}
            >
              <Text {...textStyle} center color="white" style={props.titleStyle}>More</Text>
            </Col>
          )}

        </Row>
        {data.map((row, i) => (
          <Col
            key={`row${i}`}
            backgroundColor={i % 2 === 0 ? 'white' : 'rgba(0,0,0,0.05)'}
          >
            <Row
              paddingVertical={fSize(rV, 10)} marginHorizontal={fSize(rV, 10)}
              borderTopWidth={i !== 0 && 1}
              // borderTopWidth={0}
              borderColor={'rgba(0,0,0,0.05)'} stretch
            >
              {!props.withoutNumberColumn && (
                <Col
                  width={59}
                  borderRightWidth={0}
                  borderColor={COLOR.MAIN} paddingHorizontal={5}
                  middle
                >
                  <Text {...textStyle} bold>{i + 1}</Text>
                </Col>
              )}
              {columns.slice(0, breakpoint === 'xs' ? 3 : columns.length).filter(v => !v.showInDetail).map((col, j) => (
                <Col
                  width={col.width} flex={col.flex} key={`cel${i}${j}`} paddingVertical={5}
                  borderLeftWidth={(!col.noLeftBorder && j !== 0) && 1}
                  // borderLeftWidth={0}
                  borderColor={'rgba(0,0,0,0.1)'} paddingHorizontal={col.padding || 5}
                  middle={col.center}
                >
                  <Text {...textStyle} center={col.center}>{typeof row[col.key] === 'function' ? row[col.key]() : row[col.key]}</Text>
                </Col>
              ))}
              {hasMoreButton && (
                <Col
                  width={59}
                  borderRightWidth={0}
                  borderColor={COLOR.MAIN} paddingHorizontal={5}
                  middle
                  onPress={() => setActiveIndex(activeIndex === i ? -1 : i)}
                >
                  <Col width={30} height={30} middle borderWidth={1} borderColor={COLOR.FONT}>
                    <AntDesign name={activeIndex === i ? 'minus' : 'plus'} size={16} color="black" />
                  </Col>
                </Col>
              )}

            </Row>
            {activeIndex === i && (
              <Row
                paddingVertical={fSize(rV, 10)} marginHorizontal={fSize(rV, 10)}
                borderTopWidth={activeIndex === i ? 1 : (i !== 0 && 1)}
                // borderTopWidth={0}
                borderColor={'rgba(0,0,0,0.05)'} stretch
                backgroundColor={i % 2 === 0 ? 'white' : 'rgba(1,1,1,0.005)'}
                flexWrap={'wrap'}
                style={{
                  flexWrap: 'wrap',
                }}
              >
                {columns.filter((v, vI) => breakpoint === 'xs' ? vI > 3 : !!v.showInDetail).map((col, j) => {
                  return (
                    <Row width={breakpoint === 'xs' ? '95%' : '30%'} alignItems='flex-start' key={`sub-key-${j}`} margin={5} padding={5}>
                      <Text {...textStyle} bold>{col.title}:</Text>
                      <Text {...textStyle} letterSpacing={0.5} marginLeft={5}>{typeof row[col.key] === 'function' ? row[col.key]() : row[col.key]}</Text>
                    </Row>
                  )
                })}
              </Row>
            )}
          </Col>
        ))}
      </Col>
    )
  }
  if (props.breakpointToScroll && width < WIDTHS[props.breakpointToScroll]) {
    return (
      <Scroll horizontal style={isScroll ? propStype : undefined}>
        {mainContent()}
      </Scroll>
    );
  } else {
    return mainContent();
  }
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    // borderRadius: 10,
    overflow: 'hidden',
    borderColor: 'rgba(112, 112, 112, 0.3)',
    borderWidth: 1,
  },
});

export default Table;
