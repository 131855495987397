import { VarHelper } from 'helpers';
import React, { useRef, useState, forwardRef, useImperativeHandle } from 'react';
import { ILocalFileUpload } from 'type';

interface IUploaderProps {
  onChangeLocal(file: ILocalFileUpload): void,
}

const Uploader = forwardRef((props : IUploaderProps, ref) => {
  const inputRef = useRef(null);

  const convertToUrl = (file) : Promise<string> => new Promise((resolve) => {
    var reader = new FileReader();
    reader.onload = function (e) {
      resolve(e.target.result as string);
    }
    reader.readAsDataURL(file);
  });

  const onChange = async e => {
    const file = e.target.files[0];
    if (!file) return;
    const dataUrl = await convertToUrl(file);
    props.onChangeLocal({
      path: dataUrl,
      mime: file.type,
      name: file.name,
      raw: file,
    });
  };

  const openFileDialog = () => !!inputRef.current && inputRef.current.click();

  const uploadLocal = async ({ path, mime, name, raw }) => {
    return VarHelper.erria(async () => {
      if (!raw && !path) return '';
      if (!raw && !!path && path.includes('https://')) return path;
      
    });
  };

  useImperativeHandle(ref, () => ({
    openFileDialog,
    uploadLocal,
  }));

  return (
    <input type="file" style={{ display: 'none' }} ref={inputRef} onChange={onChange} />
  );
});

export default Uploader;