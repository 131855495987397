import { COLOR, fSize } from 'const';
import React from 'react';
import { Col, Row, Text, useDynamicResponsiveValue, usePropsStyle } from 'react-quick-style-components';

interface ITabsProps {
  // props here
  tabs: Array<{ id: string, title: string }>,
  selected: string,
  onChange(id: string): void,
  [anyProp: string]: any,
}

const Tabs = (props: ITabsProps) => {
  const propStype = usePropsStyle(props);
  const rV = useDynamicResponsiveValue();
  let PADDING_UNIT = rV({ xs: 10, lg: 15 })
  return (
    <Col style={propStype}>
      <Row style={{ flexWrap: 'wrap' }}>
        {props.tabs.map((tab, i) => {
          let _selected = props.selected === tab.id;
          return (
            <Col
              borderRadius10 paddingHorizontal={PADDING_UNIT * 2} paddingVertical={PADDING_UNIT} marginLeft20 marginBottom10
              backgroundColor={!_selected ? COLOR.MAIN : COLOR.WHITE} key={`tab${i}`} style={_selected ? styles.shadow : undefined}
              onPress={() => props.onChange(tab.id)}
              onHoverStyle={styles.shadow}
            >
              <Text
                bold fontSize={fSize(rV, 16)}
                color={!_selected ? COLOR.WHITE : COLOR.MAIN}
              >{tab.title}</Text>
            </Col>
          )
        })}
      </Row>
    </Col>
  );
};

const styles = {
  shadow: {
    shadowColor: '#000000',
    shadowOffset: {
      width: 0,
      height: 3
    },
    shadowRadius: 6,
  }
}
export default Tabs;
