import React, { useEffect, useState } from 'react';
import { Col, Text, Scroll, useDynamicResponsiveValue } from 'react-quick-style-components';
import { useNavFunc } from 'navigation';
import { IScreen } from 'type';
import { Table, TableWithAction, Tabs } from 'components';
import { ASSETS, COLOR, fSize, ICON, SCREEN_NAME } from 'const';
import { Image, StyleSheet, useWindowDimensions } from 'react-native';
import { Footer, Header, Card, Input, ChatBox } from 'components';
import { VarHelper } from 'helpers';
import Store from 'store'

const Auctions: IScreen = () => {
  const { route } = useNavFunc();
  const [tab, selectTab] = useState('1');
  const { width, height } = useWindowDimensions();
  const rV = useDynamicResponsiveValue()
  const bannerHeight = rV({ xs: 150, lg: 200, xxxl: 262 })

  const [{ listPages }, iApi] = Store.Info.createStore();

  useEffect(() => {
    iApi.getListPages();
  }, []);

  const pageData = listPages.find(val => val.Title === 'Auctions');
  const pageDataImages = VarHelper.imgApiStringifyGetAll(pageData?.Images);

  const renderTab = () => {
    // check selected tab here
    return (
      <Card title="Auctions">
        <TableWithAction
          columns={[
            { key: 'name', title: 'Lorem', flex: 0.5 }, // center: true
            { key: 'address', title: 'LOREM IPSUM', flex: 2, padding: 20 },
            { key: 'foo', title: 'LOREM IPSUM', flex: 0.8, padding: 20 },
            { key: 'foo', title: 'LOREM IPSUMne', flex: 0.8, padding: 20 },
            { key: 'bar', title: 'LOREM IPSUM', flex: 1 },
          ]}
          data={[
            { name: 'Lorem ipsum', address: 'Lorem ipsumLorem ipsumLorem ipsumLorem ipsum', foo: 'Lorem ipsum', bar: 'Lorem ipsumLorem ipsumLorem ipsum' },
            { name: 'Lorem ipsum', address: 'Lorem ipsumLorem ipsumLorem ipsumLorem', foo: 'Lorem ipsum', bar: 'Lorem ipsumLorem ipsumLorem' },
            { name: 'Lorem ipsum', address: 'Lorem ipsumLorem ipsumLorem', foo: 'Lorem', bar: 'Lorem ipsumLorem ipsum' },
            { name: 'Lorem ipsum', address: 'Lorem ipsumLorem ipsumLorem ipsumLorem ipsum', foo: 'Lorem ipsum', bar: 'Lorem ipsumLorem ipsumLorem ipsum' },
          ]}
          onPressAction={(item, i) => alert(i)}
          breakpointToScroll="sm"
          style={{ margin: 20 }}
        />
      </Card>
    )
  }

  const renderContainer = ({ children }) => {
    return (
      <Col>
        <Scroll height={height}>
          {children}
        </Scroll>
      </Col>
    );
  };

  const ScreenContainer = React.Fragment;

  return (
    <Col flex1 backgroundColor={COLOR.BG}>
      <ScreenContainer >
        <Header />
        <Col middle height={bannerHeight}>
        <Image source={pageDataImages.length > 0 ? { uri: pageDataImages[0] } : ASSETS.BANNER_ACCOUNT} style={[{ height: bannerHeight }, styles.banner]} />
          <Col zIndex99 center>
            <Text fontSize={fSize(rV, 52)} colorWhite>Auctions</Text>
            <Col alignSelf="center">
              <ICON.IcPattern />
            </Col>
          </Col>
        </Col>
        <Col style={{ minHeight: height - 300 }} marginVertical={rV({ xs: 16, lg: 30, xxxl: 40 })} paddingHorizontal={rV({ xs: 4, lg: 20, xxxl: 40 })}>
          <Tabs
            tabs={[{ id: '1', title: 'AUCTIONS' }, { id: '2', title: 'PAST AUCTIONS' }]}
            selected={tab}
            marginBottom={fSize(rV, 40)}
            onChange={selectTab}
          />
          {renderTab()}
        </Col>
        <Footer />
      </ScreenContainer>
      <ChatBox />
    </Col>
  );
};

Auctions.routeInfo = {
  title: 'Auctions',
  path: '/auctions',
};

const styles = StyleSheet.create({
  banner: {
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0
  },
})

export default Auctions;