import React, { useEffect, useRef } from 'react';
import Store from 'store';
import _ from 'lodash';

const HeatMap = () => {
  const [{ }, cApi] = Store.Client.createStore();
  const [{ }, iApi] = Store.Info.createStore();

  const listClientsRef = useRef([]);
  const listCacheLocationRef = useRef([]);

  useEffect(() => {
    Promise.all([
      cApi.getListAllClients(),
      cApi.getListCacheLocation(),
    ]).then(([listClients, listCacheLocation]) => {
      listClientsRef.current = listClients[0];
      listCacheLocationRef.current = listCacheLocation[0];
      generateHeatMap();
    });
  }, []);

  const getAccessPoints = async () => {
    const listAddress = _.uniqWith(listClientsRef.current.filter(val => !!val).map(val => val['Post Code'], _.isEqual));
    const listCache = listCacheLocationRef.current;
    const list = [];
    const toCreate = [];
    const promiseArr = listAddress.map(async(val) => {
      if (!val) return;
      if (!val.trim()) return;
      try {
        const findExistingLocation = listCache.find(item => item['Address'] === val);
        if (findExistingLocation) {
          list.push([
            +findExistingLocation['latitude'],
            +findExistingLocation['longitude'],
          ])
        } else {
          const [res, err] = await iApi.convertTextAddressToLatLong(val);
          if (!!res && !!res['latitude']) {
            list.push([
              +res['latitude'],
              +res['longitude'],
            ])
            toCreate.push({
              Address: val,
              latitude: String(res['latitude']),
              longitude: String(res['longitude']),
            });
          }
        }
      } catch(err) {}
    })
    await Promise.all(promiseArr);
    iApi.addCacheLocation(toCreate);
    return list;
  };

  const generateHeatMap = async () => {
    const L = window.L;
    var map = L.map('map').setView([51.61519, 0.184997], 6);
    var tiles = L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(map);
    const addressPoints = await getAccessPoints();
    console.log('addressPoints', addressPoints);
    var heat = L.heatLayer(addressPoints).addTo(map);
    console.log(heat);
  };

  return (
    <>
      <div id="map" style={{ height: '100%' }}>
        
      </div>
    </>
  );
};

export default HeatMap;