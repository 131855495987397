import React, { useEffect, useState, useRef } from 'react';
import { Col, Text, Row, useDynamicResponsiveValue } from 'react-quick-style-components';
import { useNavFunc, Reset } from 'navigation';
import { IScreen, ILocalFileUpload } from 'type';
import { HeatMap, Table, TableWithAction, Tabs } from 'components';
import { ASSETS, COLOR, fSize, ICON, SCREEN_NAME } from 'const';
import { Image, StyleSheet, useWindowDimensions } from 'react-native';
import { Footer, Header, Button, Input, ChatBox, Uploader, Dropdown, UploaderMultiple } from 'components';
import { VarHelper } from 'helpers';
import { TSingleOption } from 'components/Dropdown';
import Store from 'store'

const SPACE_UNIT = 50;

const AdminUploadAssets: IScreen = () => {
  const [{ firebaseUser }, uApi] = Store.User.createStore();
  const rV = useDynamicResponsiveValue()

  const [email, setEmail] = useState('');
  const [type, setType] = useState<TSingleOption>();
  const uploadRef = useRef<{ openFileDialog: Function }>();
  const [localFiles, setLocalFiles] = useState<Array<ILocalFileUpload>>([]);
  const [loading, setLoading] = useState(false);

  const ScreenContainer = React.Fragment;

  if (!firebaseUser || !firebaseUser.email) {
    return (
      <Reset to={SCREEN_NAME.Home} />
    )
  }

  const uploadSingle = async (localFile : ILocalFileUpload) => {
    const serverSidePath = `be-files/${email}/${type.value}/${localFile.name.replace(/\s/g, '_')}`;
    const [res, err] = await uApi.getUploadUrl({
      contentType: localFile.mime,
      serverSidePath,
    });
    if (err) throw err;
    if (res.error) throw res.error;
    // const binary = await getBinary(localFile.raw);
    const [res2, err2] = await uApi.performUpload({
      signedUrl: res.data,
      contentType: localFile.mime,
      file: localFile.raw,
    });
    if (err2) throw err2;
    const [res3, err3] = await uApi.addAssetRecord({
      email: email.toLowerCase(),
      type: type.value,
      path: serverSidePath,
    });
    if (err3) throw err3;
  }

  const startImport = async () => {
    // const getBinary = (raw) => new Promise((resolve, reject) => {
    //   var reader = new FileReader();
    //   reader.onload = function (e) {
    //     // console.log('onload')
    //     var data = e.target.result;
    //     resolve(data);
    //   };
    //   reader.onerror = function (ex) {
    //     reject(ex);
    //   };
    //   reader.readAsBinaryString(raw as any);
    // });
    
    if (!email) return alert('Please input the client email');
    if (!type) return alert('Please input the asset type');

    setLoading(true);
    const onEnd = () => {
      setLoading(false);
      return true;
    };
    
    for (let i = 0; i< localFiles.length; i++) {
      try {
        await uploadSingle(localFiles[i]);
      } catch(err) {
        alert(err);
        onEnd();
        return;
      }
      
    }

    onEnd();
    alert('The files are uploaded');
    setEmail('');
    setType(undefined);
    setLocalFiles([]);
  }

  const removeFile = i => {
    setLocalFiles(localFiles.filter((val, index) => index !== i));
  }

  return (
    <Col flex1 backgroundColor={COLOR.BG}>
      <ScreenContainer >
        <Header />
        <Col height100p middle>
          <Col width300>
            <Input label="Email Address"
              value={email} onChangeText={(text) => setEmail(text)}
            />

            <Button
              title="Choose file"
              preset='white' paddingHorizontal15 marginTop20
              onPress={() => uploadRef.current?.openFileDialog()}
            />

            <Dropdown
              marginTop20
              value={type}
              placeholder="Type of asset"
              options={
                [
                  // { value: 'doc', label: 'Document' },
                  { value: 'cert', label: 'Certificate' },
                ]}
              onChange={(data) => setType(data)}
            />

            {Boolean(localFiles.length > 0) && (
              <Col>
                {localFiles.map((file, i) => (
                  <Row justifyContent="space-between" marginBottom10>
                    <Text>{file.name}</Text>
                    <Col onPress={() => removeFile(i)}>
                      <ICON.Close fill="black" width={12} height={12} />
                    </Col>
                  </Row>
                  
                ))}
                <Button
                  title={loading ? "Uploading.." : "Upload"}
                  preset='main' paddingHorizontal15 marginTop20
                  onPress={startImport}
                />
              </Col>
            )}

            <UploaderMultiple ref={uploadRef} onChangeLocal={setLocalFiles} />
          </Col>
        </Col>
        <Footer />
      </ScreenContainer>
      <ChatBox />
    </Col>
  );
};

AdminUploadAssets.routeInfo = {
  title: 'Upload Assets',
  path: '/admin/upload-assets',
};

export default AdminUploadAssets;